import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const CHANGE_RESOURCE_USER_TASK_ASSIGNMENT_SUMMARY = gql`
  query getChangeResourceUserTaskAssignmentSummary(
    $projectId: String!
    $existingResourceUserId: String!
    $newResourceUserId: String!
    $asOfDate: Date2
    $filter: GetResourceUsersFilter!
  ) {
    resourceUsers2(filter: $filter) {
      id
      changeResourceUserTaskAssignmentSummary(
        projectId: $projectId
        existingResourceUserId: $existingResourceUserId
        newResourceUserId: $newResourceUserId
        asOfDate: $asOfDate
      ) {
        hasTaskAssignmentConflict
      }
    }
  }
`;

const useChangeResourceUserTaskAssignmentSummary = ({
  asOfDate,
  existingResourceUserId,
  newResourceUserId,
  projectId,
  skip
}) => {
  const { data, loading } = useQuery(
    CHANGE_RESOURCE_USER_TASK_ASSIGNMENT_SUMMARY,
    {
      variables: {
        asOfDate,
        projectId,
        existingResourceUserId,
        newResourceUserId,
        filter: { userIds: [existingResourceUserId] }
      },
      fetchPolicy: 'cache-and-network',
      skip
    }
  );

  return {
    hasTaskAssignmentConflict:
      data?.resourceUsers2?.[0]?.changeResourceUserTaskAssignmentSummary
        .hasTaskAssignmentConflict,
    isLoading: loading
  };
};

export default useChangeResourceUserTaskAssignmentSummary;
