import React from 'react';
import PropTypes from 'prop-types';
import { tabs } from './ResourceUsersDropdownTabs';
import ResourceUsersDropdownSkeleton from './ResourceUsersDropdownSkeleton';
import AvailabilityListSubheader from './AvailabilityListSubheader';

export const ResourceUsersAvailabilityDropdownItem = ({
  params,
  classes,
  isAvailabilityEnabled,
  hideAvailability,
  sort,
  onSortChange,
  toggleAvailabilityColumnVisibility,
  loading,
  selectedTab,
  isProjectRoleEnabled,
  assignedRole,
  tabClasses,
  handleTabChange
}) => {
  if (loading && params.key === 0) {
    return (
      <>
        <div className={classes.groupUl}>{params.children}</div>
        <ResourceUsersDropdownSkeleton
          isAvailabilityEnabled={isAvailabilityEnabled}
          classes={classes}
          hideAvailability={hideAvailability}
          selectedTab={selectedTab}
          assignedRole={assignedRole}
          isProjectRoleEnabled={isProjectRoleEnabled}
          sort={sort}
          onSortChange={onSortChange}
          toggleAvailabilityColumnVisibility={
            toggleAvailabilityColumnVisibility
          }
          tabClasses={tabClasses}
          handleTabChange={handleTabChange}
        />
      </>
    );
  }

  return (
    !loading &&
    (params.group ? (
      <>
        <AvailabilityListSubheader
          sort={sort}
          onSortChange={onSortChange}
          isAvailabilityEnabled={isAvailabilityEnabled}
          showAvailability={!hideAvailability && params.key === 1}
          toggleAvailabilityColumnVisibility={
            toggleAvailabilityColumnVisibility
          }
          group={params.group}
          classes={classes}
        />
        <div className={classes.groupUl}>{params.children}</div>
      </>
    ) : (
      <div className={classes.groupUl}>{params.children}</div>
    ))
  );
};

ResourceUsersAvailabilityDropdownItem.propTypes = {
  params: PropTypes.object,
  classes: PropTypes.object,
  sort: PropTypes.object.isRequired,
  onSortChange: PropTypes.func.isRequired,
  isAvailabilityEnabled: PropTypes.bool,
  hideAvailability: PropTypes.bool,
  toggleAvailabilityColumnVisibility: PropTypes.func,
  loading: PropTypes.bool,
  selectedTab: PropTypes.oneOf([tabs.ALL_RESOURCES, tabs.PROJECT_RESOURCES]),
  isProjectRoleEnabled: PropTypes.bool,
  assignedRole: PropTypes.object,
  tabClasses: PropTypes.object,
  handleTabChange: PropTypes.func
};

export default ResourceUsersAvailabilityDropdownItem;
