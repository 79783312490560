import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  CreatableDropdownItem,
  NoneDropdownItem,
  SimpleAutocomplete,
  useOptionsMerge,
  useInputClasses
} from '~/modules/common/components/SearchAutocomplete';
import {
  useAdditionalOptions,
  NO_CHANGE_OPTION_ID,
  NO_VALUE_OPTION_ID
} from '~/modules/common/hooks';

const renderOption = option => {
  if (option.id === NO_CHANGE_OPTION_ID || option.id === NO_VALUE_OPTION_ID)
    return <NoneDropdownItem value={option.displayText} />;

  return <CreatableDropdownItem option={option} optionPropText="displayText" />;
};

export const CostTypeDropdown = ({
  variant = 'filled',
  value,
  onChange,
  formControlMargin,
  dataQeId,
  enableNoChangeOption = false,
  enableNoValueOption = false
}) => {
  const classes = useInputClasses({ value });
  const intl = useIntl();
  const additionalOptions = useAdditionalOptions({
    enableNoChangeOption,
    enableNoValueOption,
    noValueMessageId: 'dropdownItem.unClassified'
  });

  const costTypeOptions = [
    'urn:replicon:cost-type:capital',
    'urn:replicon:cost-type:operational'
  ];

  const options = costTypeOptions.map(key => ({
    id: key,
    displayText: intl.formatMessage({ id: `projectFinancialsCard.${key}` })
  }));

  const mergedOptions = useOptionsMerge({ options, additionalOptions });

  const selectedValue = useMemo(
    () => (value ? mergedOptions.find(x => x.id === value.id) : null),
    [mergedOptions, value]
  );

  return (
    <SimpleAutocomplete
      classes={classes}
      variant={variant}
      options={mergedOptions}
      value={selectedValue}
      onChange={onChange}
      optionPropText="displayText"
      inputLabel={intl.formatMessage({ id: `projectFinancialsCard.costType` })}
      formControlMargin={formControlMargin}
      dataQeId={dataQeId}
      renderOption={additionalOptions.length > 0 ? renderOption : null}
    />
  );
};

CostTypeDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  formControlMargin: PropTypes.string,
  dataQeId: PropTypes.string,
  variant: PropTypes.string,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool
};

export default CostTypeDropdown;
