/* eslint-disable react/jsx-max-depth */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Waypoint } from 'react-waypoint';
import { FileAttachment } from '~/modules/attachments/AttachmentsBlock';
import { CUSTOM_METADATA_BUILDER } from '~/modules/attachments/uploader/attachmentUtil';
import { CalendarLoading } from '~/modules/common/components/DetailsPage';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import {
  useIsBreakpointDown,
  useEditableState,
  useHasFeatureFlag,
  useIsDefaultEdit
} from '~/modules/common/hooks';
import { usePageTitle } from '~/modules/common/title';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';
import ResourceDetailsHeader from '../ResourceDetailsHeader';
import useResourceDetails from '../enhancers/useResourceDetails';
import ResourceReadOnlyCard from './ResourceReadOnlyCard';
import { Certificates } from './Certificates';
import RolesInfo from './Role/RolesInfo';
import SkillsInfo from './Skills/SkillsInfo';
import ResourceCalendarCard from './ResourceCalendar/ResourceCalendarCard';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minHeight: '-webkit-max-content'
  },
  others: {
    width: '100%',
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0, 0, 0)
    }
  },
  userInfo: {
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexBasis: 200
    }
  },
  calendarLoading: { marginBottom: theme.spacing(2) }
}));

export const ResourceUserDetails = ({
  showBackButton = true,
  canEditPreferences = true,
  userSlug
}) => {
  const classes = useStyles();

  const isDefaultEdit = useIsDefaultEdit();
  const { editable, setEditable, toggleEditable } = useEditableState(
    isDefaultEdit
  );

  useEffect(() => {
    setEditable(isDefaultEdit);
  }, [isDefaultEdit, setEditable]);

  usePageTitle([{ messageId: 'routes.userProfile' }]);

  const lgDown = useIsBreakpointDown('lg');
  const calendarAspectRatio = lgDown ? '1' : '1.5';

  const isMobile = useIsBreakpointDown('sm');
  const [smallAvatar, setSmallAvatar] = useState(isMobile);
  const isPsaPraaUserDocumentReplicationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPraaUserDocumentReplicationEnabled'
  });

  const onWaypointEnter = useCallback(() => {
    setSmallAvatar(isMobile);
  }, [setSmallAvatar, isMobile]);
  const onWaypointLeave = useCallback(() => {
    setSmallAvatar(true);
  }, [setSmallAvatar]);

  const { userDetails, error, isResourceDetailsLoading } = useResourceDetails({
    userSlug
  });

  const notResourceUser =
    error && error.message && error.message.includes('NotResourceUser');
  const slugNotFound =
    error && error.message && error.message.includes('SlugNotFoundError1');

  if (notResourceUser || slugNotFound) {
    return <RedirectToNotFound />;
  }
  if (error) return <RedirectToUnhandledError />;

  const permittedActions = userDetails
    ? userDetails.permittedActions
    : [
        'urn:replicon:user-action:view-project-role',
        'urn:replicon:user-action:view-skill-assignments',
        'urn:replicon:user-action:view-supervisor',
        'urn:replicon:user-action:view-custom-fields'
      ];

  return (
    <>
      <StickyHeader showMenuButton={!showBackButton && isMobile} bottomBorder>
        <ResourceDetailsHeader
          editable={editable}
          canEditAvatar={canEditPreferences}
          toggleEditable={toggleEditable}
          userDetails={userDetails}
          isResourceDetailsLoading={isResourceDetailsLoading}
          showBackButton={showBackButton}
          smallAvatar={smallAvatar}
        />
      </StickyHeader>
      <Waypoint onEnter={onWaypointEnter} onLeave={onWaypointLeave} />
      <div className={classes.root}>
        <div className={classes.userInfo}>
          <ResourceReadOnlyCard
            editable={editable}
            userDetails={userDetails}
            permittedActions={permittedActions}
            isResourceDetailsLoading={isResourceDetailsLoading}
          />
        </div>
        <Grid container spacing={2} className={classes.others}>
          <Grid item xs={12} sm={6} lg={6}>
            {permittedActions.includes(
              'urn:replicon:user-action:view-project-role'
            ) && (
              <RolesInfo
                key="role"
                editable={
                  editable &&
                  permittedActions.includes(
                    'urn:replicon:user-action:edit-project-role'
                  )
                }
                userDetails={userDetails}
                isResourceDetailsLoading={isResourceDetailsLoading}
              />
            )}
            {permittedActions.includes(
              'urn:replicon:user-action:view-skill-assignments'
            ) && (
              <>
                <SkillsInfo
                  key="skill"
                  editable={
                    editable &&
                    permittedActions.includes(
                      'urn:replicon:user-action:edit-skill-assignments'
                    )
                  }
                  userDetails={userDetails}
                  isResourceDetailsLoading={isResourceDetailsLoading}
                />
                <Certificates
                  editable={
                    editable &&
                    permittedActions.includes(
                      'urn:replicon:user-action:edit-skill-assignments'
                    )
                  }
                  userDetails={userDetails}
                  isResourceDetailsLoading={isResourceDetailsLoading}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            {isResourceDetailsLoading ? (
              <Card className={classes.calendarLoading}>
                <CalendarLoading />
              </Card>
            ) : (
              <ResourceCalendarCard
                userDetails={userDetails}
                calendarAspectRatio={calendarAspectRatio}
              />
            )}
            {permittedActions.includes(
              'urn:replicon:user-action:view-documents'
            ) && (
              <FileAttachment
                attachToUri={userDetails ? userDetails.uri : null}
                editable={
                  editable &&
                  permittedActions.includes(
                    'urn:replicon:user-action:edit-documents'
                  )
                }
                isLoading={isResourceDetailsLoading}
                customMetadataFn={
                  isPsaPraaUserDocumentReplicationEnabled
                    ? CUSTOM_METADATA_BUILDER.RESOURCE
                    : undefined
                }
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

ResourceUserDetails.propTypes = {
  userSlug: PropTypes.string,
  showBackButton: PropTypes.bool,
  canEditPreferences: PropTypes.bool
};

export default ResourceUserDetails;
