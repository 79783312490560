export const UPDATE_AVAILABILITY_COLUMN = 'UPDATE_AVAILABILITY_COLUMN';
export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

export default (state, action = {}) => {
  switch (action.type) {
    case UPDATE_AVAILABILITY_COLUMN:
      return {
        ...state,
        isAvailabilityColumnEnabled: action.value
      };

    case UPDATE_SORT:
      return { ...state, sort: action.value };

    case UPDATE_SELECTED_TAB:
      return { ...state, selectedTab: action.value };

    case UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
