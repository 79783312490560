import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import {
  getRoundedHours,
  getTotalHoursForPeriodFromScheduleRules
} from '~/modules/resourcing/common/util/scheduleUtil';
import { convertDurationToHours } from '~/modules/common/util/util';
import { getScheduleRulesInChartDisplayRange } from './allocation-util';

export const getUserAllocatedTotalHoursPeriod = ({
  chartDisplayPeriods,
  chartDisplayDateRange: { startDate: chartStartDate, endDate: chartEndDate },
  allocations
}) =>
  Object.values(
    allocations.reduce((acc, allocation) => {
      const { scheduleRules, startDate, endDate } = allocation;

      const noOverlap =
        mapIsoStringtoUtcObject(endDate) < chartStartDate ||
        mapIsoStringtoUtcObject(startDate) > chartEndDate;

      const scheduleRulesInRange = noOverlap
        ? []
        : getScheduleRulesInChartDisplayRange({
            rangeStart: chartStartDate,
            rangeEnd: chartEndDate,
            scheduleRules
          });

      chartDisplayPeriods.forEach(range => {
        const { startDate: start, endDate: end } = range;

        const key = start.toString();

        if (!acc[key]) {
          acc[key] = {
            key,
            totalHours: 0
          };
        }

        acc[key].totalHours += getTotalHoursForPeriodFromScheduleRules({
          start,
          end,
          scheduleRules: scheduleRulesInRange,
          useVersion2: true
        });
      });

      return acc;
    }, {})
  );

export const getUserActualTotalHoursByPeriod = ({ actualSeries, scale }) =>
  actualSeries
    .reduce((acc, entry) => [...acc, ...entry.periods], [])
    .reduce((accumulator, p) => {
      const pkey = p.dateRange.startDate.toString();
      const { totalHours } = accumulator[pkey] || {
        totalHours: 0
      };

      return {
        ...accumulator,
        [pkey]: {
          totalHours: getRoundedHours({
            hours: totalHours + convertDurationToHours(p.hours),
            scale
          })
        }
      };
    }, {});
