import PropTypes from 'prop-types';
import React from 'react';
import AbbreviatedDecimal from '../AbbreviatedDecimal';
import Decimal from '../Decimal';

export const getCurrencySymbol = (currencies, currencyUri) =>
  currencyUri ? currencies.filter(x => x.id === currencyUri)[0].symbol : '';

const MultiCurrencyMoney = ({
  className,
  moneyValues,
  variant = 'decimal',
  ...rest
}) => {
  const Tag = variant === 'summary' ? AbbreviatedDecimal : Decimal;

  return (
    <>
      {moneyValues.map(money => (
        <div key={money.currencyUri}>
          <Tag
            className={className}
            value={money.amount}
            prefix={`${money.currencySymbol} `}
            {...rest}
          />
        </div>
      ))}
    </>
  );
};

MultiCurrencyMoney.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  moneyValues: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      currencyUri: PropTypes.string,
      currencySymbol: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default MultiCurrencyMoney;
