/* eslint-disable max-depth */

import { useMemo } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

export const ITEM_KEY = {
  ALLOCATION: 'ALLOCATION',
  TIMEOFF: 'TIMEOFF',
  HOLIDAY: 'HOLIDAY'
};

const createUserAllocationSummaryPeriod = ({ startDate, items, endDate }) => ({
  startDate: mapIsoStringtoUtcObject(startDate),
  loadRatio: items.reduce((acc, i) => acc + i.load / 100.0, 0),
  projectsWithLoad: items.map(i => ({
    allocationStatus: i.allocationStatus && i.allocationStatus,
    projectName: i.name,
    load: i.load
  })),
  containsAllocation: true,
  endDate: mapIsoStringtoUtcObject(endDate)
});

export const useUserAllocationSummaryPeriods = ({
  resource: { resourceAllocations = [] }
}) => {
  return useMemo(() => {
    const items = resourceAllocations.map(allocation => ({
      ...allocation,
      name: allocation.project.displayText,
      key: ITEM_KEY.ALLOCATION
    }));

    const dateItems = {};

    items.forEach(i => {
      // The below algorithm always marks periods as ending endDate - 1 to handle overlaps properly
      const dayAfterEndDate = mapIsoStringtoUtcObject(i.endDate)
        .plus({ days: 1 })
        .toISO();

      if (dateItems[i.startDate]) {
        dateItems[i.startDate].itemsStarting.push(i);
      } else {
        dateItems[i.startDate] = {
          itemsStarting: [i],
          itemsEnding: []
        };
      }
      if (dateItems[dayAfterEndDate]) {
        dateItems[dayAfterEndDate].itemsEnding.push(i);
      } else {
        dateItems[dayAfterEndDate] = {
          itemsStarting: [],
          itemsEnding: [i]
        };
      }
    });

    const dates = Object.keys(dateItems).sort();

    const userAllocationSummaryPeriods = [];

    if (dates.length === 0) {
      return {
        userAllocationSummaryPeriods
      };
    }
    let start = null;
    let currentItems = [];

    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];

      start = start || date;
      const dateItem = dateItems[date];

      if (currentItems.length > 0) {
        const summaryPeriod = createUserAllocationSummaryPeriod({
          startDate: start,
          totalAllocationHours: 1,
          totalAvailableHours: 1,
          items: currentItems,
          endDate: mapIsoStringtoUtcObject(date)
            .minus({ days: 1 })
            .toISO()
        });

        userAllocationSummaryPeriods.push(summaryPeriod);
        start = date;
      }

      if (dateItem.itemsStarting.length > 0) {
        currentItems.push(...dateItem.itemsStarting);
      }
      if (dateItem.itemsEnding.length > 0) {
        currentItems = currentItems.filter(
          item => dateItem.itemsEnding.indexOf(item) === -1
        );
        if (currentItems.length === 0) {
          start = null;
        }
      }
    }

    return {
      userAllocationSummaryPeriods
    };
  }, [resourceAllocations]);
};

export default useUserAllocationSummaryPeriods;
