import React, { useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useIssueBill } from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/';
import {
  useHasBillPermission,
  useIssueAction
} from '~/modules/billing-invoicing/common/hooks';
import { useMeContext } from '~/modules/me/useMeContext';
import { useDialogState } from '~/modules/common/hooks';
import { IssueActionButton } from './IssueActionButton';
import { IssuedBillMenu } from './IssuedBillMenu';
import { BillIssueConfirmationDialog } from './BillIssueConfirmationDialog';

const useActionIconStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2, 0, 1)
  }
}));
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginLeft: 'auto'
  },
  action: {
    flexDirection: 'row'
  }
}));

export const ReadOnlyActionButtons = ({
  onMenuClick,
  onDelete,
  resourceKeys,
  bill,
  onCloseDrawer,
  refetchAvailableToBillTotals,
  billPermission,
  summarizeColumnOptions
}) => {
  const me = useMeContext();
  const iconClasses = useActionIconStyles();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const onClose = useCallback(() => setAnchorEl(null), []);
  const [openPopper, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const { issueBill, loading } = useIssueBill({
    transactionId: bill.id,
    updateDetails: true,
    record: bill
  });
  const {
    open: billIssueDialogOpen,
    openDialog: openBillIssueDialog,
    closeDialog: closeBillIssueDialog
  } = useDialogState(false);
  const {
    failedLineItems,
    setFailedLineItems,
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick
  } = useIssueAction({
    me,
    issueBill,
    onMenuClick,
    record: bill,
    onClose: closeBillIssueDialog
  });

  const deleteItem = useCallback(() => {
    onClose();
    onDelete();
  }, [onClose, onDelete]);

  const handleClick = useCallback(
    event => setAnchorEl(event.currentTarget),
    []
  );

  const {
    canEditBasicDetailOrDeleteBill,
    canVoidBill,
    canIssueBill,
    canReopenBill,
    isBillIssuedAndPaid,
    canSyncBill
  } = useHasBillPermission({
    bill,
    billPermission
  });

  const [confirmationDialogName, setConfirmationDialogName] = useState('sync');
  const billIssueDialogName = useCallback(
    dialogName => {
      setConfirmationDialogName(dialogName);
      openBillIssueDialog();
    },
    [openBillIssueDialog]
  );

  return (
    <>
      <div className={classes.root}>
        {canIssueBill ? (
          <>
            {isBillIssuedAndPaid ? (
              <Button variant="contained" onClick={onCloseDrawer}>
                <FormattedMessage id="billDetails.ok" />
              </Button>
            ) : (
              <IssueActionButton
                billStatus={bill.billStatus}
                openPopper={openPopper}
                setOpen={setOpen}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                canSyncBill={canSyncBill}
                handleIssuePrintClick={handleIssuePrintClick}
                handleIssueEmailClick={handleIssueEmailClick}
                handleIssueSyncClick={handleIssueSyncClick}
                isLoading={isLoading}
                setLoading={setLoading}
                openBillIssueDialog={billIssueDialogName}
              />
            )}
          </>
        ) : null}
        <div className={classes.action}>
          {canVoidBill || canReopenBill ? (
            <IssuedBillMenu
              billId={bill.id}
              canReopenBill={canReopenBill}
              canVoidBill={canVoidBill}
              refetchAvailableToBillTotals={refetchAvailableToBillTotals}
              summarizeColumnOptions={summarizeColumnOptions}
            />
          ) : null}
          {canEditBasicDetailOrDeleteBill ? (
            <>
              <IconButton
                aria-label={resourceKeys.deleteMenu}
                onClick={handleClick}
                classes={iconClasses}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={onClose}
              >
                <MenuItem key="item-Delete" onClick={deleteItem}>
                  <FormattedMessage id={`${resourceKeys.removeButton}`} />
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </div>
      </div>
      {billIssueDialogOpen ? (
        <BillIssueConfirmationDialog
          open={billIssueDialogOpen}
          onClose={closeBillIssueDialog}
          loading={loading}
          billDisplayText={bill.displayId}
          failedLineItems={failedLineItems}
          setFailedLineItems={setFailedLineItems}
          confirmationDialogName={confirmationDialogName}
          handleIssuePrintClick={handleIssuePrintClick}
          handleIssueEmailClick={handleIssueEmailClick}
          handleIssueSyncClick={handleIssueSyncClick}
        />
      ) : null}
    </>
  );
};

ReadOnlyActionButtons.propTypes = {
  onMenuClick: PropTypes.func,
  onDelete: PropTypes.func,
  resourceKeys: PropTypes.object.isRequired,
  bill: PropTypes.object,
  onCloseDrawer: PropTypes.func,
  refetchAvailableToBillTotals: PropTypes.func,
  summarizeColumnOptions: PropTypes.any,
  billPermission: PropTypes.object
};
export default ReadOnlyActionButtons;
