import React, { useCallback, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import useKeyBoardEventHandlers from '~/modules/common/hooks/useKeyBoardEventHandlers';
import { isNullOrUndefined } from '~/modules/common/util';
import { FieldLabel } from '../../TaskAllocationEditor';
import {
  PlaceholderEstimateContent,
  PlaceholderEstimatedHoursEditor,
  ResourceEstimateContent,
  ResourceEstimatedHoursEditor
} from './components';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'space-between',
    margin: theme.spacing(0, 1, 1),
    height: theme.spacing(9),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius
  },
  text: {
    color: theme.palette.text.secondary,
    pointerEvents: 'none'
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  }
}));

export const TaskEstimateCard = ({
  canEditTasks,
  resourceEstimate,
  rowIndex,
  isReadOnlyView,
  projectCurrencyId
}) => {
  const classes = useStyles();

  const hasResource = Boolean(resourceEstimate?.resourceUser);
  const initialEstimatedHours = resourceEstimate?.initialEstimatedHours;
  const initialEstimatedCost = resourceEstimate?.initialEstimatedCost;

  const [anchorEl, setAnchorEl] = useState(null);
  const onOpen = useCallback(
    event => {
      if (!isReadOnlyView && canEditTasks) setAnchorEl(event.target);
    },
    [isReadOnlyView, canEditTasks, setAnchorEl]
  );

  const { handleKeyDown } = useKeyBoardEventHandlers({ onEnterClick: onOpen });

  return (
    <>
      <Grid
        item
        className={classNames(classes.container, {
          [classes.hover]: !isReadOnlyView && canEditTasks
        })}
        onClick={onOpen}
        onKeyDown={handleKeyDown}
        tabIndex={!isReadOnlyView && canEditTasks ? 0 : -1}
        data-qe-id="TaskEstimateCard"
      >
        {hasResource ? (
          <ResourceEstimateContent
            resourceEstimate={resourceEstimate}
            isReadOnlyView={isReadOnlyView}
          />
        ) : !hasResource && !isNullOrUndefined(initialEstimatedHours) ? (
          <PlaceholderEstimateContent
            initialEstimatedHours={initialEstimatedHours}
            initialEstimatedCost={initialEstimatedCost}
          />
        ) : (
          <div>
            <FieldLabel
              messageId="taskResourceEstimates.estimate"
              className={classes.text}
              variant="caption"
            />
            <FieldLabel
              variant="body2"
              className={classes.text}
              messageId={
                !isReadOnlyView && canEditTasks
                  ? 'taskResourceEstimates.addEstimate'
                  : 'taskResourceEstimates.noEstimateSet'
              }
            />
          </div>
        )}
      </Grid>

      {Boolean(anchorEl) &&
        (hasResource ? (
          <ResourceEstimatedHoursEditor
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            resourceEstimate={resourceEstimate}
            projectCurrencyId={projectCurrencyId}
            rowIndex={rowIndex}
          />
        ) : (
          <PlaceholderEstimatedHoursEditor
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            resourceEstimate={resourceEstimate}
            projectCurrencyId={projectCurrencyId}
            rowIndex={rowIndex}
          />
        ))}
    </>
  );
};

TaskEstimateCard.propTypes = {
  canEditTasks: PropTypes.bool,
  resourceEstimate: PropTypes.object,
  rowIndex: PropTypes.number,
  isReadOnlyView: PropTypes.bool,
  projectCurrencyId: PropTypes.string
};

export default TaskEstimateCard;
