import React from 'react';
import { Typography, makeStyles, Chip, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import LaunchIcon from '@mui/icons-material/Launch';
import { HiringStatus } from '~/types';
import HiringRequestStatusAlert from './HiringRequestStatusAlert';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1, 0, -1.5)
  },
  alertText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    margin: 0
  },
  icon: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1, 0, 0.5)
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0.75, 0, 0)
  },
  linkText: {
    ...theme.typography.body2
  }
}));

const useChipStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.dtm.text,
    backgroundColor: theme.palette.dtm.main,
    cursor: 'pointer',
    padding: theme.spacing(0.25, 0, 0),
    margin: theme.spacing(0.25, 0, 0)
  }
}));

export const HiringRequisitionInfoTitle = ({
  expanded,
  requisitionStatus,
  requisitionUrl,
  hiringRequestStatus
}) => {
  const classes = useStyles();
  const chipClasses = useChipStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="subtitle1" className={classes.title}>
          <FormattedMessage id="requisitionInformation.hiringRequest" />
        </Typography>

        {!expanded && (
          <>
            {hiringRequestStatus === HiringStatus.HiringCancelled ? (
              <HiringRequestStatusAlert
                classes={classes}
                titleId="hiringRequestCancelledAlert.title"
              />
            ) : hiringRequestStatus === HiringStatus.HiringWithdrawn ? (
              <HiringRequestStatusAlert
                classes={classes}
                titleId="hiringRequestWithdrawnAlert.title"
              />
            ) : (
              <Chip
                size="small"
                label={requisitionStatus}
                classes={chipClasses}
              />
            )}
          </>
        )}
      </div>

      <Link href={requisitionUrl} target="_blank" className={classes.link}>
        <Typography className={classes.linkText}>
          <FormattedMessage id="requisitionInformation.viewHiringRequest" />
        </Typography>
        <LaunchIcon className={classes.icon} fontSize="small" />
      </Link>
    </div>
  );
};

HiringRequisitionInfoTitle.propTypes = {
  expanded: PropTypes.bool.isRequired,
  requisitionStatus: PropTypes.string,
  requisitionUrl: PropTypes.string.isRequired,
  hiringRequestStatus: PropTypes.oneOf([
    HiringStatus.HiringCancelled,
    HiringStatus.HiringRequested,
    HiringStatus.HiringWithdrawn
  ])
};

export default HiringRequisitionInfoTitle;
