import { useCallback } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

const useOnRemoveTaskAllocationSuccess = ({
  onClose,
  handleRemoveResourceAllocation,
  userId
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();

  const isCompleteResourceAllocationEditEnabled =
    Boolean(
      permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
    ) && isRmpTaskAllocationPhase2Enabled;

  return {
    onRemoveTaskAllocationSuccess: useCallback(
      ({ deleteTaskResourceUserAllocationResult }) => {
        onClose();
        if (
          isCompleteResourceAllocationEditEnabled &&
          !deleteTaskResourceUserAllocationResult
            ?.deleteTaskResourceUserAllocation?.syncedResourceAllocation
        ) {
          handleRemoveResourceAllocation(userId);
        }
      },
      [
        handleRemoveResourceAllocation,
        isCompleteResourceAllocationEditEnabled,
        onClose,
        userId
      ]
    )
  };
};

export default useOnRemoveTaskAllocationSuccess;
