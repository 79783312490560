import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { DAY_OF_WEEK_ENUM } from '~/modules/projects/project/common/enums';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  select: {
    paddingBottom: theme.spacing(0.75),
    fontSize: theme.typography.body2.fontSize,
    height: '1.2rem'
  },
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'inline-flex',
    maxWidth: 170,
    '& .MuiFormControl-root .MuiFilledInput-root': {
      borderTopLeftRadius: 0
    }
  }
}));

const DayOfWeekDropdown = ({
  value,
  onChange,
  hiddenLabel = false,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const intl = useIntl();
  const options = useMemo(
    () =>
      Object.keys(DAY_OF_WEEK_ENUM).map(dayOfWeek => ({
        id: dayOfWeek,
        displayText: intl.formatMessage({
          id: `billPlan.dayOfWeek.${dayOfWeek}`
        })
      })),
    []
  );

  const selectedValue = useMemo(() => options.find(x => x.id === value), [
    options,
    value
  ]);
  const dropdownClasses = useMemo(
    () => ({
      container: classes.container,
      select: classes.select
    }),
    [classes.container, classes.select]
  );

  return (
    <div className={classes.root}>
      <SimpleDropdown
        options={options}
        onChange={onChange}
        noneOption={false}
        value={selectedValue}
        classes={dropdownClasses}
        disableSort
        variant="filled"
        hiddenLabel={hiddenLabel}
      />
    </div>
  );
};

DayOfWeekDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  hiddenLabel: PropTypes.bool,
  classes: PropTypes.object
};

export default DayOfWeekDropdown;
