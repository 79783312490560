import React from 'react';
import { Tooltip, Grid, Chip, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { NoValue } from '~/modules/common/components';
import {
  getLuxonJsDateTimeFormatFromMe,
  isoStringToFormattedISOString
} from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me';
import { HiringStatus } from '~/types';
import HiringRequisitionInfoField from './HiringRequisitionInfoField';
import HiringRequestStatusAlert from './HiringRequestStatusAlert';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    padding: theme.spacing(1, 1, 0, 1)
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary
  },
  alertText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    margin: 0
  },
  status: {
    maxWidth: '100%',
    color: theme.palette.dtm.text,
    backgroundColor: theme.palette.dtm.main
  },
  cancelledStatus: {
    maxWidth: '100%',
    color: theme.palette.getContrastText(theme.palette.grey[200]),
    backgroundColor: theme.palette.grey[200]
  }
}));

export const HiringRequisitionInfoContent = ({
  requisitionInfo,
  hiringRequestStatus
}) => {
  const classes = useStyles();

  const me = useMeContext();
  const dateFormat = getLuxonJsDateTimeFormatFromMe(me);

  const formattedPostedDate = isoStringToFormattedISOString(
    requisitionInfo?.datePosted,
    dateFormat
  );

  const formattedCreatedDate = isoStringToFormattedISOString(
    requisitionInfo?.dateCreated,
    dateFormat
  );

  return (
    <>
      {hiringRequestStatus === HiringStatus.HiringCancelled ? (
        <HiringRequestStatusAlert
          classes={classes}
          titleId="hiringRequestCancelledAlert.title"
          descriptionId="hiringRequestCancelledAlert.description"
        />
      ) : hiringRequestStatus === HiringStatus.HiringWithdrawn ? (
        <HiringRequestStatusAlert
          classes={classes}
          titleId="hiringRequestWithdrawnAlert.title"
          descriptionId="hiringRequestWithdrawnAlert.description"
        />
      ) : null}

      <Grid container spacing={2} className={classes.container}>
        <HiringRequisitionInfoField
          labelId="requisitionInformation.requisitionCode"
          value={requisitionInfo?.code}
        />
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.label} noWrap>
            <FormattedMessage id="requisitionInformation.status" />
          </Typography>
          {requisitionInfo?.status ? (
            <Tooltip title={requisitionInfo?.status} placement="bottom-start">
              <Chip
                size="medium"
                label={requisitionInfo?.status}
                className={classNames({
                  [classes.status]:
                    hiringRequestStatus !== HiringStatus.HiringCancelled,
                  [classes.cancelledStatus]:
                    hiringRequestStatus === HiringStatus.HiringCancelled
                })}
              />
            </Tooltip>
          ) : (
            <NoValue />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.container}>
        <HiringRequisitionInfoField
          labelId="requisitionInformation.jobTitle"
          value={requisitionInfo?.jobTitle}
        />
        <HiringRequisitionInfoField
          labelId="requisitionInformation.hiresNeeded"
          value={requisitionInfo?.hiresNeeded}
        />
      </Grid>

      <Grid container spacing={2} className={classes.container}>
        <HiringRequisitionInfoField
          labelId="requisitionInformation.hiringManager"
          value={requisitionInfo?.hiringManager?.displayName}
          email={requisitionInfo?.hiringManager?.email}
        />
        <HiringRequisitionInfoField
          labelId="requisitionInformation.recruiter"
          value={requisitionInfo?.recruiter?.displayName}
          email={requisitionInfo?.recruiter?.email}
        />
      </Grid>

      <Grid container spacing={2} className={classes.container}>
        <HiringRequisitionInfoField
          labelId="requisitionInformation.dateCreated"
          value={formattedCreatedDate}
        />
        <HiringRequisitionInfoField
          labelId="requisitionInformation.datePosted"
          value={formattedPostedDate}
        />
      </Grid>
    </>
  );
};

HiringRequisitionInfoContent.propTypes = {
  requisitionInfo: PropTypes.object.isRequired,
  hiringRequestStatus: PropTypes.oneOf([
    HiringStatus.HiringCancelled,
    HiringStatus.HiringRequested,
    HiringStatus.HiringWithdrawn
  ])
};

export default HiringRequisitionInfoContent;
