import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.table.border}`
  },
  tab: {
    width: '50%'
  }
}));

export const tabs = {
  ALL_RESOURCES: 'ALL_RESOURCES',
  PROJECT_RESOURCES: 'PROJECT_RESOURCES'
};

const TabLabels = {
  projectResources: (
    <FormattedMessage id="resourceUsersDropdown.projectResources" />
  ),
  allResources: <FormattedMessage id="resourceUsersDropdown.allResources" />
};

export const ResourceUsersDropdownTabs = ({
  selectedTab,
  handleTabChange,
  tabClasses
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs value={selectedTab} onChange={handleTabChange} classes={tabClasses}>
        <Tab
          value={tabs.PROJECT_RESOURCES}
          label={TabLabels.projectResources}
          className={classes.tab}
        />
        <Tab
          value={tabs.ALL_RESOURCES}
          label={TabLabels.allResources}
          className={classes.tab}
        />
      </Tabs>
    </div>
  );
};

ResourceUsersDropdownTabs.propTypes = {
  selectedTab: PropTypes.oneOf([tabs.ALL_RESOURCES, tabs.PROJECT_RESOURCES]),
  handleTabChange: PropTypes.func,
  tabClasses: PropTypes.object
};

export default ResourceUsersDropdownTabs;
