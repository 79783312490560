import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { ListItemText } from '@material-ui/core';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { NO_CHANGE_OPTION_ID, noChange } from '~/modules/common/hooks';
import { NoneDropdownItem } from '~/modules/common/components/SearchAutocomplete';

const label = <FormattedMessage id="massEditProject.timeEntry" />;
const getOptions = intl => [
  noChange(intl),
  {
    id: 'taskSelectionOptional',
    displayText: intl.formatMessage({
      id: 'massEditProject.taskSelectionOptional.option'
    }),
    component: (
      <ListItemText
        primary={intl.formatMessage({
          id: 'massEditProject.taskSelectionOptional.option'
        })}
        secondary={intl.formatMessage({
          id: 'massEditProject.taskSelectionOptional.description'
        })}
      />
    )
  },
  {
    id: 'taskSelectionRequired',
    displayText: intl.formatMessage({
      id: 'massEditProject.taskSelectionRequired.option'
    }),
    component: (
      <ListItemText
        primary={intl.formatMessage({
          id: 'massEditProject.taskSelectionRequired.option'
        })}
        secondary={intl.formatMessage({
          id: 'massEditProject.taskSelectionRequired.description'
        })}
      />
    )
  }
];

const inputLabelProps = {
  id: 'time-entry-dropdown-label'
};

export const TimeEntryDropdown = ({ value, onChange }) => {
  const intl = useIntl();
  const options = getOptions(intl);

  const selectProps = useMemo(
    () => ({
      labelId: 'time-entry-dropdown-label',
      id: 'time-entry-dropdown',
      SelectDisplayProps: {
        role: 'listbox'
      },
      renderValue: selectedValue => {
        const option = options.find(({ id }) => id === selectedValue);

        return option.id === NO_CHANGE_OPTION_ID ? (
          <NoneDropdownItem value={option.displayText} />
        ) : (
          option?.displayText
        );
      }
    }),
    [options]
  );

  return (
    <SimpleDropdown
      variant="filled"
      label={label}
      noneOption={false}
      options={options}
      onChange={onChange}
      value={value}
      selectProps={selectProps}
      inputLabelProps={inputLabelProps}
      disableSort
    />
  );
};

TimeEntryDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default TimeEntryDropdown;
