import React from 'react';
import { FormattedMessage } from 'react-intl';

export const resourcingLegendItems = {
  request: {
    label: <FormattedMessage id="resourcingLegends.request" />,
    className: 'request',
    barType: 'dashedBar'
  },
  requestRejected: {
    label: <FormattedMessage id="resourcingLegends.requestRejected" />,
    className: 'rejected',
    barType: 'dashedBar'
  },
  toBeHired: {
    label: <FormattedMessage id="resourcingLegends.toBeHired" />,
    className: 'toBeHired',
    barType: 'dashedBar'
  },
  resourceRejected: {
    label: <FormattedMessage id="resourcingLegends.resourceRejected" />,
    className: 'rejected',
    barType: 'roundBar'
  },
  pending: {
    label: <FormattedMessage id="resourcingLegends.pending" />,
    className: 'pending',
    barType: 'roundBar'
  },
  proposed: {
    label: <FormattedMessage id="resourcingLegends.proposed" />,
    className: 'proposed',
    barType: 'roundBar'
  },
  complete: {
    label: <FormattedMessage id="resourcingLegends.complete" />,
    className: 'complete',
    barType: 'roundBar'
  },
  allocatedProgress: {
    isProgressBarLegend: true,
    className: 'allocated',
    label: <FormattedMessage id="resourcingLegends.allocated" />
  },
  overAllocated: {
    className: 'overAllocated',
    label: <FormattedMessage id="resourcingLegends.overAllocated" />
  },
  timeOff: {
    className: 'timeOff',
    label: <FormattedMessage id="resourcingLegends.timeOff" />
  },
  holiday: {
    className: 'holiday',
    label: <FormattedMessage id="resourcingLegends.holidays" />
  },
  notWorking: {
    className: 'notWorking',
    label: <FormattedMessage id="resourcingLegends.notWorking" />
  },
  rejectedAllocation: {
    className: 'rejected',
    label: <FormattedMessage id="resourcingLegends.rejectedAllocation" />,
    barType: 'roundBar'
  },
  proposedAllocation: {
    className: 'proposed',
    label: <FormattedMessage id="resourcingLegends.proposedAllocation" />,
    barType: 'roundBar'
  },
  pendingAllocation: {
    className: 'pending',
    label: <FormattedMessage id="resourcingLegends.pendingAllocation" />,
    barType: 'roundBar'
  },
  allocation: {
    className: 'complete',
    label: <FormattedMessage id="resourcingLegends.allocation" />,
    barType: 'roundBar'
  },
  timeoffAllocation: {
    className: 'timeOffAllocation',
    label: <FormattedMessage id="resourcingLegends.timeOff" />,
    barType: 'roundBar'
  }
};

export const resourcingLegendItemsV2 = {
  request: {
    key: 'request',
    label: <FormattedMessage id="resourcingLegends.request" />,
    className: 'request',
    barType: 'dashedBar'
  },
  requestRejected: {
    key: 'rejected',
    label: <FormattedMessage id="resourcingLegends.requestRejected" />,
    className: 'rejected',
    barType: 'dashedBar'
  },
  toBeHired: {
    key: 'toBeHired',
    label: <FormattedMessage id="resourcingLegends.toBeHired" />,
    className: 'toBeHired',
    barType: 'dashedBar'
  },
  resourceRejected: {
    key: 'resourceRejected',
    label: <FormattedMessage id="resourcingLegends.resourceRejected" />,
    className: 'rejected',
    barType: 'squareBar'
  },
  pending: {
    key: 'pending',
    label: <FormattedMessage id="resourcingLegends.pendingAllocation" />,
    className: 'pending',
    barType: 'squareBar'
  },
  proposed: {
    key: 'proposed',
    label: <FormattedMessage id="resourcingLegends.proposedAllocation" />,
    className: 'proposed',
    barType: 'squareBar'
  },
  complete: {
    key: 'complete',
    label: <FormattedMessage id="resourcingLegends.allocation" />,
    className: 'complete',
    barType: 'squareBar'
  },
  allocatedProgress: {
    key: 'allocated',
    isProgressBarLegend: true,
    className: 'allocated',
    label: <FormattedMessage id="resourcingLegends.allocated" />
  },
  overAllocated: {
    key: 'overAllocated',
    className: 'overAllocated',
    barType: 'squareBar',
    label: <FormattedMessage id="resourcingLegends.overAllocated" />
  },
  actuals: {
    key: 'actuals',
    className: 'actuals',
    label: <FormattedMessage id="resourcingLegends.actuals" />,
    barType: 'squareBar'
  },
  timeOff: {
    key: 'timeOff',
    className: 'timeOff',
    label: <FormattedMessage id="resourcingLegends.timeOff" />
  },
  holiday: {
    key: 'holiday',
    className: 'holiday',
    label: <FormattedMessage id="resourcingLegends.holidays" />
  },
  notWorking: {
    key: 'notWorking',
    className: 'notWorking2',
    label: <FormattedMessage id="resourcingLegends.notWorking" />
  },
  rejectedAllocation: {
    key: 'rejectedAllocation',
    className: 'rejected',
    label: <FormattedMessage id="resourcingLegends.rejectedAllocation" />,
    barType: 'squareBar',
    groupType: 'resourceRequests'
  },
  proposedAllocation: {
    key: 'proposed',
    className: 'proposed',
    label: <FormattedMessage id="resourcingLegends.proposedAllocation" />,
    barType: 'squareBar'
  },
  pendingAllocation: {
    key: 'pending',
    className: 'pending',
    label: <FormattedMessage id="resourcingLegends.pendingAllocation" />,
    barType: 'squareBar'
  },
  allocation: {
    key: 'complete',
    className: 'complete',
    label: <FormattedMessage id="resourcingLegends.allocation" />,
    barType: 'squareBar'
  },
  timeoffAllocation: {
    key: 'timeOffAllocation',
    className: 'timeOffAllocation',
    label: <FormattedMessage id="resourcingLegends.timeOff" />,
    barType: 'squareBar'
  }
};
