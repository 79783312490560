import { useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import { toRepliconDate } from '~/modules/common/dates/convert';
import { formattedBillingAddress } from '~/modules/billing-invoicing/bill/formattedBillingAddress';
import {
  getTransitionUrlByRecordTypeFromPath,
  formatPutBillInput
} from '~/modules/billing-invoicing/common/util';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import {
  getValidationErrorByUri,
  getValidationErrorByDisplayText
} from '~/modules/common/graphql/errors';
import { formatInvoiceItems } from '~/modules/billing-invoicing/common/util/billUtil';
import { isNullOrUndefined } from '~/modules/common/util';
import {
  createDueDateValidator,
  createIssueDateValidator,
  createPeriodValidator
} from './validation-util';

export const buildValidationSchema = ({ intl }) =>
  object().shape({
    issueDate: createIssueDateValidator(intl),
    dueDate: createDueDateValidator(intl),
    period: createPeriodValidator(intl),
    description: string().max(
      255,
      intl.formatMessage({
        id: 'billDetails.validations.descriptionExceedsMaxLength'
      })
    ),
    poNumber: string().max(
      255,
      intl.formatMessage({
        id: 'billDetails.validations.poNumberExceedsMaxLength'
      })
    ),
    displayId: string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'billDetails.validations.referenceNumberIsRequired'
        })
      )
      .max(
        255,
        intl.formatMessage({
          id: 'billDetails.validations.referenceNumberExceedsMaxLength'
        })
      ),
    client: object().shape({
      id: string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'billDetails.validations.clientIsRequired'
          })
        )
    })
  });

const getBillLineitemsOrDefault = (
  billLineitems,
  defaultInvoiceItem,
  isMobile,
  section,
  isNewBill
) => {
  let lineItems;
  const adhocDefaultInvoiceItem = defaultInvoiceItem;

  if (section === 'adhoc') {
    adhocDefaultInvoiceItem.isAdhoc = true;
  }

  if (isNewBill) {
    lineItems =
      section === 'adhoc'
        ? [adhocDefaultInvoiceItem]
        : section === 'default'
        ? [defaultInvoiceItem]
        : [];
  } else {
    lineItems = billLineitems || [];
  }

  if (!isMobile) {
    lineItems = lineItems.map((item, i) => {
      if (i === 0) {
        return { ...item, project: { ...item.project, hasFocus: true } };
      }

      return item;
    });
  }

  return lineItems;
};

const getValueOrDefault = (billId, value, defaultValue) =>
  !isNullOrUndefined(billId) ? value : defaultValue;

export const useInitialState = ({
  projectUri,
  projectName,
  period,
  me: { baseCurrency },
  bill = {},
  client,
  clientCurrency,
  billingAddress,
  clientPaymentTerm,
  clientInvoiceTemplate,
  clientTaxProfile,
  poNumber = '',
  standardLineItems,
  adhocLineItems,
  invoiceDefaultTemplate,
  nextReferenceNumberText,
  summarizeColumnOptions,
  billingSettings,
  isPsaPrpBillingDefaultForProjectEnabled
}) => {
  const currencyFromClient = client ? client.invoiceCurrency : undefined;
  const paymentTermFromClient = client
    ? client.defaultInvoicePaymentTerm
    : undefined;
  const invoiceTemplateFromClient = client && client.invoiceTemplate;
  const billCurrency =
    bill.currency || currencyFromClient || clientCurrency || baseCurrency;
  const invoiceTemplate =
    bill.invoiceTemplate ||
    invoiceTemplateFromClient ||
    clientInvoiceTemplate ||
    invoiceDefaultTemplate ||
    null;
  const paymentTerm =
    bill.paymentTerms >= 0
      ? bill.paymentTerms
      : paymentTermFromClient >= 0
      ? paymentTermFromClient
      : clientPaymentTerm >= 0
      ? clientPaymentTerm
      : undefined;
  const defaultPaymentTerm = paymentTerm === undefined ? 30 : paymentTerm;
  const isNewBill = Object.keys(bill).length === 0;
  const defaultInvoiceItem = {
    project: { id: projectUri, displayText: projectName },
    amount: {
      amount: 0,
      currency: billCurrency
    }
  };
  const isMobile = useIsBreakpointDown('sm');
  const values = useMemo(
    () => ({
      id: bill.id || null,
      displayId: bill.displayId || nextReferenceNumberText || '',
      description: getValueOrDefault(
        bill?.id,
        bill?.description || '',
        billingSettings?.description || ''
      ),
      issueDate: bill.issueDate || toRepliconDate(DateTime.local()),
      paymentTerms: defaultPaymentTerm,
      poNumber: bill.poNumber || poNumber,
      period: bill.period || period || { startDate: null, endDate: null },
      taxProfile: isPsaPrpBillingDefaultForProjectEnabled
        ? bill.taxProfile || clientTaxProfile || null
        : bill.taxProfile || { id: null },
      invoiceTemplate,
      billingAddress:
        typeof billingAddress === 'object'
          ? formattedBillingAddress(billingAddress)
          : bill.billingAddress || billingAddress || '',
      dueDate:
        bill.dueDate ||
        toRepliconDate(DateTime.local().plus({ days: defaultPaymentTerm })),
      billCurrency,
      initialBillCurrency: bill.currency || baseCurrency,
      client: bill.client || client || { id: '' },
      standardLineItems: getBillLineitemsOrDefault(
        standardLineItems,
        defaultInvoiceItem,
        isMobile,
        'standard',
        isNewBill
      ),
      adhocLineItems: getBillLineitemsOrDefault(
        adhocLineItems,
        defaultInvoiceItem,
        isMobile,
        'adhoc',
        isNewBill
      ),
      syncStatus: bill.syncStatus || null,
      summarizeColumnOptions: bill.summarizeColumn ||
        summarizeColumnOptions ||
        billingSettings?.billLineItemsBy || [BILLING_COLUMN_TYPE.PROJECT],
      voidBillDescription: bill.voidBillDescription || '',
      notesForCustomer: bill.notesForCustomer || '',
      internalNotes: getValueOrDefault(
        bill?.id,
        bill?.internalNotes || '',
        billingSettings?.internalNotes || ''
      ),
      showComments: getValueOrDefault(
        bill?.id,
        bill?.showComments,
        billingSettings?.showComments
      ),
      draftSubStatusUri: bill?.draftSubStatusUri || null
    }),
    [
      bill,
      nextReferenceNumberText,
      billingSettings,
      defaultPaymentTerm,
      period,
      invoiceTemplate,
      billingAddress,
      billCurrency,
      baseCurrency,
      client,
      defaultInvoiceItem,
      isMobile,
      isNewBill,
      standardLineItems,
      adhocLineItems,
      summarizeColumnOptions,
      isPsaPrpBillingDefaultForProjectEnabled
    ]
  );

  return values;
};

const redirectToBill = ({ history, billUri, isGlobalBilling }) => {
  const record = { id: billUri, type: 'BILL' };
  const transitionUrl = getTransitionUrlByRecordTypeFromPath({
    record,
    path: history.location.pathname,
    isGlobalBilling
  });

  if (transitionUrl) {
    history.push(transitionUrl);
  }
};

export const useOnSubmit = ({
  putBill,
  setSaving,
  onClose,
  setEditable,
  bill,
  history,
  summarizeColumnOptions,
  isGlobalBilling,
  intl,
  billingSettings,
  isPsaPrpBillingDefaultForProjectEnabled,
  isPsaPswatBillingDraftSubStatusEnabled = false
}) => {
  return useCallback(
    async (values, { setFieldError }) => {
      const { standardLineItems, adhocLineItems } = values;

      setSaving(true);
      const isEditMode = bill !== undefined;
      const lineItemInputs = {
        standardLineItems,
        adhocLineItems
      };

      lineItemInputs.standardLineItems = formatInvoiceItems(standardLineItems);
      lineItemInputs.adhocLineItems = formatInvoiceItems(adhocLineItems);

      try {
        const formattedPutBillInput = formatPutBillInput(
          values,
          isPsaPrpBillingDefaultForProjectEnabled
        );

        formattedPutBillInput.standardLineItems = values.id
          ? []
          : lineItemInputs.standardLineItems;
        formattedPutBillInput.adhocLineItems = values.id
          ? []
          : lineItemInputs.adhocLineItems;
        formattedPutBillInput.summarizeColumn = summarizeColumnOptions ||
          billingSettings?.billLineItemsBy || [BILLING_COLUMN_TYPE.PROJECT];
        if (
          isPsaPswatBillingDraftSubStatusEnabled &&
          values.draftSubStatusUri
        ) {
          formattedPutBillInput.draftSubStatusUri = values.draftSubStatusUri;
        }
        const {
          data: {
            putBill: { billUri }
          }
        } = await putBill(formattedPutBillInput);

        setSaving(false);
        if (!isEditMode) {
          onClose();
          redirectToBill({ history, billUri, isGlobalBilling });
        }
        setEditable(false);
      } catch (error) {
        setSaving(false);
        const displayIdError =
          getValidationErrorByUri(
            error,
            'urn:replicon:validation-failure:invoice-number-is-used-by-another-invoice'
          ) ||
          getValidationErrorByDisplayText(
            error,
            'The specified Invoice already exists.'
          );

        if (displayIdError) {
          setFieldError('displayId', 'duplicateReferenceNumber');
        }
      }
    },
    [
      setSaving,
      bill,
      isPsaPrpBillingDefaultForProjectEnabled,
      summarizeColumnOptions,
      billingSettings?.billLineItemsBy,
      putBill,
      setEditable,
      onClose,
      history,
      isGlobalBilling,
      isPsaPswatBillingDraftSubStatusEnabled
    ]
  );
};

export const usePutBillFormState = ({
  projectUri,
  projectName,
  me,
  bill,
  client,
  clientCurrency,
  clientPaymentTerm,
  clientInvoiceTemplate,
  clientTaxProfile,
  poNumber,
  billingAddress,
  putBill,
  setSaving,
  onClose,
  setEditable,
  period,
  history,
  standardLineItems,
  adhocLineItems,
  summarizeColumnOptions,
  isGlobalBilling,
  invoiceDefaultTemplate,
  nextReferenceNumberText,
  billingSettings
}) => {
  const {
    isPsaPrpBillingDefaultForProjectEnabled,
    isPsaPswatBillingDraftSubStatusEnabled
  } = me.featureFlags;
  const initialValues = useInitialState({
    projectUri,
    projectName,
    me,
    bill,
    period,
    client,
    clientCurrency,
    clientPaymentTerm,
    clientInvoiceTemplate,
    clientTaxProfile,
    poNumber,
    billingAddress,
    standardLineItems,
    adhocLineItems,
    summarizeColumnOptions,
    invoiceDefaultTemplate,
    nextReferenceNumberText,
    billingSettings,
    isPsaPrpBillingDefaultForProjectEnabled
  });

  const intl = useIntl();
  const onSubmit = useOnSubmit({
    putBill,
    setSaving,
    onClose,
    setEditable,
    bill,
    history,
    summarizeColumnOptions,
    isGlobalBilling,
    intl,
    billingSettings,
    isPsaPrpBillingDefaultForProjectEnabled,
    isPsaPswatBillingDraftSubStatusEnabled
  });
  const validationSchema = useMemo(() => buildValidationSchema({ intl }), [
    intl
  ]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  });

  return formik;
};

export default usePutBillFormState;
