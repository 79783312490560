import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ChartResizeHandle } from '~/modules/common/charts/timeline/components';
import CHART_HANDLE_VARIANT_ENUM from '~/modules/common/charts/timeline/components/ChartResizeHandle/ChartHandleVariantEnum';
import {
  ProjectChartRowsLoading,
  ShowMoreRow,
  ResourceRequestSubmitAllChartBanner,
  ResourceRequestAcceptAllChartBanner,
  ResourceMatchPopover
} from '~/modules/resourcing/common/components';
import { ResourceRequestAccessLevel } from '~/types';
import useMeContext from '~/modules/me/useMeContext';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useResourceMatchPopoverHandler } from '~/modules/resourcing/common/hooks';
import RESOURCE_MATCH_POPOVER_BUTTON_ACTION from '~/modules/resourcing/common/components/ResourceMatchPopover/enum';
import GroupTitleHeader from '../GroupTitleHeader';
import NoDataChartRow from '../NoDataChartRow';
import ResourceRequestChartRow from '../ResourceRequestChartRow';
import useStyles from './useStyles';

const startLabel = <FormattedMessage id="resourceRequestChart.projectStart" />;
const endLabel = <FormattedMessage id="resourceRequestChart.projectEnd" />;

export const ResourceRequestChartContent = ({
  classes: classesOverride,
  chartDates,
  project,
  hasResourceRequests,
  resourceRequests,
  scale,
  hasMoreRows,
  loadingRows,
  loadMoreRows,
  loadingMoreRows,
  isEditSwitchEnabled,
  leftComponentWidth,
  chartDisplayDateRange,
  displayUnit,
  updateProjectEndDate,
  updateProjectStartDate,
  allocationChartRef,
  maxSkillLevel
}) => {
  const {
    userAccessRoles,
    featureFlags: {
      isPsaRmpTaskAllocation1Enabled,
      isPsaPraaResourcingUIEnabled,
      isPsaPraaResourceMatchingEnabled
    }
  } = useMeContext();

  const classes = useStyles({ classes: classesOverride });

  const { hasStartDate, hasEndDate, startDate, endDate } = project;
  const [{ start: chartStartDate }] = chartDates;

  const isPsaPraaResourcingUIAndRMRole =
    isPsaPraaResourcingUIEnabled &&
    Boolean(
      userAccessRoles?.length &&
        userAccessRoles?.includes(USER_ACCESS_ROLE.RESOURCE_MANAGER)
    );

  const {
    anchorEl,
    popoverResourceRequest,
    setAnchorEl,
    setPopoverResourceRequest,
    handleCloseResourceMatchPopover
  } = useResourceMatchPopoverHandler();

  const showResourceMatchPopover =
    isPsaPraaResourceMatchingEnabled &&
    isPsaPraaResourcingUIAndRMRole &&
    popoverResourceRequest;

  if (loadingRows) {
    return <ProjectChartRowsLoading />;
  }

  return (
    <>
      {hasResourceRequests ? (
        <div className={classes.chart}>
          {!isPsaRmpTaskAllocation1Enabled && (
            <>
              <GroupTitleHeader
                className={classNames({
                  [classes.containerReadonly]: !isEditSwitchEnabled
                })}
              >
                <FormattedMessage id="resourceRequestChartHeader.resourceRequests" />
              </GroupTitleHeader>
              {isPsaPraaResourcingUIEnabled && (
                <>
                  <ResourceRequestSubmitAllChartBanner
                    resourceRequests={resourceRequests}
                  />
                  <ResourceRequestAcceptAllChartBanner
                    projectUri={project.id}
                    resourceRequests={resourceRequests}
                  />
                </>
              )}
            </>
          )}
          {resourceRequests.map(req => {
            const { resourceAllocations, ...resourceRequest } = req;

            return (
              <ResourceRequestChartRow
                key={resourceRequest.id}
                scale={scale}
                isEditSwitchEnabled={isEditSwitchEnabled}
                chartDates={chartDates}
                resourceRequest={resourceRequest}
                leftComponentWidth={leftComponentWidth}
                resourceAllocations={resourceAllocations}
                chartDisplayDateRange={chartDisplayDateRange}
                requestAccessLevel={ResourceRequestAccessLevel.All}
                displayUnit={displayUnit}
                allocationChartRef={allocationChartRef}
                isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
                maxSkillLevel={maxSkillLevel}
                isPsaPraaResourcingUIAndRMRole={isPsaPraaResourcingUIAndRMRole}
                setAnchorEl={setAnchorEl}
                setPopoverResourceRequest={setPopoverResourceRequest}
              />
            );
          })}
          {hasStartDate && isEditSwitchEnabled && (
            <ChartResizeHandle
              leftComponentWidth={leftComponentWidth}
              variant={CHART_HANDLE_VARIANT_ENUM.LEFT}
              displayLabel={startLabel}
              value={startDate}
              limit={endDate}
              chartStartDate={chartStartDate}
              scale={scale}
              onValueChange={updateProjectStartDate}
            />
          )}
          {hasEndDate && isEditSwitchEnabled && (
            <ChartResizeHandle
              leftComponentWidth={leftComponentWidth}
              variant={CHART_HANDLE_VARIANT_ENUM.RIGHT}
              displayLabel={endLabel}
              value={endDate}
              limit={startDate}
              chartStartDate={chartStartDate}
              scale={scale}
              onValueChange={updateProjectEndDate}
            />
          )}
          {loadingMoreRows && <ProjectChartRowsLoading />}
          {!loadingMoreRows && hasMoreRows && (
            <ShowMoreRow
              loadMore={loadMoreRows}
              recordCount={resourceRequests.length}
              resourceKey="projectResourcing.showMoreRow.resourceRequestsMessage"
            />
          )}
          {showResourceMatchPopover && (
            <ResourceMatchPopover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseResourceMatchPopover}
              scale={scale}
              displayUnit={displayUnit}
              resourceRequest={popoverResourceRequest}
              allocationChartRef={allocationChartRef}
              buttonType={RESOURCE_MATCH_POPOVER_BUTTON_ACTION.ACCEPT}
            />
          )}
        </div>
      ) : (
        <NoDataChartRow
          scale={scale}
          scaleItemCount={chartDates.length}
          resourceKey="projectResourcing.noRows"
        />
      )}
    </>
  );
};

ResourceRequestChartContent.propTypes = {
  classes: PropTypes.object,
  chartDates: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  resourceRequests: PropTypes.array.isRequired,
  updateProjectStartDate: PropTypes.func,
  updateProjectEndDate: PropTypes.func,
  scale: PropTypes.string,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  displayUnit: PropTypes.string,
  hasResourceRequests: PropTypes.bool,
  leftComponentWidth: PropTypes.number.isRequired,
  hasMoreRows: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  loadingRows: PropTypes.bool,
  loadingMoreRows: PropTypes.bool.isRequired,
  allocationChartRef: PropTypes.object,
  maxSkillLevel: PropTypes.number
};

export default ResourceRequestChartContent;
