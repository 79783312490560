import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  input: {
    '&::placeholder, & input::placeholder': {
      fontSize: theme.typography.body2.fontSize,
      fontStyle: 'italic',
      opacity: '0.7 !important'
    }
  }
}));

const InputLabelProps = { shrink: true };

export const useInputFieldPropsOverride = ({ enableNoChange }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return enableNoChange
    ? {
        placeholder: formatMessage({ id: 'dropdownItem.noChange' }),
        classes,
        InputLabelProps
      }
    : {};
};

export default useInputFieldPropsOverride;
