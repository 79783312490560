import React from 'react';
import PropTypes from 'prop-types';
import { deepPure } from '~/util';
import { RequestActions } from '~/modules/resourcing/common/components/RequestActions/RequestActions';
import ViewMultipleResourceRequestsDialog from '~/modules/resourcing/common/components/ViewMultipleResourceRequestsDialog';
import ReleaseAllocationsDialog from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/ReleaseAllocationsDialog';
import ChangeResourceDialog from '~/modules/resourcing/common/components/ChangeResourceDialog';
import { PROJECT_PERMISSION } from '~/modules/common/enums';

export const LeftItemActions = ({
  actions,
  actionsClasses,
  qeIdTag,
  showReleaseResourcesDialog,
  allocatedUser,
  project,
  releaseResourceAllocation,
  onReleaseResourceAllocationSuccess,
  closeReleaseResourcesDialog,
  releaseAllocationLoading,
  showViewMultipleResourceRequestsDialog,
  onViewMultipleResourceRequestsDialogClose,
  openResourceRequestDrawer,
  showChangeResourceDialog,
  closeChangeResourceDialog,
  resetResourceAllocationChart
}) => {
  const canEditTask = project.permittedActionUris?.includes(
    PROJECT_PERMISSION.EDIT_TASK
  );

  return (
    <>
      <RequestActions
        actions={actions}
        classes={actionsClasses}
        qeIdTag={`${qeIdTag}_RequestActions`}
      />
      {showReleaseResourcesDialog && (
        <ReleaseAllocationsDialog
          allocatedUser={allocatedUser}
          projectUri={project.id}
          onReleaseResourceAllocation={releaseResourceAllocation}
          open={showReleaseResourcesDialog}
          onCancelClick={closeReleaseResourcesDialog}
          canEditTask={canEditTask}
          releaseAllocationLoading={releaseAllocationLoading}
          onReleaseSuccess={onReleaseResourceAllocationSuccess}
        />
      )}
      {showViewMultipleResourceRequestsDialog && (
        <ViewMultipleResourceRequestsDialog
          open={showViewMultipleResourceRequestsDialog}
          onClose={onViewMultipleResourceRequestsDialogClose}
          projectId={project.id}
          user={allocatedUser.user}
          resourceRequestListItemClickHandler={openResourceRequestDrawer}
        />
      )}
      {showChangeResourceDialog && (
        <ChangeResourceDialog
          canEditTask={canEditTask}
          existingResourceUser={allocatedUser}
          open={showChangeResourceDialog}
          onChangeResourceSuccess={resetResourceAllocationChart}
          onClose={closeChangeResourceDialog}
          projectId={project.id}
        />
      )}
    </>
  );
};

LeftItemActions.propTypes = {
  actions: PropTypes.array,
  actionsClasses: PropTypes.object,
  qeIdTag: PropTypes.string,
  showReleaseResourcesDialog: PropTypes.bool,
  allocatedUser: PropTypes.object,
  project: PropTypes.object,
  releaseResourceAllocation: PropTypes.func,
  closeReleaseResourcesDialog: PropTypes.func,
  releaseAllocationLoading: PropTypes.bool,
  onReleaseResourceAllocationSuccess: PropTypes.func,
  showViewMultipleResourceRequestsDialog: PropTypes.bool,
  onViewMultipleResourceRequestsDialogClose: PropTypes.func,
  openResourceRequestDrawer: PropTypes.func,
  showChangeResourceDialog: PropTypes.bool.isRequired,
  closeChangeResourceDialog: PropTypes.func.isRequired,
  resetResourceAllocationChart: PropTypes.func.isRequired
};

export default deepPure(LeftItemActions);
