import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { BindingContext } from '~/types';

export const GET_EXTENSION_FIELD_DEFINITIONS_QUERY = gql`
  query extensionFieldDefinitions($bindingContext: BindingContext!) {
    extensionFieldDefinitions(bindingContext: $bindingContext) {
      id
      definitionTypeUri
      displayText
      fileDetails {
        id
        code
        name
        uri
        enabledSourceUris
      }
      tags {
        id
        displayText
        definition {
          id
          displayText
        }
      }
    }
  }
`;

export const useGetExtensionFieldDefinitions = () => {
  const { loading, data } = useQuery(GET_EXTENSION_FIELD_DEFINITIONS_QUERY, {
    variables: {
      bindingContext: BindingContext.Project
    }
  });

  return {
    extensionFields: data?.extensionFieldDefinitions || [],
    loading
  };
};
