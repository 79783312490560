import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { GetUserProfileSkillsQuery } from './useUserProfileSkills';
import { mapResourceSkillDetailsToUserSkillInputs } from './mapSkills';

const REJECT_USER_NUDGES = gql`
  mutation RejectUserNudges($userUri: String!, $skillNames: [String!]!) {
    rejectUserNudges(userUri: $userUri, skillNames: $skillNames)
  }
`;

const updateUserProfileSkillsCache = ({
  cache,
  userUri,
  removedUserSkillsInput
}) => {
  const { userProfileSkills: cachedSkills } = cache.readQuery({
    query: GetUserProfileSkillsQuery,
    variables: { userUri }
  });

  const categoryMap = removedUserSkillsInput.reduce((acc, skill) => {
    if (!acc[skill.category.id]) acc[skill.category.id] = {};
    acc[skill.category.id][skill.name] = true;

    return acc;
  }, {});

  cache.writeQuery({
    query: GetUserProfileSkillsQuery,
    variables: { userUri },
    data: {
      userProfileSkills: {
        id: userUri,
        skills: cachedSkills.skills.filter(
          skill => !categoryMap[skill.categoryUri]?.[skill.skillName]
        )
      }
    }
  });
};

const useOnRejectUserNudges = ({ userUri }) => {
  const [rejectUserNudges] = useMutation(REJECT_USER_NUDGES, {});
  const onRejectUserNudges = useCallback(
    async rejectedSkills => {
      const userSkillsInput = rejectedSkills.flatMap(
        mapResourceSkillDetailsToUserSkillInputs
      );

      await rejectUserNudges({
        variables: {
          skillNames: userSkillsInput.map(s => s.name),
          userUri
        },
        optimisticResponse: { rejectUserNudges: true },
        update: cache => {
          updateUserProfileSkillsCache({
            cache,
            userUri,
            removedUserSkillsInput: userSkillsInput
          });
        }
      });
    },
    [rejectUserNudges, userUri]
  );

  return { onRejectUserNudges };
};

export default useOnRejectUserNudges;
