import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';
import { useHasFeatureFlag, noChange } from '~/modules/common/hooks';
import { projectWorkFlowOptions } from '../BulkProjectStatusChangeDialog/BulkProjectStatusChangeDialogContent';

const status = <FormattedMessage id="massEditProject.status" />;

const selectProps = {
  labelId: 'workflow-dropdown-label',
  id: 'workflow-dropdown',
  SelectDisplayProps: {
    role: 'listbox'
  }
};

const inputLabelProps = {
  id: 'workflow-dropdown-label'
};

export const WorkflowDropdown = ({ value, onChange }) => {
  const intl = useIntl();
  const noChangeOption = noChange(intl);
  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const options = projectWorkFlowOptions({
    intl,
    customStatusMapping,
    isCustomStatusNameEnabled
  });

  return (
    <SimpleDropdown
      ariaLabel={intl.formatMessage({
        id: 'massEditProject.status'
      })}
      variant="filled"
      label={status}
      noneOption={false}
      options={options}
      onChange={onChange}
      value={value}
      additionalOptions={[noChangeOption]}
      selectProps={selectProps}
      inputLabelProps={inputLabelProps}
    />
  );
};

WorkflowDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default WorkflowDropdown;
