import PropTypes from 'prop-types';
import React from 'react';
import { useFormikContext } from 'formik';
import { useProjectResourceAllocationsByIds } from '~/modules/resourcing/common/hooks';
import useGetUsersAssignedImpactedTaskSummary from '~/modules/resourcing/common/hooks/useGetUsersAssignedImpactedTaskSummary';
import { ChangeResourceUserModificationType } from '~/types';
import { ChangeResourceDialogContent } from './ChangeResourceDialogContent';
import { ChangeResourceDialogFooter } from './ChangeResourceDialogFooter';
import { ChangeResourceDialogTitle } from './ChangeResourceDialogTitle';
import { mapDateToUtcObject } from './hooks/useFormOnChangeHandlers';
import { useErrorMessageSummary } from './hooks';

export const ChangeResourceDialogBody = ({
  canEditTask,
  dialogTitleId,
  existingResourceUser,
  onClose,
  projectId
}) => {
  const {
    values: { asOfDate, modificationType, newResourceUser }
  } = useFormikContext();

  const { resourceAllocations } = useProjectResourceAllocationsByIds({
    allocationIds:
      existingResourceUser.roles[0].resourceAllocationReference[0].id
  });

  const asOfDateUtc =
    modificationType === ChangeResourceUserModificationType.Asof
      ? mapDateToUtcObject(asOfDate)
      : null;

  const {
    isLoading: isImpactedTaskSummaryLoading,
    usersAssignedImpactedTaskSummary
  } = useGetUsersAssignedImpactedTaskSummary({
    asOfDate: asOfDateUtc,
    includeMaxTaskEndDate: true,
    projectId,
    userIds: [existingResourceUser.user.id]
  });

  const taskAssignmentCount =
    usersAssignedImpactedTaskSummary?.[0]?.impactedTaskSummary.taskUris.length;

  const errorMessageSummary = useErrorMessageSummary({
    asOfDate: asOfDateUtc,
    canEditTask,
    existingResourceUser: existingResourceUser.user,
    isImpactedTaskSummaryLoading,
    maxTaskEndDate:
      usersAssignedImpactedTaskSummary?.[0]?.impactedTaskSummary.maxTaskEndDate,
    newResourceUser,
    projectId,
    taskAssignmentCount
  });

  return (
    <>
      <ChangeResourceDialogTitle
        dialogTitleId={dialogTitleId}
        onClose={onClose}
      />
      <ChangeResourceDialogContent
        errorMessageSummary={errorMessageSummary}
        existingResourceUser={existingResourceUser.user}
        isImpactedTaskSummaryLoading={isImpactedTaskSummaryLoading}
        projectId={projectId}
        resourceAllocation={resourceAllocations?.[0]}
        taskAssignmentCount={taskAssignmentCount}
      />
      <ChangeResourceDialogFooter
        hasError={errorMessageSummary.messages.length > 0}
        isLoading={errorMessageSummary.isLoading}
        onClose={onClose}
      />
    </>
  );
};

ChangeResourceDialogBody.propTypes = {
  canEditTask: PropTypes.bool.isRequired,
  dialogTitleId: PropTypes.string.isRequired,
  existingResourceUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ChangeResourceDialogBody;
