import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { ChartTotalItems } from '~/modules/common/charts/timeline/components';
import { FormattedHoursAndCost } from '~/modules/resourcing/common/components';
import getAllocationsTotalForResourceRequest from '~/modules/resourcing/hooks/getAllocationsTotalForResourceRequest';
import { useMeContext } from '~/modules/me';
import useStyles from './useStyles';

export const ResourceAllocationChartFooterItem = ({
  qeIdTag,
  resourceAllocation,
  contextCurrency
}) => {
  const me = useMeContext();

  const classes = useStyles();
  const {
    totalAllocatedCostForResourceRequest,
    totalAllocatedHoursForResourceRequest
  } = getAllocationsTotalForResourceRequest({
    me,
    resourceAllocations: [resourceAllocation],
    contextCurrency
  });

  const {
    project: { permittedActionUris = [] }
  } = useProjectContext();

  const total = useMemo(
    () => (
      <FormattedHoursAndCost
        classes={classes}
        hours={totalAllocatedHoursForResourceRequest}
        costs={totalAllocatedCostForResourceRequest}
        hoursSuffix="h"
        showAbbreviatedValue={false}
        hideCosts={
          !permittedActionUris.includes(
            'urn:replicon:project-action:view-cost-data'
          )
        }
      />
    ),
    [
      classes,
      totalAllocatedCostForResourceRequest,
      totalAllocatedHoursForResourceRequest,
      permittedActionUris
    ]
  );

  return <ChartTotalItems qeIdTag={qeIdTag} allocated={total} />;
};

ResourceAllocationChartFooterItem.propTypes = {
  qeIdTag: PropTypes.string,
  resourceAllocation: PropTypes.object,
  contextCurrency: PropTypes.object
};

export default ResourceAllocationChartFooterItem;
