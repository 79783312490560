/* eslint-disable react/jsx-max-depth */
import React, { Suspense, useRef, useMemo, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { LinearQueryProgress } from '~/modules/common';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import UnhandledErrorPage from '~/modules/common/unhandled-error';
import { useSubstituteUserMetadata } from '~/modules/me/useSubstituteUserMetadata';
import useOfflineState from '~/modules/common/components/Offline/useOfflineState';
import OfflineContext from '~/modules/common/components/Offline/OfflineContext';
import SideNavigation from '../SideNavigation';
import SidePanel from '../SidePanel';
import SubstituteBanner from '../SubstituteBanner';
import UserResumeParsingProcessedAlert from '../UserResumeParsingProcessedAlert';
import Routes from './Routes';
import Footer from './Footer';
import useStyles from './useStyles';
import RedirectToLandingPage from './RedirectToLandingPage';
import PageLayoutPageContainer from './PageLayoutPageContainer';
import PageLayoutContext from './PageLayoutContext';
import HideFooterContext from './HideFooterContext';

const linearProgress = <LinearQueryProgress />;

export const PageLayout = () => {
  // Used to reset the state of the ErrorBoundary after navigating away from
  // the condition that caused the error.

  const [hideFooter, setHideFooter] = useState(false);
  const isSubstituteUser = useSubstituteUserMetadata();
  const classes = useStyles({ isSubstituteUser });
  const mainRef = useRef(null);
  const contextValue = useMemo(
    () => ({
      mainRef
    }),
    [mainRef]
  );
  const history = useHistory();
  const { isOffline } = useOfflineState();

  return (
    <OfflineContext.Provider value={isOffline}>
      <PageLayoutContext.Provider value={contextValue}>
        <SubstituteBanner />
        <UserResumeParsingProcessedAlert />
        <Route path="/" exact component={RedirectToLandingPage} />
        <SideNavigation>
          <PageLayoutPageContainer>
            <main id="mainContent" className={classes.content} ref={mainRef}>
              <ErrorBoundary FallbackComponent={UnhandledErrorPage}>
                <HideFooterContext.Provider value={setHideFooter}>
                  <Suspense fallback={linearProgress}>
                    <Routes classes={classes} />
                    {!hideFooter && <Footer />}
                    {isOffline && (
                      <Redirect
                        to={{
                          pathname: '/offline',
                          state: { source: history.location }
                        }}
                      />
                    )}
                  </Suspense>
                </HideFooterContext.Provider>
              </ErrorBoundary>
            </main>
          </PageLayoutPageContainer>
          <SidePanel />
        </SideNavigation>
      </PageLayoutContext.Provider>
    </OfflineContext.Provider>
  );
};

export default PageLayout;
