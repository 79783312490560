import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useEnabledCustomFieldDefinitions } from '~/modules/customFields/hooks/useEnabledCustomFieldDefinitions';
import {
  useForm,
  useGetExtensionFieldDefinitions,
  useCreateMassEditProjectBatch
} from './hooks';
import MassEditProjectForm from './MassEditProjectForm';
import MassEditProjectProgressContent from './MassEditProjectProgressContent';

const dialogTitleId = 'mass-edit-dialog-title';
const dialogDescriptionId = 'mass-edit-dialog-description';

const confirmationDialogRole = {
  role: 'presentation'
};

export const MassEditProjectDialog = ({
  open,
  onClose,
  projects,
  onBatchComplete
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsBreakpointDown('sm');

  const {
    customFieldDefinitionsLoading,
    customFieldDefinitions
  } = useEnabledCustomFieldDefinitions('Project');
  const { loading, extensionFields } = useGetExtensionFieldDefinitions();

  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });
  const createMassEditProjectBatch = useCreateMassEditProjectBatch({
    projectIds: projects.map(p => p.id),
    setBatchState
  });
  const { values, setFieldValue, handleSubmit, handleChange } = useForm({
    customFields: customFieldDefinitions,
    extensionFields,
    createMassEditProjectBatch
  });

  if (loading || customFieldDefinitionsLoading) return null;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={confirmationDialogRole}
      aria-labelledby={dialogTitleId}
      role="dialog"
    >
      <DialogTitle id={dialogTitleId}>
        <strong>
          {formatMessage({
            id: 'massEditProject.title'
          })}
        </strong>
        {formatMessage(
          {
            id: 'massEditProject.count'
          },
          { count: projects.length || 0 }
        )}
      </DialogTitle>
      {batchState.batchInProgress || batchState.batchId ? (
        <MassEditProjectProgressContent
          onClose={onClose}
          itemCounts={projects?.length}
          batchState={batchState}
          setBatchState={setBatchState}
          onBatchComplete={onBatchComplete}
        />
      ) : (
        <>
          <DialogContent id={dialogDescriptionId}>
            <MassEditProjectForm
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </DialogContent>
          <DialogActions id="mass-edit-dialog-actions">
            <Button onClick={onClose}>
              {formatMessage({ id: 'button.cancel' })}
            </Button>
            <Button color="primary" type="submit" onClick={handleSubmit}>
              {formatMessage({ id: 'button.save' })}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

MassEditProjectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  onBatchComplete: PropTypes.func.isRequired
};

export default MassEditProjectDialog;
