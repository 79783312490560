import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me';
import { isNullOrUndefined } from '~/modules/common/util';
import { PROJECT_PROGRESS_TYPE_ENUM } from '~/modules/projects/project/common/enums/projectProgressType';

export const projectProgressSummaryQuery = gql`
  query Eager_GetProjectProgressSummaryDetails(
    $projectId: String!,
    ${`$includeRolledUpHoursSummary: Boolean = false`},
    ${`$includeRolledUpCostSummary: Boolean = false`}
  ) {
    project(projectId: $projectId) {
      id
      budgetHours @include(if: $includeRolledUpHoursSummary)
      rolledUpSummary @include(if: $includeRolledUpHoursSummary) {
        id
        actualHours,
        totalEstimatedAtCompletionHours,
        totalEstimatedRemainingHours
      }
      rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
        actualCostInProjectCurrency {
          amount
        }
        budgetedCostInProjectCurrency {
          amount
        }
        totalEstimatedAtCompletionCostInProjectCurrency {
          amount
        }
        totalExpensesInProjectCurrency {
          amount
        }
      }
    }
  }
`;

const addNullableAmounts = (resourceCost, expenseCost) =>
  isNullOrUndefined(resourceCost) && isNullOrUndefined(expenseCost)
    ? undefined
    : (resourceCost || 0) + (expenseCost || 0);

const getCostSummary = ({ rolledUpCostSummary }) => {
  const resourceCost = rolledUpCostSummary?.actualCostInProjectCurrency?.amount;
  const expense = rolledUpCostSummary?.totalExpensesInProjectCurrency?.amount;

  const actualCostAmount = addNullableAmounts(resourceCost, expense);
  const estimatedAtCompletionCostAmount =
    rolledUpCostSummary?.totalEstimatedAtCompletionCostInProjectCurrency
      ?.amount;

  return {
    actualCostAmount,
    budgetedCostAmount:
      rolledUpCostSummary?.budgetedCostInProjectCurrency?.amount,
    estimatedAtCompletionCostAmount,
    estimatedRemainingCostAmount: !isNullOrUndefined(
      estimatedAtCompletionCostAmount
    )
      ? estimatedAtCompletionCostAmount - (actualCostAmount || 0)
      : undefined
  };
};

export const useProjectProgresSummary = ({
  projectId,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  projectProgressView,
  skip = false
}) => {
  const {
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled }
  } = useMeContext();

  const includeRolledUpHoursSummary =
    isRolledUpTaskEstimateCalculationMethodEnabled &&
    projectProgressView === PROJECT_PROGRESS_TYPE_ENUM.HOUR;

  const includeRolledUpCostSummary =
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled &&
    projectProgressView === PROJECT_PROGRESS_TYPE_ENUM.COST;

  const { loading, error, data: { project } = {} } = useQuery(
    projectProgressSummaryQuery,
    {
      variables: {
        projectId,
        includeRolledUpHoursSummary,
        includeRolledUpCostSummary
      },
      skip,
      fetchPolicy: 'cache-and-network'
    }
  );

  const hourSummary = !loading
    ? {
        budgetHours: project?.budgetHours,
        rolledUpSummary: project?.rolledUpSummary
      }
    : {};

  const costSummary = !loading
    ? getCostSummary({ rolledUpCostSummary: project?.rolledUpCostSummary })
    : {};

  return {
    hourSummary,
    costSummary,
    isLoading: loading,
    error
  };
};

export default useProjectProgresSummary;
