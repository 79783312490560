import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useEmployeeTypes from '~/modules/common/enhancers/useEmployeeTypes';
import { useAdditionalOptions } from '~/modules/common/hooks';
import { useOptionsMerge } from '~/modules/common/components/SearchAutocomplete';
import { useStyledGroupLabels } from './hooks';
import GroupDropdown from './GroupDropdown';

export const EmployeeTypeDropdown = ({
  classes,
  groupSettings,
  value = '',
  policy,
  onChange,
  enableNoChangeOption = false,
  enableNoValueOption = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const { employeeType: employeeTypeLabel } = useStyledGroupLabels();
  const { employeeTypes, loading, hasMore } = useEmployeeTypes({
    searchTerm,
    policy
  });
  const additionalOptions = useAdditionalOptions({
    enableNoChangeOption,
    enableNoValueOption,
    noValueMessageId: 'dropdownItem.noEmployeeType'
  });

  const noGroupsText = groupSettings.singularName
    ? formatMessage(
        { id: 'groupsDropdown.noGroup' },
        { group: groupSettings.singularName }
      )
    : formatMessage({ id: 'employeeTypeDropdown.noEmployeeType' });

  const options = useOptionsMerge({
    options: employeeTypes,
    additionalOptions
  });

  return (
    <GroupDropdown
      hasMore={hasMore}
      classes={classes}
      options={options}
      label={employeeTypeLabel}
      value={value}
      loading={loading}
      setSearchTerm={setSearchTerm}
      noOptionsText={noGroupsText}
      onChange={onChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.employeeType'
      })}
      {...rest}
    />
  );
};

EmployeeTypeDropdown.propTypes = {
  classes: PropTypes.object,
  groupSettings: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
  policy: PropTypes.string,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool
};

export default EmployeeTypeDropdown;
