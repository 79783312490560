import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQueryParamSearchContext } from '~/modules/billing-invoicing/common/hooks';
import { BillingTransactionContext } from './BillingTransactionContext';
import { useBillingTransactionSearchCriteria } from './hooks/useBillingTransactionSearchCriteria';

export default function BillingTransactionContextProvider({
  children,
  searchState,
  queryParams,
  isAccountReceivable,
  isGlobalBilling,
  onRowSelect,
  selectedTransactionItems,
  featureFlags,
  hasRowSelector
}) {
  const key = queryParams ? queryParams.get('key') : null;

  const filterTags = ['clientTag'];

  const queryParamSearchContext = useQueryParamSearchContext({ filterTags });

  const history = useHistory();

  const [quickFilter, setQuickFilter] = useState({
    filterByAccess: !isAccountReceivable
      ? 'all-transaction'
      : 'account-receivable',
    filterByStatus: key
  });

  const handleSetQuickFilter = useCallback(
    (newAllKey, newKey) => {
      setQuickFilter({ filterByAccess: newAllKey, filterByStatus: newKey });
      const path =
        newAllKey === 'all-transaction'
          ? '/financials/billing/transactions'
          : '/financials/billing/receivable';

      isGlobalBilling && history.replace(path);
    },
    [history, isGlobalBilling]
  );

  const summaryFilterState = {
    quickFilter,
    setKeys: handleSetQuickFilter
  };

  const {
    searchCriteria,
    setSearchCriteria
  } = useBillingTransactionSearchCriteria(
    searchState,
    featureFlags?.isPsaPrpManualBulkBillCreationEnabled
  );

  const value = useMemo(
    () => ({
      searchCriteria,
      setSearchCriteria,
      summaryFilterState,
      quickFilter,
      isAccountReceivable,
      onRowSelect,
      selectedTransactionItems,
      featureFlags,
      hasRowSelector
    }),
    [
      searchCriteria,
      setSearchCriteria,
      summaryFilterState,
      quickFilter,
      isAccountReceivable,
      onRowSelect,
      selectedTransactionItems,
      featureFlags,
      hasRowSelector
    ]
  );

  const overviewCallToActionValues = useMemo(
    () => ({
      ...queryParamSearchContext.searchContext,
      summaryFilterState,
      quickFilter,
      isAccountReceivable
    }),
    [
      isAccountReceivable,
      queryParamSearchContext.searchContext,
      quickFilter,
      summaryFilterState
    ]
  );

  return (
    <BillingTransactionContext.Provider
      value={
        queryParamSearchContext.hasValue ? overviewCallToActionValues : value
      }
    >
      {children}
    </BillingTransactionContext.Provider>
  );
}

BillingTransactionContextProvider.propTypes = {
  children: PropTypes.node,
  searchState: PropTypes.string,
  queryParams: PropTypes.any,
  isAccountReceivable: PropTypes.bool,
  isGlobalBilling: PropTypes.bool,
  onRowSelect: PropTypes.func,
  selectedTransactionItems: PropTypes.array,
  featureFlags: PropTypes.object,
  hasRowSelector: PropTypes.bool
};
