import { useCallback } from 'react';
import { useTheme } from '@material-ui/core';
import getNumberSuffixFormat from '~/modules/common/components/SummaryFormattedNumber/getNumberSuffixFormat';

export const OVER_ALLOCATED_STACK = 1;

const LABEL_FONT_SIZE_PX = 7;

const calcBarHeight = ({ scale, datum }) =>
  Math.abs(scale.y(datum._y1) - scale.y(datum._y0));

const calcOverAllocatedOffset = ({ scale, datum }) =>
  (scale.y(datum._y0) - scale.y(datum._y1)) / 2;

export const useVictoryLabelCenteredOffset = () =>
  useCallback(
    ({ scale, datum }) =>
      datum._stack === OVER_ALLOCATED_STACK
        ? calcOverAllocatedOffset({ scale, datum }) - LABEL_FONT_SIZE_PX / 2
        : LABEL_FONT_SIZE_PX / 2 + calcBarHeight({ scale, datum }) / 2,
    []
  );

export const getVictoryChartLabelValue = ({ datum }) =>
  datum.labelValue || datum.value || 0;

export const getTooltipLabels = ({ datum }) => {
  const value = getVictoryChartLabelValue({ datum });

  return Math.abs(value) !== 0 && datum.childName !== 'bar'
    ? `${Math.abs(value.toFixed(1))}`
    : undefined;
};

export const useVictoryLabelValue = () =>
  useCallback(({ datum, scale, height }) => {
    const barHeight = calcBarHeight({ scale, datum });
    const renderValue = getVictoryChartLabelValue({ datum });

    if (!renderValue || barHeight <= LABEL_FONT_SIZE_PX) {
      return null;
    }
    const value = Number(renderValue.toFixed(1));
    const { formattedValue, suffix } = getNumberSuffixFormat(value);

    return `${Math.abs(formattedValue)}${suffix}`;
  }, []);

export const useVictoryLabelStyles = () => {
  const theme = useTheme();

  const makeLabelStyle = barColor => ({
    fontSize: LABEL_FONT_SIZE_PX,
    fill: theme.palette.getContrastText(barColor),
    cursor: 'pointer'
  });

  return {
    overAllocated: makeLabelStyle(
      theme.palette.resourceAllocation.overAllocated
    ),
    holiday: makeLabelStyle(theme.palette.resourceAllocation.holiday),
    timeOff: makeLabelStyle(theme.palette.resourceAllocation.timeOff),
    allocated: makeLabelStyle(theme.palette.resourceAllocation.allocated),
    requested: makeLabelStyle(theme.palette.resourceRequest.pending.main),
    available: makeLabelStyle(theme.palette.common.white),
    toBeHired: makeLabelStyle(theme.palette.resourceRequest.toBeHired.main)
  };
};
