import { makeStyles } from '@material-ui/core';

export const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: ({
    isPsaPrpManualBulkBillCreationEnabled,
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled
  }) => ({
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(2)
    },
    ...(isPsaPrpManualBulkBillCreationEnabled
      ? {
          '& th:first-child': {
            width: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
              ? 'unset'
              : theme.spacing(9.5)
          },
          '& th:nth-child(2)': {
            minWidth: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
              ? 'unset'
              : 300,
            zIndex: 2
          },
          '& td:nth-child(2)': {
            minWidth: 300,
            '& span': {
              width: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
                ? 'unset'
                : theme.spacing(80),
              whiteSpace: 'pre-line',
              overflowWrap: 'break-word',
              zIndex: 1
            }
          },
          '& tr:hover td:first-child, tr:hover td:nth-child(2)': {
            backgroundColor: ({ editable }) =>
              editable ? theme.palette.grey[300] : 'unset'
          }
        }
      : {
          '& th:first-child': {
            minWidth: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
              ? 'unset'
              : 300,
            zIndex: 2
          },
          '& td:first-child': {
            minWidth: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
              ? 'unset'
              : 300,
            '& span': {
              width: theme.spacing(80),
              whiteSpace: 'pre-line',
              overflowWrap: 'break-word',
              zIndex: 1
            }
          },
          '& tr:hover td:first-child': {
            backgroundColor: ({ editable }) =>
              editable ? theme.palette.grey[300] : 'unset'
          }
        })
  }),
  tableHeaderCell: {
    padding: theme.spacing(1, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    color: theme.palette.grey[600],
    fontSize: theme.typography.body2.fontSize,
    top: ({ headerLevel }) => theme.spacing((headerLevel + 1) * 6),
    position: 'sticky',
    zIndex: 1
  },
  tableCell: {
    cursor: ({ editable }) => (editable ? 'pointer' : 'default'),
    height: 40,
    padding: theme.spacing(0.5, 2)
  },
  tableFooter: ({
    isPsaPrpManualBulkBillCreationEnabled,
    isBulkBillSelected
  }) => ({
    ...(isPsaPrpManualBulkBillCreationEnabled &&
      isBulkBillSelected && {
        position: 'sticky',
        bottom: theme.spacing(7),
        zIndex: 7
      }),
    backgroundColor: theme.palette.grey[50],
    color: 'inherit',
    '& ul': {
      listStyleType: 'none',
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  }),
  tableFooterCell: {
    padding: theme.spacing(1, 2),
    height: 20
  }
}));

export const useColumnStyles = makeStyles(theme => ({
  bottomHeader: {
    top: 40
  },
  rowSelector: { position: 'sticky', left: 0 },
  client: {
    width: '30%',
    borderRight: `1px solid ${theme.palette.grey[100]}`
  },
  amount: {
    width: '20%'
  },
  total: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    maxWidth: 200,
    verticalAlign: 'top'
  },
  createBill: {
    '& button': {
      color: theme.palette.primary.main
    }
  },
  dynamicColumn: {
    maxWidth: theme.spacing(30),
    minWidth: theme.spacing(25)
  }
}));
