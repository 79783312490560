import { sortBy, sortedUniqBy, uniqBy } from 'lodash';

export const isValidValue = value =>
  value &&
  typeof value === 'object' &&
  Object.prototype.hasOwnProperty.call(value, 'id') &&
  Object.prototype.hasOwnProperty.call(value, 'displayText') &&
  value.id !== null &&
  value.id !== undefined &&
  value.id.length > 0 &&
  value.displayText !== null &&
  value.displayText !== undefined &&
  value.displayText.length > 0;

export const useOptionsMerge = ({
  options,
  disableSort,
  value,
  additionalOptions
}) => {
  const mergedOptions = isValidValue(value) ? options.concat(value) : options;
  const processedOptions = disableSort
    ? uniqBy(mergedOptions, option => option.displayText)
    : sortedUniqBy(
        sortBy(mergedOptions, option => option.displayText),
        option => option.displayText
      );

  if (Array.isArray(additionalOptions) && additionalOptions?.length) {
    return uniqBy(
      additionalOptions.concat(...processedOptions),
      option => option.displayText
    );
  }

  return processedOptions;
};
