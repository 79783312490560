import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, styled } from '@material-ui/core';
import AllRowSelectorFormatter from './formatters/AllRowSelectorFormatter';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.text.secondary
}));

export const buildColumns = ({
  classes,
  editable,
  availableToBillStates,
  isPsaPrpManualBulkBillCreationEnabled,
  isPsaPrpAddProjectColumnOnAvailableToBillEnabled,
  visibleColumns = []
}) => ({
  ...(isPsaPrpManualBulkBillCreationEnabled && {
    rowSelector: {
      field: 'rowSelector',
      visible: editable,
      hideInSettingsDialog: true,
      className: classes.rowSelector,
      availableToBillStates,
      value: (
        <AllRowSelectorFormatter
          availableToBillStates={availableToBillStates}
        />
      )
    }
  }),
  client: {
    field: 'client',
    visible: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? visibleColumns.includes('client')
      : true,
    required: true,
    isDefault: true,
    value: <FormattedMessage id="avaialbleToBillList.client" />,
    className: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? classes.dynamicColumn
      : classes.client
  },
  ...(isPsaPrpAddProjectColumnOnAvailableToBillEnabled && {
    project: {
      field: 'project',
      visible: visibleColumns.includes('project'),
      required: true,
      isDefault: true,
      value: <FormattedMessage id="avaialbleToBillList.project" />,
      className: classes.dynamicColumn,
      customNoValue: (
        <StyledTypography variant="body2">
          <FormattedMessage id="avaialbleToBillList.multipleProjects" />
        </StyledTypography>
      )
    },
    projectLeader: {
      field: 'projectLeader',
      visible: visibleColumns.includes('projectLeader'),
      required: false,
      isDefault: true,
      value: <FormattedMessage id="avaialbleToBillList.projectLeader" />,
      className: classes.dynamicColumn
    }
  }),
  date: {
    field: 'date',
    visible: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? visibleColumns.includes('date')
      : true,
    required: true,
    isDefault: true,
    value: <FormattedMessage id="avaialbleToBillList.date" />
  },
  amount: {
    field: 'amount',
    visible: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? visibleColumns.includes('amount')
      : true,
    required: true,
    isDefault: true,
    value: <FormattedMessage id="avaialbleToBillList.amount" />,
    align: 'right',
    className: classes.amount
  },
  createBill: {
    field: 'createBill',
    visible: editable,
    hideInSettingsDialog: true,
    value: '',
    className: classes.createBill
  }
});
