import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import CalendarDate from '../CalendarDate';
import Date from '../Date';
import ReadOnlyContainer from '../ReadOnlyContainer';
import {
  toRepliconDate,
  getLuxonJsDateFormatFromMe
} from '../../dates/convert';
import { equalDateTimes } from '../../dates/compare';

export const handleDateChange = ({ onChange, setSelectedDate }) => date => {
  setSelectedDate(date);
  onChange(toRepliconDate(date));
};

export const handleInputChange = ({
  onChange,
  setSelectedDate,
  setError
}) => newDate => {
  if (newDate && !newDate.isValid) {
    if (setError) {
      setError('Invalid date');
    }

    return;
  }

  if (setError) {
    setError(undefined);
  }

  setSelectedDate(newDate);
  onChange(toRepliconDate(newDate));
};

const useStyles = makeStyles(theme => ({
  input: {
    '&.MuiFilledInput-adornedEnd': {
      paddingRight: 0
    },
    '&.MuiInput-adornedEnd': {
      paddingRight: 0
    }
  }
}));

export const DateField = ({
  dataQeId,
  editable,
  classes,
  value,
  onChange,
  setError,
  label,
  className,
  containerClassName,
  minHeight,
  underline = true,
  readOnlyComponent = 'Typography',
  keyboard,
  variant,
  ariaLabel,
  hiddenLabel,
  useDateFieldStyles,
  startAdornment,
  ...rest
}) => {
  const me = useMeContext();
  const dateFieldClasses = useStyles({ classes });
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = me;

  const date = value
    ? typeof value === 'string'
      ? DateTime.fromISO(value, { zone: 'utc' })
      : DateTime.fromObject(value, { zone: 'utc' })
    : null;

  const [selectedDate, setSelectedDate] = useState(date);

  // this is the recommended methodology to update state for prop changes https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  if (!equalDateTimes(selectedDate, date)) {
    setSelectedDate(date);
  }

  const onDateChange = handleDateChange({ setSelectedDate, onChange });
  const onInputChange = handleInputChange({
    setSelectedDate,
    onChange,
    setError
  });

  const luxonFormat = getLuxonJsDateFormatFromMe(me);

  const inputProps = useMemo(
    () => ({
      'aria-label': ariaLabel,
      readOnly: isPsaRmpTaskAllocation1Enabled ? false : !keyboard,
      ...(dataQeId && {
        'data-qe-id': `${dataQeId}_DateField_CalendarDateInput`
      })
    }),
    [ariaLabel, dataQeId, isPsaRmpTaskAllocation1Enabled, keyboard]
  );

  return (
    <>
      {editable ? (
        <CalendarDate
          InputProps={{
            className: dateFieldClasses.input,
            id: dataQeId,
            disableUnderline: !underline,
            inputProps,
            startAdornment
          }}
          value={selectedDate}
          onAccept={onDateChange}
          onChange={onInputChange}
          label={label}
          inputVariant={variant}
          format={luxonFormat}
          keyboard={keyboard}
          data-qe-id={dataQeId}
          hiddenLabel={hiddenLabel}
          {...rest}
        />
      ) : (
        <ReadOnlyContainer
          className={containerClassName}
          label={label}
          minHeight={minHeight}
          underline={underline}
          {...rest}
        >
          <Date
            component={readOnlyComponent}
            value={value}
            data-qe-id={dataQeId}
          />
        </ReadOnlyContainer>
      )}
    </>
  );
};

DateField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  editable: PropTypes.bool,
  label: PropTypes.node,
  readOnlyComponent: PropTypes.oneOf(['Typography', 'TextField']),
  ...CalendarDate.propTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  keyboard: PropTypes.bool,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  dataQeId: PropTypes.string
};

export default DateField;
