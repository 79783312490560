import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const REQUISITION_STATUS_QUERY = gql`
  query GetRequisitionData($resourceRequestUri: String!) {
    getRequisitionData(resourceRequestUri: $resourceRequestUri) {
      id
      status
    }
  }
`;

const useFetchRequisitionHiringStatus = ({ resourceRequestUri, skip }) => {
  const { data } = useQuery(REQUISITION_STATUS_QUERY, {
    variables: { resourceRequestUri },
    skip
  });

  return get(data, 'getRequisitionData.status');
};

export default useFetchRequisitionHiringStatus;
