import React, { useMemo, useState, useCallback } from 'react';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const mapOption = option => ({
  ...option,
  value: option.id,
  label: option.displayText || option.name,
  key: option.key || option.displayText || option.name,
  displayText: option.displayText || option.name
});

export const mapOptions = options => options.map(mapOption);

export const matchOption = fetchOptions => async (_, term) => {
  if (!term) {
    return null;
  }

  const options = await fetchOptions(cleanSearchTerm(term));

  // required because we inject the 'NONE' client side
  const option =
    term === NONE_OPTION_KEY
      ? options.shift()
      : options.filter(r => r.value !== NONE_OPTION_KEY)[0];

  if (option) {
    return {
      ...option,
      key: option.key || option.displayText || option.name
    };
  }

  return null;
};

export const withCriteriaProvider = (tag, context) => BaseComponent => ({
  fetchOptions,
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const $fetchOptions = useCallback(
    async searchTerm => mapOptions(await fetchOptions(searchTerm)),
    [fetchOptions]
  );

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption($fetchOptions),
      selected,
      setSelected,
      fetchOptions: $fetchOptions,
      suggestions: $fetchOptions
    }),
    [$fetchOptions, selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

export const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
