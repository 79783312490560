import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateField } from '~/modules/common/components';
import {
  getLuxonJsDateFormatFromMe,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  BillingPlanFrequencyDropdown,
  DayOfMonthDropdown,
  DayOfWeekDropdown,
  SemiMonthlyDaysDropdown
} from '~/modules/projects/project/common/components';
import { BILLING_PLAN_FREQUENCY_ENUM } from '~/modules/projects/project/common/enums/billingPlanFrequencyLegacy';
import { BillingFrequency } from '~/types';
import {
  propertiesToRelativeDateMap,
  relativeDateToPropertyMap,
  getRelativeDateFromAbsoluteDate
} from './util';

const useStyles = makeStyles(theme => ({
  date: { width: '45%' },
  offset: {
    maxWidth: 'unset'
  },
  frequency: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    whiteSpace: 'nowrap'
  },
  prefix: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    width: 60,
    whiteSpace: 'break-spaces',
    lineHeight: '1.22em',
    flexGrow: 0,
    flexShrink: 0,
    display: 'inline-flex',
    backgroundColor: '#e9e9e9',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid #00000061`,
    paddingTop: theme.spacing(2.9),
    paddingBottom: theme.spacing(0.8)
  }
}));

export const BillFrequencyDropdown = ({
  value: frequency,
  billFrequencyAbsoluteDate: absoluteDate,
  onFrequencyChange: onFrequencyChangeCallback,
  onBillFrequencyAbsoluteDateChange
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const me = useMeContext();

  const dateFormat =
    frequency === BillingFrequency.Biweekly
      ? `EEEE (${getLuxonJsDateFormatFromMe(me)})`
      : 'MMM d';

  const onFrequencyChange = useCallback(
    value => {
      onFrequencyChangeCallback(value);
      onBillFrequencyAbsoluteDateChange(
        propertiesToRelativeDateMap[value.id](),
        getRelativeDateFromAbsoluteDate(
          propertiesToRelativeDateMap[value.id](),
          value.id
        )
      );
    },
    [onBillFrequencyAbsoluteDateChange, onFrequencyChangeCallback]
  );

  const onDayOfWeekChange = useCallback(
    ({ id: dayOfWeek }) => {
      onBillFrequencyAbsoluteDateChange(
        propertiesToRelativeDateMap[frequency](dayOfWeek),
        getRelativeDateFromAbsoluteDate(
          propertiesToRelativeDateMap[frequency](dayOfWeek),
          frequency
        )
      );
    },
    [frequency, onBillFrequencyAbsoluteDateChange]
  );
  const onDayOfMonthChange = useCallback(
    ({ id: dayOfMonth }) => {
      onBillFrequencyAbsoluteDateChange(
        propertiesToRelativeDateMap[frequency](dayOfMonth),
        getRelativeDateFromAbsoluteDate(
          propertiesToRelativeDateMap[frequency](dayOfMonth),
          frequency
        )
      );
    },
    [frequency, onBillFrequencyAbsoluteDateChange]
  );
  const onSemiMonthlyDaysChange = useCallback(
    ({ id: dayOfMonth }) => {
      onBillFrequencyAbsoluteDateChange(
        propertiesToRelativeDateMap[frequency](dayOfMonth),
        getRelativeDateFromAbsoluteDate(
          propertiesToRelativeDateMap[frequency](dayOfMonth),
          frequency
        )
      );
    },
    [frequency, onBillFrequencyAbsoluteDateChange]
  );
  const onRelativeDateChange = useCallback(
    newDate => {
      onBillFrequencyAbsoluteDateChange(
        mapRepliconDateToMidnightUTCString(newDate),
        getRelativeDateFromAbsoluteDate(
          mapRepliconDateToMidnightUTCString(newDate),
          frequency
        )
      );
    },
    [frequency, onBillFrequencyAbsoluteDateChange]
  );

  const offsetDropdownClasses = useMemo(() => ({ root: classes.offset }), [
    classes.offset
  ]);

  const frequencyOffset =
    relativeDateToPropertyMap[frequency] &&
    relativeDateToPropertyMap[frequency](absoluteDate);

  return (
    <div className={classes.frequency}>
      <BillingPlanFrequencyDropdown
        frequencies={Object.values(BILLING_PLAN_FREQUENCY_ENUM)}
        value={frequency}
        onChange={onFrequencyChange}
        label={formatMessage({ id: 'billingCard.subTitle.billFrequency' })}
      />

      {frequency === BillingFrequency.Weekly && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billingCard.subTitle.on" />
          </Typography>
          <DayOfWeekDropdown
            value={frequencyOffset}
            onChange={onDayOfWeekChange}
            classes={offsetDropdownClasses}
          />
        </>
      )}
      {frequency === BillingFrequency.Monthly && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billingCard.subTitle.onThe" />
          </Typography>
          <DayOfMonthDropdown
            value={frequencyOffset}
            onChange={onDayOfMonthChange}
            classes={offsetDropdownClasses}
          />
        </>
      )}
      {frequency === BillingFrequency.Semimonthly && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billingCard.subTitle.onThe" />
          </Typography>
          <SemiMonthlyDaysDropdown
            value={frequencyOffset}
            onChange={onSemiMonthlyDaysChange}
            classes={offsetDropdownClasses}
          />
        </>
      )}
      {(frequency === BillingFrequency.Yearly ||
        frequency === BillingFrequency.Quarterly ||
        frequency === BillingFrequency.Biweekly) && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage
              id={
                frequency === BillingFrequency.Yearly
                  ? 'billingCard.subTitle.on'
                  : 'billingCard.subTitle.from'
              }
            />
          </Typography>
          <div className={classes.date}>
            <DateField
              clearable={false}
              value={absoluteDate}
              editable
              format={dateFormat}
              onChange={onRelativeDateChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

BillFrequencyDropdown.propTypes = {
  value: PropTypes.string,
  onFrequencyChange: PropTypes.func,
  onBillFrequencyAbsoluteDateChange: PropTypes.func,
  billFrequencyAbsoluteDate: PropTypes.string
};

export default BillFrequencyDropdown;
