import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import {
  useResourceRequestToolbarContext,
  useRefetchQueriesOnTaskAllocationModification
} from '~/modules/projects/resourcing-plan/hooks';
import { TaskAllocationTimelineBlocks } from '~/modules/resourcing/common/components/ResourceRequestUserAllocationBlock';
import {
  TaskAllocationTimelineEditorOverlay,
  ResourceAllocationChangeSnackBar,
  UserRoleActualsTimeLineRow
} from '~/modules/resourcing/common/components';
import {
  getUserActualTotalHoursByPeriod,
  getUserAllocatedTotalHoursPeriod
} from '~/modules/resourcing/util';
import {
  useUpdateTaskResourceUserAllocation,
  useCreateTaskResourceUserAllocation
} from '~/modules/common/components/TaskDrawer/common/hooks';
import { useResourceTimelineEditorChangeHandlers } from '~/modules/resourcing/common/hooks';
import { useTaskAssignmentTimelineEditorChangeHandlers } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';
import { useDialogState } from '~/modules/common/hooks';
import { useUserAllocationsSummaryContext } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/components/ResourceAllocationChartRow/UserAllocationsSummaryContext.js';
import { RangeBoundaries } from '~/modules/common/charts/timeline/components';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import {
  RemoveTaskAllocationConfirmationDialog,
  RemoveTaskAssignmentConfirmationDialog
} from '~/modules/projects/resourcing-plan/components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden'
  },
  actualsRoot: ({ timelineRowCount }) => ({
    minHeight: (timelineRowCount + 1) * theme.spacing(4) + 4
  })
}));

const mapTaskAllocationToAllocationTimeline = ({
  user,
  userScheduleDetails,
  taskResourceUserAllocation = {}
}) => {
  const { displayText, id, uri } = user;
  const { scheduleDurationByPeriodMap } = userScheduleDetails;

  return {
    user: {
      id,
      uri,
      scheduleDurationByPeriodMap,
      displayText,
      user: {
        id,
        uri,
        displayText
      }
    },
    ...taskResourceUserAllocation
  };
};

export const getOverDistributedDisplayProperties = ({
  userOverDistributedPeriodsMap
}) => ({ periodStart }) => ({
  isOverDistributed: Boolean(userOverDistributedPeriodsMap[periodStart.toISO()])
});

const getActualSummaryBlockPopupProps = ({
  userActualTotalHours,
  userAllocatedTotalHours
}) => ({
  userActualTotalHours,
  userAllocatedTotalHours
});

export const TaskAssignmentTimeline = ({
  chartDisplayPeriods,
  handleRemoveTaskAssignment,
  user,
  taskActualSeriesData,
  taskResourceEstimate,
  taskResourceUserAllocation,
  userScheduleDetails
}) => {
  const classes = useStyles({
    timelineRowCount: Boolean(
      taskResourceUserAllocation || taskActualSeriesData
    )
  });
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();

  const {
    scale,
    chartDates,
    dateRange,
    displayUnit,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();
  const {
    project,
    projectObjectPermissions: { canEditTasks, canViewCost }
  } = useProjectContext();
  const { id: projectId, startDate, endDate } = project;
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: ''
  });

  const {
    loadingResourceAllocation,
    resourceAllocationScheduleRules,
    userOverDistributedPeriodsMap,
    userTaskAllocationsSummaryLoading,
    userTaskAllocationsSummaryScheduleRules,
    userCostRateDetails,
    handleRemoveResourceAllocation
  } = useUserAllocationsSummaryContext();

  const updateTaskResourceUserAllocation = useUpdateTaskResourceUserAllocation();
  const createTaskResourceUserAllocation = useCreateTaskResourceUserAllocation();

  const [allocationPeriodEditTarget, setAllocationPeriodEditTarget] = useState(
    null
  );

  const {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  } = useResourceTimelineEditorChangeHandlers({
    allocationPeriodEditTarget,
    chartDates,
    isPercentageMode: displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE,
    setAllocationPeriodEditTarget,
    resourceAllocation: taskResourceUserAllocation || {}
  });

  const {
    open: isRemoveTaskAssignmentDialogOpen,
    openDialog: openRemoveTaskAssignmentDialog,
    closeDialog: closeRemoveTaskAssignmentDialog
  } = useDialogState(false);

  const {
    open: isRemoveTaskAllocationDialogOpen,
    openDialog: openRemoveTaskAllocationDialog,
    closeDialog: closeRemoveTaskAllocationDialog
  } = useDialogState(false);

  const {
    onPeriodClose,
    onAllocationChange
  } = useTaskAssignmentTimelineEditorChangeHandlers({
    updateTaskResourceUserAllocation,
    createTaskResourceUserAllocation,
    taskResourceUserAllocation,
    taskResourceEstimate,
    projectId,
    userId: user.id,
    openRemoveTaskAllocationDialog,
    setSnackBarState
  });

  const mappedTaskAllocation = mapTaskAllocationToAllocationTimeline({
    user,
    userScheduleDetails,
    taskResourceUserAllocation
  });

  const onRemoveTaskAssignmentSuccess = useCallback(
    ({ taskId }) => {
      handleRemoveTaskAssignment({ taskId });
    },
    [handleRemoveTaskAssignment]
  );

  const refetchQueries = useRefetchQueriesOnTaskAllocationModification({
    userId: user.id,
    projectId
  });

  const userAllocatedTotalHours = isResourceActualModeEnabled
    ? getUserAllocatedTotalHoursPeriod({
        allocations: taskResourceUserAllocation
          ? [taskResourceUserAllocation]
          : [],
        chartDisplayDateRange: dateRange,
        chartDisplayPeriods
      })
    : [];

  const userActualTotalHours = getUserActualTotalHoursByPeriod({
    actualSeries: taskActualSeriesData ? [taskActualSeriesData] : [],
    scale
  });

  return (
    <div
      className={classNames(classes.root, {
        [classes.actualsRoot]:
          isResourceActualModeEnabled && isRmpTaskAllocationPhase2Enabled
      })}
    >
      <RangeBoundaries
        chartStartDate={dateRange.startDate}
        scale={scale}
        start={startDate}
        end={endDate}
      />
      <TaskAllocationTimelineBlocks
        taskAllocation={mappedTaskAllocation}
        chartDisplayPeriods={chartDisplayPeriods}
        scale={scale}
        isEditable={canEditTasks}
        chartDisplayDateRange={dateRange}
        onAllocationChange={onAllocationChange}
        handleAllocationPeriodClick={handleAllocationPeriodClick}
        getCustomDisplayPeriodProps={
          taskResourceUserAllocation &&
          getOverDistributedDisplayProperties({
            userOverDistributedPeriodsMap
          })
        }
        userCostRateDetails={userCostRateDetails}
        hideCost={!isRmpTaskAllocationPhase2Enabled}
      />
      {isResourceActualModeEnabled && isRmpTaskAllocationPhase2Enabled && (
        <UserRoleActualsTimeLineRow
          actualSummaryBlockPopupProps={getActualSummaryBlockPopupProps({
            userActualTotalHours,
            userAllocatedTotalHours
          })}
          actualsData={taskActualSeriesData}
          chartDisplayDateRange={dateRange}
          chartDisplayPeriods={chartDisplayPeriods}
          topOffSet={40}
          resourceAllocations={
            taskResourceUserAllocation && [taskResourceUserAllocation]
          }
          scale={scale}
          user={user}
          userScheduleDetails={userScheduleDetails}
        />
      )}
      {canEditTasks && allocationPeriodEditTarget?.anchorEl && (
        <TaskAllocationTimelineEditorOverlay
          chartDisplayDateRange={dateRange}
          scale={scale}
          loadingResourceAllocation={loadingResourceAllocation}
          resourceAllocationScheduleRules={resourceAllocationScheduleRules}
          userTaskAllocationsSummaryLoading={userTaskAllocationsSummaryLoading}
          userTaskAllocationsSummaryScheduleRules={
            userTaskAllocationsSummaryScheduleRules
          }
          allocationPeriodEditTarget={allocationPeriodEditTarget}
          onChange={onPeriodClose}
          handleAllocationPeriodClose={handleAllocationPeriodClose}
          taskResourceUserAllocation={mappedTaskAllocation}
          setNextPeriod={setNextPeriod}
          setPreviousPeriod={setPreviousPeriod}
          userId={user.id}
          resourceAvailabilitySummaryByPeriodMap={
            userScheduleDetails.resourceAvailabilitySummaryByPeriodMap
          }
        />
      )}

      {isRemoveTaskAllocationDialogOpen && (
        <RemoveTaskAllocationConfirmationDialog
          projectId={projectId}
          task={taskResourceEstimate.task}
          user={user}
          open={isRemoveTaskAllocationDialogOpen}
          onClose={closeRemoveTaskAllocationDialog}
          openRemoveTaskAssignmentDialog={openRemoveTaskAssignmentDialog}
          onRemoveTaskAllocationSuccess={closeRemoveTaskAllocationDialog}
          refetchQueries={refetchQueries}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
        />
      )}
      {isRemoveTaskAssignmentDialogOpen && (
        <RemoveTaskAssignmentConfirmationDialog
          projectId={projectId}
          task={taskResourceEstimate.task}
          user={user}
          open={isRemoveTaskAssignmentDialogOpen}
          onClose={closeRemoveTaskAssignmentDialog}
          onRemoveTaskAssignmentSuccess={onRemoveTaskAssignmentSuccess}
          refetchQueries={refetchQueries}
          canViewCost={canViewCost && isRmpTaskAllocationPhase2Enabled}
        />
      )}

      {snackBarState.open && (
        <ResourceAllocationChangeSnackBar
          snackBarState={snackBarState}
          setSnackBarState={setSnackBarState}
        />
      )}
    </div>
  );
};

TaskAssignmentTimeline.propTypes = {
  taskActualSeriesData: PropTypes.object,
  taskResourceUserAllocation: PropTypes.object,
  user: PropTypes.object,
  userScheduleDetails: PropTypes.object,
  chartDisplayPeriods: PropTypes.array.isRequired,
  taskResourceEstimate: PropTypes.object,
  handleRemoveTaskAssignment: PropTypes.func
};

export default TaskAssignmentTimeline;
