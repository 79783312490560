export const useFormOnChangeHandlers = ({
  setFieldValue,
  values,
  checkForExpenseProduct
}) => ({
  onBudgetHoursChange: event =>
    setFieldValue('budgetHours', event.target.value || 0),
  onBudgetedCostAmountChange: event =>
    setFieldValue('budgetedCost.amount', event.target.value || 0),
  onBudgetedCostCurrencyChange: value => {
    const { budgetedCost } = values;

    setFieldValue('budgetedCost.currency', value);
    if (value && budgetedCost && budgetedCost.id !== value.id) {
      setFieldValue('isBudgetCurrencyChanged', true);
    }
  },
  onTotalEstimatedContractAmountChange: event =>
    setFieldValue('totalEstimatedContract.amount', event.target.value || 0),
  onTotalEstimatedContractCurrencyChange: value =>
    setFieldValue('totalEstimatedContract.currency', value),
  onLimitIssuedBillsToTCVOnChange: event =>
    setFieldValue('limitIssuedBillsToTCV', event.target.checked),
  onEstimatedHoursChange: event =>
    setFieldValue('estimatedHours', event.target.value || 0),
  onEstimatedCostAmountChange: event =>
    setFieldValue('estimatedCost.amount', event.target.value || 0),
  onEstimatedCostCurrencyChange: value =>
    setFieldValue('estimatedCost.currency', value),

  onResourceCostAmountChange: event =>
    setFieldValue('resourceBudgetedCost.amount', event.target.value || 0),
  onResourceCostCurrencyChange: value => {
    setFieldValue('budgetedCost.currency', value);
    setFieldValue('resourceBudgetedCost.currency', value);
    setFieldValue('expenseBudgetedCost.currency', value);
  },
  onExpenseCostAmountChange: event =>
    setFieldValue('expenseBudgetedCost.amount', event.target.value || 0),
  onExpenseCostCurrencyChange: value => {
    setFieldValue('budgetedCost.currency', value);
    setFieldValue('expenseBudgetedCost.currency', value);
    setFieldValue('resourceBudgetedCost.currency', value);
  },

  onProjectCurrencyChange: value => {
    const {
      budgetedCost,
      resourceBudgetedCost,
      expenseBudgetedCost,
      estimatedCost,
      totalEstimatedContract,
      projectCurrency
    } = values;

    if (value && projectCurrency && projectCurrency.id !== value.id) {
      setFieldValue('isProjectCurrencyChanged', true);
    }

    setFieldValue('projectCurrency', value);

    if (checkForExpenseProduct) {
      if (
        resourceBudgetedCost.amount === 0 &&
        expenseBudgetedCost.amount === 0 &&
        budgetedCost.amount === 0
      ) {
        setFieldValue('resourceBudgetedCost.currency', value);
        setFieldValue('expenseBudgetedCost.currency', value);
        setFieldValue('budgetedCost.currency', value);
      }
    } else if (budgetedCost.amount === 0)
      setFieldValue('budgetedCost.currency', value);

    if (estimatedCost.amount === 0)
      setFieldValue('estimatedCost.currency', value);
    if (totalEstimatedContract.amount === 0)
      setFieldValue('totalEstimatedContract.currency', value);
  },
  onCostTypeChange: value => {
    setFieldValue('costType', value);
  }
});
