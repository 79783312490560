import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AttachmentIcon from '@material-ui/icons/AttachmentSharp';
import { FormControl, FormHelperText } from '@material-ui/core';
import EditableCard, {
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import FileWebAddressDialog from '~/modules/extensionFields/components/FileWebAddressDialog';
import { useAttachmentsHandlers } from './hooks';
import NewAttachmentButton from './NewAttachmentButton';
import AttachmentsList from './AttachmentsList';
import NoAttachments from './NoAttachments';

const title = <FormattedMessage id="attachments.documents" />;
const cardAvatar = <AttachmentIcon />;

export const InnerAttachmentsBlock = ({
  attachments,
  onDialogOpen,
  onDialogClose,
  onDialogOK,
  isDialogOpen,
  deleteAttachment,
  editable,
  error,
  isUploading,
  isLoading,
  enabledSourceUris,
  ariaAttributes,
  AttachmentStatusComponent,
  AttachmentStatusActionComponent
}) => (
  <EditableCard
    editable={false}
    ariaLabelKey="attachments.attachmentsCardDialog"
  >
    <ReadOnly
      avatar={cardAvatar}
      title={title}
      isLoading={isLoading}
      ariaAttributes={ariaAttributes}
    >
      <ReadOnlyContent>
        {attachments.length > 0 && (
          <AttachmentsList
            attachments={attachments}
            deleteAttachment={deleteAttachment}
            editable={editable}
            AttachmentStatusComponent={AttachmentStatusComponent}
          />
        )}
        {error && (
          <FormControl error>
            <FormHelperText>{error}</FormHelperText>
          </FormControl>
        )}
        {attachments.length === 0 && <NoAttachments />}
        {editable && (
          <NewAttachmentButton
            onClick={onDialogOpen}
            disabled={isLoading}
            hasAttachment={attachments.length > 0}
          />
        )}
        <FileWebAddressDialog
          open={isDialogOpen}
          onCancel={onDialogClose}
          onOK={onDialogOK}
          enabledSourceUris={enabledSourceUris}
          showWebAddressNameField
          initialValue={null}
          isUploading={isUploading}
          multiple
        />
        {AttachmentStatusActionComponent && (
          <AttachmentStatusActionComponent attachments={attachments} />
        )}
      </ReadOnlyContent>
    </ReadOnly>
  </EditableCard>
);

InnerAttachmentsBlock.propTypes = {
  attachments: PropTypes.array.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onDialogOK: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool,
  deleteAttachment: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  error: PropTypes.string,
  isUploading: PropTypes.bool,
  isLoading: PropTypes.bool,
  enabledSourceUris: PropTypes.array,
  ariaAttributes: PropTypes.object,
  AttachmentStatusComponent: PropTypes.func,
  AttachmentStatusActionComponent: PropTypes.func
};

const AttachmentsBlock = ({
  isLoadingDetails,
  attachToUri,
  editable,
  customMetadataFn,
  ariaAttributes,
  AttachmentStatusComponent,
  AttachmentStatusActionComponent
}) => {
  const {
    attachments,
    isDialogOpen,
    onDialogOpen,
    onDialogClose,
    onFileUpload,
    isUploading,
    isLoading,
    error,
    deleteAttachment,
    enabledSourceUris
  } = useAttachmentsHandlers({ attachToUri, customMetadataFn });

  return (
    <InnerAttachmentsBlock
      attachments={attachments}
      onDialogOpen={onDialogOpen}
      onDialogClose={onDialogClose}
      isDialogOpen={isDialogOpen}
      attachToUri={attachToUri}
      deleteAttachment={deleteAttachment}
      editable={editable}
      onDialogOK={onFileUpload}
      error={error}
      isUploading={isUploading}
      isLoading={isLoading || isLoadingDetails}
      enabledSourceUris={enabledSourceUris}
      ariaAttributes={ariaAttributes}
      AttachmentStatusComponent={AttachmentStatusComponent}
      AttachmentStatusActionComponent={AttachmentStatusActionComponent}
    />
  );
};

AttachmentsBlock.propTypes = {
  attachToUri: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  isLoadingDetails: PropTypes.bool,
  customMetadataFn: PropTypes.func,
  ariaAttributes: PropTypes.object,
  AttachmentStatusComponent: PropTypes.func,
  AttachmentStatusActionComponent: PropTypes.func
};

export default memo(AttachmentsBlock);
