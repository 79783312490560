import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const RESOURCE_USER_AVAILABLE_HOURS_FOR_DATE_RANGE_QUERY = gql`
  query GetResourceUserAvailableHoursForDateRange(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
  ) {
    resourceUsers2(page: $page, pagesize: $pagesize, filter: $filter) {
      id
      timeOffDuration
      holidayDuration
      allocatedDuration
      scheduleDuration
    }
  }
`;

const getQueryVariables = ({ resourceUserId, startDate, endDate }) => ({
  page: 1,
  pagesize: 1,
  filter: {
    userIds: [resourceUserId],
    availabilityPercentageInRange: {
      dateRange: {
        startDate,
        endDate
      },
      number: 0
    }
  }
});

const getAvailableDuration = ({ loading, data }) => {
  const resourceUserDurations = data?.resourceUsers2?.[0];

  return loading
    ? 0
    : resourceUserDurations?.scheduleDuration -
        resourceUserDurations?.allocatedDuration -
        resourceUserDurations?.holidayDuration -
        resourceUserDurations?.timeOffDuration;
};

const getWorkDuration = ({ loading, data }) => {
  const resourceUserDurations = data?.resourceUsers2?.[0];

  return loading
    ? 0
    : resourceUserDurations?.scheduleDuration -
        resourceUserDurations?.holidayDuration -
        resourceUserDurations?.timeOffDuration;
};

const mapDurationValues = ({ data, loading }) => ({
  availableDuration: getAvailableDuration({ loading, data }),
  workDuration: getWorkDuration({ loading, data }),
  allocatedDuration: data?.resourceUsers2?.[0]?.allocatedDuration
});

const makeRefetchCallback = refetch => async ({
  resourceUserId,
  startDate: newStartDate,
  endDate: newEndDate
}) => {
  const { loading, error, data } = await refetch(
    getQueryVariables({
      resourceUserId,
      startDate: newStartDate,
      endDate: newEndDate
    })
  );

  return {
    ...mapDurationValues({ data, loading }),
    loading,
    error
  };
};

export const useTotalResourceAvailableDurationForDateRange = ({
  resourceUserId,
  startDate,
  endDate,
  skip
}) => {
  const { loading, error, data, refetch } = useQuery(
    RESOURCE_USER_AVAILABLE_HOURS_FOR_DATE_RANGE_QUERY,
    {
      variables: getQueryVariables({ resourceUserId, startDate, endDate }),
      skip,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    }
  );

  return {
    ...mapDurationValues({ data, loading }),
    loading,
    error,
    refetchTotalAvailableDurationForDateRange: makeRefetchCallback(refetch)
  };
};

export default useTotalResourceAvailableDurationForDateRange;
