import React, { useMemo, useState, useCallback } from 'react';
import {
  Grid,
  TextField,
  Collapse,
  InputAdornment,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  useOnBillInfoChange,
  useTaxProfileOptions
} from '~/modules/billing-invoicing/bill/hooks';
import {
  DateField,
  InvoiceTemplateDropdown,
  NumberTextField,
  ReadOnlyContainer
} from '~/modules/common/components';
import { SearchableClientDropdown } from '~/modules/projects/project/common/components';
import { getError, hasError } from '~/util';
import {
  FormCurrencyDropdown,
  ReferenceNumberField,
  ShowCommentsOnInvoiceCheckBox
} from '~/modules/billing-invoicing/common/components';
import { SearchableTaxProfileDropdown } from '~/modules/common/components/TaxProfileDropdown';
import {
  mapRepliconDateToUtcObject,
  mapRepliconDateToJSDate,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';
import ToggleDetailsButton from '~/modules/common/components/CollapsibleDetailsPanel/ToggleDetailsButton';
import { useBillInfoResourceHook } from '~/modules/billing-invoicing/bill/enhancers';
import { useMeContext } from '~/modules/me';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';
import { dateFormatMap } from '~/modules/common/dates/dateFormats';
import withStyles from './withBillInfoStyles';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './PeriodDaterangePicker.css';
import { useBillPeriodSelector } from './useBillPeriodSelector';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[850],
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    '&:hover': {
      background: 'rgb(0 0 0 / 12%)'
    },
    borderRadius: [[theme.shape.borderRadius, theme.shape.borderRadius, 0, 0]],
    paddingLeft: theme.spacing(0.45),
    paddingTop: theme.spacing(0.45),
    borderBottom: ({ errors }) =>
      getError(errors, 'period')
        ? '2px solid #d9240f'
        : '1px solid rgba(0, 0, 0, 0.42)'
  },
  error: {
    color: '#d9240f',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(1.4)
  }
}));

export const BillInfo = ({
  classes,
  intl,
  errors,
  values,
  hasClient,
  setFieldValue,
  containerClass,
  fieldContainerClass,
  isCollapsible = false,
  isBillUnpaid = true,
  isSubmitting,
  invoiceDefaultTemplate
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const me = useMeContext();
  const { featureFlags } = me;

  const { loading, taxProfiles, handleInputChange } = useTaxProfileOptions();

  const {
    client,
    displayId,
    description,
    issueDate,
    paymentTerms,
    invoiceTemplate,
    poNumber,
    dueDate,
    billCurrency,
    period,
    notesForCustomer,
    taxProfile,
    internalNotes,
    billingAddress,
    showComments
  } = values;
  const {
    onDisplayIdChange,
    onDescriptionChange,
    onIssueDateChange,
    onPaymentTermsChange,
    onPONumberChange,
    onBillCurrencyChange,
    onDueDateChange,
    onClientChange,
    onInvoiceTemplateChange,
    onPeriodChange,
    onNotesForCustomerChange,
    onTaxProfileChange,
    onInternalNotesChange,
    onBillingAddressChange,
    onShowCommentsOnInvoiceChange
  } = useOnBillInfoChange({
    setFieldValue,
    values,
    invoiceDefaultTemplate,
    isPsaPrpBillingDefaultForProjectEnabled:
      featureFlags.isPsaPrpBillingDefaultForProjectEnabled
  });
  const {
    resourceLabels,
    accesibilityResourceLabels,
    toggleButtonResourceKeys
  } = useBillInfoResourceHook({ intl });

  const toggleDetailsButtonClasses = useMemo(
    () => ({ button: classes.toggleDetailsButton }),
    [classes.toggleDetailsButton]
  );

  const collapseClasses = useMemo(
    () => ({ container: classes.collapseContainer }),
    [classes.collapseContainer]
  );
  const [isCollapsed, setIsCollapsed] = useState(isCollapsible);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleCollapsedChange = useCallback(
    open => {
      setIsCollapsed(!open);
    },
    [setIsCollapsed]
  );
  const styleClasses = useStyles({ errors });

  const inputLabelProps = useMemo(
    () => ({
      className: classes.internalNotesLabel
    }),
    [classes.internalNotesLabel]
  );

  const inputProps = useMemo(
    () => ({
      className: classes.internalNotes
    }),
    [classes.internalNotes]
  );

  useBillPeriodSelector(setFocusedInput);

  const paymentTermAdornment = (
    <InputAdornment position="start">
      {intl.formatMessage({ id: 'billDetails.net' })}
    </InputAdornment>
  );

  const inputRefCallback = useCallback(
    errorId => inputRef => {
      if (isSubmitting && hasError(errors, errorId) && inputRef)
        inputRef.focus();
    },
    [isSubmitting, errors]
  );

  const clientTextFieldProps = useMemo(
    () => ({
      error: hasError(errors, 'client'),
      helperText: hasError(errors, 'client') && (
        <FormattedMessage id="billDetails.validations.clientIsRequired" />
      )
    }),
    [errors]
  );
  const numberOfMonths = useMediaQuery(theme => theme.breakpoints.down('sm'))
    ? 1
    : 2;
  const setFocusedInputCallback = useCallback(
    focusInput => setFocusedInput(focusInput),
    [setFocusedInput]
  );
  const luxonFormat = getLuxonJsDateFormatFromMe(me);
  const isOutsideRange = useCallback(() => false, []);

  return (
    <>
      <Grid
        container
        className={containerClass || classes.container}
        spacing={2}
      >
        {Boolean(!hasClient) && (
          <Grid item xs={12} data-qe-id="BillClientDropdown">
            <SearchableClientDropdown
              aria-label={accesibilityResourceLabels.clientAriaLabel}
              variant="filled"
              value={client}
              inputRef={inputRefCallback('client')}
              onChange={onClientChange}
              autoFocus={!hasClient}
              editable
              TextFieldProps={clientTextFieldProps}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <ReferenceNumberField
            hasClient={hasClient}
            onReferenceNumberChange={onDisplayIdChange}
            transactionType={billingTransactionType.BILL}
            isSubmitting={isSubmitting}
            errors={errors}
            displayId={displayId}
            displayText={intl.formatMessage({ id: 'billDetails.bill' })}
            inputRefCallback={inputRefCallback}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateField
            variant="filled"
            fullWidth
            required
            clearable={false}
            label={resourceLabels.issueDate}
            ariaLabel={resourceLabels.issueDate}
            value={issueDate}
            onChange={onIssueDateChange}
            editable={isBillUnpaid}
            underline={isBillUnpaid}
            inputRef={inputRefCallback('issueDate')}
            error={hasError(errors, 'issueDate')}
            helperText={getError(errors, 'issueDate')}
            useDateFieldStyles={false}
            data-qe-id="billDetails.issueDate"
          />
        </Grid>
        <Grid item xs={isCollapsible ? 9 : 12} sm={isCollapsible ? 3 : 4}>
          <DateField
            variant="filled"
            required
            fullWidth
            clearable={false}
            label={resourceLabels.dueDate}
            inputRef={inputRefCallback('dueDate')}
            ariaLabel={resourceLabels.dueDate}
            value={dueDate}
            onChange={onDueDateChange}
            editable
            underline
            useDateFieldStyles={false}
            error={hasError(errors, 'dueDate')}
            helperText={getError(errors, 'dueDate')}
            data-qe-id="billDetails.dueDate"
          />
        </Grid>
        {isCollapsible && (
          <Grid item xs={3} sm={1} className={classes.expandButton}>
            <ToggleDetailsButton
              open={!isCollapsed}
              onChange={handleCollapsedChange}
              resourceKeys={toggleButtonResourceKeys}
              classes={toggleDetailsButtonClasses}
            />
          </Grid>
        )}
      </Grid>
      <Collapse
        in={!isCollapsed}
        mountOnEnter
        unmountOnExit
        classes={collapseClasses}
      >
        <Grid
          container
          className={fieldContainerClass || classes.collapsibleContainer}
          spacing={2}
        >
          <Grid item xs={12} sm={4}>
            <TextField
              id="bill.poNumber"
              variant="filled"
              fullWidth
              inputRef={inputRefCallback('poNumber')}
              label={resourceLabels.poNumber}
              value={poNumber}
              onChange={onPONumberChange}
              error={hasError(errors, 'poNumber')}
              helperText={getError(errors, 'poNumber')}
              data-qe-id="billDetails.poNumber"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={styleClasses.root}>
              <ReadOnlyContainer label={resourceLabels.period} />
              <div className="billPeriod">
                <DateRangePicker
                  startDate={
                    period && period.startDate
                      ? moment(
                          mapRepliconDateToJSDate(
                            mapRepliconDateToUtcObject(period.startDate)
                          )
                        )
                      : null
                  }
                  startDateAriaLabel={resourceLabels.period}
                  startDateId="start_date_id"
                  endDateId="end_date_id"
                  endDate={
                    period && period.endDate
                      ? moment(
                          mapRepliconDateToJSDate(
                            mapRepliconDateToUtcObject(period.endDate)
                          )
                        )
                      : null
                  }
                  small
                  isOutsideRange={isOutsideRange}
                  startDatePlaceholderText={intl.formatMessage({
                    id: 'billDetails.startDate'
                  })}
                  endDatePlaceholderText={intl.formatMessage({
                    id: 'billDetails.endDate'
                  })}
                  numberOfMonths={numberOfMonths}
                  displayFormat={dateFormatMap(luxonFormat)}
                  minimumNights={0}
                  focusedInput={focusedInput}
                  onFocusChange={setFocusedInputCallback}
                  onDatesChange={onPeriodChange}
                  hideKeyboardShortcutsPanel
                  verticalSpacing={10}
                  disableScroll
                />
              </div>
            </div>
            {getError(errors, 'period') ? (
              <div className={styleClasses.error}>
                <FormattedMessage id="billDetails.validations.periodValue" />
              </div>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberTextField
              id="bill.paymentTerm"
              variant="filled"
              required
              fullWidth
              label={resourceLabels.paymentTerms}
              startAdornment={paymentTermAdornment}
              min={0}
              max={25000}
              step={1}
              onChange={onPaymentTermsChange}
              value={paymentTerms}
              data-qe-id="billDetails.paymentTerm"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormCurrencyDropdown
              ariaLabel={accesibilityResourceLabels.currencyAriaLabel}
              variant="filled"
              label={resourceLabels.billCurrency}
              value={billCurrency}
              noneOption={false}
              onCurrencyChange={onBillCurrencyChange}
              editable={isBillUnpaid}
              dataQeId="billDetails.billCurrency"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InvoiceTemplateDropdown
              value={invoiceTemplate}
              onChange={onInvoiceTemplateChange}
              disableClearable
              dataQeId="billDetails.invoiceTemplate"
            />
          </Grid>
          {featureFlags.isPsaPrpBillingDefaultForProjectEnabled ? (
            <>
              <Grid item xs={12} sm={4}>
                <SearchableTaxProfileDropdown
                  loading={loading}
                  options={taxProfiles}
                  onChange={onTaxProfileChange}
                  label={resourceLabels.taxProfile}
                  value={taxProfile}
                  onInputChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="bill.billingAddress"
                  label={resourceLabels.billingAddress}
                  multiline
                  rows={isMobile ? 2 : 3}
                  fullWidth
                  value={billingAddress}
                  onChange={onBillingAddressChange}
                  variant="filled"
                  data-qe-id="billDetails.billingAddress"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="bill.description"
                  variant="filled"
                  fullWidth
                  inputRef={inputRefCallback('description')}
                  rows={isMobile ? 2 : 3}
                  multiline
                  maxrows={isMobile ? 2 : 3}
                  label={resourceLabels.description}
                  value={description}
                  onChange={onDescriptionChange}
                  error={hasError(errors, 'description')}
                  helperText={getError(errors, 'description')}
                  data-qe-id="billDetails.description"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="bill.description"
                  variant="filled"
                  fullWidth
                  inputRef={inputRefCallback('description')}
                  label={resourceLabels.description}
                  value={description}
                  onChange={onDescriptionChange}
                  error={hasError(errors, 'description')}
                  helperText={getError(errors, 'description')}
                  data-qe-id="billDetails.description"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="bill.billingAddress"
                  label={resourceLabels.billingAddress}
                  multiline
                  rows={isMobile ? 2 : 3}
                  fullWidth
                  value={billingAddress}
                  onChange={onBillingAddressChange}
                  variant="filled"
                  data-qe-id="billDetails.billingAddress"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={4}>
            <TextField
              id="bill.notesForCustomer"
              variant="filled"
              multiline
              rows={isMobile ? 2 : 3}
              maxrows={isMobile ? 2 : 3}
              fullWidth
              inputRef={inputRefCallback('notesForCustomer')}
              label={resourceLabels.notesForCustomer}
              value={notesForCustomer}
              onChange={onNotesForCustomerChange}
              data-qe-id="billDetails.notesForCustomer"
              inputProps={inputProps}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="bill.internalNotes"
              variant="filled"
              multiline
              rows={isMobile ? 2 : 3}
              maxrows={isMobile ? 2 : 3}
              fullWidth
              inputRef={inputRefCallback('internalNotes')}
              label={resourceLabels.internalNotes}
              value={internalNotes}
              onChange={onInternalNotesChange}
              data-qe-id="billDetails.internalNotes"
              InputLabelProps={
                internalNotes.length > 0 ? inputLabelProps : undefined
              }
              inputProps={inputProps}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ShowCommentsOnInvoiceCheckBox
              showCommentsLabel={resourceLabels.showComments}
              showComments={showComments}
              onShowCommentsOnInvoiceChange={onShowCommentsOnInvoiceChange}
              isReadOnly={false}
              dataQeId="EditableShowCommentsOnInvoice"
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

BillInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func,
  containerClass: PropTypes.object,
  fieldContainerClass: PropTypes.object,
  isCollapsible: PropTypes.bool,
  isBillUnpaid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  hasClient: PropTypes.bool.isRequired,
  invoiceDefaultTemplate: PropTypes.object
};

export default injectIntl(withStyles(BillInfo));
