import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const REQUISITION_QUERY = gql`
  query GetRequisitionData($resourceRequestUri: String!) {
    getRequisitionData(resourceRequestUri: $resourceRequestUri) {
      id
      code
      status
      jobTitle
      hiresNeeded
      hiringManager {
        displayName
        email
      }
      recruiter {
        displayName
        email
      }
      dateCreated
      datePosted
      requisitionUrl
    }
  }
`;

const useFetchRequisitionData = ({ resourceRequestUri, skip }) => {
  const { data, loading } = useQuery(REQUISITION_QUERY, {
    variables: { resourceRequestUri },
    skip
  });

  return {
    requisition: get(data, 'getRequisitionData', {}),
    loading
  };
};

export default useFetchRequisitionData;
