import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  HiringStatusBadge,
  Decimal,
  Percentage
} from '~/modules/common/components';
import { RoleName } from '~/modules/common/components/entities/role';
import { RequestStatusLabel } from '~/modules/resourcing/common/components';
import {
  useValueWithPrecision,
  useHasFeatureFlag,
  useFetchRequisitionHiringStatus
} from '~/modules/common/hooks';
import { showHiringStatusBadge } from '~/modules/common/components/HiringStatusBadge';
import { ResourceRequestDetailIndicator } from '../components';
import useStyles from './useStyles';

export const ResourceRequestReadonlyItem = ({
  qeIdTag,
  resourceRequest,
  maxSkillLevel,
  displayEntityType = 'ROLE'
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const isPsaDtmRequisitionEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaDtmRequisitionEnabled'
  });

  const requisitionHiringStatus = useFetchRequisitionHiringStatus({
    resourceRequestUri: resourceRequest.id,
    skip: !isPsaDtmRequisitionEnabled
  });

  const { role, project, currency } = resourceRequest;
  const selectedRole = useMemo(
    () =>
      role
        ? {
            ...role,
            amount: role.currentRate ? role.currentRate.amount : null,
            symbol: currency ? currency.displayText : null
          }
        : null,
    [role, currency]
  );

  const loadValue = useValueWithPrecision({
    value: resourceRequest.load
  });

  const loadingToolTip = useMemo(
    () => (
      <>
        {formatMessage({
          id: 'resourceRequestReadonlyItem.loading'
        })}
        : {loadValue}%
        {resourceRequest.isAdjustedLoading && (
          <div className={classes.tooltipDetails}>
            {formatMessage({
              id: 'resourceRequestReadonlyItem.averageLoading'
            })}
          </div>
        )}
      </>
    ),
    [
      formatMessage,
      loadValue,
      resourceRequest.isAdjustedLoading,
      classes.tooltipDetails
    ]
  );

  return (
    <div className={classes.root}>
      <div className={classes.labelRow}>
        {displayEntityType !== 'ROLE' ? (
          <span className={classes.roleName}>{project.name}</span>
        ) : selectedRole ? (
          <RoleName role={selectedRole} className={classes.roleName} />
        ) : (
          <span className={classes.noValue}>
            <FormattedMessage id="resourceRequestReadonlyItem.noRole" />
          </span>
        )}
        <RequestStatusLabel value={resourceRequest.requestStatus} short />
      </div>
      <div
        data-qe-id={qeIdTag ? `${qeIdTag}_RoleDetails` : 'RoleDetails'}
        className={classes.roleDetails}
      >
        <Tooltip
          title={`${formatMessage({
            id: 'resourceRequestReadonlyItem.quantity'
          })}: ${resourceRequest.quantity}`}
        >
          <Decimal
            dataQeId={
              qeIdTag ? `${qeIdTag}_NumberOfAssignees` : 'NumberOfAssignees'
            }
            value={resourceRequest.quantity}
            fixedDecimalScale={false}
            className={classes.quantity}
          />
        </Tooltip>
        <span className={classes.atDivider}>&nbsp;@&nbsp;</span>
        <Percentage
          dataQeId={qeIdTag ? `${qeIdTag}_RolePercentage` : 'RolePercentage'}
          title={loadingToolTip}
          value={resourceRequest.load}
          fixedDecimalScale={false}
          className={classNames(classes.loading, {
            [classes.adjustedLoading]: resourceRequest.isAdjustedLoading
          })}
        />
        {resourceRequest.isAdjustedLoading && '*'}
        <ResourceRequestDetailIndicator
          resourceRequest={resourceRequest}
          maxSkillLevel={maxSkillLevel}
        />
      </div>
      {isPsaDtmRequisitionEnabled &&
        showHiringStatusBadge({ requisitionHiringStatus, resourceRequest }) && (
          <div className={classes.badgeContainer}>
            <HiringStatusBadge
              requisitionHiringStatus={requisitionHiringStatus}
            />
          </div>
        )}
    </div>
  );
};

ResourceRequestReadonlyItem.propTypes = {
  qeIdTag: PropTypes.string,
  displayEntityType: PropTypes.string,
  resourceRequest: PropTypes.object.isRequired,
  maxSkillLevel: PropTypes.number
};

export default ResourceRequestReadonlyItem;
