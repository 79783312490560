import { ListItemText } from '@material-ui/core';
import React from 'react';
import { ConsolidateBillsBy } from '~/types';

export const getOptions = formatMessage => [
  {
    id: ConsolidateBillsBy.Client,
    displayText: formatMessage({
      id: 'billingCard.subTitle.consolidateBillsBy.options.client'
    }),
    component: (
      <ListItemText
        primary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.options.client'
        })}
        secondary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.description.client'
        })}
      />
    )
  },
  {
    id: ConsolidateBillsBy.Project,
    displayText: formatMessage({
      id: 'billingCard.subTitle.consolidateBillsBy.options.project'
    }),
    component: (
      <ListItemText
        primary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.options.project'
        })}
        secondary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.description.project'
        })}
      />
    )
  }
];
