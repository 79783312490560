import React from 'react';
import { useIntl } from 'react-intl';
import { ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '70%',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  resourcesColumn: {
    minWidth: theme.spacing(20)
  },
  smartMatchColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
  },
  delaText: {
    fontSize: theme.spacing(1.625),
    lineHeight: 1,
    color: theme.palette.Dela.darkIcon
  }
}));

export const ResourceMatchListHeader = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <ListItem data-qe-id="ResourceMatchListHeader" className={classes.root}>
      <div className={classes.resourcesColumn}>
        <Typography variant="subtitle2">
          {formatMessage({
            id: 'resourceMatchPopover.resources'
          })}
        </Typography>
      </div>
      <div className={classes.smartMatchColumn}>
        <Typography variant="subtitle2">
          {formatMessage({
            id: 'resourceMatchPopover.smartMatch'
          })}
        </Typography>
        <Typography className={classes.delaText} variant="subtitle2">
          {formatMessage({
            id: 'resourceMatchPopover.withDelaAI'
          })}
        </Typography>
      </div>
    </ListItem>
  );
};

export default ResourceMatchListHeader;
