import isNil from 'lodash/fp/isNil';

export const includes = value => xs => xs.includes(value);

export const filter = predicate => xs => xs.filter(predicate);

export const map = func => xs => xs.map(func);

export const invokeWith = (...args) => func => func(...args);

export const concat = (x, y) => x.concat(y);

export const flatMap = func => xs => xs.map(func).reduce(concat, []);

export const alt = alternativeValue => x => (isNil(x) ? alternativeValue : x);
