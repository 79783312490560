import { useDeepCompareMemoize } from '~/modules/common/hooks';

export const useOptionsMerge = ({ options, additionalOptions }) => {
  return useDeepCompareMemoize(
    Array.isArray(additionalOptions) && additionalOptions?.length
      ? [...additionalOptions, ...(options || [])]
      : options
  );
};

export default useOptionsMerge;
