import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormControl } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem,
  CreatableDropdownItem,
  useOptionsMerge,
  useInputClasses
} from '~/modules/common/components/SearchAutocomplete';
import {
  useAdditionalOptions,
  NO_CHANGE_OPTION_ID,
  NO_VALUE_OPTION_ID
} from '~/modules/common/hooks';
import { useGetTagFieldsQuery } from '../hooks/useGetTagFieldsQuery';

const renderOption = option => {
  if (option.id === NO_CHANGE_OPTION_ID || option.id === NO_VALUE_OPTION_ID)
    return <NoneDropdownItem value={option.displayText} />;

  return <CreatableDropdownItem option={option} optionPropText="displayText" />;
};

export const TagField = ({
  field,
  label,
  editable,
  variant,
  onChange,
  definitionId,
  setDefinitionId,
  fullWidth = true,
  enableNoChangeOption = false,
  enableNoValueOption = false
}) => {
  const additionalOptions = useAdditionalOptions({
    enableNoChangeOption,
    enableNoValueOption,
    noValueMessageId: 'dropdownItem.noValue',
    noValueMessageValues: { value: label }
  });
  const noChangeOption = additionalOptions.find(
    x => x.id === NO_CHANGE_OPTION_ID
  );
  const value = field.tag || noChangeOption;
  const classes = useInputClasses({ value });
  const [tagSearchPhrase, setTagSearchPhrase] = useState('');
  const { tags, loading } = useGetTagFieldsQuery({
    tagId: definitionId,
    searchPhrase: tagSearchPhrase
  });

  const options = useOptionsMerge({
    options: tags,
    additionalOptions
  });

  const onInputChange = useCallback(
    (_, val, reason) => setTagSearchPhrase(reason === 'input' ? val : ''),
    [setTagSearchPhrase]
  );

  const { formatMessage } = useIntl();

  const handleOnChange = useCallback(
    val => {
      onChange(null, val);
      setTagSearchPhrase('');
    },
    [onChange, setTagSearchPhrase]
  );

  const onInputFocus = useCallback(() => {
    setTagSearchPhrase('');
    setDefinitionId(field.definition.id);
  }, [field, setTagSearchPhrase, setDefinitionId]);

  return (
    <FormControl
      disabled={!editable}
      fullWidth={fullWidth}
      {...(editable && {
        variant
      })}
    >
      <SimpleAutocomplete
        classes={classes}
        loading={loading}
        inputLabel={label}
        value={value}
        onInputChange={onInputChange}
        onChange={handleOnChange}
        onFocus={onInputFocus}
        disabled={!editable}
        variant={variant}
        optionPropText="displayText"
        noOptionsText={formatMessage({ id: 'projectTasksPage.none' })}
        options={options}
        renderOption={
          enableNoChangeOption || enableNoValueOption ? renderOption : null
        }
      />
    </FormControl>
  );
};

TagField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  definitionId: PropTypes.string.isRequired,
  setDefinitionId: PropTypes.func.isRequired,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool
};

export default TagField;
