import { useMeContext } from '~/modules/me/useMeContext';
import { buildColumns } from './columns';

export const useEnabledAvailableToBillListColumnGroups = editable => {
  const me = useMeContext();
  const {
    featureFlags: {
      isPsaPrpManualBulkBillCreationEnabled,
      isPsaPrpAddProjectColumnOnAvailableToBillEnabled
    }
  } = me;

  const basicColumns = buildColumns({
    classes: {},
    editable,
    isPsaPrpManualBulkBillCreationEnabled,
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled
  });

  return {
    defaultColumns: Object.values(basicColumns)
      .filter(({ isDefault }) => isDefault)
      .map(({ field }) => field),
    enabledColumnGroups: [
      {
        id: 'basic',
        title: null,
        columns: Object.values(basicColumns)
      }
    ]
  };
};
