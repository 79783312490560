import { makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Date from '~/modules/common/components/Date';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  compareDateObjects,
  getDaysBetweenDates
} from '~/modules/common/dates/compare';
import { isoStringToObjectWithCache as isoStringToObject } from '~/modules/common/dates/convert';
import { getTodayForUser } from '~/modules/common/dates/today';
import { useUpdateClosedOnDate } from '../hooks';
import ClosedOnDatePickerButton from './ClosedOnDatePickerButton';

const useStyles = makeStyles(theme => ({
  closedOnDate: {
    display: 'flex',
    fontSize: theme.typography.caption.fontSize,
    alignItems: 'center',
    padding: theme.spacing(0.5, 0.5, 0.5, 2),
    backgroundColor: theme.palette.grey[300]
  },
  date: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    fontSize: theme.typography.caption.fontSize
  }
}));

const closedByInheritanceMessage = ({
  isTodayBeforeCloseDate,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isPsaPrpManualTaskStatusEnabled
}) => {
  if (isPsaPrpManualTaskStatusEnabled) {
    return isTodayBeforeCloseDate ? (
      <FormattedMessage id="taskDrawerEdit.effectivelyCompletingOn" />
    ) : (
      <FormattedMessage id="taskDrawerEdit.effectivelyCompletedOn" />
    );
  }

  return isRolledUpTaskEstimateCalculationMethodEnabled ? (
    <FormattedMessage id="taskDrawerEdit.completionOn" />
  ) : isTodayBeforeCloseDate ? (
    <FormattedMessage id="taskDrawerEdit.effectiveClosingOn" />
  ) : (
    <FormattedMessage id="taskDrawerEdit.effectiveClosedOn" />
  );
};

const closedMessage = ({
  isTodayBeforeCloseDate,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isPsaPrpManualTaskStatusEnabled
}) => {
  return isRolledUpTaskEstimateCalculationMethodEnabled ||
    isPsaPrpManualTaskStatusEnabled ? (
    <FormattedMessage id="taskDrawerEdit.completionOn" />
  ) : isTodayBeforeCloseDate ? (
    <FormattedMessage id="taskDrawerEdit.closingOn" />
  ) : (
    <FormattedMessage id="taskDrawerEdit.closedOn" />
  );
};

export const ClosedOnDatePicker = ({ task, canEditTask }) => {
  const classes = useStyles();
  const me = useMeContext();
  const { updateClosedOnDate } = useUpdateClosedOnDate(task);

  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: { isPsaPrpManualTaskStatusEnabled }
  } = me;
  const [closedOnDate, setClosedOnDate] = useState(task.effectiveClosedOnDate);
  const isClosed = task.isClosed || task.isClosedByTaskInheritance;
  const todayForUser = getTodayForUser(me);
  const isTodayBeforeCloseDate = isClosed
    ? compareDateObjects(todayForUser, isoStringToObject(closedOnDate)) === -1
    : null;
  const daysLeft = isTodayBeforeCloseDate
    ? getDaysBetweenDates(isoStringToObject(closedOnDate), todayForUser)
    : 0;

  const onChange = useCallback(
    newDate => {
      setClosedOnDate(newDate);
      updateClosedOnDate(newDate.toISODate());
    },
    [updateClosedOnDate]
  );

  return (
    <div className={classes.closedOnDate}>
      {task.isClosedByTaskInheritance &&
        !task.isClosed &&
        closedByInheritanceMessage({
          isTodayBeforeCloseDate,
          isRolledUpTaskEstimateCalculationMethodEnabled,
          isPsaPrpManualTaskStatusEnabled
        })}
      {task.isClosed && (
        <span>
          {closedMessage({
            isTodayBeforeCloseDate,
            isRolledUpTaskEstimateCalculationMethodEnabled,
            isPsaPrpManualTaskStatusEnabled
          })}
        </span>
      )}

      <Date value={closedOnDate} className={classes.date} />
      {isTodayBeforeCloseDate && (
        <FormattedMessage
          id="taskDrawerEdit.daysLeft"
          values={{
            count: daysLeft
          }}
        />
      )}
      {canEditTask && task.isClosed && (
        <DatePicker
          variant="dialog"
          value={closedOnDate}
          onChange={onChange}
          TextFieldComponent={ClosedOnDatePickerButton}
          disableFuture={false}
          clearable={false}
          autoOk
        />
      )}
    </div>
  );
};

ClosedOnDatePicker.propTypes = {
  task: PropTypes.object.isRequired,
  canEditTask: PropTypes.bool.isRequired
};

export default ClosedOnDatePicker;
