import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { BillStatus } from '~/types';
import { useMeContext } from '~/modules/me/useMeContext';

export const ISSUE_BILL = gql`
  mutation issueBill(
    $input: IssueBillInput!
    $isPsaPrpCappedBillingEnabled: Boolean!
  ) {
    issueBill(issueBillInput: $input) {
      success
      failedLineItems @include(if: $isPsaPrpCappedBillingEnabled) {
        project {
          id
          displayText
        }
        projectTCVAmount {
          amount
          currency {
            symbol
            displayText
          }
        }
        exceededBillAmount {
          amount
          currency {
            symbol
            displayText
          }
        }
      }
    }
  }
`;

export const useIssueBill = ({
  transactionId,
  updateDetails = false,
  refreshTransactionList = true,
  record
}) => {
  const {
    featureFlags: {
      isPsaPrpCappedBillingEnabled,
      isPsaFpGraphqlOptimizationEnabled
    }
  } = useMeContext();
  const [issueBill, { loading }] = useMutation(ISSUE_BILL);

  const refetchQueries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : [
        'getDraftTotals',
        'getOverdueBillSummary',
        'getOutstandingBillSummary',
        'getIssuedBillSummary'
      ];

  if (refreshTransactionList)
    refetchQueries.push('getPageOfBillingTransactions');

  if (updateDetails)
    refetchQueries.push(
      'getBillDetails',
      'getBillLineItems',
      'getBillTaxLineItems'
    );

  return {
    issueBill: async syncBill => {
      const response = await issueBill({
        variables: {
          input: {
            invoiceId: transactionId,
            syncBill
          },
          isPsaPrpCappedBillingEnabled
        },
        skip: record.billStatus === BillStatus.Billed,
        refetchQueries,
        awaitRefetchQueries: true
      });

      return { ...response.data };
    },
    loading
  };
};

export default useIssueBill;
