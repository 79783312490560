import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  resourceRow: {
    display: 'flex',
    padding: theme.spacing(0.5),
    minHeight: theme.spacing(5),
    width: theme.spacing(43.75),
    '&:hover': {
      cursor: 'pointer',
      '&:hover': {
        background: alpha(theme.palette.action.active, 0.1)
      }
    },
    '&:hover $openButton': {
      display: 'flex',
      background: 'none',
      opacity: 1
    }
  },
  resourceRowWithActuals: {
    minHeight: theme.spacing(10)
  },
  iconLink: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'flex-end'
  },
  resource: {
    textAlign: 'initial'
  },
  resourceButton: {
    width: theme.spacing(38.25)
  },
  timeoffLabel: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 'bold',
    minHeight: theme.spacing(5),
    width: '100%',
    textAlign: 'right',
    padding: theme.spacing(1, 1.5, 0)
  },
  timeoffLabelFFOn: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    minHeight: theme.spacing(5),
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(1, 1.5, 0, 3)
  },
  expandButton: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandIconOpen: {
    transform: 'rotate(180deg)'
  },
  openButton: {
    display: 'flex',
    flexGrow: 1,
    opacity: 0,
    padding: theme.spacing(0.5, 0, 0.5, 0),
    margin: 0,
    justifyContent: 'flex-end'
  }
}));

export default useStyles;
