import React from 'react';
import { List, Divider, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useHasUserAccessRole } from '~/modules/common/permissions/useHasUserAccessRole';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  AdministrationIcon,
  ReportIcon
} from '~/modules/common/components/Icons';
import { TenantType } from '~/types';
import ExternalLinksMenuItem from './ExternalLinksMenuItem';
import BusinessIntelligenceMenuItem from './BusinessIntelligenceMenuItem';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1)
  }
}));

const resourceLabels = {
  reports: <FormattedMessage id="externalLinks.reports" />,
  administration: <FormattedMessage id="externalLinks.administration" />,
  team: <FormattedMessage id="externalLinks.team" />
};

const icon = {
  reports: <ReportIcon />,
  administration: <AdministrationIcon />
};

export const getReportsUrl = ({ uiRootPath, companyKey }) => {
  const index = uiRootPath.indexOf(`/${companyKey}/`);

  const rootUrl = uiRootPath.slice(0, index);

  return `${rootUrl}/r/reports/list`;
};

export const ExternalLinksMenuItems = () => {
  const classes = useStyles();
  const isReportUser = useHasUserAccessRole({
    roleUri: USER_ACCESS_ROLE.REPORT_USER
  });

  const isAdminUser = useHasUserAccessRole({
    roleUri: USER_ACCESS_ROLE.ADMIN
  });
  const {
    uiRootPath,
    companyKey,
    tenantType,
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const { isMobileApp = false } = window;

  const mobileMenuItems = (
    <>
      <BusinessIntelligenceMenuItem />
      <Divider role="" component="li" className={classes.divider} light />
    </>
  );

  if (isMobileApp)
    return isReportUser && tenantType === TenantType.Trial ? (
      isPsaPrpWcagFixesQ4Enabled ? (
        mobileMenuItems
      ) : (
        <List disablePadding>{mobileMenuItems}</List>
      )
    ) : null;

  const menuItems = (
    <>
      {isReportUser && (
        <li>
          <ExternalLinksMenuItem
            url={getReportsUrl({ uiRootPath, companyKey })}
            icon={icon.reports}
            text={resourceLabels.reports}
          />
        </li>
      )}
      {isReportUser && tenantType === TenantType.Trial ? (
        <BusinessIntelligenceMenuItem />
      ) : null}
      {isAdminUser && (
        <li>
          <ExternalLinksMenuItem
            url={`${uiRootPath}administration`}
            icon={icon.administration}
            text={resourceLabels.administration}
            dataQeId="ExternalLinksMenu_AdministrationTab"
          />
        </li>
      )}
      <Divider role="" component="li" className={classes.divider} light />
    </>
  );

  return (
    (isReportUser || isAdminUser) &&
    (isPsaPrpWcagFixesQ4Enabled ? (
      menuItems
    ) : (
      <List disablePadding>{menuItems}</List>
    ))
  );
};

export default ExternalLinksMenuItems;
