import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useFormikContext } from 'formik';
import deepEqual from 'fast-deep-equal';
import { makeStyles } from '@material-ui/core';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { useHasFeatureFlag, useDialogState } from '~/modules/common/hooks';
import BillingCardReadOnly from './BillingCardReadOnly';
import BillingCardEditableTitle from './BillingCardEditableTitle';
import BillingCardEditable from './BillingCardEditable';
import BillingSettingsOverrideConfirmationDialog from './BillingSettingsOverrideConfirmationDialog';
import { BillingCardActionComponent } from './BillingCardActionComponent';

const useDialogStyles = makeStyles(theme => ({
  editContentRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export const billingCardResourceKeys = {
  cardTitle: 'billingCard.cardTitle',
  schedule: 'billingCard.title.schedule',
  paymentTermNet: 'billingCard.subTitleValue.net',
  paymentTerm: 'billingCard.subTitle.paymentTerm',
  structure: 'billingCard.title.structure',
  billLineItemsBy: 'billingCard.subTitle.billLineItemsBy',
  defaults: 'billingCard.title.defaults',
  billingCurrency: 'billingCard.subTitle.invoiceCurrency',
  taxProfile: 'billingCard.subTitle.taxProfile',
  noTaxProfile: 'billingCard.subTitleValue.noTaxProfile',
  invoiceTemplate: 'billingCard.subTitle.invoiceTemplate',
  noInvoiceTemplate: 'billingCard.subTitleValue.noInvoiceTemplate',
  description: 'billingCard.subTitle.description',
  internalNotes: 'billingCard.subTitle.internalNotes',
  showComments: 'billingCard.subTitle.showComments',
  poNumber: 'billingCard.subTitle.poNumber',
  consolidateBillsBy: 'billingCard.subTitle.consolidateBillsBy.label',
  billFrequency: 'billingCard.subTitle.billFrequency'
};

const avatar = <RequestQuoteIcon />;
const title = <FormattedMessage id="billingCard.cardTitle" />;

export const BillingCard = ({ editable, isLoading }) => {
  const {
    handleReset,
    handleSubmit,
    isValid,
    isSubmitting,
    errors,
    initialValues,
    values
  } = useFormikContext();

  const { context, key, showConfirmationDialogBeforeSave } = values;
  const dialogClasses = useDialogStyles();

  const isPsaPrpAddProjectColumnOnAvailableToBillEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpAddProjectColumnOnAvailableToBillEnabled'
  });

  const {
    open: billingSettingsOverrideDialogOpen,
    openDialog: openBillingSettingsOverrideDialog,
    closeDialog: closeBillingSettingsOverrideDialog
  } = useDialogState(false);

  const onSave = useCallback(() => {
    if (context === 'project') {
      const {
        key: prevKey,
        poNumber: prevPoNumber,
        ...prevClientBillingSettings
      } = initialValues;
      const {
        key: currKey,
        poNumber: currPoNumber,
        ...currClientBillingSettings
      } = values;

      const isClientValuesOverrided = !deepEqual(
        prevClientBillingSettings,
        currClientBillingSettings
      );

      if (isClientValuesOverrided && showConfirmationDialogBeforeSave) {
        openBillingSettingsOverrideDialog();
      } else {
        handleSubmit();
      }
    } else handleSubmit();
  }, [
    context,
    handleSubmit,
    initialValues,
    openBillingSettingsOverrideDialog,
    showConfirmationDialogBeforeSave,
    values
  ]);

  const actionComponentCustomProps = useMemo(
    () => ({
      onSave,
      context,
      showConfirmationDialogBeforeSave
    }),
    [context, onSave, showConfirmationDialogBeforeSave]
  );

  if (isLoading) return <CardLoading avatar={avatar} title={title} />;

  return (
    <>
      <EditableCard
        key={key}
        editable={editable}
        editContentClassName={
          isPsaPrpAddProjectColumnOnAvailableToBillEnabled
            ? dialogClasses.editContentRoot
            : undefined
        }
        dataQeId="BillingCard"
        ariaLabelKey="billingCard.billingCardDialog"
      >
        <ReadOnly avatar={avatar} title={title}>
          <ReadOnlyContent>
            <BillingCardReadOnly
              billingCardResourceKeys={billingCardResourceKeys}
            />
          </ReadOnlyContent>
        </ReadOnly>
        <Edit
          saveable={isValid && !isSubmitting}
          onCancel={handleReset}
          onSave={onSave}
          ActionComponent={BillingCardActionComponent}
          actionComponentCustomProps={actionComponentCustomProps}
        >
          <EditTitle>
            <BillingCardEditableTitle />
          </EditTitle>
          <EditContent>
            <BillingCardEditable
              billingCardResourceKeys={billingCardResourceKeys}
              isSubmitting={isSubmitting}
              errors={errors}
            />
          </EditContent>
        </Edit>
      </EditableCard>
      {billingSettingsOverrideDialogOpen ? (
        <BillingSettingsOverrideConfirmationDialog
          open={billingSettingsOverrideDialogOpen}
          onClose={closeBillingSettingsOverrideDialog}
          handleSubmit={handleSubmit}
        />
      ) : null}
    </>
  );
};

BillingCard.propTypes = {
  editable: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default BillingCard;
