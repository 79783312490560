import { combineSkillsAndUserProfileSkills } from './mapSkills';

export const useCombineSkillsAndUserProfileSkills = ({
  skills,
  userProfileSkills
}) => {
  return combineSkillsAndUserProfileSkills({
    skills,
    userProfileSkills,
    mapSkillAssignment: (skill, { fromUserProfileSkills }) => ({
      ...skill,
      ...(fromUserProfileSkills && { isDelaRecommendation: true })
    })
  });
};

export default useCombineSkillsAndUserProfileSkills;
