import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { taskStartEndDateFragment } from '~/modules/projects/tasks/hooks/useProjectTasks';

const setTaskTimeEntryDateRangeMutation = gql`
  mutation setTaskTimeEntryDateRange(
    $taskId: String!
    $startDate: Date
    $endDate: Date
    $rescheduleTaskModificationOption: RescheduleTaskModificationOption
    $shouldRollupTaskDates: Boolean
  ) {
    setTaskTimeEntryDateRange: setTaskTimeEntryDateRange2(
      input: {
        taskId: $taskId
        startDate: $startDate
        endDate: $endDate
        rescheduleTaskModificationOption: $rescheduleTaskModificationOption
        shouldRollupTaskDates: $shouldRollupTaskDates
      }
    ) {
      dummy
    }
  }
`;

const useSetTaskTimeEntryDateRangeMutation = () =>
  useMutation(setTaskTimeEntryDateRangeMutation);

export const updateCachedTaskDateRange = ({
  taskId,
  startDate,
  endDate
}) => proxy => {
  const cachedId = `Task:${taskId}`;
  const fragment = taskStartEndDateFragment;
  const fragmentName = 'TaskStartEndDate';

  proxy.writeFragment({
    id: cachedId,
    fragment,
    fragmentName,
    data: {
      startDate,
      endDate
    }
  });
};

export const useSetTaskTimeEntryDateRange = () => {
  const [
    setTaskTimeEntryDateRange,
    { loading }
  ] = useSetTaskTimeEntryDateRangeMutation();

  const setTaskTimeEntryDateRangeCallback = useCallback(
    ({
      taskId,
      startDate,
      endDate,
      rescheduleTaskModificationOption,
      shouldRollupTaskDates = false
    }) =>
      setTaskTimeEntryDateRange({
        variables: {
          taskId,
          startDate,
          endDate,
          rescheduleTaskModificationOption,
          shouldRollupTaskDates
        },
        update: updateCachedTaskDateRange({
          taskId,
          startDate,
          endDate
        })
      }),
    [setTaskTimeEntryDateRange]
  );

  return {
    setTaskTimeEntryDateRange: setTaskTimeEntryDateRangeCallback,
    loading
  };
};

export default useSetTaskTimeEntryDateRange;
