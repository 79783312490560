import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { UserAllocationsSummaryContext } from './UserAllocationsSummaryContext';

const UserAllocationsSummaryContextProvider = ({
  resourceAllocationScheduleRules,
  loadingResourceAllocation,
  userTaskAllocationsSummaryScheduleRules,
  userTaskAllocationsSummaryLoading,
  userOverDistributedPeriodsMap,
  userCostRateDetails,
  resetResourceAllocationChart,
  handleRemoveResourceAllocation,
  children
}) => {
  const value = useMemo(
    () => ({
      resourceAllocationScheduleRules,
      loadingResourceAllocation,
      userTaskAllocationsSummaryScheduleRules,
      userTaskAllocationsSummaryLoading,
      userOverDistributedPeriodsMap,
      userCostRateDetails,
      resetResourceAllocationChart,
      handleRemoveResourceAllocation
    }),
    [
      resourceAllocationScheduleRules,
      loadingResourceAllocation,
      userTaskAllocationsSummaryScheduleRules,
      userTaskAllocationsSummaryLoading,
      userOverDistributedPeriodsMap,
      userCostRateDetails,
      resetResourceAllocationChart,
      handleRemoveResourceAllocation
    ]
  );

  return (
    <UserAllocationsSummaryContext.Provider value={value}>
      {children}
    </UserAllocationsSummaryContext.Provider>
  );
};

UserAllocationsSummaryContextProvider.propTypes = {
  children: PropTypes.node,
  resourceAllocationScheduleRules: PropTypes.array,
  loadingResourceAllocation: PropTypes.bool,
  userTaskAllocationsSummaryScheduleRules: PropTypes.array,
  userTaskAllocationsSummaryLoading: PropTypes.bool,
  userOverDistributedPeriodsMap: PropTypes.object,
  userCostRateDetails: PropTypes.object,
  resetResourceAllocationChart: PropTypes.func,
  handleRemoveResourceAllocation: PropTypes.func
};

export default UserAllocationsSummaryContextProvider;
