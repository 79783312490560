import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { CardExpansionPanel } from '~/modules/common/components';
import { HiringStatus } from '~/types';
import {
  HiringRequisitionInfoTitle,
  HiringRequisitionInfoContent
} from './components';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    width: '100%',
    padding: theme.spacing(0, 0, 6)
  }
}));

export const useExpansionStyles = makeStyles(theme => ({
  expansionPanel: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '0 !important'
  },
  expansionPanelSummary: {
    alignItems: 'center',
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
  },
  expansionPanelSummaryContent: {
    padding: 0
  },
  expandIcon: {
    padding: theme.spacing(1.5, 2)
  },
  expansionPanelDetails: {
    overflow: 'hidden'
  }
}));

export const HiringRequisitionInfoExpansionPanel = ({
  requisitionInfo,
  hiringRequestStatus
}) => {
  const classes = useStyles();
  const expansionClasses = useExpansionStyles();

  const titleComponentProps = {
    requisitionStatus: requisitionInfo?.status,
    requisitionUrl: requisitionInfo?.requisitionUrl,
    hiringRequestStatus
  };

  return (
    <CardExpansionPanel
      elevation={0}
      TitleComponent={HiringRequisitionInfoTitle}
      titleComponentProps={titleComponentProps}
      classes={expansionClasses}
      defaultExpanded={false}
    >
      <Grid container className={classes.container}>
        <HiringRequisitionInfoContent
          requisitionInfo={requisitionInfo}
          hiringRequestStatus={hiringRequestStatus}
        />
      </Grid>
    </CardExpansionPanel>
  );
};

HiringRequisitionInfoExpansionPanel.propTypes = {
  requisitionInfo: PropTypes.object.isRequired,
  hiringRequestStatus: PropTypes.oneOf([
    HiringStatus.HiringCancelled,
    HiringStatus.HiringRequested,
    HiringStatus.HiringWithdrawn
  ])
};
export default HiringRequisitionInfoExpansionPanel;
