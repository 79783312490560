import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { AddIconButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  }
}));

const NewAttachmentButton = ({ onClick, disabled, hasAttachment }) => {
  const classes = useStyles();

  return (
    <AddIconButton
      onClick={onClick}
      disabled={disabled}
      data-qe-id="addFileOrUrl"
      classes={hasAttachment ? null : classes}
    >
      <FormattedMessage id="attachments.addFileOrUrl" />
    </AddIconButton>
  );
};

NewAttachmentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasAttachment: PropTypes.bool
};

export default NewAttachmentButton;
