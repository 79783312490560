import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  NO_CHANGE_OPTION_ID,
  NO_VALUE_OPTION_ID
} from '~/modules/common/hooks';

export const useInputStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

export const useInputClasses = ({ value }) => {
  const inputClasses = useInputStyles();

  return useMemo(
    () =>
      value?.id === NO_CHANGE_OPTION_ID || value?.id === NO_VALUE_OPTION_ID
        ? inputClasses
        : null,
    [inputClasses, value]
  );
};

export default useInputClasses;
