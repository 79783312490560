import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import TaskEstimateRowContextProvider from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContextProvider';
import RemoveTaskAllocationDialog from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/components/RemoveTaskAllocationDialog';
import { BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY } from '~/modules/resourcing/common/hooks';
import { useTaskEstimateRowContextDataBuilder } from '../../hooks';
import useOnRemoveTaskAllocationSuccess from './hooks/useOnRemoveTaskAllocationSuccess';

export const RemoveTaskAllocationConfirmationDialog = ({
  projectId,
  task,
  user,
  open,
  onClose,
  openRemoveTaskAssignmentDialog,
  refetchQueries,
  handleRemoveResourceAllocation
}) => {
  const {
    loading,
    taskDetail,
    taskResourceEstimate
  } = useTaskEstimateRowContextDataBuilder({ projectId, task, user });

  const { onRemoveTaskAllocationSuccess } = useOnRemoveTaskAllocationSuccess({
    onClose,
    handleRemoveResourceAllocation,
    userId: user.id
  });

  const dialogProps = useMemo(
    () => ({
      openRemoveEstimateDialog: openRemoveTaskAssignmentDialog
    }),
    [openRemoveTaskAssignmentDialog]
  );

  const updateTaskResourceAllocationCache = useCallback(
    proxy => {
      proxy.writeQuery({
        query: BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY,
        variables: {
          filter: {
            userUri: user.id,
            projectUri: projectId,
            taskUris: [task.id]
          }
        },
        data: { taskResourceUserAllocationsForUser: [] }
      });
    },
    [projectId, task.id, user.id]
  );

  return (
    <TaskEstimateRowContextProvider
      task={taskDetail}
      resourceEstimate={taskResourceEstimate}
      canEditTasks={false}
      dialogProps={dialogProps}
    >
      <RemoveTaskAllocationDialog
        isLoading={loading}
        open={open}
        onClose={onClose}
        onRemoveTaskAllocationSuccess={onRemoveTaskAllocationSuccess}
        updateCache={updateTaskResourceAllocationCache}
        refetchQueries={refetchQueries}
      />
    </TaskEstimateRowContextProvider>
  );
};

RemoveTaskAllocationConfirmationDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  task: PropTypes.object,
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openRemoveTaskAssignmentDialog: PropTypes.func.isRequired,
  refetchQueries: PropTypes.array,
  handleRemoveResourceAllocation: PropTypes.func.isRequired
};
