import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { SELECTED_TASK_STATUS } from '~/modules/common/enums';
import { OverriddenTaskStatus } from '~/types';

const openTaskMutationFragment = gql`
  fragment OpenTaskMutationFragment on Mutation {
    openTask: openTask2(input: { taskId: $taskId }) {
      task {
        ...TaskStatusFragment
      }
    }
  }
`;

const closeTaskMutationFragment = gql`
  fragment CloseTaskMutationFragment on Mutation {
    closeTask: closeTask2(input: { taskId: $taskId }) {
      task {
        ...TaskStatusFragment
        closedOnDate: closedOnDate2
        effectiveClosedOnDate: effectiveClosedOnDate2
      }
    }
  }
`;

const setOverriddenTaskStatusMutationFragment = gql`
  fragment SetOverriddenTaskStatusMutationFragment on Mutation {
    setOverriddenTaskStatus: setOverriddenTaskStatus(
      input: {
        taskId: $taskId
        projectId: $projectId
        status: $overriddenTaskStatus
      }
    ) {
      task {
        ...TaskStatusFragment
      }
    }
  }
`;

const taskStatusFragment = gql`
  fragment TaskStatusFragment on Task {
    id
    isClosed
    isClosedByTaskInheritance
    overriddenTaskStatus
  }
`;

const applySelectedTaskStatusMutation = gql`
  mutation ApplySelectedTaskStatus(
    $taskId: String!
    $projectId: String!
    $open: Boolean!
    $close: Boolean!
    $setOverriddenTaskStatus: Boolean!
    $overriddenTaskStatus: OverriddenTaskStatus
  ) {
    ...OpenTaskMutationFragment @include(if: $open)
    ...CloseTaskMutationFragment @include(if: $close)
    ...SetOverriddenTaskStatusMutationFragment
      @include(if: $setOverriddenTaskStatus)
  }

  ${openTaskMutationFragment}
  ${closeTaskMutationFragment}
  ${setOverriddenTaskStatusMutationFragment}
  ${taskStatusFragment}
`;

const mapSelectedTaskStatusToOverriddenTaskStatus = selectedTaskStatus => {
  const selectedToOverriddenMap = {
    [SELECTED_TASK_STATUS.CALCULATED_BY_TIME_ENTRIES]: {
      isClosed: false,
      overriddenTaskStatus: null
    },
    [SELECTED_TASK_STATUS.MANUAL_NOT_STARTED]: {
      isClosed: false,
      overriddenTaskStatus: OverriddenTaskStatus.ManualNotStarted
    },
    [SELECTED_TASK_STATUS.MANUAL_IN_PROGRESS]: {
      isClosed: false,
      overriddenTaskStatus: OverriddenTaskStatus.ManualInProgress
    },
    [SELECTED_TASK_STATUS.COMPLETED]: {
      isClosed: true,
      overriddenTaskStatus: null
    }
  };

  return selectedToOverriddenMap[selectedTaskStatus];
};

const useOnTaskStatusSelected = task => {
  const [applySelectedTaskStatusMutationFunc, { loading }] = useMutation(
    applySelectedTaskStatusMutation
  );
  const applySelectedTaskStatus = useCallback(
    async mutationInput => {
      applySelectedTaskStatusMutationFunc({
        variables: mutationInput,
        refetchQueries: ['projectTasksQuery'],
        awaitRefetchQueries: true
      });
    },
    [applySelectedTaskStatusMutationFunc]
  );

  const [
    isTaskStatusSelectorExpanded,
    setTaskStatusSelectorExpanded
  ] = useState(false);

  const toggleTaskStatusSelectorExpanded = useCallback(() => {
    setTaskStatusSelectorExpanded(expanded => !expanded);
  }, [setTaskStatusSelectorExpanded]);

  const onTaskStatusSelected = useCallback(
    async event => {
      const mappedStatus = mapSelectedTaskStatusToOverriddenTaskStatus(
        event.target.value
      );

      const mutationInput = {
        taskId: task.id,
        projectId: task.projectReference.id,
        open: !mappedStatus.isClosed && task.isClosed,
        close: mappedStatus.isClosed && !task.isClosed,
        setOverriddenTaskStatus:
          mappedStatus.overriddenTaskStatus !== task.overriddenTaskStatus,
        overriddenTaskStatus: mappedStatus.overriddenTaskStatus
      };

      if (
        mutationInput.open ||
        mutationInput.close ||
        mutationInput.setOverriddenTaskStatus
      ) {
        await applySelectedTaskStatus(mutationInput);
      }
    },
    [applySelectedTaskStatus, task]
  );

  return {
    onTaskStatusSelected,
    isTaskStatusSelectorExpanded,
    toggleTaskStatusSelectorExpanded,
    loading
  };
};

export default useOnTaskStatusSelected;
