import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  DialogContent,
  DialogActions,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { BATCH_QUERY } from '~/modules/common/components/BillingBatchStatus/BillingBatchStatus';
import { BatchState } from '~/types';
import ProgressResult from './ProgressResult';

const useStyles = makeStyles(theme => ({
  progressRoot: {
    display: 'flex',
    width: '100%'
  },
  progressValue: {
    marginLeft: 'auto',
    fontWeight: theme.typography.fontWeightMedium
  },
  infoMessage: {
    fontWeight: theme.typography.fontWeightMedium
  },
  undoInfoMessageRoot: {
    paddingTop: theme.spacing(2)
  }
}));

export const ProgressContent = ({
  batchState,
  onCancel,
  onBatchComplete,
  syncBilled
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [hasCompleted, setHasCompleted] = useState(false);
  const { batchId, batchInProgress } = batchState;

  const { stopPolling, data: { getBillingBatchStatus = {} } = {} } = useQuery(
    BATCH_QUERY,
    {
      variables: {
        batchId
      },
      fetchPolicy: 'network-only',
      pollInterval: 500
    }
  );

  const { executionState, overallProgress } = getBillingBatchStatus;
  const hasProcessed =
    executionState === BatchState.Succeeded ||
    executionState === BatchState.Cancelled ||
    executionState === BatchState.Failed;

  const progressValue = useMemo(
    () =>
      !isNaN(overallProgress?.currentProgress) &&
      !isNaN(overallProgress?.maximumProgress)
        ? (100 * overallProgress?.currentProgress) /
          overallProgress?.maximumProgress
        : 0,
    [overallProgress?.currentProgress, overallProgress?.maximumProgress]
  );

  useEffect(() => {
    if (!hasProcessed || hasCompleted) return;
    stopPolling();
    onBatchComplete(overallProgress);
    setHasCompleted(true);
  }, [
    overallProgress,
    hasProcessed,
    stopPolling,
    batchId,
    onBatchComplete,
    hasCompleted
  ]);

  return (
    <>
      <DialogContent>
        {batchInProgress ? (
          <>
            <div className={classes.progressRoot}>
              <Typography
                variant="subtitle2"
                className={classes.progressValue}
              >{`${Math.round(
                isNaN(progressValue) ? 100 : progressValue
              )}%`}</Typography>
            </div>
            <LinearProgress
              variant="determinate"
              value={isNaN(progressValue) ? 100 : progressValue}
            />
            <div className={classes.undoInfoMessageRoot}>
              <Typography className={classes.infoMessage} variant="caption">
                <FormattedMessage id="billingTransactionList.cancelActionInfo" />
              </Typography>
            </div>
          </>
        ) : (
          <ProgressResult syncBilled={syncBilled} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          {formatMessage({
            id: batchInProgress ? 'button.cancel' : 'button.ok'
          })}
        </Button>
      </DialogActions>
    </>
  );
};

ProgressContent.propTypes = {
  batchState: PropTypes.shape({
    batchId: PropTypes.string,
    batchInProgress: PropTypes.bool
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  syncBilled: PropTypes.object
};

export default ProgressContent;
