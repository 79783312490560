const USER_ACCESS_ROLE = {
  ADMIN: 'urn:replicon:user-access-role:administrator',
  BILLING_MANAGER: 'urn:replicon:user-access-role:billing-manager',
  CLIENT_MANAGER: 'urn:replicon:user-access-role:client-manager',
  EXPENSE_USER: 'urn:replicon:user-access-role:expense-user',
  PAYROLL_MANAGER: 'urn:replicon:user-access-role:payroll-manager',
  PORTFOLIO_ADMIN: 'urn:replicon:user-access-role:portfolio-administrator',
  PORTFOLIO_MANAGER: 'urn:replicon:user-access-role:portfolio-manager',
  PROGRAM_MANAGER: 'urn:replicon:user-access-role:program-manager',
  PROJECT_ADMIN: 'urn:replicon:user-access-role:project-administrator',
  PROJECT_MANAGER: 'urn:replicon:user-access-role:project-manager',
  PROJECT_RESOURCE: 'urn:replicon:user-access-role:project-resource',
  REPORT_USER: 'urn:replicon:user-access-role:report-user',
  RESOURCE_MANAGER: 'urn:replicon:user-access-role:resource-manager',
  RESOURCE_POOL_MANAGER: 'urn:replicon:user-access-role:resource-pool-manager',
  REVENUE_MANAGER: 'urn:replicon:user-access-role:revenue-manager',
  SUPERVISOR: 'urn:replicon:user-access-role:supervisor',
  TIMEOFF_USER: 'urn:replicon:user-access-role:time-off-user',
  TIMESHEET_USER: 'urn:replicon:user-access-role:timesheet-user',
  TIMEPUNCH_USER: 'urn:replicon:user-access-role:time-punch-user'
};

export default USER_ACCESS_ROLE;
