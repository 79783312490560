import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {
  OverviewChart,
  OverviewChartLoading
} from '~/modules/common/charts/dashboard';
import { useMeContext } from '~/modules/me/useMeContext';
import { useProjectOverviewSummary } from './hooks';
import ProjectRevenueOverviewChart from './ProjectRevenueOverviewChart';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-2)
  }
}));

const tabs = {
  billableRevenue: {
    value: 'billableRevenue',
    label: <FormattedMessage id="kpiCharts.billableRevenue" />
  },
  billedRevenue: {
    value: 'billedRevenue',
    label: <FormattedMessage id="kpiCharts.billedRevenue" />
  }
};

export const ProjectOverviewChart = ({ chartDataInput }) => {
  const me = useMeContext();
  const classes = useStyles();
  const {
    featureFlags: {
      isPsaPrp2024q4BugFixesEnabled,
      isPsaPrpCappedBillingEnabled
    }
  } = me;

  const [tab, setTab] = useState(tabs.billableRevenue.value);
  const handleTabChange = useCallback((_, value) => setTab(value), [setTab]);

  const {
    projectId,
    dateRange,
    isProjectCurrencyChanged,
    isBillPlanChanged,
    projectPermissions: { canViewProjectCostData, canViewProjectBillingData }
  } = chartDataInput;

  const {
    costAmountSummary,
    revenueAmountSummary,
    profitAmountSummary,
    timeEnteredSummary,
    loading
  } = useProjectOverviewSummary({
    projectId,
    dateRange,
    includeBilledRevenue: false,
    skip: isPsaPrpCappedBillingEnabled
  });

  if (loading || isProjectCurrencyChanged || isBillPlanChanged)
    return <OverviewChartLoading />;

  return (
    <>
      {!isPsaPrpCappedBillingEnabled ? (
        <OverviewChart
          showCost={
            isPsaPrp2024q4BugFixesEnabled ? canViewProjectCostData : undefined
          }
          costAmountSummary={costAmountSummary}
          revenueAmountSummary={revenueAmountSummary}
          profitAmountSummary={profitAmountSummary}
          timeEnteredSummary={timeEnteredSummary}
          showRevenue={Boolean(canViewProjectBillingData)}
        />
      ) : (
        <div className={classes.root}>
          <Tabs value={tab} indicatorColor="primary" onChange={handleTabChange}>
            <Tab {...tabs.billableRevenue} />
            <Tab {...tabs.billedRevenue} />
          </Tabs>
          <ProjectRevenueOverviewChart
            projectId={projectId}
            dateRange={dateRange}
            canViewProjectCostData={canViewProjectCostData}
            canViewProjectBillingData={canViewProjectBillingData}
            includeBilledRevenue={tab === tabs.billedRevenue.value}
            isPsaPrp2024q4BugFixesEnabled={isPsaPrp2024q4BugFixesEnabled}
          />
        </div>
      )}
    </>
  );
};

ProjectOverviewChart.propTypes = {
  chartDataInput: PropTypes.object.isRequired
};

export default ProjectOverviewChart;
