import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useDefaultStyles = makeStyles(theme => ({
  tableRoot: {},
  tableHeader: {
    display: 'table-header-group',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.common.white,
    zIndex: 1300
  },
  tableHeaderRow: {
    display: 'table-row',
    backgroundColor: 'inherit'
  },
  tableHeaderCell: {
    display: 'table-cell',
    height: '64px',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '4px 24px 4px 24px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    verticalAlign: 'middle',
    backgroundColor: 'inherit',
    position: 'sticky',
    top: '0px'
  },
  tableHeaderSecondaryLabel: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  },
  tableBody: {
    display: 'table-row-group',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.common.white,
    textAlign: 'left'
  },
  tableRow: {
    display: 'table-row',
    backgroundColor: 'inherit'
  },
  tableCell: {
    display: 'table-cell',
    height: '64px',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '4px 24px 4px 24px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    verticalAlign: 'middle',
    backgroundColor: 'inherit'
  },
  tableCellDisabled: {
    color: theme.palette.grey[400]
  },
  tableFooter: {
    display: 'table-footer-group',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'right'
  },
  tableFooterRow: {
    display: 'table-row',
    backgroundColor: 'inherit'
  },
  tableFooterCell: {
    display: 'table-cell',
    height: '64px',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '4px 24px 4px 24px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    verticalAlign: 'middle',
    backgroundColor: 'inherit',
    position: 'sticky',
    bottom: '0px'
  },
  tableNavBody: {
    display: 'table-row-group',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.grey[100]
  },
  tabelCellSelection: {
    padding: 0,
    width: theme.spacing(5.25)
  },
  tableFixedCellWithSelection: {
    left: theme.spacing(5.125),
    paddingLeft: theme.spacing(0.5)
  },
  checkbox: {
    backgroundColor: 'unset !important',
    margin: theme.spacing(-0.25, 0)
  },
  headerCellWithNavigationButtons: {},
  pageOutOfBoundHeaderFix: {},
  selectAllBannerCell: {
    paddingRight: '0px !important'
  }
}));

export const ListTableThemeContext = React.createContext();

export const ListTableThemeConsumer = ListTableThemeContext.Consumer;

export const ListTableThemeProvider = ({ classes, children }) => {
  const styles = useDefaultStyles({ classes });

  return (
    <ListTableThemeContext.Provider value={styles}>
      {children}
    </ListTableThemeContext.Provider>
  );
};

ListTableThemeProvider.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any.isRequired
};

export const useListTableThemeContext = () => useContext(ListTableThemeContext);
