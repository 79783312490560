import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  OverviewChart,
  OverviewChartLoading
} from '~/modules/common/charts/dashboard';
import { useProjectOverviewSummary } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2, 2, 4)
  }
}));

const billableRevenueLabels = {
  revenueOverview: <FormattedMessage id="kpiCharts.billableRevenue" />,
  profitOverview: <FormattedMessage id="kpiCharts.profitBillable" />,
  marginOverview: <FormattedMessage id="kpiCharts.marginBillable" />
};

const billedRevenueLabels = {
  revenueOverview: <FormattedMessage id="kpiCharts.billedRevenue" />,
  profitOverview: <FormattedMessage id="kpiCharts.profitBilled" />,
  marginOverview: <FormattedMessage id="kpiCharts.marginBilled" />
};

export const ProjectRevenueOverviewChart = ({
  projectId,
  dateRange,
  canViewProjectCostData,
  canViewProjectBillingData,
  includeBilledRevenue,
  isPsaPrp2024q4BugFixesEnabled
}) => {
  const classes = useStyles();
  const {
    costAmountSummary,
    revenueAmountSummary,
    profitAmountSummary,
    timeEnteredSummary,
    billedRevenueAmountSummary,
    loading
  } = useProjectOverviewSummary({
    projectId,
    dateRange,
    includeBilledRevenue
  });

  if (loading)
    return (
      <div className={classes.root}>
        <OverviewChartLoading height={90} />
      </div>
    );

  return (
    <div className={classes.root}>
      <OverviewChart
        showCost={
          isPsaPrp2024q4BugFixesEnabled ? canViewProjectCostData : undefined
        }
        costAmountSummary={costAmountSummary}
        revenueAmountSummary={
          includeBilledRevenue
            ? billedRevenueAmountSummary
            : revenueAmountSummary
        }
        profitAmountSummary={profitAmountSummary}
        timeEnteredSummary={timeEnteredSummary}
        showRevenue={Boolean(canViewProjectBillingData)}
        resourceLabels={
          includeBilledRevenue ? billedRevenueLabels : billableRevenueLabels
        }
      />
    </div>
  );
};

ProjectRevenueOverviewChart.propTypes = {
  projectId: PropTypes.string.isRequired,
  dateRange: PropTypes.object,
  includeBilledRevenue: PropTypes.bool,
  canViewProjectCostData: PropTypes.bool,
  canViewProjectBillingData: PropTypes.bool,
  isPsaPrp2024q4BugFixesEnabled: PropTypes.bool
};

export default ProjectRevenueOverviewChart;
