import React from 'react';
import PropTypes from 'prop-types';
import { Typography, styled } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { User as UserAvatar, NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  noValue: {
    marginLeft: theme.spacing(1)
  }
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.text.secondary
}));

export const ProjectManagerFormatter = ({ field, record }) => {
  const classes = useStyles();
  const user = field ? record[field] : undefined;

  if (!record.project) {
    return (
      <StyledTypography variant="body2">
        <FormattedMessage id="avaialbleToBillList.multipleProjects" />
      </StyledTypography>
    );
  }

  return user ? (
    <UserAvatar user={user} />
  ) : (
    <NoValue className={classes.noValue} />
  );
};

ProjectManagerFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default ProjectManagerFormatter;
