import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListSubheader } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import { NoDataItem } from '~/modules/common/components';
import SkillDetails from './SkillDetails';

const useStyles = makeStyles(theme => ({
  content: {
    color: theme.palette.text.primary
  },
  contentRoot: {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.secondary
  },
  skillName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1,
    padding: theme.spacing(1, 0, 1, 0),
    backgroundColor: theme.palette.background.paper
  }
}));

const skillDetailsKey = (category, skillAssignment) => {
  const skillAssignmentKey = skillAssignment.uri || skillAssignment.displayText;

  return `${category.uri}|${skillAssignmentKey}`;
};

export const getSkillHeader = ({ classes, skill, index }) => (
  <ListSubheader
    data-qe-id={`ReadOnlySkillCategory_${index + 1}`}
    className={classes.skillName}
  >
    {skill?.category ? (
      skill.category.displayText
    ) : (
      <FormattedMessage id="skillInfoCard.unCategorized" />
    )}
  </ListSubheader>
);

export const SkillsReadOnly = ({
  certificates,
  classes: classesOverride,
  skills,
  maxSkillLevel,
  onAcceptUserNudges,
  onRejectUserNudges
}) => {
  const {
    featureFlags: { isPsaPraaUserProfileNudgesEnabled }
  } = useMeContext();
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.content}>
      {skills && skills.length > 0 ? (
        skills.map((skill, index) => (
          <List
            data-qe-id={`ReadOnlySkillList_${index + 1}`}
            key={skill.category.uri}
            subheader={getSkillHeader({ classes, skill, index })}
          >
            {skill.skillAssignments.map((skillAssignment, index1) => (
              <SkillDetails
                dataQeId={`${
                  skill.category.displayText
                }_Category_Skill_${index1 + 1}`}
                key={
                  isPsaPraaUserProfileNudgesEnabled
                    ? skillDetailsKey(skill.category, skillAssignment)
                    : skillAssignment.displayText
                }
                skillAssignment={skillAssignment}
                maxSkillLevel={maxSkillLevel}
                certificates={certificates}
                category={skill.category}
                onAcceptUserNudges={onAcceptUserNudges}
                onRejectUserNudges={onRejectUserNudges}
              />
            ))}
          </List>
        ))
      ) : (
        <NoDataItem>
          <FormattedMessage id="skillInfoCard.noSkillsAssigned" />
        </NoDataItem>
      )}
    </div>
  );
};

SkillsReadOnly.propTypes = {
  skills: PropTypes.array.isRequired,
  classes: PropTypes.object,
  maxSkillLevel: PropTypes.number,
  certificates: PropTypes.array,
  onAcceptUserNudges: PropTypes.func.isRequired,
  onRejectUserNudges: PropTypes.func.isRequired
};

export default SkillsReadOnly;
