export const USER_PERMISSION = {
  VIEW_AVAILABILITY: 'urn:replicon:user-action:view-availability',
  EDIT_USER: 'urn:replicon:user-action:edit-user',
  EDIT_PREFERENCES: 'urn:replicon:user-action:edit-preferences'
};

export const USER_DAL = {
  PROJECT_MANAGER: 'urn:replicon:user-data-access-level:project-manager',
  RESOURCE_MANAGER: 'urn:replicon:user-data-access-level:resource-manager',
  SYSTEM_ADMINISTRATOR:
    'urn:replicon:user-data-access-level:administrative-data-access-scope',
  USER: 'urn:replicon:user-data-access-level:self'
};
