import { ClickAwayListener } from '@material-ui/core';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useHasFeatureFlag, useMenuState } from '~/modules/common/hooks';
import { convertDurationToHours } from '~/modules/common/util';
import { TimelineBlock } from '~/modules/resourcing/common/chart/components';
import {
  DISPLAY_UNIT_ENUM,
  PERIOD_SCALE_ENUM
} from '~/modules/resourcing/common/enums';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import {
  getDayViewRoundedPercentage,
  getRoundedHours
} from '~/modules/resourcing/common/util';
import { ActualSummaryPeriodPopup } from '../../ActualSummaryPeriodPopup';
import ResourceActualSummaryDialog from '../../ResourceActualSummaryDialog';
import useStyles, { useTimeLineStyles } from './useStyles2';

export const ResourceActualHoursSummaryBlock2 = ({
  actualSummaryBlockPopupProps,
  allocationPeriod,
  classes: classesOverride,
  dataQeId,
  dynamicPosition,
  endDate,
  hours,
  resourceAvailabilityTimePeriod,
  scale,
  startDate
}) => {
  const zeroHours = hours === 0;

  const classes = useStyles({ classes: classesOverride });
  const blockClasses = useTimeLineStyles();
  const isRmpTaskAllocationPhase2Enabled = useHasFeatureFlag({
    featureFlag: 'isRmpTaskAllocationPhase2Enabled'
  });
  const { displayUnit } = useDisplayUnitContext();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();

  const timelineBlockClasses = useMemo(
    () => ({
      container: blockClasses.container,
      label: zeroHours ? blockClasses.zeroLabel : blockClasses.label
    }),
    [
      blockClasses.container,
      blockClasses.label,
      blockClasses.zeroLabel,
      zeroHours
    ]
  );

  const scheduledHours = resourceAvailabilityTimePeriod?.scheduledDuration
    ? convertDurationToHours(resourceAvailabilityTimePeriod.scheduledDuration)
    : allocationPeriod?.totalScheduleHours || 0;

  const preciseValue = resourceAvailabilityTimePeriod?.allocatedDuration
    ? convertDurationToHours(resourceAvailabilityTimePeriod.allocatedDuration)
    : allocationPeriod?.totalHours || 0;

  const allocatedHours = isRmpTaskAllocationPhase2Enabled
    ? getRoundedHours({ hours: preciseValue, scale })
    : preciseValue;

  const loading = scheduledHours ? (hours * 100) / scheduledHours : 0;
  const percentage = getDayViewRoundedPercentage({
    percentage: loading,
    scale
  });

  const totalHours = getRoundedHours({
    hours,
    scale
  });

  const showArrow =
    scale === PERIOD_SCALE_ENUM.DAYS &&
    displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE
      ? percentage !== 100 && totalHours !== allocatedHours
      : totalHours !== allocatedHours;

  const showSummaryBlock = allocatedHours > 0 || totalHours > 0;

  return (
    <>
      {showSummaryBlock && (
        <>
          <ClickAwayListener onClickAway={onMenuClose}>
            <div
              className={classNames(classes.root, {
                [classes.nonZeroRoot]: !zeroHours
              })}
              style={dynamicPosition}
              onClick={onMenuClick}
              onKeyDown={onMenuClick}
              role="presentation"
            >
              <div className={classes.timeBlock}>
                <TimelineBlock
                  dataQeId={dataQeId}
                  classes={timelineBlockClasses}
                  hours={totalHours}
                  percentage={percentage}
                  showPercentage={scheduledHours > 0}
                />
                {showArrow &&
                  (totalHours > allocatedHours ? (
                    <ArrowUpwardSharpIcon className={classes.arrowUp} />
                  ) : (
                    <ArrowDownwardSharpIcon className={classes.arrowDown} />
                  ))}
              </div>
            </div>
          </ClickAwayListener>
          {anchorEl &&
            (actualSummaryBlockPopupProps ? (
              <ActualSummaryPeriodPopup
                anchorEl={anchorEl}
                startDate={startDate}
                endDate={endDate}
                scheduledHours={scheduledHours}
                roleAllocatedHours={allocatedHours}
                roleActualHours={totalHours}
                onClose={onMenuClose}
                {...actualSummaryBlockPopupProps}
              />
            ) : (
              <ResourceActualSummaryDialog
                actualHours={totalHours}
                allocatedHours={allocatedHours}
                anchorEl={anchorEl}
                endDate={endDate}
                onClose={onMenuClose}
                scheduledHours={scheduledHours}
                startDate={startDate}
              />
            ))}
        </>
      )}
    </>
  );
};

ResourceActualHoursSummaryBlock2.propTypes = {
  actualSummaryBlockPopupProps: PropTypes.object,
  allocationPeriod: PropTypes.object,
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  dynamicPosition: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  hours: PropTypes.number.isRequired,
  resourceAvailabilityTimePeriod: PropTypes.object,
  scale: PropTypes.string,
  startDate: PropTypes.object.isRequired
};

export default ResourceActualHoursSummaryBlock2;
