import { object, string, mixed } from 'yup';
import { useCodeAndNameRequired } from '~/modules/common/hooks/useCodeAndNameRequired';
import { buildCustomFieldValidationSchema } from '~/modules/customFields/utils';
import { getProjectPermissions } from './useProjectPermissions';

const buildRequiredFieldValidator = ({ message }) => ({
  name: 'isRequired',
  message,
  test: field => Boolean(field && field.id)
});

const buildClientFieldValidator = ({ intl, clientFieldValidatorCheck }) => {
  let validator = mixed();

  if (clientFieldValidatorCheck) {
    const message = intl.formatMessage({
      id: 'addProject.clientIsRequired'
    });

    validator = validator.test(buildRequiredFieldValidator({ message }));
  }

  return validator;
};

const buildProgramFieldValidator = ({ intl, programFieldValidatorCheck }) => {
  let validator = mixed();

  if (programFieldValidatorCheck) {
    const message = intl.formatMessage({
      id: 'addProject.programIsRequired'
    });

    validator = validator.test(buildRequiredFieldValidator({ message }));
  }

  return validator;
};

const buildPortfolioFieldValidator = ({
  intl,
  portfolioFieldValidatorCheck
}) => {
  let validator = mixed();

  if (portfolioFieldValidatorCheck) {
    const message = intl.formatMessage({
      id: 'addProject.portfolioIsRequired'
    });

    validator = validator.test(buildRequiredFieldValidator({ message }));
  }

  return validator;
};

const buildProjectTemplateFieldValidator = ({
  intl,
  projectTemplateFieldValidatorCheck
}) => {
  let validator = mixed();

  if (projectTemplateFieldValidatorCheck) {
    const message = intl.formatMessage({
      id: 'addProject.projectTemplateIsRequired'
    });

    validator = validator.test(buildRequiredFieldValidator({ message }));
  }

  return validator;
};

const MAX_CODE_LENGTH = 50;

const _buildValidationSchema = ({
  customFieldDefinitions,
  intl,
  projectNameAndCodeUniqueness,
  clientFieldValidatorCheck,
  programFieldValidatorCheck,
  portfolioFieldValidatorCheck,
  projectTemplateFieldValidatorCheck
}) => {
  const { isCodeRequired, isNameRequired } = useCodeAndNameRequired({
    codeNameAndUniqueness: projectNameAndCodeUniqueness
  });

  return object().shape({
    name: isNameRequired
      ? string()
          .max(
            255,
            intl.formatMessage({
              id: 'projectBasicInfo.projectNameExceedsMaxLength'
            })
          )
          .required(
            intl.formatMessage({ id: 'addProject.enterProjectNameErrorMsg' })
          )
      : string().max(
          255,
          intl.formatMessage({
            id: 'projectBasicInfo.projectNameExceedsMaxLength'
          })
        ),
    code: isCodeRequired
      ? string()
          .max(
            MAX_CODE_LENGTH,
            intl.formatMessage(
              {
                id: 'projectBasicInfo.projectCodeExceedsMaxLength'
              },
              {
                max: MAX_CODE_LENGTH
              }
            )
          )
          .required(
            intl.formatMessage({ id: 'addProject.enterProjectCodeErrorMsg' })
          )
      : string().max(
          MAX_CODE_LENGTH,
          intl.formatMessage(
            {
              id: 'projectBasicInfo.projectCodeExceedsMaxLength'
            },
            {
              max: MAX_CODE_LENGTH
            }
          )
        ),
    customFields: buildCustomFieldValidationSchema({
      customFieldDefinitions,
      intl
    }),
    client: buildClientFieldValidator({
      clientFieldValidatorCheck,
      intl
    }),
    program: buildProgramFieldValidator({
      programFieldValidatorCheck,
      intl
    }),
    portfolio: buildPortfolioFieldValidator({
      portfolioFieldValidatorCheck,
      intl
    }),
    projectTemplate: buildProjectTemplateFieldValidator({
      projectTemplateFieldValidatorCheck,
      intl
    })
  });
};

export const buildValidationSchemaForNonProjectManager = ({
  customFieldDefinitions,
  intl,
  me
}) => {
  const {
    permissionsMap,
    userAccessRoles,
    projectNameAndCodeUniqueness,
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = me;
  const {
    canEditAllProjects,
    isClientManager,
    isProgramManager,
    isPortfolioManager
  } = getProjectPermissions({ permissionsMap, userAccessRoles });
  const clientFieldValidatorCheck = !canEditAllProjects && isClientManager;
  const programFieldValidatorCheck = !canEditAllProjects && isProgramManager;
  const portfolioFieldValidatorCheck =
    !canEditAllProjects && isPortfolioManager;

  const projectTemplateFieldValidatorCheck = isPsaPrpPsaPpmMergerEnabled;

  return _buildValidationSchema({
    customFieldDefinitions,
    intl,
    projectNameAndCodeUniqueness,
    clientFieldValidatorCheck,
    programFieldValidatorCheck,
    portfolioFieldValidatorCheck,
    projectTemplateFieldValidatorCheck
  });
};

export const buildValidationSchemaForProjectManager = ({
  customFieldDefinitions,
  intl,
  me
}) => {
  const {
    permissionsMap,
    userAccessRoles,
    projectNameAndCodeUniqueness,
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = me;
  const {
    canEditAllProjects,
    projectManagerCanViewProject,
    isClientManager,
    isProgramManager,
    isPortfolioManager
  } = getProjectPermissions({ permissionsMap, userAccessRoles });
  const clientFieldValidatorCheck =
    !canEditAllProjects && !projectManagerCanViewProject && isClientManager;
  const programFieldValidatorCheck =
    !canEditAllProjects && !projectManagerCanViewProject && isProgramManager;
  const portfolioFieldValidatorCheck =
    !canEditAllProjects && !projectManagerCanViewProject && isPortfolioManager;
  const projectTemplateFieldValidatorCheck = isPsaPrpPsaPpmMergerEnabled;

  return _buildValidationSchema({
    customFieldDefinitions,
    intl,
    projectNameAndCodeUniqueness,
    clientFieldValidatorCheck,
    programFieldValidatorCheck,
    portfolioFieldValidatorCheck,
    projectTemplateFieldValidatorCheck
  });
};
