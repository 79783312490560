import { useMeContext } from '~/modules/me';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { PROJECT_PERMISSION } from '~/modules/common/enums';
import { ResourceCostModeType } from '~/types';

export const getUserCostRateDetails = ({ resourceAllocation } = {}) => ({
  exchangeRateValues: resourceAllocation?.exchangeRateValues,
  costRate: resourceAllocation?.user?.costRate,
  primaryRoleCostRate: resourceAllocation?.user?.primaryRoleCostRate,
  costCurrency: resourceAllocation?.user?.costCurrency,
  primaryRoleCostCurrency: resourceAllocation?.user?.primaryRoleCostCurrency
});

const formatTotalCost = ({
  totalHours,
  costRate,
  contextCurrency,
  contextExchangeRates
}) => [
  costRate
    ? {
        amount:
          totalHours *
          costRate *
          (Object.values(contextExchangeRates || {}).find(
            r => r?.currencyId === contextCurrency?.id
          )?.rate || 1),
        currency: {
          symbol: contextCurrency?.displayText
        },
        currencySymbol: contextCurrency?.displayText
      }
    : { amount: null }
];

export const useResourceAllocationCost = ({
  totalHours,
  userCostRateDetails
}) => {
  const {
    resourceCostMode,
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();
  const { project } = useProjectContext();

  const canViewCost = project.permittedActionUris.includes(
    PROJECT_PERMISSION.VIEW_COST_DATA
  );

  const isUserCostModeEnabled =
    ResourceCostModeType.Usercost === resourceCostMode;

  return {
    canViewCost: isRmpTaskAllocationPhase2Enabled ? canViewCost : false,
    allocationCost: userCostRateDetails
      ? formatTotalCost({
          totalHours,
          costRate: isUserCostModeEnabled
            ? userCostRateDetails?.costRate
            : userCostRateDetails?.primaryRoleCostRate,
          contextCurrency:
            project.budgetedCost?.currency ||
            (isUserCostModeEnabled
              ? userCostRateDetails?.costCurrency
              : userCostRateDetails?.primaryRoleCostCurrency),
          contextExchangeRates: userCostRateDetails?.exchangeRateValues
        })
      : []
  };
};
