import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Decimal } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';
import { ResourceEstimateCost } from '../ResourceEstimateCost';

const useStyles = makeStyles(theme => ({
  hours: {
    lineHeight: 1,
    fontSize: theme.typography.body2.fontSize
  },
  cost: {
    fontSize: theme.typography.caption.fontSize
  },
  noValue: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0, 0.1875)
  }
}));

export const FormattedHourCostItem = ({
  hours,
  cost = {},
  hoursSuffix,
  canViewCost = false,
  classes: classesOverride,
  isReadOnlyView = true
}) => {
  const classes = useStyles({ classes: classesOverride });
  const variant = !isReadOnlyView ? 'pointerIcon' : 'default';

  return (
    <div>
      {isNullOrUndefined(hours) ? (
        <Typography className={classNames(classes.hours, classes.noValue)}>
          <FormattedMessage id="taskDrawer.na" />
        </Typography>
      ) : (
        <Decimal value={hours} className={classes.hours} suffix={hoursSuffix} />
      )}
      {canViewCost && (
        <>
          <ResourceEstimateCost
            hours={hours}
            cost={cost}
            className={classes.cost}
            variant={variant}
          />
        </>
      )}
    </div>
  );
};

FormattedHourCostItem.propTypes = {
  hours: PropTypes.number,
  cost: PropTypes.object,
  hoursSuffix: PropTypes.string,
  canViewCost: PropTypes.bool,
  isReadOnlyView: PropTypes.bool,
  classes: PropTypes.object
};

export default FormattedHourCostItem;
