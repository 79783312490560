import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonBase, Typography } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import IconLinkButton from '~/modules/resourcing/common/components/IconLinkButton';
import { ResourceRequestStatusIndicator } from '~/modules/resourcing/common/components';
import { ResourceRequestsIcon } from '~/modules/common/components';
import { ResourceRequestStatus } from '~/types';
import ResourceRequestReadonlyItem from './ResourceRequestReadonlyItem';
import useStyles from './useStyles';

export const LegacyResourceRequestItem = ({
  actions,
  classes: classesOverrides,
  displayEntityType,
  maxSkillLevel = 5,
  project,
  qeIdTag,
  resourceRequest
}) => {
  const {
    featureFlags: {
      isPsaPraaResourcingUIEnabled,
      isPsaRmpTaskAllocation1Enabled
    }
  } = useMeContext();

  const classes = useStyles({
    classes: classesOverrides
  });

  const intl = useIntl();
  const history = useHistory();

  const iconLinkButtonClasses = useMemo(
    () => ({ openButton: classes.openButton }),
    [classes.openButton]
  );

  const location = useMemo(
    () => ({
      hash: `#/resourceRequest/${resourceRequest.id}`,
      state: {
        isProjectEditEnabled: false
      }
    }),
    [resourceRequest.id]
  );

  const openDrawer = useCallback(() => {
    history.push(location);
  }, [history, location]);

  const columnClasses = useMemo(
    () => classnames(classes.column, classes.clickableColumn),
    [classes.clickableColumn, classes.column]
  );

  return (
    <>
      <div
        className={classnames(
          classes.column,
          classes.columnBorder,
          classes.actionsColumn
        )}
      >
        <ResourceRequestStatusIndicator
          qeIdTag={qeIdTag}
          status={resourceRequest.requestStatus}
        />
        {actions && (
          <div
            data-qe-id={
              qeIdTag ? `RowTotalAction_${qeIdTag}` : 'RowTotalAction'
            }
            className={classnames(classes.actions, {
              [classes.delaActions]:
                isPsaPraaResourcingUIEnabled &&
                resourceRequest.requestStatus ===
                  ResourceRequestStatus.Submitted
            })}
          >
            {actions}
          </div>
        )}
      </div>
      <div className={classes.container}>
        <ButtonBase className={columnClasses} onClick={openDrawer}>
          {isPsaRmpTaskAllocation1Enabled &&
          resourceRequest.requestStatus === ResourceRequestStatus.Complete &&
          resourceRequest?.mergedResourceRequests?.length ? (
            <div className={classes.requestContainer}>
              <ResourceRequestsIcon />
              <Typography className={classes.multipleRequestsLabel}>
                <FormattedMessage id="resourceRequestItem.multipleRequests" />
              </Typography>
            </div>
          ) : (
            <ResourceRequestReadonlyItem
              qeIdTag={qeIdTag}
              displayEntityType={displayEntityType}
              resourceRequest={resourceRequest}
              toLink={location}
              project={project}
              maxSkillLevel={maxSkillLevel}
            />
          )}
          <IconLinkButton
            classes={iconLinkButtonClasses}
            qeIdTag={qeIdTag ? `Drawer_Icon_${qeIdTag}` : 'Drawer_Icon'}
            toLink={location}
            ariaLabel={intl.formatMessage({
              id: 'resourceRequestItem.showMoreDetails'
            })}
          />
        </ButtonBase>
      </div>
    </>
  );
};

LegacyResourceRequestItem.propTypes = {
  actions: PropTypes.node.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  displayEntityType: PropTypes.string,
  maxSkillLevel: PropTypes.number,
  qeIdTag: PropTypes.string,
  project: PropTypes.object,
  classes: PropTypes.object
};

export default LegacyResourceRequestItem;
