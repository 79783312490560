/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { PropTypes } from 'prop-types';
import Drawer from '~/modules/common/components/Drawer';
import { useSkillLevels } from '~/modules/common/hooks';
import ErrorDrawer from '~/modules/common/components/TaskDrawer/ErrorDrawer';
import LoadingDrawer from '~/modules/common/components/TaskDrawer/LoadingDrawer';
import { useResourceRequestDetails } from '~/modules/resourcing/common/hooks';
import { useMeContext } from '~/modules/me';
import ResourceRequestDrawerContent from './ResourceRequestDrawerContent/ResourceRequestDrawerContent';
import { useFetchRequisitionData } from './hooks';

export const ResourceRequestDrawer = ({ id, onClose }) => {
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled, isPsaDtmRequisitionEnabled }
  } = useMeContext();

  const {
    loading,
    projectRequestDetails,
    error: errorData
  } = useResourceRequestDetails(id, isPsaRmpTaskAllocation1Enabled);

  const { maxSkillLevel, isSkillLoading } = useSkillLevels();

  const {
    requisition,
    loading: isRequisitionLoading
  } = useFetchRequisitionData({
    resourceRequestUri: id,
    skip: !isPsaDtmRequisitionEnabled
  });

  if (isSkillLoading || loading || isRequisitionLoading) {
    return <LoadingDrawer onClose={onClose} />;
  }

  if (errorData && errorData.error) {
    return <ErrorDrawer data={errorData} onClose={onClose} />;
  }

  return (
    <Drawer>
      <ResourceRequestDrawerContent
        {...projectRequestDetails}
        maxSkillLevel={maxSkillLevel}
        onClose={onClose}
        requisition={requisition}
      />
    </Drawer>
  );
};

ResourceRequestDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ResourceRequestDrawer;
