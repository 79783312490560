import React, { useMemo } from 'react';
import { Grid, withStyles, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ReadOnlyField,
  DateField,
  DateRange,
  ReadOnlyContainer
} from '~/modules/common/components';
import { MainWrapper } from '~/modules/common/components/LayoutWrapper';
import { ShowCommentsOnInvoiceCheckBox } from '~/modules/billing-invoicing/common/components';
import { DateAndEditIcon } from './components';

export const styles = theme => ({
  container: {
    padding: theme.spacing(1, 1, 0, 1),
    margin: 0,
    width: '100%',
    minHeight: '-webkit-max-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  displayText: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  }
});
const useStyles = makeStyles(theme => ({
  date: {
    lineHeight: '1.43',
    color: 'inherit',
    fontSize: '0.8125rem',
    fontWeight: '400',
    display: 'inline'
  },
  spacer: {
    color: 'inherit',
    fontWeight: 400,
    lineHeight: 'inherit'
  },
  noDateIcon: {
    color: theme.palette.error.dark
  }
}));

const resourceLabels = {
  referenceNumber: <FormattedMessage id="billDetails.referenceNumber" />,
  issueDate: <FormattedMessage id="billDetails.issueDate" />,
  description: <FormattedMessage id="billDetails.description" />,
  paymentTerms: <FormattedMessage id="billDetails.paymentTerm" />,
  dueDate: <FormattedMessage id="billDetails.dueDate" />,
  poNumber: <FormattedMessage id="billDetails.poNumber" />,
  period: <FormattedMessage id="billDetails.Period" />,
  billingAddress: <FormattedMessage id="billDetails.billingAddress" />,
  billCurrency: <FormattedMessage id="billDetails.billCurrency" />,
  taxProfile: <FormattedMessage id="billDetails.taxProfile" />,
  syncStatus: <FormattedMessage id="billDetails.syncStatus" />,
  invoiceTemplate: <FormattedMessage id="billDetails.invoiceTemplate" />,
  notesForCustomer: <FormattedMessage id="billDetails.notesForCustomer" />,
  internalNotes: <FormattedMessage id="billDetails.internalNotes" />,
  showComments: <FormattedMessage id="billDetails.showComments" />
};

export const BillInfoReadOnly = ({
  me,
  classes,
  values: {
    displayId,
    description,
    issueDate,
    paymentTerms,
    poNumber,
    period,
    billingAddress,
    dueDate,
    billCurrency,
    taxProfile,
    syncStatus,
    invoiceTemplate,
    notesForCustomer,
    internalNotes,
    showComments
  },
  editable,
  canEdit,
  setToEditMode
}) => {
  const {
    featureFlags: {
      isPsaPrpBillingDefaultForProjectEnabled,
      isPsaPswatBillingDraftSubStatusEnabled
    }
  } = me;
  const intl = useIntl();

  const syncValue = useMemo(
    () => ({
      label: syncStatus
        ? intl.formatMessage({
            id: `billingTransactionList.${syncStatus}`,
            defaultMessage: syncStatus
          })
        : null
    }),
    [intl, syncStatus]
  );
  const daterangeStyles = useStyles();

  const dateRangeClasses = useMemo(
    () => ({
      range: daterangeStyles.range,
      date: daterangeStyles.date,
      spacer: daterangeStyles.spacer
    }),
    [daterangeStyles]
  );

  return (
    <>
      <MainWrapper>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={4}>
            <ReadOnlyField
              className={classes.displayText}
              label={resourceLabels.referenceNumber}
              value={displayId}
              dataQeId="billDetails.referenceNumber"
            />
          </Grid>
          <Grid item xs={4}>
            <DateField
              variant="standard"
              fullWidth
              clearable={false}
              label={resourceLabels.issueDate}
              value={issueDate}
              editable={false}
              underline={false}
              dataQeId="billDetails.issueDate"
            />
          </Grid>
          <Grid item xs={4}>
            <DateAndEditIcon
              resourceLabels={resourceLabels}
              dueDate={dueDate}
              isPsaPswatBillingDraftSubStatusEnabled={
                isPsaPswatBillingDraftSubStatusEnabled
              }
              editable={editable}
              canEdit={canEdit}
              setToEditMode={setToEditMode}
            />
          </Grid>
          {poNumber && (
            <Grid item xs={4}>
              <ReadOnlyField
                className={classes.displayText}
                label={resourceLabels.poNumber}
                value={poNumber}
                dataQeId="billDetails.poNumber"
              />
            </Grid>
          )}
          {period.startDate && period.endDate ? (
            <Grid item xs={4}>
              <ReadOnlyContainer
                label={resourceLabels.period}
                className={classes.displayText}
              >
                <DateRange
                  classes={dateRangeClasses}
                  start={period.startDate}
                  end={period.endDate}
                />
              </ReadOnlyContainer>
            </Grid>
          ) : null}
          <Grid item xs={4}>
            <ReadOnlyField
              label={resourceLabels.paymentTerms}
              value={paymentTerms}
              dataQeId="billDetails.paymentTerm"
            />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyField
              label={resourceLabels.billCurrency}
              value={billCurrency.displayText}
              dataQeId="billDetails.billCurrency"
            />
          </Grid>
          {invoiceTemplate ? (
            <Grid item xs={4}>
              <ReadOnlyField
                label={resourceLabels.invoiceTemplate}
                value={invoiceTemplate.displayText}
                className={classes.displayText}
                dataQeId="billDetails.invoiceTemplate"
              />
            </Grid>
          ) : null}
          {isPsaPrpBillingDefaultForProjectEnabled ? (
            <>
              {taxProfile && taxProfile.displayText && (
                <Grid item xs={4}>
                  <ReadOnlyField
                    label={resourceLabels.taxProfile}
                    value={taxProfile.displayText}
                    dataQeId="billDetails.taxProfile"
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <ReadOnlyField
                  label={resourceLabels.syncStatus}
                  value={syncValue.label}
                />
              </Grid>
              {billingAddress && (
                <Grid item xs={12} sm={4}>
                  <ReadOnlyField
                    className={classes.displayText}
                    label={resourceLabels.billingAddress}
                    value={billingAddress}
                    dataQeId="billDetails.billingAddress"
                  />
                </Grid>
              )}
              {description && (
                <Grid item xs={invoiceTemplate ? 4 : 12}>
                  <ReadOnlyField
                    className={classes.displayText}
                    label={resourceLabels.description}
                    value={description}
                    dataQeId="billDetails.description"
                  />
                </Grid>
              )}
            </>
          ) : (
            <>
              {description && (
                <Grid item xs={invoiceTemplate ? 4 : 12}>
                  <ReadOnlyField
                    className={classes.displayText}
                    label={resourceLabels.description}
                    value={description}
                    dataQeId="billDetails.description"
                  />
                </Grid>
              )}
              {taxProfile && taxProfile.displayText && (
                <Grid item xs={4}>
                  <ReadOnlyField
                    label={resourceLabels.taxProfile}
                    value={taxProfile.displayText}
                    dataQeId="billDetails.taxProfile"
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <ReadOnlyField
                  label={resourceLabels.syncStatus}
                  value={syncValue.label}
                />
              </Grid>
              {billingAddress && (
                <Grid item xs={12} sm={4}>
                  <ReadOnlyField
                    className={classes.displayText}
                    label={resourceLabels.billingAddress}
                    value={billingAddress}
                    dataQeId="billDetails.billingAddress"
                  />
                </Grid>
              )}
            </>
          )}

          {notesForCustomer && (
            <Grid item xs={4}>
              <ReadOnlyField
                label={resourceLabels.notesForCustomer}
                value={notesForCustomer}
                className={classes.displayText}
                dataQeId="billDetails.notesForCustomer"
              />
            </Grid>
          )}
          {internalNotes && (
            <Grid item xs={4}>
              <ReadOnlyField
                label={resourceLabels.internalNotes}
                value={internalNotes}
                className={classes.displayText}
                dataQeId="billDetails.internalNotes"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <ShowCommentsOnInvoiceCheckBox
              showCommentsLabel={resourceLabels.showComments}
              showComments={showComments}
              isReadOnly
              dataQeId="ReadOnlyShowCommentsOnInvoice"
            />
          </Grid>
        </Grid>
      </MainWrapper>
    </>
  );
};
BillInfoReadOnly.propTypes = {
  values: PropTypes.object.isRequired,
  classes: PropTypes.object,
  me: PropTypes.object,
  canEdit: PropTypes.bool,
  editable: PropTypes.bool,
  setToEditMode: PropTypes.func
};

export default withStyles(styles)(BillInfoReadOnly);
