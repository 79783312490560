import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import useStyles from './useStyles';
import AttachmentsListRow from './AttachmentsListRow';

export const AttachmentsList = ({
  attachments,
  deleteAttachment,
  editable,
  AttachmentStatusComponent
}) => {
  const classes = useStyles();

  return (
    <List className={classes.root} component="div">
      {attachments.map((attachment, index) => (
        <AttachmentsListRow
          attachment={attachment}
          deleteAttachment={deleteAttachment}
          key={attachment.uploadUri}
          editable={editable}
          lastItem={attachments.length - 1 === index}
          AttachmentStatusComponent={AttachmentStatusComponent}
        />
      ))}
    </List>
  );
};

AttachmentsList.propTypes = {
  attachments: PropTypes.array.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  AttachmentStatusComponent: PropTypes.func
};

export default AttachmentsList;
