import React from 'react';
import { FormattedMessage } from 'react-intl';

export const buildFooters = ({
  classes,
  editable,
  isPsaPrpManualBulkBillCreationEnabled,
  isPsaPrpAddProjectColumnOnAvailableToBillEnabled,
  visibleColumns = []
}) => ({
  ...(isPsaPrpManualBulkBillCreationEnabled && {
    rowSelector: {
      id: 'rowSelector',
      visible: isPsaPrpManualBulkBillCreationEnabled && editable
    }
  }),
  client: {
    id: 'client',
    value: !isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
      <FormattedMessage id="avaialbleToBillList.total" />
    ) : (
      undefined
    ),
    fixed: true,
    visible: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? visibleColumns.includes('client')
      : true,
    align: 'right',
    className: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? classes.dynamicColumn
      : classes.total
  },
  ...(isPsaPrpAddProjectColumnOnAvailableToBillEnabled && {
    project: {
      visible: visibleColumns.includes('project'),
      className: classes.dynamicColumn
    },
    projectLeader: {
      visible: visibleColumns.includes('projectLeader'),
      className: classes.dynamicColumn
    }
  }),
  date: {
    id: 'date',
    visible: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? visibleColumns.includes('date')
      : true,
    value: isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
      <FormattedMessage id="avaialbleToBillList.total" />
    ) : (
      undefined
    ),
    align: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? 'right'
      : undefined
  },
  amount: {
    id: 'amount',
    visible: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      ? visibleColumns.includes('amount')
      : true,
    align: 'right',
    flip: false
  },
  createBill: {
    id: 'createBill',
    visible: editable
  }
});
