import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, CircularProgress } from '@material-ui/core';
import { useGetAttachmentInfo } from '../hooks';
import DelaLoadingTooltip from './DelaLoadingTooltip';

const useStyles = makeStyles(theme => ({
  spinner: {
    marginTop: theme.spacing(0.5)
  }
}));

const useTooltipStyles = makeStyles(theme => ({
  tooltip: {
    borderRadius: theme.spacing(2)
  }
}));

const title = <DelaLoadingTooltip />;

export const DelaAttachmentStatus = ({ attachment, setShowDelaBanner }) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { processed, notifyUser } = useGetAttachmentInfo({
    binaryObjectUri: attachment.uploadUri
  });

  useEffect(() => {
    if (processed && notifyUser) {
      setShowDelaBanner(true);
    }
  }, [setShowDelaBanner, processed, notifyUser]);

  return (
    !processed && (
      <Tooltip classes={tooltipClasses} placement="bottom-end" title={title}>
        <CircularProgress className={classes.spinner} size={20} />
      </Tooltip>
    )
  );
};

DelaAttachmentStatus.propTypes = {
  attachment: PropTypes.object.isRequired,
  setShowDelaBanner: PropTypes.func.isRequired
};

export default DelaAttachmentStatus;
