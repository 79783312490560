import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';

export const PROJECT_ACTUAL_SERIES_BY_TASK_QUERY = gql`
  query getResourceUserProjectActualSeriesByTask(
    $projectId: String!
    $userUri: String!
    $dateRange: DateRangeInput!
    $periodResolution: PeriodResolutionOption!
    $taskUris: [String!]
  ) {
    resourceUsers2(
      page: 1
      pagesize: 1
      filter: { userIds: [$userUri], includeDisabled: true }
    ) {
      id
      projectActualSeriesByTask(
        projectUri: $projectId
        dateRange: $dateRange
        periodResolution: $periodResolution
        taskUris: $taskUris
      ) {
        task {
          id
          displayText
        }
        periods {
          hours {
            hours
            minutes
            seconds
          }
          dateRange {
            startDate: startDate2
            endDate: endDate2
          }
        }
      }
    }
  }
`;

export const useProjectActualSeriesByTaskForUser = ({
  dateRange,
  projectId,
  scale,
  taskUris,
  userUri,
  skip
}) => {
  const { data, loading } = useQuery(PROJECT_ACTUAL_SERIES_BY_TASK_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      dateRange,
      userUri,
      projectId,
      periodResolution: mapScaleToPeriodResolution(scale),
      taskUris
    },
    skip
  });

  return {
    loading,
    projectActualSeriesByTask: (
      data?.resourceUsers2?.[0]?.projectActualSeriesByTask || []
    ).reduce((retVal, current) => {
      // eslint-disable-next-line no-param-reassign
      retVal[current.task.id] = current;

      return retVal;
    }, {})
  };
};

export default useProjectActualSeriesByTaskForUser;
