import {
  Button,
  Chip,
  Divider,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core';
import ExpandLessSharp from '@material-ui/icons/ExpandLessSharp';
import ExpandMoreSharp from '@material-ui/icons/ExpandMoreSharp';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TaskStatus } from '~/types';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import { NoValue, User } from '~/modules/common/components';
import ErrorCard from '~/modules/tasks/modecs/ErrorCard';
import Date from '~/modules/common/components/Date';
import DateRange from '~/modules/common/components/DateRange';
import Decimal from '~/modules/common/components/Decimal';
import { RoleName } from '~/modules/common/components/entities/role';
import { resolveValueOrDefault } from '~/modules/common/components/FormCustomField';
import FormSelectField from '~/modules/common/components/FormSelectField';
import ReadOnlyContainer from '~/modules/common/components/ReadOnlyContainer';
import ExtensionFieldValueText from '~/modules/extensionFields/components/ExtensionFieldValueText';
import { useMeContext } from '~/modules/me';
import { useTaskCustomFieldDefinitions } from '~/modules/tasks/modecs/useTaskCustomFieldDefinitions';
import { useDialogState } from '~/modules/common/hooks';
import RescheduleTaskDialog from '~/modules/tasks/components/RescheduleTaskDialog';
import { useSetTaskTimeEntryDateRange } from '~/modules/tasks/hooks';
import {
  TaskStatusSelectField,
  ClosedOnDatePicker,
  ReadOnlyCustomFieldValue,
  RescheduleHeaderInfo
} from './components';
import { useExpandedState, useOnOpenCloseSelection } from './hooks';
import TaskDrawerHeaderInfoBarExpanded from './TaskDrawerHeaderInfoBarExpanded';
import RescheduleButton from './RescheduleButton';

const useStyles = makeStyles({
  label: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize
  },
  wrapper: ({ showRolledUpHeader }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingBottom: showRolledUpHeader ? theme.spacing(1) : theme.spacing(2)
  }),
  flexRow: ({ showRolledUpHeader }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: showRolledUpHeader ? 'inherit' : 'center',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1)
  }),
  rowItem: {
    display: 'flex',
    paddingRight: theme.spacing(2)
  },
  columnItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 0,
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(1.5)
  },
  subheading: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  boldItem: {
    fontWeight: theme.typography.fontWeightMedium
  },
  textItem: {
    paddingLeft: '0.3em'
  },
  lineItem: {
    paddingLeft: theme.spacing(1)
  },
  smallIconButton: {
    padding: '0'
  },
  expandButton: ({ showRolledUpHeader }) => ({
    color: showRolledUpHeader
      ? theme.palette.text.main
      : theme.palette.text.secondary,
    marginTop: showRolledUpHeader ? theme.spacing(2.25) : 0,
    textTransform: showRolledUpHeader ? 'none' : undefined
  }),
  expanded: {
    backgroundColor: theme.palette.background.default
  },
  details: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginBottom: 'auto'
  },
  chip: {
    backgroundColor: theme.palette.text.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5)
  },
  statusChip: ({ status }) => ({
    backgroundColor: theme.palette.taskStatus[status].color,
    fontWeight: 600
  }),
  field: ({ isRolledUpTaskEstimateCalculationMethodEnabled, status }) => ({
    color: theme.palette.common.white,
    backgroundColor: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.palette.taskStatus[status].color
      : theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    padding: isRolledUpTaskEstimateCalculationMethodEnabled
      ? 0
      : theme.spacing(0, 0, 0, 1),
    fontWeight: isRolledUpTaskEstimateCalculationMethodEnabled ? 600 : 400
  }),
  inputBase: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,
    height: theme.spacing(4),
    '&.MuiInputBase-root.Mui-disabled': {
      color: theme.palette.common.white
    },
    justifyContent: 'center',
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(3.75),
      backgroundColor: 'transparent'
    }
  },
  select: {
    paddingLeft: '5px',
    paddingTop: theme.spacing(1)
  },
  select2: {
    padding: theme.spacing(0.25, 1.5, 0, 1.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
    color: theme.palette.text.main
  },
  icon: ({ isRolledUpTaskEstimateCalculationMethodEnabled }) => ({
    color: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.palette.text.main
      : theme.palette.common.white,
    right: theme.spacing(0.5)
  }),
  noDateIcon: {
    color: theme.palette.error.dark
  },
  noValue: { fontSize: theme.typography.body2.fontSize },
  roleName: {
    fontSize: theme.typography.body2.fontSize
  },
  description: {
    whiteSpace: 'pre-wrap',
    hyphens: 'auto',
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  },
  range: ({ hasStartAndEndDate }) => ({
    marginTop: hasStartAndEndDate ? theme.spacing(1) : theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    display: hasStartAndEndDate ? 'inline' : 'flex',
    alignItems: hasStartAndEndDate ? undefined : 'center',
    color: 'inherit'
  }),
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    display: 'inline'
  },
  spacer: {
    color: 'inherit',
    fontWeight: 400,
    lineHeight: 'inherit'
  },
  container: {
    overflow: 'hidden',
    marginTop: theme.spacing(1.5)
  },
  name: {
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    paddingLeft: theme.spacing(1) - 1
  },
  rescheduleColumnItem: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(1.5),
    alignItems: 'flex-start'
  },
  rollupDisabledIcon: {
    cursor: 'pointer',
    marginTop: -theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.75)
  },
  rollupDisabledContent: {
    padding: theme.spacing(0.5, 1),
    fontSize: theme.spacing(1.75),
    color: theme.palette.text.secondary,
    fontWeight: 500
  },
  infoIconContainer: {
    marginLeft: -theme.spacing(7),
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(0.5)
  }
});

const InputBase = classes => (
  <Input className={classes.inputBase} disableUnderline />
);

export const TaskDrawerHeaderInfoBar = ({
  task,
  expandedInitialState = false,
  handleRefetch
}) => {
  const { formatMessage } = useIntl();
  const me = useMeContext();
  const {
    featureFlags: {
      isPsaPrpPsaPpmMergerEnabled,
      isPsaRmpTaskAllocation1Enabled,
      isPsaPrpManualTaskStatusEnabled,
      isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled,
      isPsaPswatTaskDateRollupEnabled
    },
    isRolledUpTaskEstimateCalculationMethodEnabled,
    isExpenseProductEnabled,
    hasViewProjectBillingOptions
  } = me;
  const showRolledUpHeader =
    isRolledUpTaskEstimateCalculationMethodEnabled ||
    isPsaPrpManualTaskStatusEnabled;

  const {
    startDate,
    endDate,
    taskStatus,
    assignedUser,
    assignedRole,
    isClosedByTaskInheritance,
    isClosed,
    extensionFieldValues,
    initialEstimatedHours,
    timeAndExpenseEntryType,
    isTimeEntryAllowed,
    costTypeUri,
    description,
    project,
    rolledUpSummary
  } = task;

  const taskState = isClosed || isClosedByTaskInheritance ? 1 : 2;
  const isTaskDateRollupEnabled = project?.isTaskDateRollupEnabled || false;
  const hasStartAndEndDate = startDate && endDate;
  const actualHours = rolledUpSummary?.actualHours;
  const rolledUpTaskStatus =
    taskStatus === TaskStatus.Completed
      ? TaskStatus.Completed
      : actualHours
      ? TaskStatus.Inprogress
      : TaskStatus.Notstarted;

  const classes = useStyles({
    status: isRolledUpTaskEstimateCalculationMethodEnabled
      ? rolledUpTaskStatus
      : taskStatus,
    hasStartAndEndDate,
    isRolledUpTaskEstimateCalculationMethodEnabled,
    showRolledUpHeader
  });
  const selectClasses = useMemo(
    () => ({
      icon: classes.icon,
      select: isRolledUpTaskEstimateCalculationMethodEnabled
        ? classes.select2
        : classes.select
    }),
    [
      isRolledUpTaskEstimateCalculationMethodEnabled,
      classes.icon,
      classes.select,
      classes.select2
    ]
  );
  const dateRangeClasses = useMemo(
    () => ({
      range: classes.range,
      date: classes.date,
      spacer: classes.spacer
    }),
    [classes]
  );
  const noValueClasses = useMemo(
    () => ({
      root: classes.noValue
    }),
    [classes.noValue]
  );

  const {
    openCloseSelection,
    openCloseExpand,
    onOpenCloseExpand
  } = useOnOpenCloseSelection(task);

  const {
    open: isRescheduleTaskDialogOpen,
    openDialog: openRescheduleTaskDialog,
    closeDialog: closeRescheduleTaskDialog
  } = useDialogState(false);
  const { expanded, setExpanded } = useExpandedState(expandedInitialState);
  const { setTaskTimeEntryDateRange, loading } = useSetTaskTimeEntryDateRange();

  const {
    loading: customFieldDefsLoading,
    error: customFieldDefsError,
    customFieldDefinitions
  } = useTaskCustomFieldDefinitions();

  const onRescheduleTaskSubmit = useCallback(
    async ({
      startDate: newStartDate,
      endDate: newEnddate,
      rescheduleTaskModificationOption
    }) => {
      await setTaskTimeEntryDateRange({
        taskId: task.id,
        startDate: newStartDate,
        endDate: newEnddate,
        rescheduleTaskModificationOption,
        shouldRollupTaskDates:
          isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled
      });
      closeRescheduleTaskDialog();
      handleRefetch();
    },
    [
      setTaskTimeEntryDateRange,
      task.id,
      isPsaPswatTaskDateRollupEnabled,
      isTaskDateRollupEnabled,
      closeRescheduleTaskDialog,
      handleRefetch
    ]
  );

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting: task?.project?.projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });
  const { hasCostType } = templateSettings || {};

  const [canViewProject, canEditTask, canViewEstimate, canViewCostType] = [
    Boolean(
      project?.permittedActionUris?.includes(
        'urn:replicon:project-action:view-project'
      )
    ),
    Boolean(
      project?.permittedActionUris?.includes(
        'urn:replicon:project-action:edit-tasks'
      )
    ),
    Boolean(
      project?.permittedActionUris?.includes(
        'urn:replicon:project-action:view-project-estimates'
      )
    ),
    (!isPsaPrpPsaPpmMergerEnabled ||
      (isPsaPrpPsaPpmMergerEnabled && hasCostType)) &&
      Boolean(
        project?.permittedActionUris?.includes(
          'urn:replicon:project-action:view-cost-type'
        )
      )
  ];

  const taskOwner = useMemo(
    () =>
      canViewProject
        ? assignedUser
        : { id: me.id, uri: me.uri, displayText: me.displayText },
    [canViewProject, assignedUser, me.id, me.uri, me.displayText]
  );

  const options = isRolledUpTaskEstimateCalculationMethodEnabled
    ? [
        {
          value: 2,
          displayText: formatMessage({
            id: `taskDrawer.${actualHours ? 'inProgress' : 'notStarted'}`
          })
        },
        {
          value: 1,
          displayText: formatMessage({ id: 'taskDrawer.completed' })
        }
      ]
    : [
        {
          value: 1,
          displayText: formatMessage({ id: 'taskDrawer.closed' })
        },
        {
          value: 2,
          displayText: formatMessage({ id: 'taskDrawer.open' })
        }
      ];

  const visibleCustomFieldDefinitions = customFieldDefinitions.filter(
    def => def.isVisible
  );

  const customFields = useMemo(
    () =>
      visibleCustomFieldDefinitions.reduce(
        (fields, definition) => ({
          ...fields,
          [definition.uri]: resolveValueOrDefault(task.customFields, definition)
        }),
        {}
      ),
    [visibleCustomFieldDefinitions, task]
  );

  const hasViewProjectBillingOption = isPsaPrpPsaPpmMergerEnabled
    ? templateSettings?.hasBilling && hasViewProjectBillingOptions
    : hasViewProjectBillingOptions;

  const selectedTimeAndExpenseEntryType = useMemo(() => {
    if (!hasViewProjectBillingOption && isTimeEntryAllowed)
      return formatMessage({ id: 'timeAndExpenseEntryOptions.yes' });

    if (timeAndExpenseEntryType && timeAndExpenseEntryType.uri)
      return formatMessage({
        id: `timeAndExpenseEntryOptions.${timeAndExpenseEntryType.uri}`
      });

    return '';
  }, [
    hasViewProjectBillingOption,
    isTimeEntryAllowed,
    formatMessage,
    timeAndExpenseEntryType
  ]);

  const showTimeAndExpenseEntryTypeField =
    hasViewProjectBillingOption &&
    project?.billingType?.id !== 'urn:replicon:billing-type:non-billable';

  const showRescheduleButton = isPsaRmpTaskAllocation1Enabled && canEditTask;
  const isRescheduleDisabled =
    showRescheduleButton &&
    isTaskDateRollupEnabled &&
    isPsaPswatTaskDateRollupEnabled &&
    task.hasChildren;

  return customFieldDefsLoading ? (
    <Skeleton data-qe-id="TaskDrawer_Loading" variant="rect" height={48} />
  ) : customFieldDefsError ? (
    <ErrorCard error={customFieldDefsError} />
  ) : (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.flexRow}>
          {!showRolledUpHeader && (
            <>
              <div className={classes.rowItem}>
                {canEditTask &&
                  (isClosedByTaskInheritance && !isClosed ? (
                    <Chip
                      className={classes.chip}
                      label={options[0].displayText}
                    />
                  ) : (
                    <FormSelectField
                      className={classes.field}
                      classes={selectClasses}
                      value={taskState}
                      onChange={openCloseSelection}
                      onOpen={onOpenCloseExpand}
                      onClose={onOpenCloseExpand}
                      input={InputBase(classes)}
                      IconComponent={
                        openCloseExpand ? ExpandLessSharp : ExpandMoreSharp
                      }
                      disabled={!canEditTask}
                    >
                      {options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.displayText}
                        </MenuItem>
                      ))}
                    </FormSelectField>
                  ))}
                {!canEditTask && (
                  <Chip
                    color="primary"
                    label={formatMessage({
                      id: `taskDrawer.${
                        isClosed || isClosedByTaskInheritance
                          ? 'closed'
                          : 'open'
                      }`
                    })}
                  />
                )}
              </div>
              <div
                className={classNames(classes.rowItem, {
                  [classes.rescheduleColumnItem]: showRescheduleButton
                })}
              >
                <DateRange start={startDate} end={endDate} />
                {showRescheduleButton && (
                  <RescheduleButton
                    onClick={openRescheduleTaskDialog}
                    isDisabled={isRescheduleDisabled}
                    isRolledUpTaskEstimateCalculationMethodEnabled={
                      isRolledUpTaskEstimateCalculationMethodEnabled
                    }
                  />
                )}
              </div>
              {isRescheduleDisabled && (
                <RescheduleHeaderInfo
                  isRolledUp={isRolledUpTaskEstimateCalculationMethodEnabled}
                />
              )}
              <div className={classes.rowItem}>
                {taskOwner && <User user={taskOwner} />}
              </div>
            </>
          )}
          {showRolledUpHeader && (
            <>
              <div className={classes.columnItem}>
                {isPsaPrpManualTaskStatusEnabled ? (
                  <TaskStatusSelectField
                    task={task}
                    canEditTask={canEditTask}
                  />
                ) : (
                  <>
                    <span className={classes.subheading}>
                      {formatMessage({
                        id: 'taskDrawer.Status'
                      })}
                    </span>
                    {canEditTask ? (
                      <FormSelectField
                        className={classes.field}
                        classes={selectClasses}
                        value={taskState}
                        onChange={openCloseSelection}
                        onOpen={onOpenCloseExpand}
                        onClose={onOpenCloseExpand}
                        input={InputBase(classes)}
                        IconComponent={
                          openCloseExpand ? ExpandLessSharp : ExpandMoreSharp
                        }
                        disabled={!canEditTask}
                      >
                        {options.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.displayText}
                          </MenuItem>
                        ))}
                      </FormSelectField>
                    ) : (
                      <Chip
                        className={classes.statusChip}
                        label={formatMessage({
                          id: `projectTasksPage.tasksStatus.${rolledUpTaskStatus}`
                        })}
                      />
                    )}
                  </>
                )}
              </div>
              <div className={classes.columnItem}>
                <span className={classes.subheading}>
                  {formatMessage({
                    id: 'taskDrawer.plannedDates'
                  })}
                  {showRescheduleButton && (
                    <RescheduleButton
                      onClick={openRescheduleTaskDialog}
                      isDisabled={isRescheduleDisabled}
                      isRolledUpTaskEstimateCalculationMethodEnabled={
                        isRolledUpTaskEstimateCalculationMethodEnabled
                      }
                    />
                  )}
                  {isRescheduleDisabled && (
                    <RescheduleHeaderInfo
                      isRolledUp={
                        isRolledUpTaskEstimateCalculationMethodEnabled
                      }
                    />
                  )}
                </span>
                <DateRange
                  classes={dateRangeClasses}
                  start={startDate}
                  end={endDate}
                  noDateComponent={
                    <ReportProblemOutlinedIcon className={classes.noDateIcon} />
                  }
                />
              </div>
              <div className={classes.rowItem}>
                {taskOwner && (
                  <User
                    classes={{
                      container: classes.container,
                      name: classes.name
                    }}
                    user={taskOwner}
                    size="small"
                  />
                )}
              </div>
            </>
          )}
          <div className={classes.rowItem}>
            <Button
              data-qe-id="TaskDrawer_MoreFields_Icon"
              size="small"
              onClick={() => setExpanded(!expanded)}
              className={classes.expandButton}
            >
              {expanded ? (
                <ExpandLessSharp className={classes.boldItem} />
              ) : (
                <ExpandMoreSharp className={classes.boldItem} />
              )}
              {formatMessage({
                id: expanded ? 'taskDrawer.less' : 'taskDrawer.more'
              })}
            </Button>
          </div>
        </div>
      </div>
      {(isClosed || isClosedByTaskInheritance) && (
        <ClosedOnDatePicker task={task} canEditTask={canEditTask} />
      )}
      {isPsaRmpTaskAllocation1Enabled && expanded && (
        <TaskDrawerHeaderInfoBarExpanded
          task={task}
          expandedInitialState={expandedInitialState}
          visibleCustomFieldDefinitions={visibleCustomFieldDefinitions}
          customFields={customFields}
          hasCostType={hasCostType}
          templateSettings={templateSettings}
        />
      )}
      {!isPsaRmpTaskAllocation1Enabled && expanded && (
        <div className={classes.expanded}>
          <Divider />
          <Grid container spacing={1} className={classes.details}>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={formatMessage({
                  id: 'taskDrawerEdit.startDate'
                })}
              >
                <Date value={startDate} />
              </ReadOnlyContainer>
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={formatMessage({
                  id: 'taskDrawerEdit.endDate'
                })}
              >
                <Date value={endDate} />
              </ReadOnlyContainer>
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={formatMessage({
                  id: 'taskDrawerEdit.role'
                })}
              >
                {assignedRole ? (
                  <RoleName
                    role={assignedRole}
                    showTooltip={false}
                    showRate={false}
                    className={classes.roleName}
                  />
                ) : (
                  <NoValue classes={noValueClasses} />
                )}
              </ReadOnlyContainer>
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={formatMessage({
                  id: 'taskDrawerEdit.taskOwner'
                })}
              >
                {taskOwner ? (
                  <User user={taskOwner} />
                ) : (
                  <NoValue classes={noValueClasses} />
                )}
              </ReadOnlyContainer>
            </Grid>
            {isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled ? (
              <>
                {showTimeAndExpenseEntryTypeField && (
                  <Grid item xs={6}>
                    <ReadOnlyContainer
                      label={formatMessage({
                        id: 'taskDrawerEdit.timeAndExpenseEntry'
                      })}
                    >
                      <Typography variant="body2">
                        {formatMessage({
                          id: `timeAndExpenseEntryOptions.${timeAndExpenseEntryType.uri}`
                        })}
                      </Typography>
                    </ReadOnlyContainer>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <ReadOnlyContainer
                    label={formatMessage({
                      id: 'taskDrawerEdit.allowTimeEntry.label'
                    })}
                  >
                    <Typography variant="body2">
                      {formatMessage({
                        id: isTimeEntryAllowed
                          ? 'taskDrawerEdit.allowTimeEntry.options.yes'
                          : 'taskDrawerEdit.allowTimeEntry.options.no'
                      })}
                    </Typography>
                  </ReadOnlyContainer>
                </Grid>
              </>
            ) : (
              <>
                {timeAndExpenseEntryType && (
                  <Grid item xs={6}>
                    <ReadOnlyContainer
                      label={formatMessage({
                        id: isExpenseProductEnabled
                          ? 'taskDrawerEdit.timeAndExpenseEntryAllowed'
                          : 'taskDrawerEdit.timeEntryAllowed'
                      })}
                    >
                      <Typography variant="body2">
                        {selectedTimeAndExpenseEntryType}
                      </Typography>
                    </ReadOnlyContainer>
                  </Grid>
                )}
              </>
            )}
            {canViewCostType && (
              <Grid item xs={6}>
                <ReadOnlyContainer
                  label={formatMessage({ id: 'taskDrawer.costType' })}
                >
                  <Typography variant="body2" data-qe-id="costType">
                    {costTypeUri ? (
                      formatMessage({
                        id: `projectFinancialsCard.${costTypeUri}`
                      })
                    ) : (
                      <NoValue />
                    )}
                  </Typography>
                </ReadOnlyContainer>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyContainer
                label={formatMessage({ id: 'taskDrawerEdit.description' })}
              >
                <Typography variant="body2" className={classes.description}>
                  {description || <NoValue />}
                </Typography>
              </ReadOnlyContainer>
            </Grid>
            {visibleCustomFieldDefinitions.map(definition => (
              <Grid key={definition.uri} item xs={12} sm={6}>
                <ReadOnlyContainer label={definition.displayText}>
                  <ReadOnlyCustomFieldValue
                    definition={definition}
                    value={customFields[definition.uri]}
                  />
                </ReadOnlyContainer>
              </Grid>
            ))}
            {Boolean(extensionFieldValues?.length) &&
              extensionFieldValues.map(e => (
                <Grid item xs={12} sm={6} key={e.definition.id}>
                  <ReadOnlyContainer label={e.definition.displayText}>
                    <Typography variant="body2" className={classes.description}>
                      <ExtensionFieldValueText extensionFieldValue={e} />
                    </Typography>
                  </ReadOnlyContainer>
                </Grid>
              ))}
            {canViewEstimate && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <ReadOnlyContainer
                    label={formatMessage({
                      id: 'taskDrawerEdit.initialEstimatedHours'
                    })}
                  >
                    <Typography variant="body2">
                      <Decimal value={initialEstimatedHours} />
                    </Typography>
                  </ReadOnlyContainer>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      )}
      {isPsaRmpTaskAllocation1Enabled && isRescheduleTaskDialogOpen && (
        <RescheduleTaskDialog
          onSubmit={onRescheduleTaskSubmit}
          onCancel={closeRescheduleTaskDialog}
          isSetTaskTimeEntryDateRangeLoading={loading}
          task={task}
        />
      )}
    </div>
  );
};

TaskDrawerHeaderInfoBar.propTypes = {
  task: PropTypes.object.isRequired,
  expandedInitialState: PropTypes.bool,
  handleRefetch: PropTypes.func
};

export default TaskDrawerHeaderInfoBar;
