import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { mapToServiceRequest } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { getSourceMetadata } from '../../hooks/useMarkToBeHiredResourceRequestWithHiringRequestedSubStatus';
import { SUBMIT_RESOURCE_REQUEST } from './withMarkResourceRequestAsSubmitted';

const useMarkResourceRequestAsSubmitted = ({
  resourceRequest,
  isPsaDtmRequisitionEnabled
}) => {
  const [markResourceRequestAsSubmitted] = useMutation(SUBMIT_RESOURCE_REQUEST);

  const onMarkResourceRequestAsSubmitted = useCallback(
    () =>
      markResourceRequestAsSubmitted(
        mapToServiceRequest({
          request: resourceRequest,
          responseType: 'markResourceRequestAsSubmitted2',
          responseTypeName: 'MarkResourceRequestAsSubmittedResult',
          requestStatus: ResourceRequestStatus.Submitted,
          sourceMetadata: getSourceMetadata({
            isPsaDtmRequisitionEnabled,
            resourceRequest
          })
        })
      ),
    [
      isPsaDtmRequisitionEnabled,
      markResourceRequestAsSubmitted,
      resourceRequest
    ]
  );

  return {
    onMarkResourceRequestAsSubmitted
  };
};

export default useMarkResourceRequestAsSubmitted;
