import { useMemo } from 'react';
import useGetLazyParentTaskDetailsQuery from './useGetLazyParentTaskDetailsQuery';

export const DISABLED_REASON = {
  PARENT_IS_CLOSED: 'PARENT_IS_CLOSED',
  LOADING: 'LOADING'
};

const isDisabledByParent = ({
  task,
  parentDetailsQueryCalled,
  isParentLoading,
  parentDetails
}) => {
  if (task.isClosedByTaskInheritance && !task.isClosed) {
    return {
      disabled: true,
      reason: DISABLED_REASON.PARENT_IS_CLOSED
    };
  }

  if (!parentDetailsQueryCalled) {
    return { disabled: false, reason: null };
  }

  if (isParentLoading) {
    return {
      disabled: true,
      reason: DISABLED_REASON.LOADING
    };
  }

  if (parentDetails.isClosed || parentDetails.isClosedByTaskInheritance) {
    return {
      disabled: true,
      reason: DISABLED_REASON.PARENT_IS_CLOSED
    };
  }

  return { disabled: false, reason: null };
};

const useIsTaskDisabled = (task, loading) => {
  const { isClosed, isClosedByTaskInheritance, parent } = task;

  const {
    getParentTaskDetails,
    called: parentDetailsQueryCalled,
    loading: isParentLoading,
    task: parentDetails
  } = useGetLazyParentTaskDetailsQuery();

  const needParentDetails =
    isClosed && isClosedByTaskInheritance && parent?.uri;

  if (needParentDetails && !parentDetailsQueryCalled) {
    getParentTaskDetails(parent?.uri);
  }

  const isDisabled = useMemo(() => {
    if (loading) {
      return {
        disabled: true,
        reason: DISABLED_REASON.LOADING
      };
    }

    return isDisabledByParent({
      task,
      parentDetailsQueryCalled,
      isParentLoading,
      parentDetails
    });
  }, [task, loading, parentDetailsQueryCalled, isParentLoading, parentDetails]);

  return isDisabled;
};

export default useIsTaskDisabled;
