import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  DelaAcceptAllButton,
  DelaRejectAllButton
} from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  delaRecommendationButtonsContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      margin: theme.spacing(1, 0, 0, 0)
    },
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(0.25)
    }
  }
}));

const useDelaButtonClasses = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(1.3),
      padding: theme.spacing(0.3, 1)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.3),
      padding: theme.spacing(0.3, 0.5)
    }
  }
}));

const title = <FormattedMessage id="skillInfoCard.skills" />;

const SkillsCardTitle = ({
  editable,
  delaRecommendedSkills,
  onAcceptUserNudges,
  onRejectUserNudges
}) => {
  const classes = useStyles();
  const delaButtonClasses = useDelaButtonClasses();

  const onClickAccept = useCallback(
    () => onAcceptUserNudges(delaRecommendedSkills),
    [onAcceptUserNudges, delaRecommendedSkills]
  );

  const onClickReject = useCallback(
    () => onRejectUserNudges(delaRecommendedSkills),
    [onRejectUserNudges, delaRecommendedSkills]
  );

  const showActions = delaRecommendedSkills.length > 0 && editable;

  if (!showActions) {
    return title;
  }

  return (
    <div className={classes.titleContainer}>
      {title}
      <div className={classes.delaRecommendationButtonsContainer}>
        <DelaAcceptAllButton
          classes={delaButtonClasses}
          onClick={onClickAccept}
          dataQeId="AcceptAllSkillsUserNudges"
        >
          <FormattedMessage id="skillInfoCard.acceptAll" />
        </DelaAcceptAllButton>
        <DelaRejectAllButton
          classes={delaButtonClasses}
          onClick={onClickReject}
          dataQeId="RejectAllSkillsUserNudges"
        >
          <FormattedMessage id="skillInfoCard.rejectAll" />
        </DelaRejectAllButton>
      </div>
    </div>
  );
};

SkillsCardTitle.propTypes = {
  editable: PropTypes.bool.isRequired,
  delaRecommendedSkills: PropTypes.array.isRequired,
  onAcceptUserNudges: PropTypes.func.isRequired,
  onRejectUserNudges: PropTypes.func.isRequired
};

export default SkillsCardTitle;
