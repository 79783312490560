import { useState, useCallback, useMemo } from 'react';

export const getBillingLimitAlertHelper = (
  totalEstimatedAmount,
  totalEstimatedContractInProjectCurrency
) => {
  if (
    totalEstimatedAmount?.amount <=
    totalEstimatedContractInProjectCurrency?.amount
  ) {
    return {
      severity: 'info',
      messageId:
        'projectFinancialsCard.limitIssuedBillsToTCV.issuedBillsLimitedToTCV'
    };
  }

  return {
    severity: 'warning',
    messageId:
      'projectFinancialsCard.limitIssuedBillsToTCV.issuedBillsLimitedToTCV'
  };
};

const useBillingBannerHelper = ({
  editable,
  totalEstimatedAmount,
  totalEstimatedContractInProjectCurrency
}) => {
  const [showFinancialEditableCard, setShowFinancialEditableCard] = useState(
    false
  );

  const handleBannerClick = useCallback(() => {
    if (editable) setShowFinancialEditableCard(true);
  }, [editable]);

  const values = useMemo(
    () => ({
      symbol: totalEstimatedContractInProjectCurrency.currency.displayText,
      amount: totalEstimatedContractInProjectCurrency.amount
    }),
    [
      totalEstimatedContractInProjectCurrency.amount,
      totalEstimatedContractInProjectCurrency.currency.displayText
    ]
  );

  const renderAlert = useCallback(() => {
    if (!totalEstimatedAmount || !totalEstimatedContractInProjectCurrency)
      return null;

    const alertInfo = getBillingLimitAlertHelper(
      totalEstimatedAmount,
      totalEstimatedContractInProjectCurrency
    );

    return {
      severity: alertInfo.severity,
      messageId: alertInfo.messageId,
      onClick: handleBannerClick
    };
  }, [
    totalEstimatedAmount,
    totalEstimatedContractInProjectCurrency,
    handleBannerClick
  ]);

  return {
    showFinancialEditableCard,
    setShowFinancialEditableCard,
    renderAlert,
    values
  };
};

export default useBillingBannerHelper;
