import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { ChartLegends } from '~/modules/common/charts/dashboard';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const labels = {
  notStarted: <FormattedMessage id="taskStatusBadge.notStarted" />,
  inProgress: <FormattedMessage id="taskStatusBadge.inProgress" />,
  completed: <FormattedMessage id="taskStatusBadge.completed" />,
  parentTask: <FormattedMessage id="taskStatusBadge.parentTask" />
};

const useStyles = makeStyles(() => ({
  legendText: {
    fontSize: theme.typography.caption.fontSize
  }
}));

const halfShadedBlock = (color, lightColor) => ({
  display: 'flex',
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  alignSelf: 'center',
  border: `1px solid ${color}`,
  background: `linear-gradient(90deg, ${color} 50%, ${lightColor} 50%)`
});

const legends = isPsaPrpManualTaskStatusEnabled => [
  {
    label: labels.notStarted,
    color: theme.palette.taskStatus.NOTSTARTED.color,
    labelColor: theme.palette.common.black,
    ...(isPsaPrpManualTaskStatusEnabled && {
      custom: halfShadedBlock(
        theme.palette.taskStatus.NOTSTARTED.color,
        theme.palette.taskStatus.NOTSTARTED.lightColor
      )
    })
  },
  {
    label: labels.inProgress,
    color: theme.palette.taskStatus.INPROGRESS.color,
    labelColor: theme.palette.common.black,
    ...(isPsaPrpManualTaskStatusEnabled && {
      custom: halfShadedBlock(
        theme.palette.taskStatus.INPROGRESS.color,
        theme.palette.taskStatus.INPROGRESS.lightColor
      )
    })
  },
  {
    label: labels.completed,
    color: theme.palette.taskStatus.COMPLETED.color,
    labelColor: theme.palette.common.black
  }
];

const rolledUpLegends = isPsaPrpManualTaskStatusEnabled => [
  {
    label: labels.notStarted,
    color: theme.palette.taskStatus.NOTSTARTED.color,
    labelColor: theme.palette.common.black,
    ...(isPsaPrpManualTaskStatusEnabled && {
      custom: halfShadedBlock(
        theme.palette.taskStatus.NOTSTARTED.color,
        theme.palette.taskStatus.NOTSTARTED.lightColor
      )
    })
  },
  {
    label: labels.inProgress,
    color: theme.palette.taskStatus.INPROGRESS.color,
    labelColor: theme.palette.common.black,
    custom: halfShadedBlock(
      theme.palette.taskStatus.INPROGRESS.color,
      theme.palette.taskStatus.INPROGRESS.lightColor
    )
  },
  {
    label: labels.completed,
    color: theme.palette.taskStatus.COMPLETED.color,
    labelColor: theme.palette.common.black
  },
  {
    label: labels.parentTask,
    color: '#ffffff',
    labelColor: theme.palette.common.black,
    custom: {
      display: 'flex',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      alignSelf: 'center',
      borderLeft: '2px solid #656565',
      borderRight: '2px solid #656565',
      background:
        'linear-gradient(180deg, transparent 40%, #656565 40%, #656565 60%, transparent 60%)'
    }
  }
];

export const TaskStatusLegends = () => {
  const classes = useStyles();
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: { isPsaPrpManualTaskStatusEnabled }
  } = useMeContext();

  return (
    <ChartLegends
      legends={
        isRolledUpTaskEstimateCalculationMethodEnabled
          ? rolledUpLegends(isPsaPrpManualTaskStatusEnabled)
          : legends(isPsaPrpManualTaskStatusEnabled)
      }
      classes={classes}
    />
  );
};

TaskStatusLegends.propTypes = {};

export default TaskStatusLegends;
