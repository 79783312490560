import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  CreatableAutocomplete,
  CreatableDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import { useHasPermission } from '~/modules/common/permissions';
import AddProjectDialog from '~/modules/projects/components/AddProject/AddProjectDialog';
import MoreResult from '~/modules/common/components/MoreResult';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { ProjectManagementType } from '~/types';
import { useDialogState } from '~/modules/common/hooks';
import { useProjectDropdownOptionsWithStatusFilter } from './useProjectDropdownOptionsWithStatusFilter';

const useDropdownStyles = makeStyles(() => ({
  primary: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  }
}));

const useStyles = makeStyles(() => ({
  input: {}
}));

const getOptionLabel = option => option.displayText;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

const filter = createFilterOptions();

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const CreatableProjectDropdown = ({
  label,
  value,
  variant,
  classes: classesOverrides,
  dataQeId = '',
  placeholder,
  onChange,
  customPopperComponent,
  InputLabelPropsOverride
}) => {
  const { open, openDialog, closeDialog } = useDialogState();

  const canCreateProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });
  const intl = useIntl();
  const ref = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const {
    loading,
    projects,
    hasMore
  } = useProjectDropdownOptionsWithStatusFilter({
    searchTerm: value && value.id ? '' : searchTerm,
    excludeProjectStatus: ['urn:replicon:project-status-type:archived']
  });

  const dropdownClasses = useDropdownStyles();
  const classes = useStyles({
    classes: classesOverrides,
    current: ref?.current
  });

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <CreatableDropdownItem
          option={option}
          classes={dropdownClasses}
          onCreate={openDialog}
          optionPropText="displayText"
          primaryLabelTextId="quickAllocation.createNewProject"
        />
      ),
    [dropdownClasses, openDialog]
  );

  const onClose = useCallback(
    v => {
      closeDialog();
      if (v) {
        onChange({ displayText: v.name, id: v.id });
      }
    },
    [closeDialog, onChange]
  );

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);

      if (canCreateProject) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          displayText: params.inputValue
        });
      }

      return filtered;
    },
    [canCreateProject]
  );

  return (
    <>
      <CreatableAutocomplete
        ref={ref}
        hasMore={hasMore}
        loading={loading}
        fullWidth
        clearOnBlur
        classes={classes}
        variant={variant}
        placeholder={placeholder}
        value={value}
        renderOption={renderOption}
        onChange={onChange}
        dataQeId={dataQeId}
        onInputChange={handleInputChange}
        options={projects}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        getOptionDisabled={getOptionDisabled}
        inputLabel={label}
        optionTypeText={intl.formatMessage({ id: 'moreOptions.projects' })}
        PopperComponent={customPopperComponent}
        InputLabelPropsOverride={InputLabelPropsOverride}
      />
      {open && canCreateProject && (
        <AddProjectDialog
          open={open}
          onClose={onClose}
          showCopyProjectTab={false}
          projectType={ProjectManagementType.Managed}
        />
      )}
    </>
  );
};

CreatableProjectDropdown.propTypes = {
  value: PropTypes.any,
  label: PropTypes.node,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  placeholder: PropTypes.string,
  customPopperComponent: PropTypes.func,
  InputLabelPropsOverride: PropTypes.object
};

export default CreatableProjectDropdown;
