import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import TaskEstimateRowContextProvider from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContextProvider';
import { ChangeTaskResourceDialog } from '~/modules/tasks/components';
import { useTaskEstimateRowContextDataBuilder } from '../../hooks';

export const ChangeTaskResourceConfirmationDialog = ({
  projectId,
  changeTaskResourceDialogOpen,
  closeChangeTaskResourceDialog,
  onChangeTaskResourceSuccess,
  task,
  user,
  canViewCost = false
}) => {
  const {
    params: { slug }
  } = useRouteMatch();
  const {
    loading,
    taskDetail,
    taskResourceEstimate
  } = useTaskEstimateRowContextDataBuilder({
    projectId,
    task,
    user,
    includeTaskResourceUserCostProgressSummary: canViewCost
  });

  const taskWithProject = useMemo(
    () => ({
      ...task,
      projectReference: {
        id: projectId,
        slug
      }
    }),
    [projectId, slug, task]
  );

  return (
    <TaskEstimateRowContextProvider
      task={taskDetail}
      resourceEstimate={taskResourceEstimate}
      canEditTasks={false}
      canViewCost={canViewCost}
    >
      <ChangeTaskResourceDialog
        isLoading={loading}
        onClose={closeChangeTaskResourceDialog}
        onChangeTaskResourceSuccess={onChangeTaskResourceSuccess}
        open={changeTaskResourceDialogOpen}
        resourceEstimate={taskResourceEstimate}
        task={taskWithProject}
      />
    </TaskEstimateRowContextProvider>
  );
};

ChangeTaskResourceConfirmationDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  canViewCost: PropTypes.bool,
  changeTaskResourceDialogOpen: PropTypes.bool.isRequired,
  closeChangeTaskResourceDialog: PropTypes.func.isRequired,
  onChangeTaskResourceSuccess: PropTypes.func.isRequired
};
