import React from 'react';
import get from 'lodash/fp/get';
import isNull from 'lodash/fp/isNull';
import isUndefined from 'lodash/fp/isUndefined';

export const isNullOrUndefined = x => isNull(x) || isUndefined(x);

export const getOrThrow = path => res => {
  if (isNullOrUndefined(get(path)(res))) {
    throw new Error(`Unable to get value at path ${path}`);
  }

  return get(path)(res);
};

export const convertDurationToHours = duration =>
  duration.hours + duration.minutes / 60.0 + duration.seconds / 3600.0;

const precisionDenominatorNumber = 10000000000;

export const roundNumberWithHighPrecision = number =>
  Math.round(number * precisionDenominatorNumber) / precisionDenominatorNumber;

export const createObjectPath = (obj, path, value = null, prevValue = {}) => {
  let tempPath = typeof path === 'string' ? path.split('.') : path;
  let current = obj;
  let prevValueRef = prevValue;

  while (tempPath.length > 1) {
    const [head, ...tail] = tempPath;

    prevValueRef = prevValueRef[head];
    tempPath = tail;
    if (current[head] === undefined) {
      current[head] = {
        ...prevValueRef
      };
    }
    current = current[head];
  }
  current[tempPath[0]] = value;

  return obj;
};

export const mapHoursToDuration = hours => {
  let seconds = hours * 3600.0;
  const hrs = Math.floor(seconds / 3600);

  seconds -= hrs * 3600;

  const minutes = Math.floor(seconds / 60);

  seconds -= minutes * 60;
  seconds = Math.round(seconds);

  return {
    hours: hrs,
    minutes,
    seconds
  };
};

export const checkForCriteria = (criterions, tag) =>
  criterions && criterions[tag] && criterions[tag].length > 0;

export const getForCriteria = (criterions, tag) =>
  checkForCriteria(criterions, tag) ? criterions[tag].map(v => v.value) : null;

export const getDateForCriteria = (criterions, tag) =>
  checkForCriteria(criterions, tag) &&
  (criterions[tag][0].range.startDate || criterions[tag][0].range.endDate)
    ? {
        startDate: criterions[tag][0].range.startDate
          ? criterions[tag][0].range.startDate
          : null,
        endDate: criterions[tag][0].range.endDate
          ? criterions[tag][0].range.endDate
          : null
      }
    : null;

const boldText = chunks => <strong>{chunks}</strong>;

export const getTextInBold = values => ({
  ...(values || {}),
  b: boldText
});

export const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const delay = timeout =>
  new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
