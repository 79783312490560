import { gql } from 'graphql-tag';

export const PROJECT_DETAILS_QUERY = gql`
  query getProjectDetails(
    $projectId: String!
    $page: Int
    $pageSize: Int
    $projectEstimatedExpenseRemainingSeriesDateRange: PeriodInput
    $maxHierarchyDepth: Int
    $isRolledUpTaskEstimateCalculationMethodEnabled: Boolean = false
    $includeRolledUpCostSummary: Boolean = false
  ) {
    project(projectId: $projectId) {
      id
      name
      code
      isTaskDateRollupEnabled
      startDate: startDate2
      endDate: endDate2
      budgetHours
      estimatedHours
      estimatedCost {
        amount
      }
      status {
        id
        name
      }
      permittedActionUris
      rolledUpSummary
        @include(if: $isRolledUpTaskEstimateCalculationMethodEnabled) {
        id
        actualHours
        totalEstimatedAtCompletionHours
        totalProjectTasksEstimatedAtCompletionHours
        totalEstimatedRemainingHours
        earliestTimeEntryDate
        estimatedCompletionDate
      }
      rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
        actualCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalEstimatedRemainingCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalEstimatedAtCompletionCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalProjectTasksEstimatedAtCompletionCostInProjectCurrency {
          amount
        }
        budgetedCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalExpensesInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        expensesRemainingInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalEstimatedExpensesInProjectCurrency {
          amount
        }
      }
      projectEstimatedExpenseRemainingSeriesByMonth(
        dateRange: $projectEstimatedExpenseRemainingSeriesDateRange
      ) {
        dataPoints {
          estimatedExpensesInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
          period {
            start: periodStart2
            end: periodEnd2
          }
        }
      }
      workflow {
        uri
      }
      tasks(
        page: $page
        pageSize: $pageSize
        maxHierarchyDepth: $maxHierarchyDepth
      ) {
        id
        name
        estimatedCompletionDate
        endDate
        closedOnDate: closedOnDate2
        initialEstimatedHours
        taskStatus
        rolledUpSummary {
          id
          actualHours
          totalEstimatedAtCompletionHours
          totalEstimatedRemainingHours
          earliestTimeEntryDate
          estimatedCompletionDate
        }
        rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
          actualCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
          totalEstimatedRemainingCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
          totalEstimatedAtCompletionCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
          initialEstimatedCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
        }
      }
    }
  }
`;
