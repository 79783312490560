import React from 'react';
import { DialogContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ResourceUserSelectionRow } from '~/modules/tasks/components/ChangeTaskResourceDialog/components';
import {
  BatchInProgressIndicator,
  StackedListMessageSummaryAlert
} from '~/modules/common/components';
import {
  ChangeResourceAllocationRangeOptions,
  ImpactedTaskSummaryAlert
} from './components';
import {
  useFormOnChangeHandlers,
  useImpactedTaskMessageSummary
} from './hooks';
import { useReleasedAllocationSummary } from './hooks/useFormOnChangeHandlers';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const useResourceUserSelectionRowStyles = makeStyles(theme => ({
  container: {
    marginBottom: `${theme.spacing(1)}px !important`
  }
}));

export const ChangeResourceDialogContent = ({
  errorMessageSummary,
  existingResourceUser,
  isImpactedTaskSummaryLoading,
  projectId,
  resourceAllocation,
  taskAssignmentCount
}) => {
  const classes = useStyles();
  const resourceUserSelectionRowClasses = useResourceUserSelectionRowStyles();

  const { isSubmitting, setFieldValue, setValues, values } = useFormikContext();

  const { asOfDate, modificationType, newResourceUser } = values;

  const {
    isLoading: isErrorMessageSummaryLoading,
    messages: errorMessages,
    title: errorTitle
  } = errorMessageSummary;

  const {
    isLoading: isReleasedAllocationSummaryLoading,
    releasedAllocationHours,
    releasedEndDate,
    releasedStartDate
  } = useReleasedAllocationSummary({
    asOfDate,
    modificationType,
    resourceAllocation
  });

  const {
    messages: impactedTaskMessages,
    title: impactedTaskTitle
  } = useImpactedTaskMessageSummary({
    releasedAllocationHours,
    taskAssignmentCount
  });

  const {
    onAsOfDateChange,
    onChangeTypeSelect,
    onResourceUserChange
  } = useFormOnChangeHandlers({ setFieldValue, setValues, values });

  return (
    <DialogContent classes={classes}>
      {isSubmitting ? (
        <BatchInProgressIndicator messageId="changeResourceDialog.changingResource" />
      ) : (
        <>
          <ChangeResourceAllocationRangeOptions
            asOfDate={asOfDate}
            modificationType={modificationType}
            onAsOfDateChange={onAsOfDateChange}
            onChangeTypeSelect={onChangeTypeSelect}
          />
          <ResourceUserSelectionRow
            classes={resourceUserSelectionRowClasses}
            endDate={releasedEndDate}
            existingResourceUser={existingResourceUser}
            initialEstimatedHours={releasedAllocationHours}
            hideAvailability={
              isReleasedAllocationSummaryLoading || !releasedAllocationHours
            }
            newResourceUser={newResourceUser}
            onResourceUserChange={onResourceUserChange}
            projectId={projectId}
            startDate={releasedStartDate}
          />
          <ImpactedTaskSummaryAlert
            isLoading={
              isReleasedAllocationSummaryLoading || isImpactedTaskSummaryLoading
            }
            messages={impactedTaskMessages}
            severity="info"
            skeletonHeight={60}
            title={impactedTaskTitle}
          />
          <StackedListMessageSummaryAlert
            isLoading={isErrorMessageSummaryLoading}
            messages={errorMessages}
            skeletonHeight={60}
            severity="error"
            title={errorTitle}
          />
        </>
      )}
    </DialogContent>
  );
};

ChangeResourceDialogContent.propTypes = {
  errorMessageSummary: PropTypes.object.isRequired,
  existingResourceUser: PropTypes.object.isRequired,
  isImpactedTaskSummaryLoading: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  resourceAllocation: PropTypes.object,
  taskAssignmentCount: PropTypes.number
};

export default ChangeResourceDialogContent;
