const useImpactedTaskMessageSummary = ({
  releasedAllocationHours,
  taskAssignmentCount
}) => {
  const messages = [];

  if (releasedAllocationHours) {
    messages.push({
      messageId:
        'changeResourceDialog.impactedTaskSummary.releasedAllocationHours',
      values: { releasedAllocationHours: releasedAllocationHours.toFixed(2) }
    });
  }
  if (taskAssignmentCount) {
    messages.push({
      messageId: 'changeResourceDialog.impactedTaskSummary.taskAssignmentCount',
      values: { taskAssignmentCount }
    });
  }

  return {
    messages,
    title: { messageId: 'changeResourceDialog.impactedTaskSummary.title' }
  };
};

export default useImpactedTaskMessageSummary;
