import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getError, hasError } from '~/util';
import {
  NoValue,
  DateField,
  Decimal,
  Hours
} from '~/modules/common/components';
import { useStyles, useAllocatedHoursStyles } from './usePopoverContentStyles';
import { FieldLabel } from '.';

const startDateLabel = <FormattedMessage id="taskAllocationEditor.startDate" />;
const endDateLabel = <FormattedMessage id="taskAllocationEditor.endDate" />;

const PopoverContentLayout = ({
  startDate,
  endDate,
  dateRangePickerProps: {
    onStartDateChange,
    onEndDateChange,
    errors,
    shouldDisableDateFunc
  },
  onHoursChange,
  availabilityField: {
    labelComponent: availabilityLabel,
    hoursValue: availableHours,
    infoComponent: availabilityInfoComponent
  },
  allocationField: {
    labelComponent: allocationLabel,
    hoursValue: allocationHours,
    infoComponent: allocationInfoComponent
  },
  remainingField: {
    labelComponent: remainingLabel,
    hoursValue: remainingHours,
    infoComponent: remainingInfoComponent
  },
  classes: classesOverride,
  taskAllocationHoursInputRef
}) => {
  const { formatMessage } = useIntl();

  const classes = useStyles({ classes: classesOverride });
  const allocatedHoursClasses = useAllocatedHoursStyles();

  const endAdornment = useMemo(
    () => (
      <InputAdornment className={classes.inputAdornment} position="end">
        <FieldLabel
          messageId="taskAllocationEditor.hoursSuffix"
          variant="body2"
          color="textSecondary"
        />
      </InputAdornment>
    ),
    [classes.inputAdornment]
  );

  const availableHoursLabelValue = useMemo(
    () => ({
      hours: <Decimal className={classes.label} value={availableHours} />
    }),
    [availableHours, classes.label]
  );

  const remainingHoursLabelValue = useMemo(
    () => ({
      hours: (
        <Decimal
          value={remainingHours}
          className={classNames({
            [classes.error]: remainingHours < 0,
            [classes.label]: remainingHours >= 0
          })}
        />
      )
    }),
    [classes.error, classes.label, remainingHours]
  );

  return (
    <>
      <FieldLabel
        messageId="taskAllocationEditor.taskAllocation"
        className={classes.title}
      />
      <Grid>
        <Grid item container spacing={2} className={classes.datePicker}>
          <Grid item xs={12} sm={6}>
            <DateField
              clearable={false}
              variant="outlined"
              value={startDate}
              editable
              ariaLabel={formatMessage({
                id: 'taskAllocationEditor.startDate'
              })}
              onChange={onStartDateChange}
              label={startDateLabel}
              error={hasError(errors, 'startDate')}
              helperText={getError(errors, 'startDate')}
              shouldDisableDate={shouldDisableDateFunc}
              dataQeId="TaskAllocationEditor_StartDate"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateField
              clearable={false}
              variant="outlined"
              value={endDate}
              editable
              onChange={onEndDateChange}
              ariaLabel={formatMessage({ id: 'taskAllocationEditor.endDate' })}
              label={endDateLabel}
              error={hasError(errors, 'endDate')}
              helperText={getError(errors, 'endDate')}
              shouldDisableDate={shouldDisableDateFunc}
              dataQeId="TaskAllocationEditor_EndDate"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3}>
            {availabilityLabel}
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            data-qe-id="TaskAllocationEditor_AvailableHours"
          >
            {startDate && endDate ? (
              <FieldLabel
                messageId="taskAllocationEditor.hours"
                messageValues={availableHoursLabelValue}
                variant="body2"
                className={classes.hoursLabel}
                color="textSecondary"
              />
            ) : (
              <div className={classes.noValue}>
                <NoValue />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {availabilityInfoComponent}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3} className={classes.taskAllocationGrid}>
            {allocationLabel}
          </Grid>
          <Grid item xs={12} sm={3} className={classes.taskAllocation}>
            <Hours
              classes={allocatedHoursClasses}
              autoFocus
              value={allocationHours}
              variant="outlined"
              onChange={onHoursChange}
              endAdornment={endAdornment}
              error={hasError(errors, 'allocatedHours')}
              ariaLabel={formatMessage({
                id: 'taskAllocationEditor.taskAllocation'
              })}
              align="right"
              inputRef={taskAllocationHoursInputRef}
              isEditible
              data-qe-id="TaskAllocationEditor_HoursField"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {allocationInfoComponent}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3}>
            {remainingLabel}
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            data-qe-id="TaskAllocationEditor_TotalAvailableHours"
          >
            {startDate && endDate ? (
              <FieldLabel
                messageId="taskAllocationEditor.hours"
                variant="body2"
                color="textSecondary"
                className={classNames(classes.hoursLabel, {
                  [classes.error]: remainingHours < 0
                })}
                messageValues={remainingHoursLabelValue}
              />
            ) : (
              <div className={classes.noValue}>
                <NoValue />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {remainingInfoComponent}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

PopoverContentLayout.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  dateRangePickerProps: PropTypes.object,
  availabilityField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired,
    infoComponent: PropTypes.node
  }).isRequired,
  allocationField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired,
    infoComponent: PropTypes.node
  }).isRequired,
  remainingField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired,
    infoComponent: PropTypes.node
  }).isRequired,
  onHoursChange: PropTypes.func,
  varianceField: PropTypes.shape({
    labelComponent: PropTypes.node.isRequired,
    hoursValue: PropTypes.number.isRequired
  }),
  classes: PropTypes.object,
  taskAllocationHoursInputRef: PropTypes.object
};

export default PopoverContentLayout;
