import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const NO_CHANGE_OPTION_ID = 'noChange';
export const NO_VALUE_OPTION_ID = 'noValue';

export const noChange = intl => ({
  id: NO_CHANGE_OPTION_ID,
  value: NO_CHANGE_OPTION_ID,
  displayText: intl.formatMessage({ id: 'dropdownItem.noChange' }),
  label: intl.formatMessage({ id: 'dropdownItem.noChange' }),
  key: intl.formatMessage({ id: 'dropdownItem.noChange' })
});

export const noValue = (intl, messageId, values) => ({
  id: NO_VALUE_OPTION_ID,
  value: NO_VALUE_OPTION_ID,
  displayText: intl.formatMessage({ id: messageId }, values),
  label: intl.formatMessage({ id: messageId }, values),
  key: intl.formatMessage({ id: messageId }, values)
});

export const useAdditionalOptions = ({
  enableNoChangeOption,
  enableNoValueOption,
  noValueMessageId,
  noValueMessageValues
}) => {
  const intl = useIntl();

  return useMemo(
    () =>
      [
        enableNoChangeOption && noChange(intl),
        enableNoValueOption &&
          noValueMessageId &&
          noValue(intl, noValueMessageId, noValueMessageValues)
      ].filter(x => Boolean(x)),
    [
      enableNoChangeOption,
      enableNoValueOption,
      intl,
      noValueMessageId,
      noValueMessageValues
    ]
  );
};
