import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  makeStyles,
  Button
} from '@material-ui/core';
import { LoadingButton } from '~/modules/common/components';
import ProgressContent from './ProgressContent';

const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre-line'
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.breakpoints.down('sm') ? 'none' : '520px'
  }
}));

const NewSyncConfirmationDialog = ({
  open,
  itemCounts,
  batchState,
  onConfirm,
  onCancel,
  onBatchComplete,
  syncBilled,
  syncingInProgress
}) => {
  const classes = useStyles();
  const { batchInProgress, batchId } = batchState;

  return (
    <Dialog
      open={open}
      classes={useDialogStyles()}
      aria-labelledby="entity-confirmation-dialog-title"
      aria-describedby="entity-confirmation-dialog-description"
    >
      <DialogTitle id="entity-confirmation-dialog-title">
        <FormattedMessage
          id="billingTransactionList.syncInvoices"
          values={useMemo(() => ({ count: itemCounts }), [itemCounts])}
        />
      </DialogTitle>
      {batchInProgress || batchId ? (
        <ProgressContent
          batchState={batchState}
          onCancel={onCancel}
          onBatchComplete={onBatchComplete}
          syncBilled={syncBilled}
        />
      ) : (
        <>
          <DialogContent id="entity-confirmation-dialog-description">
            <DialogContentText className={classes.description}>
              <FormattedMessage id="billingTransactionList.issueNewSyncDialogDescription" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
            <LoadingButton
              onClick={onConfirm}
              color="primary"
              isLoading={syncingInProgress}
              disabled={syncingInProgress}
              autoFocus
            >
              <FormattedMessage id="billingTransactionList.syncDialogConfirmButton" />
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

NewSyncConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  syncingInProgress: PropTypes.bool.isRequired,
  itemCounts: PropTypes.number.isRequired,
  batchState: PropTypes.shape({
    batchInProgress: PropTypes.bool,
    batchId: PropTypes.string
  }).isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  syncBilled: PropTypes.object
};

export default NewSyncConfirmationDialog;
