import { useCallback } from 'react';
import {
  getPreviousDateRange,
  getNextDateRange
} from '~/modules/common/charts/timeline/calculations';

export const handlePrevious = ({ dateRange, scale, setDateRange }) => {
  const { chartStartDate, chartEndDate } = getPreviousDateRange({
    chartStartDate: dateRange.startDate,
    chartEndDate: dateRange.endDate,
    scale
  });

  setDateRange({
    startDate: chartStartDate,
    endDate: chartEndDate
  });
};

export const handleNext = ({ dateRange, scale, setDateRange }) => {
  const { chartStartDate, chartEndDate } = getNextDateRange({
    chartStartDate: dateRange.startDate,
    chartEndDate: dateRange.endDate,
    scale
  });

  setDateRange({
    startDate: chartStartDate,
    endDate: chartEndDate
  });
};

export const usePeriodDropdownHandlers = ({
  dateRange,
  scale,
  setDateRange
}) => {
  const onPreviousClick = useCallback(
    () => handlePrevious({ dateRange, scale, setDateRange }),
    [dateRange, scale, setDateRange]
  );

  const onNextClick = useCallback(
    () => handleNext({ dateRange, scale, setDateRange }),
    [dateRange, scale, setDateRange]
  );

  return { onNextClick, onPreviousClick };
};

export default usePeriodDropdownHandlers;
