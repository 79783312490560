import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { ButtonBase, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserWithRoles } from '~/modules/common/components/User';
import IconLinkButton from '~/modules/resourcing/common/components/IconLinkButton';
import { useCreateAllocationAndSetRequestStatus } from '../hooks';
import RESOURCE_MATCH_POPOVER_BUTTON_ACTION from '../enum';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    padding: theme.spacing(1.25, 0)
  },
  resourcesColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%'
  },
  score: {
    color: theme.palette.Dela.darkIcon
  },
  button: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.75, 1.25),
    boxShadow: theme.shadows[2]
  }
}));

const useUserWithRolesStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(5)
  },
  nameRow: {
    display: 'flex'
  },
  roleRow: {
    display: 'flex'
  }
}));

const useIconLinkButtonStyles = makeStyles(theme => ({
  openButton: {
    color: theme.palette.text.primary,
    opacity: '0.8'
  }
}));

export const ResourceMatchListRow = ({
  resourceRequest,
  resourceMatch,
  user,
  onClose,
  allocationChartRef,
  buttonType
}) => {
  const classes = useStyles();
  const userWithRolesClasses = useUserWithRolesStyles();
  const iconLinkButtonClasses = useIconLinkButtonStyles();
  const { formatMessage } = useIntl();

  const createAllocationAndSetRequestStatus = useCreateAllocationAndSetRequestStatus(
    {
      resourceRequest,
      user,
      allocationChartRef,
      buttonType
    }
  );

  return (
    <ListItem data-qe-id="ResourceMatchListRow" className={classes.root}>
      <div className={classes.resourcesColumn}>
        <ButtonBase>
          <UserWithRoles classes={userWithRolesClasses} user={user} />
          <IconLinkButton
            classes={iconLinkButtonClasses}
            toLink={`#/resource/${user.slug}`}
          />
        </ButtonBase>
        <div className={classes.score}>
          <Typography variant="subtitle1">
            {formatMessage(
              {
                id: 'resourceMatchPopover.score'
              },
              { score: resourceMatch.score }
            )}
          </Typography>
        </div>
      </div>
      <ButtonBase
        className={classes.button}
        onClick={() => {
          createAllocationAndSetRequestStatus();
          onClose();
        }}
      >
        <Typography variant="subtitle1">
          {formatMessage({
            id:
              buttonType === RESOURCE_MATCH_POPOVER_BUTTON_ACTION.PROPOSE
                ? 'resourceMatchPopover.propose'
                : 'resourceMatchPopover.accept'
          })}
        </Typography>
      </ButtonBase>
    </ListItem>
  );
};

ResourceMatchListRow.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  resourceMatch: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  allocationChartRef: PropTypes.object,
  buttonType: PropTypes.oneOf([
    RESOURCE_MATCH_POPOVER_BUTTON_ACTION.ACCEPT,
    RESOURCE_MATCH_POPOVER_BUTTON_ACTION.PROPOSE
  ]).isRequired
};

export default ResourceMatchListRow;
