import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import AvailabilityPeriod from '~/modules/resourcing/common/components/AvailabilityPeriod';
import CompleteAvailabilityPeriod from '~/modules/resourcing/common/components/AvailabilityPeriod/CompleteAvailabilityPeriod';
import {
  ConditionalWrapper,
  ResourceIcon,
  TaskIcon
} from '~/modules/common/components';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import useStyles, { useAvailabilityPeriodStyles } from './useStyles';

const ResourceAllocationEditorContent = ({
  classes: classesOverrides,
  targetWidth,
  targetHeight,
  isAllocationComplete,
  availableValue,
  initialAllocatedHours,
  allocatedHours,
  resourceAllocationSeriesData,
  isAvailabilityLoading,
  isPsaRmpTaskAllocation1Enabled,
  isHoursMode,
  seriesRefetch,
  seriesLoading,
  taskAllocationHoursValue
}) => {
  const classes = useStyles({
    targetWidth,
    targetHeight,
    classes: classesOverrides
  });

  const availabilityPeriodClasses = useAvailabilityPeriodStyles();

  const isRmpTaskAllocationPhase2Enabled = useHasFeatureFlag({
    featureFlag: 'isRmpTaskAllocationPhase2Enabled'
  });

  const tooltipContent = useMemo(
    () => (
      <>
        <div className={classes.tooltipHeader}>
          <FormattedMessage id="resourceAssignmentDialog.resourceAvailability" />
        </div>
        <span className={classes.tooltipDescription}>
          <FormattedMessage id="resourceAssignmentDialog.resourceAvailabilityDetails" />
        </span>
        <div className={classNames(classes.tooltipHeader, classes.taskTooltip)}>
          <FormattedMessage id="resourceAssignmentDialog.taskAllocation" />
        </div>
        <span className={classes.tooltipDescription}>
          <FormattedMessage id="resourceAssignmentDialog.taskAllocationDetails" />
        </span>
      </>
    ),
    [classes]
  );
  const availabilityComponent = useMemo(
    () => (
      <div className={classes.availability}>
        {isRmpTaskAllocationPhase2Enabled && (
          <ResourceIcon fontSize="small" className={classes.icon} />
        )}
        <ConditionalWrapper
          condition={isPsaRmpTaskAllocation1Enabled}
          WrapperComponent="div"
          className={classes.detailsText}
        >
          <FormattedMessage id="resourceAssignmentDialog.availability" />:
          {isAllocationComplete ? (
            <CompleteAvailabilityPeriod
              availableValue={availableValue}
              originalAllocatedHours={initialAllocatedHours}
              allocatedHours={allocatedHours}
              classes={
                isRmpTaskAllocationPhase2Enabled
                  ? availabilityPeriodClasses
                  : classes
              }
              resourceAllocationSeriesData={resourceAllocationSeriesData}
              isAvailabilityLoading={isAvailabilityLoading}
              isHoursMode={isHoursMode}
              seriesRefetch={seriesRefetch}
              seriesLoading={seriesLoading}
            />
          ) : (
            <AvailabilityPeriod
              classes={
                isRmpTaskAllocationPhase2Enabled
                  ? availabilityPeriodClasses
                  : classes
              }
              isHoursMode={isHoursMode}
              availableHours={availableValue}
            />
          )}
        </ConditionalWrapper>
      </div>
    ),
    [
      allocatedHours,
      availableValue,
      classes,
      initialAllocatedHours,
      isAllocationComplete,
      isAvailabilityLoading,
      isHoursMode,
      isRmpTaskAllocationPhase2Enabled,
      resourceAllocationSeriesData,
      seriesLoading,
      seriesRefetch
    ]
  );

  return isRmpTaskAllocationPhase2Enabled ? (
    <Tooltip title={tooltipContent} placement="right">
      <div className={classes.editorDetails}>
        {availabilityComponent}
        <div className={classes.tasks}>
          <TaskIcon fontSize="small" className={classes.icon} />
          <FormattedMessage id="resourceAssignmentDialog.tasks" />:
          <AvailabilityPeriod
            classes={availabilityPeriodClasses}
            isHoursMode={isHoursMode}
            availableHours={taskAllocationHoursValue}
          />
        </div>
      </div>
    </Tooltip>
  ) : (
    <div className={classes.details}>{availabilityComponent}</div>
  );
};

ResourceAllocationEditorContent.propTypes = {
  classes: PropTypes.object,
  targetWidth: PropTypes.number,
  targetHeight: PropTypes.number,
  isAllocationComplete: PropTypes.bool,
  availableValue: PropTypes.number,
  taskAllocationHoursValue: PropTypes.number,
  initialAllocatedHours: PropTypes.number,
  allocatedHours: PropTypes.number,
  resourceAllocationSeriesData: PropTypes.array,
  isAvailabilityLoading: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  isHoursMode: PropTypes.bool,
  seriesRefetch: PropTypes.func,
  seriesLoading: PropTypes.bool
};

export default ResourceAllocationEditorContent;
