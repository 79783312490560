import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Divider,
  Grid
} from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { getEquivalentRolledUpTaskStatus } from '~/modules/common/components/ProjectDrawer';
import FormattedNameCode from '~/modules/common/components/entities/task/FormattedNameCode';
import {
  TaskCardTitle,
  TaskCardSubHeader,
  TaskCardUser,
  TaskCardRole,
  TaskCardHours,
  TaskCardVariation
} from './components';
import TaskDetails from './TaskDetails';

const useStyles = makeStyles(theme => ({
  card: ({ taskLevel }) => ({
    width: '100%',
    boxShadow: '0 4px 7px -7px rgba(0, 0, 0, 0.85)',
    zIndex: 10 - taskLevel
  }),
  userRole: {
    padding: theme.spacing(1, 2)
  },
  cardHeader: ({ isPSATaskEstimateRollUpMobileViewEnabled, taskLevel }) => ({
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
    ...(isPSATaskEstimateRollUpMobileViewEnabled && {
      overflow: 'hidden',
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(1)
    }),
    backgroundColor:
      isPSATaskEstimateRollUpMobileViewEnabled && taskLevel
        ? '#f7f7f7'
        : 'inherit'
  }),
  cardHeaderContent: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    ...(isPSATaskEstimateRollUpMobileViewEnabled && {
      overflow: 'hidden'
    })
  }),
  cardContent: ({ isPSATaskEstimateRollUpMobileViewEnabled, taskLevel }) => ({
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    },
    backgroundColor:
      isPSATaskEstimateRollUpMobileViewEnabled && taskLevel
        ? '#f7f7f7'
        : 'inherit'
  })
}));

export const TaskCard = ({
  canViewEstimate,
  task,
  isExpanded,
  onExpandCollapse
}) => {
  const {
    featureFlags: { isPSATaskEstimateRollUpMobileViewEnabled },
    isRolledUpTaskEstimateCalculationMethodEnabled
  } = useMeContext();
  const { hasChildren } = task;

  const classes = useStyles({
    isPSATaskEstimateRollUpMobileViewEnabled,
    taskLevel: task.fullPath.length - 1
  });
  const cardHeaderClasses = useMemo(
    () => ({
      root: classes.cardHeader,
      content: classes.cardHeaderContent
    }),
    [classes.cardHeader, classes.cardHeaderContent]
  );

  const rolledUpTaskStatus = getEquivalentRolledUpTaskStatus({
    status: task.taskStatus,
    actualHours: task.rolledUpSummary?.actualHours
  });

  const taskStatus = isRolledUpTaskEstimateCalculationMethodEnabled
    ? rolledUpTaskStatus
    : task.taskStatus;

  const displayText = useMemo(
    () => (
      <FormattedNameCode name={task.name} code={task.code} variant="body1" />
    ),
    [task.code, task.name]
  );

  const taskCardTitle = useMemo(
    () => (
      <TaskCardTitle
        isMilestone={task.isMilestone}
        displayText={displayText}
        hasChildren={hasChildren}
        isExpanded={isExpanded}
        onExpandCollapse={onExpandCollapse(task.id)}
      />
    ),
    [
      displayText,
      hasChildren,
      isExpanded,
      onExpandCollapse,
      task.id,
      task.isMilestone
    ]
  );

  const taskCardSubHeader = useMemo(
    () => (
      <TaskCardSubHeader
        startDate={task.startDate}
        endDate={task.endDate}
        taskStatus={taskStatus}
      />
    ),
    [task.endDate, task.startDate, taskStatus]
  );

  return (
    <Card className={classes.card} square elevation={0}>
      <CardHeader
        classes={cardHeaderClasses}
        title={taskCardTitle}
        subheader={taskCardSubHeader}
      />
      <CardContent
        classes={useMemo(() => ({ root: classes.cardContent }), [
          classes.cardContent
        ])}
      >
        <Grid container className={classes.userRole}>
          <Grid item xs={6}>
            <TaskCardUser user={task.assignedUser} />
          </Grid>
          <Grid item xs={6}>
            <TaskCardRole role={task.assignedRole} />
          </Grid>
        </Grid>
        {isPSATaskEstimateRollUpMobileViewEnabled &&
          isRolledUpTaskEstimateCalculationMethodEnabled && (
            <>
              <Divider variant="middle" />
              <Grid container className={classes.userRole}>
                <Grid item xs={7}>
                  <TaskCardHours task={task} />
                </Grid>
                <Grid item xs={5}>
                  <TaskCardVariation task={task} />
                </Grid>
              </Grid>
            </>
          )}
        {!isRolledUpTaskEstimateCalculationMethodEnabled && (
          <TaskDetails canViewEstimate={canViewEstimate} task={task} />
        )}
      </CardContent>
    </Card>
  );
};

TaskCard.propTypes = {
  canViewEstimate: PropTypes.bool,
  task: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  onExpandCollapse: PropTypes.func.isRequired
};

export default TaskCard;
