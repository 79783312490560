import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import HierarchyOptionItem from '~/modules/common/components/HierarchyOptionItem';
import {
  NoneDropdownItem,
  SimpleAutocomplete,
  useInputClasses
} from '~/modules/common/components/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { NO_CHANGE_OPTION_ID, NO_VALUE_OPTION_ID } from '../../hooks';

const renderOption = option => {
  if (option.id === NO_CHANGE_OPTION_ID || option.id === NO_VALUE_OPTION_ID)
    return <NoneDropdownItem value={option.displayText} />;

  if (option.id === MORE_AVAILABLE_OPTION_ID)
    return <MoreResult message={option.displayText} />;

  return (
    <HierarchyOptionItem hierarchyLevel={option.hierarchyLevel}>
      {option.displayText}
    </HierarchyOptionItem>
  );
};

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

const getOptionSelected = (option, value) => option.id === value.id;

export const GroupDropdown = ({
  hasMore,
  classes,
  onChange,
  value = '',
  loading,
  options,
  noOptionsText,
  setSearchTerm,
  label,
  optionTypeText,
  ...rest
}) => {
  const inputClasses = useInputClasses({ value });
  const { formatMessage } = useIntl();

  const onInputChange = useCallback(
    (_, val, reason) =>
      reason === 'input' ? setSearchTerm(val) : setSearchTerm(''),
    [setSearchTerm]
  );

  const onValueChange = useCallback(
    val => {
      setSearchTerm('');
      onChange(val);
    },
    [setSearchTerm, onChange]
  );

  const groupOptions = loading ? [] : options;

  const combinedClasses =
    classes && inputClasses
      ? { ...classes, ...inputClasses }
      : inputClasses || classes;

  return (
    <SimpleAutocomplete
      hasMore={hasMore}
      classes={combinedClasses}
      onInputChange={onInputChange}
      loading={loading}
      loadingText={formatMessage({ id: 'groupsDropdown.loading' })}
      noOptionsText={noOptionsText}
      fullWidth
      inputLabel={label}
      value={value}
      getOptionSelected={getOptionSelected}
      options={groupOptions}
      optionPropText="displayText"
      onChange={onValueChange}
      renderOption={renderOption}
      getOptionDisabled={getOptionDisabled}
      optionTypeText={optionTypeText}
      {...rest}
    />
  );
};

GroupDropdown.propTypes = {
  hasMore: PropTypes.bool,
  classes: PropTypes.object,
  value: PropTypes.object,
  noOptionsText: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setSearchTerm: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  optionTypeText: PropTypes.string
};

export default GroupDropdown;
