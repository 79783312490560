import { useCallback, useState } from 'react';
import { useCancelBillingBatch } from '~/modules/billing-invoicing/common/hooks';
import { useFetchInvoiceStatusUpdateBatchResult } from '../../hooks/useFetchInvoiceStatusUpdateBatchResult';

export const useInvoiceStatusUpdateBatchHandler = ({
  batchState,
  setBatchState,
  onSyncBills,
  closeDialog,
  onReset,
  syncBills,
  billIdsForSync
}) => {
  const [syncBilled, setSyncBilled] = useState(null);

  const {
    fetchInvoiceStatusUpdateBatchResult
  } = useFetchInvoiceStatusUpdateBatchResult();

  const cancelBillingBatch = useCancelBillingBatch();

  const onConfirm = useCallback(async () => {
    onSyncBills();
  }, [onSyncBills]);

  const onBatchComplete = useCallback(async () => {
    const result = await fetchInvoiceStatusUpdateBatchResult(
      batchState?.batchId
    );

    const billToSync = [
      ...new Set([
        ...billIdsForSync,
        ...result?.updatedInvoices.map(({ invoice }) => invoice.id)
      ])
    ];

    const { completed } = await syncBills(billToSync);

    setSyncBilled({ syncInvoicesCount: completed, ...result });

    setBatchState({
      batchId: batchState?.batchId,
      batchInProgress: false
    });
  }, [
    fetchInvoiceStatusUpdateBatchResult,
    batchState?.batchId,
    billIdsForSync,
    syncBills,
    setBatchState
  ]);

  const onCancel = useCallback(async () => {
    if (batchState?.batchId) {
      if (batchState?.batchInProgress) {
        await cancelBillingBatch(batchState?.batchId);
        onBatchComplete();
      } else {
        setBatchState({ batchId: null, batchInProgress: false });
        onReset();
        closeDialog();
      }
    } else {
      setBatchState({ batchId: null, batchInProgress: false });
      onReset();
      closeDialog();
    }
  }, [
    batchState?.batchId,
    batchState?.batchInProgress,
    cancelBillingBatch,
    onBatchComplete,
    setBatchState,
    onReset,
    closeDialog
  ]);

  return {
    syncBilled,
    onConfirm,
    onCancel,
    onBatchComplete
  };
};

export default useInvoiceStatusUpdateBatchHandler;
