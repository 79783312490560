import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_USER_QUERY = gql`
  query GetResourceUser(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
    $date: Date!
  ) {
    resourceUsers: resourceUsers2(
      page: $page
      pagesize: $pagesize
      filter: $filter
    ) {
      id
      uri
      slug
      displayText
      roles {
        projectRole {
          uri
          name
          displayText
        }
        isPrimary
      }
      costRateAsOf(date: $date) {
        amount
        currency {
          id
          uri
          displayText
        }
      }
      primaryRoleCostRate(date: $date) {
        amount
        currency {
          id
          uri
          displayText
        }
      }
    }
  }
`;

export const useGetResourceUser = ({
  page,
  pageSize,
  userUris,
  date,
  skip = false
}) => {
  const { loading, data } = useQuery(RESOURCE_USER_QUERY, {
    variables: {
      page,
      pagesize: pageSize,
      filter: {
        userIds: userUris
      },
      date
    },
    skip,
    fetchPolicy: 'cache-and-network'
  });

  return {
    loading,
    resourceUsers:
      data?.resourceUsers.map(resourceUser => ({
        ...resourceUser,
        primaryRoleCostRate: resourceUser.primaryRoleCostRate?.amount || 0,
        primaryRoleCostCurrencyUri:
          resourceUser.primaryRoleCostRate?.currency.uri || null,
        primaryRoleCostCurrency:
          resourceUser.primaryRoleCostRate?.currency || null
      })) || []
  };
};

export default useGetResourceUser;
