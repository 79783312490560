import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { BillStatus } from '~/types';
import { useMeContext } from '~/modules/me';

export const UPDATE_BILL_BATCH_STATUS = gql`
  mutation updateBillStatusBatch($input: UpdateBillStatusBatchParameter!) {
    updateBillStatusBatch(updateBillStatusBatchParameter: $input) {
      batchUri
    }
  }
`;

export const mapToServiceFilter = ({
  filter: {
    clients: clientUris,
    transactionTypes,
    dateRange,
    text,
    balanceStatusList,
    isOverdue,
    dueDate
  } = {}
}) => ({
  clientUris,
  transactionTypes,
  dateRange,
  searchTerm: text,
  balanceStatusList,
  isOverdue,
  dueDate
});

export const useUpdateBillStatusBatch = ({
  includedBillIds,
  excludedBillIds,
  isAllSelected,
  filter,
  setRecalculationBatchState,
  billIdsForIssue
}) => {
  const {
    featureFlags: { isPsaPrpCappedBillingEnabled }
  } = useMeContext();

  const [updateBillStatusBatch, { loading }] = useMutation(
    UPDATE_BILL_BATCH_STATUS
  );

  return {
    updateBillStatusBatch: async () => {
      const { data } =
        (await updateBillStatusBatch({
          variables: {
            input: {
              billStatus: BillStatus.Billed,
              syncBillsInput: {
                includeBillIds: isPsaPrpCappedBillingEnabled
                  ? billIdsForIssue
                  : includedBillIds,
                excludeBillIds: excludedBillIds,
                allSelected: isAllSelected,
                filter: mapToServiceFilter({ filter })
              }
            }
          }
        })) || {};

      const batchId = get(data, 'updateBillStatusBatch.batchUri', null);

      setRecalculationBatchState({
        batchId,
        batchInProgress: true
      });
    },
    loading
  };
};
export default useUpdateBillStatusBatch;
