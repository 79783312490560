import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import {
  ProjectChartRowsLoading,
  HolidaySummaryBlocks,
  TimeOffSummaryBlocks
} from '~/modules/resourcing/common/components';
import { useFetchResourceUserTaskAssignments } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';
import {
  useHolidaySummaryPeriods,
  useTimeoffSummaryPeriods
} from '~/modules/resourcing/common/hooks';
import {
  actionsWidth,
  itemWidth
} from '~/modules/common/charts/timeline/calculations';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import UserAllocationsSummaryContextProvider from '../../UserAllocationsSummaryContextProvider';
import { UserTaskAssignmentRow } from './components/UserTaskAssignmentRow';
import {
  RowLeftItemLoading,
  UserTaskAssignmentShowMore
} from './components/UserTaskAssignmentRow/components';
import { UserTaskAssignmentFooter } from './components/UserTaskAssignmentFooter';
import { useProjectActualSeriesByTaskForUser } from './hooks/useProjectActualSeriesByTaskForUser';

const PAGE_SIZE = 10;
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1)
  },
  loadingIndicator: {
    margin: theme.spacing(1, 0, 1, 3)
  },
  noTasksAssignedContainer: {
    display: 'flex'
  },
  noTasksAssigned: {
    position: 'sticky',
    left: 0
  }
}));

const useTimeoffHolidaySummaryBlocksStyles = makeStyles(() => ({
  root: {
    height: '100%',
    top: '0px !important'
  },
  container: {
    height: '100%'
  }
}));

export const UserTaskAssignments = ({
  user,
  userCostRateDetails,
  userScheduleDetails,
  resourceAllocationScheduleRules,
  loadingResourceAllocation,
  taskResourceUserAllocationsSummary,
  userTaskAllocationsSummaryLoading,
  userOverDistributedPeriodsMap,
  chartDisplayPeriods,
  resetResourceAllocationChart,
  handleRemoveResourceAllocation
}) => {
  const classes = useStyles();
  const {
    project: { id: projectId }
  } = useProjectContext();
  const isRmpTaskAllocationPhase2Enabled = useHasFeatureFlag({
    featureFlag: 'isRmpTaskAllocationPhase2Enabled'
  });
  const {
    scale,
    dateRange,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();
  const timeOffHolidaySummaryBlocksClasses = useTimeoffHolidaySummaryBlocksStyles();

  const { id: userId, displayText } = user;
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    userTaskAssignmentsData,
    handleRemoveTaskAssignment,
    handleUpdateTaskAssignment
  } = useFetchResourceUserTaskAssignments({
    userId,
    projectId,
    pageSize: PAGE_SIZE,
    currentPage,
    setCurrentPage,
    resetResourceAllocationChart: isRmpTaskAllocationPhase2Enabled
      ? resetResourceAllocationChart
      : null
  });

  const taskUris = userTaskAssignmentsData.length
    ? userTaskAssignmentsData.map(taskAssignment => taskAssignment.task.uri)
    : [];

  const {
    loading: loadingActuals,
    projectActualSeriesByTask
  } = useProjectActualSeriesByTaskForUser({
    dateRange,
    scale,
    userUri: userId,
    projectId,
    taskUris,
    skip:
      !isRmpTaskAllocationPhase2Enabled ||
      !isResourceActualModeEnabled ||
      taskUris.length === 0
  });

  const onShowMoreButtonClick = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage]);

  const loadingFirstPage = currentPage === 1 && loading;
  const loadingMore = currentPage > 1 && loading;
  const hasMore =
    Object.keys(userTaskAssignmentsData).length === currentPage * PAGE_SIZE;

  const { timeoffs, holidays } = userScheduleDetails;

  const timeoffSummaryPeriods = useTimeoffSummaryPeriods({
    resource: { timeoffs }
  });

  const holidaySummaryPeriods = useHolidaySummaryPeriods({
    resource: { holidays }
  });

  const relativeDynamicPositionLeft = -(actionsWidth + itemWidth * 2);

  if (loadingFirstPage)
    return <ProjectChartRowsLoading LeftComponent={RowLeftItemLoading} />;

  return (
    <>
      <div data-qe-id={`${displayText}_Project`} className={classes.root}>
        {userTaskAssignmentsData.length ? (
          <UserAllocationsSummaryContextProvider
            userTaskAllocationsSummaryScheduleRules={
              taskResourceUserAllocationsSummary?.scheduleRules
            }
            userTaskAllocationsSummaryLoading={
              userTaskAllocationsSummaryLoading
            }
            resourceAllocationScheduleRules={resourceAllocationScheduleRules}
            loadingResourceAllocation={loadingResourceAllocation}
            userOverDistributedPeriodsMap={userOverDistributedPeriodsMap}
            userCostRateDetails={userCostRateDetails}
            handleRemoveResourceAllocation={handleRemoveResourceAllocation}
            resetResourceAllocationChart={resetResourceAllocationChart}
          >
            <TimeOffSummaryBlocks
              scale={scale}
              chartStartDate={dateRange.startDate}
              timeoffSummaryPeriods={timeoffSummaryPeriods}
              chartDisplayDateRange={dateRange}
              timeOffs={timeoffs}
              showTooltip
              clickable
              classes={timeOffHolidaySummaryBlocksClasses}
              relativeDynamicPositionLeft={relativeDynamicPositionLeft}
            />
            <HolidaySummaryBlocks
              scale={scale}
              chartStartDate={dateRange.startDate}
              holidaySummaryPeriods={holidaySummaryPeriods}
              chartDisplayDateRange={dateRange}
              holidays={holidays}
              showTooltip
              clickable
              classes={timeOffHolidaySummaryBlocksClasses}
              relativeDynamicPositionLeft={relativeDynamicPositionLeft}
            />
            {userTaskAssignmentsData.map(taskResourceEstimate => (
              <UserTaskAssignmentRow
                key={taskResourceEstimate.task.id}
                loadingActuals={loadingActuals}
                taskActualSeriesData={
                  projectActualSeriesByTask[taskResourceEstimate.task.id]
                }
                taskResourceEstimate={taskResourceEstimate}
                userScheduleDetails={userScheduleDetails}
                user={user}
                chartDisplayPeriods={chartDisplayPeriods}
                projectId={projectId}
                handleRemoveTaskAssignment={handleRemoveTaskAssignment}
              />
            ))}
            {loadingMore && (
              <div className={classes.loadingIndicator}>
                <CircularProgress size={24} />
              </div>
            )}
            {hasMore && !loadingMore && (
              <UserTaskAssignmentShowMore loadMore={onShowMoreButtonClick} />
            )}
          </UserAllocationsSummaryContextProvider>
        ) : (
          <div className={classes.noTasksAssignedContainer}>
            <div className={classes.noTasksAssigned}>
              <Typography className={classes.label}>
                <FormattedMessage id="taskAssignments.noTasksAssigned" />
              </Typography>
            </div>
          </div>
        )}
      </div>
      <UserTaskAssignmentFooter
        projectId={projectId}
        user={user}
        handleUpdateTaskAssignment={handleUpdateTaskAssignment}
      />
    </>
  );
};
UserTaskAssignments.propTypes = {
  user: PropTypes.object,
  userCostRateDetails: PropTypes.object,
  userScheduleDetails: PropTypes.object,
  resourceAllocationScheduleRules: PropTypes.array,
  loadingResourceAllocation: PropTypes.bool,
  taskResourceUserAllocationsSummary: PropTypes.object,
  userTaskAllocationsSummaryLoading: PropTypes.bool,
  userOverDistributedPeriodsMap: PropTypes.object,
  chartDisplayPeriods: PropTypes.array,
  resetResourceAllocationChart: PropTypes.func.isRequired,
  handleRemoveResourceAllocation: PropTypes.func
};
export default UserTaskAssignments;
