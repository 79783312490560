import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
  List
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FailedInvoiceDetails from './FailedInvoiceDetails';

const useStyles = makeStyles(theme => ({
  accordion: {
    background: theme.palette.error.lightBackground,
    color: '#560e06'
  },
  accordionSummaryExpanded: {
    minHeight: '32px !important'
  },
  accordionSummaryContentExpanded: {
    margin: '0px !important'
  },
  accordionDetails: {
    padding: theme.spacing(0, 2)
  },
  alert: {
    padding: theme.spacing(1, 0)
  },
  errorMessageList: {
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    listStyleType: 'disc'
  },
  syncMessageText: {
    fontWeight: 500
  },
  listItem: {
    marginTop: theme.spacing(1.25)
  }
}));

const expandIcon = <ExpandMoreIcon />;

export const SuccessMessage = ({ count }) => {
  const classes = useStyles();

  return (
    <Alert icon={false}>
      <AlertTitle>
        <Typography variant="body1" className={classes.syncMessageText}>
          <FormattedMessage
            id="billingTransactionList.syncSuccessMessage"
            values={useMemo(() => ({ count }), [count])}
          />
        </Typography>
      </AlertTitle>
    </Alert>
  );
};

SuccessMessage.propTypes = {
  count: PropTypes.number.isRequired
};

export const FailedInvoicesAccordion = ({ failedInvoices }) => {
  const classes = useStyles();
  const failedInvoicesCount = useMemo(
    () => ({ count: failedInvoices.length }),
    [failedInvoices.length]
  );

  return (
    <Accordion elevation={0} className={classes.accordion}>
      <AccordionSummary
        expandIcon={expandIcon}
        classes={useMemo(
          () => ({
            root: classes.accordionSummaryExpanded,
            content: classes.accordionSummaryContentExpanded
          }),
          [
            classes.accordionSummaryContentExpanded,
            classes.accordionSummaryExpanded
          ]
        )}
      >
        <Typography variant="body1" className={classes.syncMessageText}>
          <FormattedMessage
            id="billingTransactionList.syncFailureMessage"
            values={failedInvoicesCount}
          />
        </Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <List>
          {failedInvoices.map((failedInvoice, index) => (
            <li
              key={failedInvoice.invoice.id}
              className={index !== 0 ? classes.listItem : undefined}
            >
              <Typography variant="body1" className={classes.syncMessageText}>
                {failedInvoice.invoice.displayText}
              </Typography>
              <ul className={classes.errorMessageList}>
                <li>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="billingTransactionList.failedBillIssueMessage"
                      values={failedInvoicesCount}
                    />
                  </Typography>
                </li>
              </ul>
              <FailedInvoiceDetails details={failedInvoice.details} />
            </li>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

FailedInvoicesAccordion.propTypes = {
  failedInvoices: PropTypes.array
};

const ProgressResult = ({ syncBilled }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.alert}>
        <SuccessMessage count={syncBilled?.syncInvoicesCount || 0} />
      </div>
      {syncBilled?.failedInvoices.length > 0 && (
        <div className={classes.alert}>
          <FailedInvoicesAccordion failedInvoices={syncBilled.failedInvoices} />
        </div>
      )}
    </>
  );
};

ProgressResult.propTypes = {
  syncBilled: PropTypes.shape({
    syncInvoicesCount: PropTypes.number,
    updatedInvoices: PropTypes.array,
    failedInvoices: PropTypes.array
  })
};

export default ProgressResult;
