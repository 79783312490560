import { makeStyles } from '@material-ui/core/styles';
import dashBorderSVG from '../DashedBorder.svg';

const useStyles = makeStyles(theme => ({
  root: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 2, 0, 2),
    flexGrow: 1,
    flexShrink: 1
  },
  legendItem: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 0, 2),
    '&:first-child': {
      marginLeft: 0
    }
  },
  rootLegend: {
    display: 'block',
    width: theme.spacing(1),
    height: theme.spacing(1)
  },
  roundBar: {
    width: theme.spacing(1.5),
    borderRadius: theme.spacing(1 / 2)
  },
  dashedBar: {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    border: `2px dashed ${theme.palette.grey[700]}`,
    borderImage: `url(${dashBorderSVG}) 2 repeat`,
    borderRadius: theme.spacing(0.25)
  },
  legendText: {
    ...theme.typography.caption,
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(0, 0, 0, 0.5)
  },
  request: {},
  allocated: {
    backgroundColor: theme.palette.resourceAllocation.allocated
  },
  overAllocated: {
    backgroundColor: theme.palette.resourceAllocation.overAllocated
  },
  actuals: {
    backgroundColor: theme.palette.resourceAllocation.actual
  },
  toBeHired: {
    backgroundColor: theme.palette.resourceRequest.toBeHired.main
  },
  unavailable: {
    backgroundColor: theme.palette.grey[200]
  },
  rejected: {
    backgroundColor: theme.palette.resourceRequest.requestRejected.main
  },
  pending: {
    backgroundColor: theme.palette.resourceRequest.pending.main
  },
  proposed: {
    backgroundColor: theme.palette.resourceRequest.proposed.main
  },
  complete: {
    backgroundColor: theme.palette.resourceRequest.complete.main
  },
  timeOff: {
    width: theme.spacing(1),
    height: theme.spacing(2),
    backgroundColor: theme.palette.resourceAllocation.timeOff
  },
  timeOffAllocation: {
    backgroundColor: theme.palette.resourceAllocation.timeOff
  },
  holiday: {
    width: theme.spacing(1),
    height: theme.spacing(2),
    backgroundColor: theme.palette.resourceAllocation.holiday
  },
  currentAllocated: {
    backgroundColor: theme.palette.primary.main
  },
  allocatedGradient: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 0%, ${theme.palette.resourceAllocation.allocated} 100%)`
  },
  notWorking: {
    backgroundColor: theme.palette.grey[300],
    width: theme.spacing(1),
    height: theme.spacing(2),
    margin: theme.spacing(-0.5, 0)
  },
  notWorking2: {
    backgroundColor: theme.palette.grey[300],
    width: theme.spacing(1.25),
    height: theme.spacing(2)
  }
}));

export default useStyles;
