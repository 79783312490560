import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import Alert from '@material-ui/lab/Alert';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';
import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';
import { LoadingButton } from '~/modules/common/components';
import { useHasFeatureFlag } from '~/modules/common/hooks';

import {
  useCreateBulkProjectStatusChangeBatch,
  useBulkPutProjectStatus
} from './hooks';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 0
  },
  noPermissionMessage: {
    marginBottom: theme.spacing(2)
  }
}));

export const projectWorkFlowOptions = ({
  intl,
  customStatusMapping,
  isCustomStatusNameEnabled
}) => [
  {
    id: PROJECT_EXECUTION_PHASE.INITIATE,
    displayText:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_EXECUTION_PHASE.INITIATE]
        ? customStatusMapping[PROJECT_EXECUTION_PHASE.INITIATE]
        : intl.formatMessage({
            id: `projectWorkflow.${PROJECT_EXECUTION_PHASE.INITIATE}`
          })
  },
  {
    id: PROJECT_EXECUTION_PHASE.PLANNING,
    displayText:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_EXECUTION_PHASE.PLANNING]
        ? customStatusMapping[PROJECT_EXECUTION_PHASE.PLANNING]
        : intl.formatMessage({
            id: `projectWorkflow.${PROJECT_EXECUTION_PHASE.PLANNING}`
          })
  },
  {
    id: PROJECT_EXECUTION_PHASE.EXECUTION,
    displayText:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_EXECUTION_PHASE.EXECUTION]
        ? customStatusMapping[PROJECT_EXECUTION_PHASE.EXECUTION]
        : intl.formatMessage({
            id: `projectWorkflow.${PROJECT_EXECUTION_PHASE.EXECUTION}`
          })
  },
  {
    id: PROJECT_EXECUTION_PHASE.CLOSEOUT,
    displayText:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_EXECUTION_PHASE.CLOSEOUT]
        ? customStatusMapping[PROJECT_EXECUTION_PHASE.CLOSEOUT]
        : intl.formatMessage({
            id: `projectWorkflow.${PROJECT_EXECUTION_PHASE.CLOSEOUT}`
          })
  },
  {
    id: PROJECT_EXECUTION_PHASE.ARCHIVED,
    displayText: intl.formatMessage({
      id: `projectWorkflow.${PROJECT_EXECUTION_PHASE.ARCHIVED}`
    })
  }
];

const renderValue = ({ projectWorkflowState }) => () =>
  projectWorkflowState && projectWorkflowState.id
    ? projectWorkflowState.displayText
    : '-';

export const BulkProjectStatusChangeDialogContent = ({
  onClose,
  projects,
  batchState,
  setBatchState,
  projectWorkflowState,
  setProjectWorkflowState,
  bulkUpdateProjectState,
  setBulkUpdateProjectState
}) => {
  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });
  const isPsaPrpProjectStatusChangeNotificationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpProjectStatusChangeNotificationEnabled'
  });

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const intl = useIntl();
  const classes = useStyles();
  const projectsPermissionsResult = projects.reduce(
    (accumulator, project) => {
      accumulator[
        project.permittedActionUris.includes(
          'urn:replicon:project-action:edit-project'
        )
          ? 'projectsWithEditPermission'
          : 'projectsWithNoEditPermission'
      ].push(project);

      return accumulator;
    },
    { projectsWithEditPermission: [], projectsWithNoEditPermission: [] }
  );
  const selectedProjectIds = isPsaPrpProjectStatusChangeNotificationEnabled
    ? projectsPermissionsResult.projectsWithEditPermission.map(p => p.id)
    : projects.map(p => p.id);

  const options = projectWorkFlowOptions({
    intl,
    customStatusMapping,
    isCustomStatusNameEnabled
  });
  const updateStatus = useCreateBulkProjectStatusChangeBatch({
    projectIds: selectedProjectIds,
    setBatchState,
    projectWorkflowState: projectWorkflowState?.id
  });
  const bulkPutProjectStatus = useBulkPutProjectStatus({
    projectIds: selectedProjectIds,
    projectWorkflowState: projectWorkflowState?.id,
    setBulkUpdateProjectState
  });

  const handleChange = useCallback(
    event => {
      setProjectWorkflowState(
        options.find(option => option.id === event.target.value)
      );
    },
    [options, setProjectWorkflowState]
  );

  const npPermissionMessage =
    projectsPermissionsResult.projectsWithNoEditPermission.length > 0
      ? intl.formatMessage(
          {
            id: 'bulkProjectEditStatus.noPermission'
          },
          {
            value:
              projectsPermissionsResult.projectsWithNoEditPermission.length,
            object: intl.formatMessage({
              id:
                projectsPermissionsResult.projectsWithNoEditPermission.length >
                1
                  ? 'bulkProjectEditStatus.projects'
                  : 'bulkProjectEditStatus.project'
            })
          }
        )
      : null;

  const doNotHavePermissionToEditAnyProject = isPsaPrpProjectStatusChangeNotificationEnabled
    ? projects.length ===
      projectsPermissionsResult.projectsWithNoEditPermission.length
    : selectedProjectIds.length ===
      projectsPermissionsResult.projectsWithNoEditPermission.length;

  return (
    <>
      <DialogContent>
        {projectsPermissionsResult.projectsWithNoEditPermission.length > 0 ? (
          <Alert
            icon={false}
            severity="error"
            className={classes.noPermissionMessage}
          >
            {npPermissionMessage}
          </Alert>
        ) : null}
        {!doNotHavePermissionToEditAnyProject ? (
          <FormControl fullWidth variant="filled">
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              {intl.formatMessage({
                id: 'bulkProjectEditStatus.status'
              })}
            </InputLabel>
            <Select
              value={projectWorkflowState ? projectWorkflowState.id : ''}
              onChange={handleChange}
              displayEmpty
              renderValue={renderValue({ projectWorkflowState })}
            >
              {options.map(({ id, displayText }) => (
                <MenuItem value={id} key={id}>
                  {displayText}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.formControl}>
              {intl.formatMessage({
                id: 'bulkProjectEditStatus.changeStatusMessage'
              })}
            </FormHelperText>
          </FormControl>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {intl.formatMessage({
            id: 'bulkProjectEditStatus.cancel'
          })}
        </Button>
        <LoadingButton
          color="primary"
          onClick={
            projectWorkflowState
              ? isPsaPrpProjectStatusChangeNotificationEnabled
                ? bulkPutProjectStatus
                : updateStatus
              : onClose
          }
          disabled={
            doNotHavePermissionToEditAnyProject || batchState.batchInProgress
          }
          isLoading={batchState.batchInProgress}
        >
          {intl.formatMessage({
            id: 'bulkProjectEditStatus.updateStatus'
          })}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

BulkProjectStatusChangeDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  batchState: PropTypes.object.isRequired,
  setBatchState: PropTypes.func.isRequired,
  projectWorkflowState: PropTypes.object,
  setProjectWorkflowState: PropTypes.func.isRequired,
  bulkUpdateProjectState: PropTypes.object.isRequired,
  setBulkUpdateProjectState: PropTypes.func.isRequired
};

export default BulkProjectStatusChangeDialogContent;
