import { Divider, makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { NoDataItem } from '~/modules/common/components';
import { ShowCommentsOnInvoiceCheckBox } from '~/modules/billing-invoicing/common/components';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0, 2, 0)
  },
  container: {
    display: 'flex'
  },
  subtitleValue: {
    color: theme.palette.text.primary,
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  },
  margin: {
    margin: theme.spacing(1)
  },
  title: {
    color: theme.palette.text.secondary
  },
  noDataRoot: {
    '& span': {
      hyphens: 'unset'
    }
  }
}));

const BillingCardReadOnly = ({ billingCardResourceKeys }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const noDataItemClasses = useMemo(
    () => ({
      root: classes.noDataRoot
    }),
    [classes.noDataRoot]
  );
  const { values } = useFormikContext();
  const {
    defaultInvoicePaymentTerm,
    billLineItemsBy,
    invoiceCurrency,
    taxProfile,
    invoiceTemplate,
    description,
    internalNotes,
    showComments
  } = values;
  const formattedBillLineItemsBy = billLineItemsBy
    .map(columnOption => {
      return `${formatMessage({
        id: `billLineItemOptions.${columnOption}`
      })}`;
    })
    .join(', ');

  const titleField = (variant, resourceId) => {
    return (
      <Typography variant={variant} className={classes.title}>
        <FormattedMessage id={resourceId} />
      </Typography>
    );
  };
  const cardSubtitleFieldValue = text => {
    return (
      <Typography variant="body2" className={classes.subtitleValue}>
        {text}
      </Typography>
    );
  };
  const cardSubtitleFieldNoValue = resourceId => {
    return (
      <div>
        <NoDataItem classes={noDataItemClasses}>
          <FormattedMessage id={resourceId} />
        </NoDataItem>
      </div>
    );
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.margin}>
          {titleField('body1', billingCardResourceKeys.schedule)}
        </Grid>
        <Grid
          item
          xs
          className={classes.margin}
          dataQeId="ClientBillingCardSection_PaymentTerm"
        >
          {titleField('caption', billingCardResourceKeys.paymentTerm)}
          {cardSubtitleFieldValue(defaultInvoicePaymentTerm)}
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShowCommentsOnInvoiceCheckBox
            showCommentsLabel={billingCardResourceKeys.showComments}
            showComments={showComments}
            isReadOnly
            dataQeId="ReadOnlyShowCommentsOnInvoice"
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.margin}>
          {titleField('body1', billingCardResourceKeys.structure)}
        </Grid>
        <Grid item xs className={classes.margin} data-qe-id="BillLineItemsBy">
          {titleField('caption', billingCardResourceKeys.billLineItemsBy)}
          <Box width="100%" />
          {formattedBillLineItemsBy}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.margin}>
          {titleField('body1', billingCardResourceKeys.defaults)}
        </Grid>
        <Grid item xs className={classes.margin}>
          {titleField('caption', billingCardResourceKeys.billingCurrency)}
          {cardSubtitleFieldValue(invoiceCurrency?.symbol)}
        </Grid>
        <Grid
          item
          xs
          className={classes.margin}
          dataQeId="ClientBillingCardSection_TaxProfile"
        >
          {titleField('caption', billingCardResourceKeys.taxProfile)}
          {taxProfile
            ? cardSubtitleFieldValue(taxProfile?.displayText)
            : cardSubtitleFieldNoValue(billingCardResourceKeys.noTaxProfile)}
        </Grid>
        <Grid item xs className={classes.margin}></Grid>
        <Box width="100%" />
        <Grid
          item
          xs
          className={classes.margin}
          dataQeId="ClientBillingCardSection_InvoiceTemplate"
        >
          {titleField('caption', billingCardResourceKeys.invoiceTemplate)}
          {invoiceTemplate
            ? cardSubtitleFieldValue(invoiceTemplate?.displayText)
            : cardSubtitleFieldNoValue(
                billingCardResourceKeys.noInvoiceTemplate
              )}
        </Grid>
        <Grid item xs className={classes.margin} zeroMinWidth>
          {titleField('caption', billingCardResourceKeys.description)}
          {cardSubtitleFieldValue(description)}
        </Grid>
        <Grid item xs className={classes.margin} zeroMinWidth>
          {titleField('caption', billingCardResourceKeys.internalNotes)}
          {cardSubtitleFieldValue(internalNotes)}
        </Grid>
      </Grid>
    </div>
  );
};

BillingCardReadOnly.propTypes = {
  billingCardResourceKeys: PropTypes.any
};

export default BillingCardReadOnly;
