import url from 'url';

export const getNotificationPath = ({
  targetUrl,
  isPsaDtmRequisitionEnabled
}) => {
  if (!targetUrl) {
    return null;
  }
  const { hash, pathname } = url.parse(targetUrl);

  const path = `${pathname}${hash && isPsaDtmRequisitionEnabled ? hash : ''}`;

  return path;
};
