import { DateTime } from 'luxon';
import { ReleaseResourceAllocationType } from '~/types';
import { compareISOStrings } from '~/modules/common/dates/compare';

export const getImpactedTasksByReleasingResources = ({
  resources,
  allocations,
  releaseDate,
  releaseType,
  isPSAPswatFixReleaseAllocationEnabled
}) => {
  if (resources.length === 0) {
    return {
      selectedUsersForReleasing: [],
      impactedTasks: []
    };
  }

  if (isPSAPswatFixReleaseAllocationEnabled) {
    const impactedResources = resources.filter(resource =>
      allocations.some(
        allocation =>
          allocation.isSelected && allocation.user.uri === resource.id
      )
    );

    const { impactedTasks } = impactedResources.reduce(
      (acc, resource) => {
        const resourceAllocation = allocations.find(
          allocation => allocation.user.uri === resource.id
        );

        const tasks =
          resource.allocationCount === 1
            ? resource.assignedTasks || []
            : getImpactedTasksUsingAllocationAndTaskRoleMatch({
                resourceUser: resource,
                allocations: [resourceAllocation]
              });

        acc.impactedTasks.push(...tasks);

        return acc;
      },
      { impactedTasks: [] }
    );

    return {
      selectedUsersForReleasing: impactedResources,
      impactedTasks:
        releaseType === ReleaseResourceAllocationType.Entirely
          ? impactedTasks
          : impactedTasks.filter(task =>
              isTaskEndsAfterAsOfDate({
                asOfDate: releaseDate,
                task
              })
            )
    };
  }

  const usersWithOnlyOneAllocation = resources.filter(
    resource => resource.allocationCount === 1
  );

  const selectedUsers = usersWithOnlyOneAllocation
    .map(user =>
      allocations.find(
        allocation => allocation.isSelected && allocation.user.uri === user.id
      )
        ? user
        : null
    )
    .filter(x => x);

  const tasks = (selectedUsers || []).reduce((accumulator, currentValue) => {
    return [...accumulator, ...currentValue.assignedTasks];
  }, []);

  return {
    selectedUsersForReleasing: selectedUsers,
    impactedTasks:
      releaseType === ReleaseResourceAllocationType.Entirely
        ? tasks
        : tasks.filter(task =>
            isAsOfDateFallingBetweenTaskStartAndEndDate({
              asOfDate: releaseDate,
              task
            })
          )
  };
};

export const isTaskEndsAfterAsOfDate = ({ asOfDate, task }) => {
  const { endDate } = task;

  return !endDate || compareISOStrings(asOfDate, endDate) === -1;
};

export const isAsOfDateFallingBetweenTaskStartAndEndDate = ({
  asOfDate,
  task
}) => {
  const { endDate, startDate } = task;

  if (!endDate && !startDate) return true;

  const start = startDate
    ? DateTime.fromISO(startDate, { setZone: true })
    : null;
  const end = endDate ? DateTime.fromISO(endDate, { setZone: true }) : null;
  const dateToCompare = DateTime.fromISO(asOfDate, { setZone: true });

  if (!start) return dateToCompare <= end;

  if (!end) return start <= dateToCompare;

  return start <= dateToCompare && dateToCompare <= end;
};

export const getImpactedTasksUsingAllocationAndTaskRoleMatch = ({
  resourceUser,
  allocations
}) => {
  const { allocationCounts, selectedAllocationCounts } = allocations.reduce(
    (acc, { role, allocationRoleId, isSelected }) => {
      const roleId = role?.id || allocationRoleId;

      if (roleId) {
        acc.allocationCounts[roleId] = (acc.allocationCounts[roleId] ?? 0) + 1;

        if (isSelected) {
          acc.selectedAllocationCounts[roleId] =
            (acc.selectedAllocationCounts[roleId] ?? 0) + 1;
        }
      }

      return acc;
    },
    { allocationCounts: {}, selectedAllocationCounts: {} }
  );

  const { assignedTasks = [] } = resourceUser ?? {};

  return assignedTasks.filter(({ assignedRoleUri }) => {
    if (assignedRoleUri) {
      const selectedCount = selectedAllocationCounts[assignedRoleUri] ?? 0;

      return (
        selectedCount > 0 && allocationCounts[assignedRoleUri] === selectedCount
      );
    }

    return false;
  });
};
