/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import LegendItem from './LegendItem';
import LegendsPopup from './LegendsPopup';
import useStyles from './useStyles';

const legendsPropTypeShape = PropTypes.shape({
  isProgressBarLegend: PropTypes.bool,
  barType: PropTypes.oneOf(['dashedBar', 'roundBar', 'squareBar']),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string
});

export const ResourcingLegends = ({
  classes: overriddenClasses,
  legends,
  showNewLegends = false
}) => {
  const classes = useStyles({
    classes: overriddenClasses
  });

  return (
    <div className={classes.root}>
      {showNewLegends ? (
        <LegendsPopup legends={legends} />
      ) : (
        legends.map((legend, index) => (
          <LegendItem key={index} classes={classes} {...legend} />
        ))
      )}
    </div>
  );
};

ResourcingLegends.propTypes = {
  legends: PropTypes.oneOfType([
    PropTypes.arrayOf(legendsPropTypeShape).isRequired,
    PropTypes.shape({
      none: PropTypes.arrayOf(legendsPropTypeShape).isRequired,
      resourceRequests: PropTypes.arrayOf(legendsPropTypeShape).isRequired,
      resources: PropTypes.arrayOf(legendsPropTypeShape).isRequired
    }).isRequired
  ]),
  showNewLegends: PropTypes.bool,
  classes: PropTypes.object
};

export default ResourcingLegends;
