import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { useResourcingDragIndicators } from '~/modules/resourcing/hooks';
import { getProjectRemainingHoursPerDay } from '../../../common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '../../../common/dates/convert';
import {
  hasOverlap,
  truncateRuleBoundaries,
  getDateRangeFromScheduleRules,
  getOverlappingDateRange
} from '../util';
import { getScheduleRulesFromAllocationPeriods } from '../../../common/hooks/resourcing/scheduleUtil.merge';
import { getDragIndicatorsInChart } from './useResourceAllocationDragIndicators';

export const getDateRangesOverlappingAndNonOverlappingWithResourceAllocation = ({
  periodStartDate,
  periodEndDate,
  resourceAllocationScheduleRules,
  canExtendTaskAllocationBeyondProjectAllocation
}) => {
  if (!canExtendTaskAllocationBeyondProjectAllocation)
    return {
      overlappingDateRange: {
        rangeStart: periodStartDate,
        rangeEnd: periodEndDate
      }
    };

  const {
    startDate: resourceAllocationStartDate,
    endDate: resourceAllocationEndDate
  } = getDateRangeFromScheduleRules(resourceAllocationScheduleRules);

  const overlappingDateRange = getOverlappingDateRange(
    {
      startDate: periodStartDate,
      endDate: periodEndDate
    },
    {
      startDate: mapIsoStringtoUtcObject(resourceAllocationStartDate),
      endDate: mapIsoStringtoUtcObject(resourceAllocationEndDate)
    }
  );

  return {
    overlappingDateRange,
    nonOverlappingDateRange: !overlappingDateRange
      ? {
          rangeStart: periodStartDate,
          rangeEnd: periodEndDate
        }
      : periodStartDate < overlappingDateRange.rangeStart
      ? {
          rangeStart: periodStartDate,
          rangeEnd: overlappingDateRange.rangeStart.minus({ days: 1 })
        }
      : periodEndDate > overlappingDateRange.rangeEnd
      ? {
          rangeStart: overlappingDateRange.rangeEnd.plus({ days: 1 }),
          rangeEnd: periodEndDate
        }
      : undefined
  };
};

export const getCombinedPeriods = (periods1, periods2) => {
  if (!periods1.length) return periods2;
  if (!periods2.length) return periods1;

  return periods1[0]?.date < periods2[0]?.date
    ? periods1.concat(periods2)
    : periods2.concat(periods1);
};

export const calculateExtendedTaskAllocationScheduleRules = async ({
  startDate,
  endDate,
  taskAllocation: {
    startDate: allocationStartDate,
    endDate: allocationEndDate,
    scheduleRules,
    user
  },
  resourceAllocationScheduleRules,
  userTaskAllocationsSummaryScheduleRules,
  isLeftExpanded,
  canExtendTaskAllocationBeyondProjectAllocation,
  getResourceUserAvailabilityHoursSeriesForDateRange
}) => {
  const periodStartDate = isLeftExpanded
    ? startDate
    : mapIsoStringtoUtcObject(allocationEndDate).plus({ days: 1 });

  const periodEndDate = isLeftExpanded
    ? mapIsoStringtoUtcObject(allocationStartDate).minus({ days: 1 })
    : endDate;

  const {
    overlappingDateRange,
    nonOverlappingDateRange
  } = getDateRangesOverlappingAndNonOverlappingWithResourceAllocation({
    periodStartDate,
    periodEndDate,
    resourceAllocationScheduleRules,
    canExtendTaskAllocationBeyondProjectAllocation
  });

  const {
    availabilityHoursSeriesByDay: periodsNonOverlappingResourceAllocationDateRange = []
  } = nonOverlappingDateRange
    ? await getResourceUserAvailabilityHoursSeriesForDateRange(user.uri, {
        startDate: nonOverlappingDateRange.rangeStart,
        endDate: nonOverlappingDateRange.rangeEnd
      })
    : {};

  const {
    projectRemainingHoursPerDay: periodsOverlappingResourceAllocationDateRange = []
  } = overlappingDateRange
    ? getProjectRemainingHoursPerDay({
        startDate: overlappingDateRange.rangeStart,
        endDate: overlappingDateRange.rangeEnd,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules:
          userTaskAllocationsSummaryScheduleRules || []
      })
    : {};

  const allocationPeriods = !canExtendTaskAllocationBeyondProjectAllocation
    ? periodsOverlappingResourceAllocationDateRange
    : getCombinedPeriods(
        periodsOverlappingResourceAllocationDateRange,
        periodsNonOverlappingResourceAllocationDateRange
      );

  const extendedPeriodScheduleRules = getScheduleRulesFromAllocationPeriods(
    allocationPeriods
  ).filter(
    rule =>
      canExtendTaskAllocationBeyondProjectAllocation || rule.do.setHours > 0
  );

  return {
    extendedPeriodScheduleRules,
    taskAllocationScheduleRules: isLeftExpanded
      ? [...extendedPeriodScheduleRules, ...scheduleRules]
      : [...scheduleRules, ...extendedPeriodScheduleRules]
  };
};

export const calculateCollapsedTaskAllocationScheduleRules = ({
  startDate,
  endDate,
  scheduleRules
}) => {
  const start = mapRepliconDateToUtcObject(startDate);
  const end = mapRepliconDateToUtcObject(endDate);

  return {
    taskAllocationScheduleRules: flow(
      filter(hasOverlap({ start, end })),

      map(truncateRuleBoundaries({ start, end }))
    )(scheduleRules)
  };
};

export const getCollapsedPeriodDetails = ({
  dragStartDate,
  dragEndDate,
  allocationStartDateInUtc,
  allocationEndDateInUtc
}) => {
  const isLeftCollapsed = dragStartDate > allocationStartDateInUtc;

  return {
    periodStartDate: isLeftCollapsed
      ? allocationStartDateInUtc
      : dragEndDate.plus({ days: 1 }),
    periodEndDate: isLeftCollapsed
      ? dragStartDate.minus({ days: 1 })
      : allocationEndDateInUtc,
    newPeriodTaskAllocatedHours: 0
  };
};

export const getTaskAllocationModificationDetailsOnDrag = async ({
  startDate: dragStartDate,
  endDate: dragEndDate,
  taskAllocation,
  resourceAllocationScheduleRules,
  userTaskAllocationsSummaryScheduleRules,
  canExtendTaskAllocationBeyondProjectAllocation,
  getResourceUserAvailabilityHoursSeriesForDateRange
}) => {
  const {
    startDate: allocationStartDate,
    endDate: allocationEndDate,
    scheduleRules
  } = taskAllocation;

  const allocationStartDateInUtc = mapIsoStringtoUtcObject(allocationStartDate);

  const allocationEndDateInUtc = mapIsoStringtoUtcObject(allocationEndDate);

  const isLeftExpanded = dragStartDate < allocationStartDateInUtc;

  const isRightExpanded = dragEndDate > allocationEndDateInUtc;

  const isExpanded = isRightExpanded || isLeftExpanded;

  const {
    taskAllocationScheduleRules,
    extendedPeriodScheduleRules
  } = isExpanded
    ? await calculateExtendedTaskAllocationScheduleRules({
        startDate: dragStartDate,
        endDate: dragEndDate,
        taskAllocation,
        isLeftExpanded,
        resourceAllocationScheduleRules,
        userTaskAllocationsSummaryScheduleRules,
        useTaskAllocationDragIndicators,
        canExtendTaskAllocationBeyondProjectAllocation,
        getResourceUserAvailabilityHoursSeriesForDateRange
      })
    : calculateCollapsedTaskAllocationScheduleRules({
        startDate: dragStartDate,
        endDate: dragEndDate,
        scheduleRules
      });

  return {
    taskAllocationScheduleRules,
    extendedPeriodScheduleRules,
    collapsedPeriodDetails: isExpanded
      ? null
      : getCollapsedPeriodDetails({
          dragStartDate,
          dragEndDate,
          allocationStartDateInUtc,
          allocationEndDateInUtc
        })
  };
};

export const taskAllocationUpdateHandler = onAllocationChange => ({
  entity: taskAllocation,
  estimatedDate
}) => {
  const { startDate, endDate } = estimatedDate;

  onAllocationChange({
    startDate,
    endDate,
    taskAllocation
  });
};

export const useTaskAllocationDragIndicators = ({
  taskAllocation,
  chartStartDate,
  scale,
  onAllocationChange,
  chartDisplayDateRange
}) => {
  const dragIndicatorsInChart = getDragIndicatorsInChart({
    chartDisplayDateRange,
    resourceAllocation: taskAllocation
  });

  const {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dates
  } = useResourcingDragIndicators({
    entity: taskAllocation,
    chartStartDate,
    scale,
    entityUpdateHandler: taskAllocationUpdateHandler(onAllocationChange)
  });

  return {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dragIndicatorsInChart,
    dates
  };
};

export default useTaskAllocationDragIndicators;
