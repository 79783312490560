import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from 'graphql-tag';

const parentTaskDetailsQuery = gql`
  query GetParentTaskDetails($parentTaskId: String!) {
    task(taskId: $parentTaskId) {
      id
      uri
      isClosed
      isClosedByTaskInheritance
    }
  }
`;

const useGetLazyParentTaskDetailsQuery = () => {
  const [
    fetchParentTaskDetails,
    { called, loading, data, error }
  ] = useLazyQuery(parentTaskDetailsQuery);

  const getParentTaskDetails = useCallback(
    parentTaskUri => {
      if (!parentTaskUri) return;

      fetchParentTaskDetails({
        variables: {
          parentTaskId: parentTaskUri
        }
      });
    },
    [fetchParentTaskDetails, called]
  );

  const task = data?.task;

  return {
    getParentTaskDetails,
    called,
    loading,
    task,
    error
  };
};

export default useGetLazyParentTaskDetailsQuery;
