import { gql } from 'graphql-tag';

export const GET_RESOURCE_ALLOCATION_ALLOCATED_PROJECTS = gql`
  query GetResourcePlanAllocatedUsers(
    $paginationToken: String
    $pageSize: Int!
    $isSortByProjectAscending: Boolean!
    $userId: String!
    $projectWorkflowStages: [ProjectWorkflowStage!]
  ) {
    resourceUsers2(page: 1, pagesize: 1, filter: { userIds: [$userId] }) {
      id
      resourceAllocationAllocatedProjects(
        pageSize: $pageSize
        isSortByProjectAscending: $isSortByProjectAscending
        paginationToken: $paginationToken
        projectWorkflowStages: $projectWorkflowStages
      ) {
        allocatedProjects {
          id
          project {
            id
            displayText
          }
          roles {
            resourceAllocationReference {
              id
              resourceRequestIds
            }
            role {
              displayText
              id
            }
          }
        }
        paginationToken
      }
    }
  }
`;

const mapAllocatedProjects = allocatedProjects =>
  allocatedProjects.map(project => ({
    ...project,
    roles: project.roles.map(role => ({
      ...role,
      role: role.role ? { ...role.role, id: role.role.id } : role.role
    }))
  }));

export const getResourceAllocationAllocatedProjects = async ({
  page,
  pageSize,
  userId,
  apolloClient,
  pageTokens,
  projectWorkflowStages,
  isSortByProjectAscending,
  setVariables
}) => {
  const paginationToken = pageTokens.current[page] || null;

  if (page !== 0 && !paginationToken) {
    return {
      pageTokens: pageTokens.current,
      allocatedProjects: []
    };
  }

  const variables = {
    userId,
    pageSize,
    projectWorkflowStages,
    isSortByProjectAscending
  };

  setVariables({
    ...variables,
    pageTokens
  });

  const { data } = await apolloClient.query({
    query: GET_RESOURCE_ALLOCATION_ALLOCATED_PROJECTS,
    variables: { ...variables, paginationToken },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const projectData =
    data?.resourceUsers2?.[0]?.resourceAllocationAllocatedProjects || {};

  return {
    pageTokens: {
      ...pageTokens.current,
      [page + 1]: projectData.paginationToken
    },
    allocatedProjects: mapAllocatedProjects(projectData.allocatedProjects || [])
  };
};
