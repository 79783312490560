import React from 'react';
import PlayIcon from '@material-ui/icons/PlayCircleFilledSharp';
import IconButton from '@material-ui/core/IconButton';
import { PropTypes } from 'prop-types';

const StartButton = ({ onClick }) => (
  <div>
    <IconButton aria-label="Start Task" onClick={onClick}>
      <PlayIcon />
    </IconButton>
  </div>
);

StartButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default StartButton;
