import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ImportExportGroup from '~/modules/common/components/ImportExportMenu';
import {
  EXPORT_OPTIONS,
  IMPORT_OPTIONS
} from '~/modules/common/components/ImportExportMenu/enums/ImportExportMenuEnums';
import { useMeContext } from '~/modules/me';
import { useProjectTasksFilter } from '~/modules/projects/tasks/hooks';

const { IMPORT, CSV_IMPORT } = IMPORT_OPTIONS;
const { EXPORT, CSV_EXPORT } = EXPORT_OPTIONS;

const csvExportColumnHeaderOverrides = {
  assignmentsWithoutUserCount: 'projectTasksPage.numberOfPlaceholders',
  assignmentsWithUserCount: 'projectTasksPage.numberOfResourcesAssigned',
  assignedRole: 'projectTasksPage.taskRole'
};

const getActiveColumns = ({
  isPsaRmpTaskAllocation1Enabled,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => [
  'name',
  'fullPath',
  ...(isPsaRmpTaskAllocation1Enabled
    ? ['assignmentsWithoutUserCount', 'assignmentsWithUserCount']
    : []),
  'assignedUser',
  'assignedRole',
  'progressStatus',
  'startDate',
  'endDate',
  'actualStartDate',
  'estimatedCompletionDate',
  'actualCompletionDate',
  'estimatedHours',
  ...(isRolledUpTaskEstimateCalculationMethodEnabled
    ? [
        'rolledUpActualHours',
        'rolledUpEstimatedRemainingHours',
        'rolledUpEstimatedHoursAtCompletion'
      ]
    : ['actualHours', 'hoursRemaining', 'estimatedHoursAtCompletion'])
];

export const ProjectTasksImportExportMenu = ({
  projectId,
  includeClosedTasks
}) => {
  const { formatMessage } = useIntl();
  const {
    uiRootPath,
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: { isPsaRmpTaskAllocation1Enabled },
    permissionsMap
  } = useMeContext();
  const editImportPermission = Boolean(
    permissionsMap['urn:replicon-webui:project-data-import:edit-import']
  );

  const editTaskPermission = Boolean(
    permissionsMap['urn:replicon:project-action:edit-tasks']
  );

  const textSearch = useProjectTasksFilter();

  const actions = useMemo(
    () =>
      [
        editImportPermission &&
          editTaskPermission && {
            label: IMPORT,
            actionItems: [
              {
                label: CSV_IMPORT,
                href: `${uiRootPath}admin/data-import/task`
              }
            ]
          },
        {
          label: EXPORT,
          actionItems: [
            {
              label: CSV_EXPORT,
              tableKey: 'psa-project-tasks',
              activeColumns: getActiveColumns({
                isPsaRmpTaskAllocation1Enabled,
                isRolledUpTaskEstimateCalculationMethodEnabled
              }),
              activeColumnNameOverrides: isPsaRmpTaskAllocation1Enabled
                ? Object.keys(csvExportColumnHeaderOverrides).map(c => ({
                    column: c,
                    name: formatMessage({
                      id: csvExportColumnHeaderOverrides[c]
                    })
                  }))
                : undefined,
              filter: {
                taskFilter: {
                  textSearch,
                  isOpen: includeClosedTasks ? undefined : true,
                  projectUri: projectId
                }
              }
            }
          ]
        }
      ].filter(action => action),
    [
      editImportPermission,
      editTaskPermission,
      formatMessage,
      includeClosedTasks,
      isPsaRmpTaskAllocation1Enabled,
      isRolledUpTaskEstimateCalculationMethodEnabled,
      projectId,
      textSearch,
      uiRootPath
    ]
  );

  return (
    <ImportExportGroup
      actions={actions}
      isMobile={false}
      menuId="project-tasks-import-export-menu"
    />
  );
};

ProjectTasksImportExportMenu.propTypes = {
  projectId: PropTypes.string.isRequired,
  includeClosedTasks: PropTypes.bool
};

export default ProjectTasksImportExportMenu;
