import { useReducer, useCallback, useEffect } from 'react';
import {
  useKeyValueSettingsMutation,
  updateCache,
  useGetKeyValueSettings
} from '~/modules/common/hooks';
import { SortDirection } from '~/types';
import { tabs } from '../ResourceUsersDropdownTabs';
import dropdownSettingsReducer, {
  UPDATE_SELECTED_TAB,
  UPDATE_SORT,
  UPDATE_SETTINGS
} from './dropdownSettingsReducer';

export const RESOURCE_USERS_DROPDOWN_SETTINGS =
  'resource_users_dropdown_settings';

export const sortableFields = {
  availableDuration: 'availableDuration',
  displayText: 'displayText'
};

export const getSortValueBasedOnAvailabiltyColumn = ({
  isAvailabilityColumnEnabled
}) =>
  isAvailabilityColumnEnabled
    ? {
        field: sortableFields.availableDuration,
        direction: SortDirection.Desc
      }
    : {
        field: sortableFields.displayText,
        direction: SortDirection.Asc
      };

export const useDropdownSettings = ({ initialAvailabilityColumnValue }) => {
  const { keyValueSettings: dropdownSettings } = useGetKeyValueSettings(
    RESOURCE_USERS_DROPDOWN_SETTINGS,
    !initialAvailabilityColumnValue
  );

  const [state, dispatch] = useReducer(dropdownSettingsReducer, {
    isAvailabilityColumnEnabled: null,
    sort: null,
    selectedTab: tabs.PROJECT_RESOURCES
  });

  const { isAvailabilityColumnEnabled, sort, selectedTab } = state;

  useEffect(() => {
    const isAvailabilityColumnVisible =
      initialAvailabilityColumnValue &&
      Boolean(dropdownSettings?.availabilityColumnVisibility);

    dispatch({
      type: UPDATE_SETTINGS,
      payload: {
        isAvailabilityColumnEnabled: isAvailabilityColumnVisible,
        sort: getSortValueBasedOnAvailabiltyColumn({
          isAvailabilityColumnEnabled: isAvailabilityColumnVisible
        }),
        selectedTab: dropdownSettings?.currentTab || tabs.PROJECT_RESOURCES
      }
    });
  }, [
    dropdownSettings?.availabilityColumnVisibility,
    dropdownSettings?.currentTab,
    initialAvailabilityColumnValue
  ]);

  const [putKeyValueSettings] = useKeyValueSettingsMutation(
    updateCache(RESOURCE_USERS_DROPDOWN_SETTINGS)
  );

  const onSortChange = useCallback(
    field =>
      dispatch({
        type: UPDATE_SORT,
        value: {
          field,
          direction:
            sort.field === field && sort.direction === SortDirection.Asc
              ? SortDirection.Desc
              : SortDirection.Asc
        }
      }),
    [sort]
  );

  const updateKeyValue = useCallback(
    ({ newTabValue, newAvailabilityColumnVisibility }) => {
      putKeyValueSettings({
        variables: {
          input: {
            key: RESOURCE_USERS_DROPDOWN_SETTINGS,
            settings: {
              availabilityColumnVisibility:
                newAvailabilityColumnVisibility || isAvailabilityColumnEnabled,
              currentTab: newTabValue || selectedTab
            }
          }
        }
      });
    },
    [isAvailabilityColumnEnabled, putKeyValueSettings, selectedTab]
  );

  const toggleAvailabilityColumnVisibility = useCallback(() => {
    const newValue = Boolean(!isAvailabilityColumnEnabled);

    updateKeyValue({ newAvailabilityColumnVisibility: newValue });

    dispatch({
      type: UPDATE_SETTINGS,
      payload: {
        sort: getSortValueBasedOnAvailabiltyColumn({
          isAvailabilityColumnEnabled: newValue
        }),
        isAvailabilityColumnEnabled: newValue
      }
    });
  }, [isAvailabilityColumnEnabled, updateKeyValue]);

  const handleTabChange = useCallback(
    (e, newValue) => {
      updateKeyValue({ newTabValue: newValue });

      dispatch({ type: UPDATE_SELECTED_TAB, value: newValue });

      e.stopPropagation();
    },
    [updateKeyValue]
  );

  return {
    isAvailabilityColumnEnabled,
    sort,
    selectedTab,
    toggleAvailabilityColumnVisibility,
    onSortChange,
    handleTabChange
  };
};
