import React, { useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  InputBase,
  makeStyles
} from '@material-ui/core';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useIntl } from 'react-intl';
import { NO_CHANGE_OPTION_ID, NO_VALUE_OPTION_ID } from '../../hooks';
import { NoneDropdownItem } from '../SearchAutocomplete';
import { isValidValue, useOptionsMerge } from './hooks';

// Styles overrides are required
// Dependent on https://github.com/mui-org/material-ui/issues/21409

// Need labelWidth for providing width for label when variant is outlined in Select Material-UI component
// https://stackoverflow.com/a/68552713

const useStyles = makeStyles({
  hiddenLabelInput: {
    '&>input.MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
      padding: '10px 12px 11px !important'
    }
  }
});

const inputLabelStyle = { whiteSpace: 'nowrap' };

export const SimpleDropdown = ({
  classes,
  ariaLabel,
  value,
  onChange,
  options,
  label,
  dataQeId,
  disableSort = false,
  noneOption = true,
  editable = true,
  fullWidth = true,
  selectProps = {},
  inputLabelProps = {},
  variant = 'standard',
  labelWidth,
  margin,
  hiddenLabel,
  disabled,
  required,
  formControlMargin,
  additionalOptions
}) => {
  const hiddenLabelClasses = useStyles();
  const { formatMessage } = useIntl();
  const processedOptions = useOptionsMerge({
    options,
    disableSort,
    value,
    additionalOptions
  });

  const menuProps = useMemo(
    () => ({ MenuListProps: { 'aria-label': ariaLabel } }),
    [ariaLabel]
  );

  const selectPropsInput = {
    ...selectProps,
    className: hiddenLabel
      ? classNames(selectProps.className, hiddenLabelClasses.hiddenLabelInput)
      : selectProps.className
  };

  const noneLabel = formatMessage({ id: 'simpleDropdown.none' });

  const inputBaseStyle = useMemo(
    () => ({
      marginTop: label ? '12px' : '0',
      width: fullWidth
        ? '100%'
        : `${getDisplayText(value, processedOptions, noneLabel).length}em`
    }),
    [fullWidth, noneLabel, label, processedOptions, value]
  );

  return editable ? (
    <FormControl
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      required={required}
      margin={formControlMargin}
    >
      <InputLabel variant={variant} margin={margin} {...inputLabelProps}>
        {label}
      </InputLabel>
      <Select
        MenuProps={menuProps}
        variant={variant}
        label={label}
        labelWidth={labelWidth}
        margin={margin}
        hiddenLabel={hiddenLabel}
        data-qe-id={dataQeId}
        fullWidth={fullWidth}
        value={isValidValue(value) ? value.id : 'none'}
        onChange={handleChange(processedOptions, onChange)}
        {...selectPropsInput}
      >
        {noneOption && <MenuItem value="none">{noneLabel}</MenuItem>}
        {processedOptions &&
          processedOptions.map(
            ({ id, displayText, component, disabled: isDisabled }) => {
              if (id === NO_CHANGE_OPTION_ID || id === NO_VALUE_OPTION_ID)
                return (
                  <MenuItem value={id} key={id}>
                    <NoneDropdownItem value={displayText} />{' '}
                  </MenuItem>
                );

              return (
                <MenuItem
                  classes={{ root: isDisabled && classes.group }}
                  value={id}
                  key={id}
                  disabled={isDisabled}
                >
                  {component || displayText}
                </MenuItem>
              );
            }
          )}
      </Select>
    </FormControl>
  ) : (
    <FormControl>
      {label && (
        <InputLabel style={inputLabelStyle} shrink htmlFor={label}>
          {label}
        </InputLabel>
      )}
      <InputBase
        id={label}
        style={inputBaseStyle}
        value={getDisplayText(value, options, noneLabel)}
        fullWidth={fullWidth}
        readOnly
      />
    </FormControl>
  );
};

const handleChange = (options, onChange) => (_, { props }) =>
  onChange({
    displayText:
      props.value !== 'none' && options
        ? options.find(option => option.id === props.value).displayText
        : 'None',
    id: props.value === 'none' ? null : props.value
  });

export const getDisplayText = (value, options, noneLabel) =>
  value && value !== 'none' && options
    ? (
        options.find(option => option.id === value.id) || {
          displayText: noneLabel
        }
      ).displayText
    : noneLabel;

SimpleDropdown.propTypes = {
  classes: PropTypes.object,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired, // onChange will trigger with ({displayText, id}) => ...
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dataQeId: PropTypes.string,
  noneOption: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.shape({
    id: PropTypes.string,
    displayText: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayText: PropTypes.string.isRequired,
      component: PropTypes.node
    }).isRequired
  ),
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayText: PropTypes.string.isRequired,
      component: PropTypes.node
    })
  ),
  editable: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  noneLabel: PropTypes.string,
  margin: PropTypes.string,
  formControlMargin: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  disableSort: PropTypes.bool,
  required: PropTypes.bool,
  selectProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  labelWidth: PropTypes.number
};

export default SimpleDropdown;
