import { Divider, makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import { NoDataItem } from '~/modules/common/components';
import { ShowCommentsOnInvoiceCheckBox } from '~/modules/billing-invoicing/common/components';
import { useMeContext } from '~/modules/me';
import {
  getBillingPlanFrequencyDisplayText,
  BILLING_PLAN_FREQUENCY_ENUM
} from '~/modules/projects/project/common/enums/billingPlanFrequency';
import {
  getLuxonJsDateFormatFromMe,
  isoStringToObject
} from '~/modules/common/dates/convert';
import { BillingFrequency } from '~/types';
import { getDayOfMonth, getDayOfWeek } from './util';
import { getOptions } from './const';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0, 2, 0)
  },
  container: {
    display: 'flex'
  },
  subtitleValue: {
    color: theme.palette.text.primary,
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  },
  margin: {
    margin: theme.spacing(1)
  },
  paymentTerm: {
    margin: theme.spacing(1, 0, 1, 0),
    paddingLeft: theme.spacing(1)
  },
  billFrequency: {
    margin: theme.spacing(1, 0, 1, 0),
    paddingLeft: theme.spacing(1)
  },
  showCommentsLabel: {
    marginLeft: 0,
    marginRight: 0
  },
  title: {
    color: theme.palette.text.secondary
  },
  noDataRoot: {
    '& span': {
      hyphens: 'unset'
    }
  },
  checkIcon: {
    marginRight: theme.spacing(0.5),
    verticalAlign: 'bottom'
  }
}));

const BillingCardReadOnly = ({ billingCardResourceKeys }) => {
  const { formatMessage } = useIntl();

  const me = useMeContext();
  const { isPsaPrpAddProjectColumnOnAvailableToBillEnabled } = me.featureFlags;
  const classes = useStyles();
  const noDataItemClasses = useMemo(
    () => ({
      root: classes.noDataRoot
    }),
    [classes.noDataRoot]
  );
  const { values } = useFormikContext();
  const luxonFormat = useMemo(() => getLuxonJsDateFormatFromMe(me), [me]);
  const {
    defaultInvoicePaymentTerm,
    billLineItemsBy,
    invoiceCurrency,
    taxProfile,
    invoiceTemplate,
    description,
    internalNotes,
    showComments,
    poNumber,
    context,
    consolidateBillsBy,
    billFrequency,
    billFrequencyAbsoluteDate
  } = values;
  const formattedBillLineItemsBy = billLineItemsBy
    .map(columnOption => {
      return `${formatMessage({
        id: `billLineItemOptions.${columnOption}`
      })}`;
    })
    .join(', ');

  const titleField = (variant, resourceId) => {
    return (
      <Typography variant={variant} className={classes.title}>
        <FormattedMessage id={resourceId} />
      </Typography>
    );
  };
  const cardSubtitleFieldValue = text => {
    return (
      <Typography variant="body2" className={classes.subtitleValue}>
        {text}
      </Typography>
    );
  };

  const cardSubtitleFieldNoValue = resourceId => {
    return (
      <div>
        <NoDataItem classes={noDataItemClasses}>
          <FormattedMessage id={resourceId} />
        </NoDataItem>
      </div>
    );
  };

  const showConsolidateBillsBy =
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled && consolidateBillsBy;

  const showBillFrequency =
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled && context !== 'project';

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.margin}>
          {titleField('body1', billingCardResourceKeys.schedule)}
        </Grid>
        {showBillFrequency ? (
          <Grid item xs={12} md={8} className={classes.billFrequency}>
            {titleField('caption', billingCardResourceKeys.billFrequency)}
            {billFrequency ? (
              <Typography className={classes.column} variant="body2">
                {getBillingPlanFrequencyDisplayText(
                  {
                    frequency: BILLING_PLAN_FREQUENCY_ENUM[billFrequency],
                    dayOfMonth:
                      billFrequency === BillingFrequency.Monthly ||
                      billFrequency === BillingFrequency.Semimonthly
                        ? getDayOfMonth(billFrequencyAbsoluteDate)
                        : null,
                    dayOfWeek:
                      billFrequency === BillingFrequency.Weekly
                        ? getDayOfWeek(billFrequencyAbsoluteDate)
                        : null,
                    relativeDate: isoStringToObject(billFrequencyAbsoluteDate)
                  },
                  luxonFormat
                )}
              </Typography>
            ) : null}
          </Grid>
        ) : null}
        <Grid
          item
          xs={showBillFrequency ? 12 : 4}
          md={4}
          className={classes.paymentTerm}
          data-qe-id="BillingSettings_PaymentTerm"
        >
          {titleField('caption', billingCardResourceKeys.paymentTerm)}
          {cardSubtitleFieldValue(defaultInvoicePaymentTerm)}
        </Grid>
        {!isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
          <Grid item xs={8}>
            <ShowCommentsOnInvoiceCheckBox
              showCommentsLabel={billingCardResourceKeys.showComments}
              showComments={showComments}
              isReadOnly
              dataQeId="ReadOnlyShowCommentsOnInvoice"
              labelClassName={classes.showCommentsLabel}
            />
          </Grid>
        ) : null}
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.margin}>
          {titleField('body1', billingCardResourceKeys.structure)}
        </Grid>
        {showConsolidateBillsBy ? (
          <Grid item xs={6} className={classes.margin}>
            {titleField('caption', billingCardResourceKeys.consolidateBillsBy)}
            {cardSubtitleFieldValue(
              getOptions(formatMessage).find(o => o.id === consolidateBillsBy)
                ?.displayText
            )}
          </Grid>
        ) : null}
        <Grid
          item
          xs
          className={classes.margin}
          data-qe-id="BillingSettings_BillLineItemsBy"
        >
          {titleField('caption', billingCardResourceKeys.billLineItemsBy)}
          <Box width="100%" />
          {formattedBillLineItemsBy}
        </Grid>
        {isPsaPrpAddProjectColumnOnAvailableToBillEnabled && showComments ? (
          <Grid item xs={12} className={classes.margin}>
            <CheckSharpIcon fontSize="small" className={classes.checkIcon} />
            <Typography
              variant="body2"
              component="span"
              className={classes.subtitleValue}
            >
              <FormattedMessage id={billingCardResourceKeys.showComments} />
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.margin}>
          {titleField('body1', billingCardResourceKeys.defaults)}
        </Grid>
        <Grid
          item
          xs
          className={classes.margin}
          data-qe-id="BillingSettings_BillingCurrency"
        >
          {titleField('caption', billingCardResourceKeys.billingCurrency)}
          {cardSubtitleFieldValue(invoiceCurrency?.symbol)}
        </Grid>
        <Grid
          item
          xs
          className={classes.margin}
          data-qe-id="BillingSettings_TaxProfile"
          zeroMinWidth
        >
          {titleField('caption', billingCardResourceKeys.taxProfile)}
          {taxProfile
            ? cardSubtitleFieldValue(taxProfile?.displayText)
            : cardSubtitleFieldNoValue(billingCardResourceKeys.noTaxProfile)}
        </Grid>
        {context === 'project' ? (
          <Grid
            item
            xs
            className={classes.margin}
            zeroMinWidth
            data-qe-id="BillingSettings_PONumber"
          >
            {titleField('caption', billingCardResourceKeys.poNumber)}
            {cardSubtitleFieldValue(poNumber)}
          </Grid>
        ) : (
          <Grid item xs className={classes.margin}></Grid>
        )}
        <Box width="100%" />
        <Grid
          item
          xs
          className={classes.margin}
          data-qe-id="BillingSettings_InvoiceTemplate"
          zeroMinWidth
        >
          {titleField('caption', billingCardResourceKeys.invoiceTemplate)}
          {invoiceTemplate
            ? cardSubtitleFieldValue(invoiceTemplate?.displayText)
            : cardSubtitleFieldNoValue(
                billingCardResourceKeys.noInvoiceTemplate
              )}
        </Grid>
        <Grid
          item
          xs
          className={classes.margin}
          zeroMinWidth
          data-qe-id="BillingSettings_Description"
        >
          {titleField('caption', billingCardResourceKeys.description)}
          {cardSubtitleFieldValue(description)}
        </Grid>
        <Grid
          item
          xs
          className={classes.margin}
          zeroMinWidth
          data-qe-id="BillingSettings_InternalNotes"
        >
          {titleField('caption', billingCardResourceKeys.internalNotes)}
          {cardSubtitleFieldValue(internalNotes)}
        </Grid>
      </Grid>
    </div>
  );
};

BillingCardReadOnly.propTypes = {
  billingCardResourceKeys: PropTypes.any
};

export default BillingCardReadOnly;
