import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { get } from 'lodash';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';

export const projectSummaryQuery = gql`
  query ProjectOverviewSummary(
    $projectId: String!
    $dateRange: DateTimeRangeInput
    $includeBillableRevenue: Boolean!
    $includeBilledRevenue: Boolean!
  ) {
    project(projectId: $projectId) {
      id
      costAmountSummary(dateRange: $dateRange) {
        costActual {
          amount
          currency {
            id
            uri
            displayText
            name
            symbol
          }
        }
      }
      revenueAmountSummary(dateRange: $dateRange)
        @include(if: $includeBillableRevenue) {
        revenueActual {
          amount
          currency {
            id
            uri
            displayText
            name
            symbol
          }
        }
      }
      billedRevenueAmountSummary(dateRange: $dateRange)
        @include(if: $includeBilledRevenue) {
        revenueActual {
          amount
          currency {
            id
            uri
            displayText
            name
            symbol
          }
        }
      }
      timeEnteredSummary(dateRange: $dateRange) {
        timeEnteredActual
      }
    }
  }
`;

export const useProjectOverviewSummary = ({
  projectId,
  dateRange,
  includeBilledRevenue = false,
  skip = false,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const { data, loading } = useQuery(projectSummaryQuery, {
    variables: {
      projectId,
      dateRange: dateRange
        ? {
            startDate: dateToMidnightUTCString(dateRange.startDate),
            endDate: dateToMidnightUTCString(dateRange.endDate)
          }
        : isRolledUpTaskEstimateCalculationMethodEnabled && dateRange == null
        ? {
            startDate: null,
            endDate: null
          }
        : null,
      includeBillableRevenue: !includeBilledRevenue,
      includeBilledRevenue,
      skip
    },
    fetchPolicy: 'cache-and-network'
  });

  const project = get(data, 'project') || {};

  const hasBillableProfitAmount =
    get(project, 'revenueAmountSummary.revenueActual.amount', null) !== null &&
    get(project, 'costAmountSummary.costActual.amount', null) !== null;
  const hasBilledProfitAmount =
    get(project, 'billedRevenueAmountSummary.revenueActual.amount', null) !==
      null &&
    get(project, 'costAmountSummary.costActual.amount', null) !== null;

  return {
    costAmountSummary: project.costAmountSummary,
    revenueAmountSummary: project.revenueAmountSummary,
    billedRevenueAmountSummary: project.billedRevenueAmountSummary,
    profitAmountSummary: {
      profitActual: hasBillableProfitAmount
        ? {
            amount:
              project.revenueAmountSummary.revenueActual.amount -
              project.costAmountSummary.costActual.amount,
            currency: project.costAmountSummary.costActual.currency
          }
        : hasBilledProfitAmount
        ? {
            amount:
              project.billedRevenueAmountSummary.revenueActual.amount -
              project.costAmountSummary.costActual.amount,
            currency: project.costAmountSummary.costActual.currency
          }
        : null
    },
    timeEnteredSummary: project.timeEnteredSummary,
    loading
  };
};

export default useProjectOverviewSummary;
