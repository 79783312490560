import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDialogState } from '~/modules/common/hooks';
import useBulkReleaseResourceAllocations from '~/modules/resourcing/common/hooks/useBulkReleaseResourceAllocations';
import { useSelectedResourceRequestContext } from '~/modules/projects/resourcing-plan/hooks';

const useResourceAllocationChartUserRowActions = ({
  resourceRequestIds,
  userId,
  handleRemoveResourceAllocation,
  showReleaseResourceAction,
  resourcePlanAllocatedUsersAndRolesCacheVariables,
  allocatedUserCacheUpdateProps,
  resourceRequestCacheUpdateProps,
  userName,
  setSnackBarState,
  showChangeResourceAction
}) => {
  const { formatMessage } = useIntl();

  const {
    open: showViewMultipleResourceRequestsDialog,
    openDialog: onViewMultipleResourceRequestsDialogOpen,
    closeDialog: onViewMultipleResourceRequestsDialogClose
  } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const { openResourceRequestDrawer } = useSelectedResourceRequestContext();

  const {
    open: showReleaseResourcesDialog,
    openDialog: openReleaseResourcesDialog,
    closeDialog: closeReleaseResourcesDialog
  } = useDialogState(false, true);

  const {
    open: showChangeResourceDialog,
    openDialog: openChangeResourceDialog,
    closeDialog: closeChangeResourceDialog
  } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const viewResourceRequestClickHandler = useCallback(() => {
    if (resourceRequestIds.length === 1)
      openResourceRequestDrawer({ resourceRequestId: resourceRequestIds[0] });
    else onViewMultipleResourceRequestsDialogOpen();
  }, [
    openResourceRequestDrawer,
    resourceRequestIds,
    onViewMultipleResourceRequestsDialogOpen
  ]);

  const {
    releaseResourceAllocation,
    loading: releaseAllocationLoading
  } = useBulkReleaseResourceAllocations({
    handleRemoveResourceAllocation,
    userId,
    resourcePlanAllocatedUsersAndRolesCacheVariables,
    allocatedUserCacheUpdateProps,
    resourceRequestCacheUpdateProps,
    userName,
    setSnackBarState,
    sendNotification: true
  });

  const actions = [
    ...(resourceRequestIds.length
      ? [
          {
            label: formatMessage({
              id: 'resourceRequestActions.viewResourceRequest'
            }),
            onClick: viewResourceRequestClickHandler
          }
        ]
      : []),
    ...(showChangeResourceAction
      ? [
          {
            label: formatMessage({
              id: 'resourceRequestActions.changeResource'
            }),
            onClick: openChangeResourceDialog
          }
        ]
      : []),
    ...(showReleaseResourceAction
      ? [
          {
            label: formatMessage({
              id: 'resourceRequestActions.releaseResource'
            }),
            onClick: openReleaseResourcesDialog
          }
        ]
      : [])
  ];

  return {
    actions,
    showViewMultipleResourceRequestsDialog,
    onViewMultipleResourceRequestsDialogOpen,
    onViewMultipleResourceRequestsDialogClose,
    releaseAllocationLoading,
    releaseResourceAllocation,
    showReleaseResourcesDialog,
    closeReleaseResourcesDialog,
    showChangeResourceDialog,
    closeChangeResourceDialog
  };
};

export default useResourceAllocationChartUserRowActions;
