import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { dateToMidnightUTCObject } from '~/modules/common/dates/convert';

export const USER_ASSIGNED_IMPACTED_TASK_SUMMARY_QUERY = gql`
  query GetImpactedTaskDetailsSummary(
    $projectId: String!
    $asOfDate: Date2
    $filter: GetResourceUsersFilter!
    $includeMaxTaskEndDate: Boolean!
  ) {
    resourceUsers2(filter: $filter) {
      id
      displayText
      impactedTaskSummary(projectId: $projectId, asOfDate: $asOfDate) {
        taskUris
        totalHoursRemainingAsOfDate
        maxTaskEndDate @include(if: $includeMaxTaskEndDate)
      }
    }
  }
`;

const useGetUsersAssignedImpactedTaskSummary = ({
  includeMaxTaskEndDate = false,
  projectId,
  userIds,
  asOfDate,
  skip = false
}) => {
  const { data, loading } = useQuery(
    USER_ASSIGNED_IMPACTED_TASK_SUMMARY_QUERY,
    {
      variables: {
        projectId,
        filter: {
          userIds
        },
        asOfDate: asOfDate
          ? dateToMidnightUTCObject(asOfDate)
              .plus({ days: 1 })
              .toISO()
          : null,
        includeMaxTaskEndDate
      },
      skip,
      fetchPolicy: 'network-only'
    }
  );

  const resourceUsers = get(data, 'resourceUsers2', []);

  return {
    usersAssignedImpactedTaskSummary: resourceUsers,
    isLoading: loading
  };
};

export default useGetUsersAssignedImpactedTaskSummary;
