import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { isoStringToObject } from '~/modules/common/dates/convert';
import { ResourceRequestAccessLevel } from '~/types';

export const resourceAllocationSeriesDataQuery = gql`
  query Eager_ResourceAllocationSeriesData(
    $dateRange: DateRangeInput!
    $periodResolution: PeriodResolutionOption!
    $filter: ResourceAllocationSeriesFilter
    $includeToBeHiredAndRequested: Boolean!
    $requestAccessLevel: ResourceRequestAccessLevel
  ) {
    resourceAllocationSeriesData(
      dateRange: $dateRange
      periodResolution: $periodResolution
      filter: $filter
      requestAccessLevel: $requestAccessLevel
    ) {
      timeoff
      holiday
      totalSchedule
      allocated
      overAllocated
      requested @include(if: $includeToBeHiredAndRequested)
      toBeHired @include(if: $includeToBeHiredAndRequested)
      dateRange {
        startDate: startDate2
        endDate: endDate2
      }
    }
  }
`;

export const calculateTotalSummaryHours = (
  resourceAllocationSeriesData,
  resourceAllocatedHours
) => {
  const totalSummary = (resourceAllocationSeriesData &&
    resourceAllocationSeriesData.reduce(
      (acc, curr) => {
        acc.timeOffHours += curr.timeoff;
        acc.scheduledHours += curr.totalSchedule;
        acc.holidayHours += curr.holiday;
        acc.totalAllocatedHours += curr.allocated + curr.overAllocated;

        return acc;
      },
      {
        timeOffHours: 0,
        scheduledHours: 0,
        holidayHours: 0,
        totalAllocatedHours: 0
      }
    )) || {
    timeOffHours: 0,
    scheduledHours: 0,
    holidayHours: 0,
    totalAllocatedHours: 0
  };

  const otherProjectAllocationHours =
    totalSummary.totalAllocatedHours - resourceAllocatedHours;

  const workHours =
    totalSummary.scheduledHours -
    totalSummary.timeOffHours -
    totalSummary.holidayHours;

  return {
    otherProjectAllocationHours,
    workHours
  };
};

const useResourceAllocationSeriesData = ({
  dateRange,
  periodResolution,
  fetchPolicy = 'network-only',
  includeToBeHiredAndRequested = true,
  filter,
  skip = false
}) => {
  const { data, loading, refetch } = useQuery(
    resourceAllocationSeriesDataQuery,
    {
      variables: {
        dateRange: {
          startDate: dateRange.startDate.toISODate(),
          endDate: dateRange.endDate.toISODate()
        },
        periodResolution,
        filter,
        includeToBeHiredAndRequested,
        requestAccessLevel: ResourceRequestAccessLevel.ResourceManager
      },
      fetchPolicy,
      skip
    }
  );

  const resourceAllocationSeriesData = data?.resourceAllocationSeriesData?.map(
    seriesData => ({
      ...seriesData,
      dateRange:
        seriesData?.dateRange?.startDate && seriesData?.dateRange?.endDate
          ? {
              /** Need to convert back to JS object. Check for string or object type due to
               * client cache updating startDate, endDate with JS object */
              startDate:
                typeof seriesData.dateRange.startDate === 'string'
                  ? isoStringToObject(seriesData.dateRange.startDate)
                  : seriesData.dateRange.startDate,
              endDate:
                typeof seriesData.dateRange.endDate === 'string'
                  ? isoStringToObject(seriesData.dateRange.endDate)
                  : seriesData.dateRange.endDate
            }
          : null
    })
  );

  // Remove Pending and toBeHired with userId/Resource search filter
  const filteredResourceAllocationSeriesData =
    resourceAllocationSeriesData && filter?.userIds?.length > 0
      ? resourceAllocationSeriesData.map(seriesData => ({
          ...seriesData,
          requested: 0,
          toBeHired: 0
        }))
      : resourceAllocationSeriesData;

  return {
    loading,
    refetch,
    resourceAllocationSeriesData: filteredResourceAllocationSeriesData
  };
};

export default useResourceAllocationSeriesData;
