import { makeStyles, alpha } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ChartTotalItems } from '~/modules/common/charts/timeline/components';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { FormattedHoursAndCost } from '~/modules/resourcing/common/components';
import {
  ResourceChartTotalPopper,
  usePopperHandlers
} from '~/modules/resourcing/components/ResourceChartTotalPopper';
import {
  getUserCostRateDetails,
  useResourceAllocationCost
} from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';

const useTotalStyles = makeStyles(theme => ({
  hours: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  },
  cost: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  currency: {
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    height: ({ allocationRoleCount }) =>
      theme.spacing(allocationRoleCount * 3 + (allocationRoleCount + 1)),
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.1)
    }
  },
  rowContainer: {
    padding: theme.spacing(0, 0.5, 0, 0)
  }
}));

export const UserRoleRowTotal = ({ allocationsForUserRole, canViewCost }) => {
  const { isResourceActualModeEnabled } = useResourceRequestToolbarContext();

  const totalClasses = useTotalStyles();
  const classes = useStyles({
    allocationRoleCount:
      (allocationsForUserRole?.length || 0) +
      (isResourceActualModeEnabled ? 1 : 0)
  });

  const {
    anchorEl,
    onMenuClose,
    onMenuClick,
    handleKeyDown
  } = usePopperHandlers();

  const totalHours = (allocationsForUserRole || []).reduce(
    (acc, { totalHours: allocationTotalHours }) =>
      acc + (allocationTotalHours || 0),
    0
  );

  const { allocationCost } = useResourceAllocationCost({
    totalHours,
    userCostRateDetails: getUserCostRateDetails({
      resourceAllocation: allocationsForUserRole?.[0]
    })
  });

  const total = useMemo(
    () => (
      <FormattedHoursAndCost
        classes={totalClasses}
        hours={totalHours}
        costs={allocationCost}
        hoursSuffix="h"
        hideCosts={!canViewCost}
        showAbbreviatedValue={false}
      />
    ),
    [totalClasses, totalHours, allocationCost, canViewCost]
  );

  const popperContent = useMemo(
    () => (
      <ResourceChartTotalPopper
        id="resource-allocation-popper"
        anchorEl={anchorEl}
        onClose={onMenuClose}
        allocatedHours={totalHours}
        allocatedCost={allocationCost[0]}
        viewCosts={canViewCost}
        viewAllocation
        viewDifference={false}
      />
    ),
    [anchorEl, onMenuClose, totalHours, allocationCost, canViewCost]
  );

  return (
    <ChartTotalItems
      classes={classes}
      allocated={total}
      showCost={canViewCost}
      popperContent={popperContent}
      handleClick={onMenuClick}
      handleKeyDown={handleKeyDown}
      onClickAway={onMenuClose}
    />
  );
};

UserRoleRowTotal.propTypes = {
  allocationsForUserRole: PropTypes.array,
  canViewCost: PropTypes.bool
};
