import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { deepPure } from '~/util';
import { RequestActions } from '~/modules/resourcing/common/components/RequestActions/RequestActions';
import { useTaskAssignmentRowActions } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';
import {
  RemoveTaskAssignmentConfirmationDialog,
  RemoveTaskAllocationConfirmationDialog,
  ChangeTaskResourceConfirmationDialog
} from '~/modules/projects/resourcing-plan/components';
import { useRefetchQueriesOnTaskAllocationModification } from '~/modules/projects/resourcing-plan/hooks';
import { useUserAllocationsSummaryContext } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/components/ResourceAllocationChartRow/UserAllocationsSummaryContext.js';

const useRequestActionStyles = makeStyles(theme => ({
  secondaryActionsButton: {
    paddingRight: 0
  }
}));

export const RowLeftItemActions = ({
  projectId,
  task,
  user,
  hasTaskAllocation,
  handleRemoveTaskAssignment,
  canViewCost = false
}) => {
  const requestActionClasses = useRequestActionStyles();
  const {
    resetResourceAllocationChart,
    handleRemoveResourceAllocation
  } = useUserAllocationsSummaryContext();

  const {
    actions,
    removeTaskAssignmentDialogOpen,
    openRemoveTaskAssignmentDialog,
    closeRemoveTaskAssignmentDialog,
    removeTaskAllocationDialogOpen,
    closeRemoveTaskAllocationDialog,
    changeTaskResourceDialogOpen,
    closeChangeTaskResourceDialog
  } = useTaskAssignmentRowActions({ hasTaskAllocation });

  const onRemoveTaskAssignmentSuccess = useCallback(
    ({ taskId }) => {
      handleRemoveTaskAssignment({ taskId });
      closeRemoveTaskAssignmentDialog();
    },
    [handleRemoveTaskAssignment, closeRemoveTaskAssignmentDialog]
  );

  const refetchQueries = useRefetchQueriesOnTaskAllocationModification({
    userId: user.id,
    projectId
  });

  return (
    <>
      <RequestActions
        actions={actions}
        primaryActionPlaceHolder={false}
        classes={requestActionClasses}
      />
      {removeTaskAssignmentDialogOpen && (
        <RemoveTaskAssignmentConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={removeTaskAssignmentDialogOpen}
          onClose={closeRemoveTaskAssignmentDialog}
          onRemoveTaskAssignmentSuccess={onRemoveTaskAssignmentSuccess}
          refetchQueries={refetchQueries}
          canViewCost={canViewCost}
        />
      )}
      {removeTaskAllocationDialogOpen && (
        <RemoveTaskAllocationConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={removeTaskAllocationDialogOpen}
          onClose={closeRemoveTaskAllocationDialog}
          openRemoveTaskAssignmentDialog={openRemoveTaskAssignmentDialog}
          refetchQueries={refetchQueries}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
        />
      )}
      {changeTaskResourceDialogOpen && (
        <ChangeTaskResourceConfirmationDialog
          projectId={projectId}
          changeTaskResourceDialogOpen={changeTaskResourceDialogOpen}
          closeChangeTaskResourceDialog={closeChangeTaskResourceDialog}
          onChangeTaskResourceSuccess={resetResourceAllocationChart}
          task={task}
          user={user}
          canViewCost={canViewCost}
        />
      )}
    </>
  );
};

RowLeftItemActions.propTypes = {
  projectId: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  hasTaskAllocation: PropTypes.bool.isRequired,
  handleRemoveTaskAssignment: PropTypes.func.isRequired,
  canViewCost: PropTypes.bool
};

export default deepPure(RowLeftItemActions);
