import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[0],
    '&:hover': {
      boxShadow: theme.shadows[2]
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.3),
      padding: theme.spacing(0.3, 1)
    }
  }
}));

export const DelaRejectAllButton = ({
  classes: classesOverride,
  children,
  onClick,
  dataQeId,
  ...otherProps
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <Button
      variant="contained"
      {...otherProps}
      className={classes.root}
      classes={classes}
      onClick={onClick}
      data-qe-id={dataQeId}
    >
      {children}
    </Button>
  );
};

DelaRejectAllButton.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  dataQeId: PropTypes.string
};

export default DelaRejectAllButton;
