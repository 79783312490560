import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { RESOURCE_USERS_QUERY } from './useResourceUsers';

export const tryLoadCachedResourceUsersQuery = ({ proxy, variables }) => {
  try {
    return proxy.readQuery({
      query: RESOURCE_USERS_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

export const getUpdatedResourceUsersSummaryAllocation = ({
  resourceUsers,
  resourceAllocation,
  existingResourceAllocation,
  updateHoursCallback
}) =>
  resourceUsers.map(r =>
    resourceAllocation.user.userUri === r.uri
      ? {
          ...r,
          resourceAvailabilitySummarySeries: r.resourceAvailabilitySummarySeries.map(
            summary => {
              const periodHours = getTotalHoursForDateRangeFromScheduleRules({
                start: mapIsoStringtoUtcObject(summary.dateRange.startDate),
                end: mapIsoStringtoUtcObject(summary.dateRange.endDate),
                scheduleRules: resourceAllocation.scheduleRules
              });

              const existingPeriodHours =
                existingResourceAllocation &&
                getTotalHoursForDateRangeFromScheduleRules({
                  start: mapIsoStringtoUtcObject(summary.dateRange.startDate),
                  end: mapIsoStringtoUtcObject(summary.dateRange.endDate),
                  scheduleRules: existingResourceAllocation.scheduleRules
                });

              return {
                ...summary,
                allocatedDuration: {
                  ...summary.allocatedDuration,
                  hours: updateHoursCallback({
                    periodHours,
                    existingPeriodHours,
                    summary
                  })
                },
                minutes: summary.allocatedDuration.minutes,
                seconds: summary.allocatedDuration.seconds
              };
            }
          )
        }
      : r
  );

export const updateResourceUsersSummaryAllocationBar = ({
  resourceUsersCurrentPage,
  dateRange,
  sort,
  periodResolution,
  proxy,
  resourceAllocation,
  existingResourceAllocation,
  updateHoursCallback,
  filter
}) => {
  const variables = {
    page: resourceUsersCurrentPage,
    pagesize: 50,
    dateRange,
    sort,
    periodResolution,
    filter,
    isAvailabilityEnabled: true,
    isViewResourceAllocation: false,
    isViewResourceActualsEnabled: false,
    skipMerge: true
  };

  const results = tryLoadCachedResourceUsersQuery({
    proxy,
    variables
  });

  if (!results) return;

  const { resourceUsers2 } = results;

  proxy.writeQuery({
    query: RESOURCE_USERS_QUERY,
    variables,
    data: {
      resourceUsers2: getUpdatedResourceUsersSummaryAllocation({
        resourceUsers: resourceUsers2,
        resourceAllocation,
        existingResourceAllocation,
        updateHoursCallback
      })
    }
  });
};

export const updateCreateAllocationCache = ({
  resourceUsersCurrentPage,
  dateRange,
  userSort,
  periodResolution,
  filter
}) => (proxy, mutationResponse) => {
  const {
    data: {
      createFulfilledResourceAllocationWithoutResourceRequest: {
        resourceAllocation
      }
    }
  } = mutationResponse;

  updateResourceUsersSummaryAllocationBar({
    resourceUsersCurrentPage,
    proxy,
    resourceAllocation,
    sort: userSort,
    periodResolution,
    dateRange,
    filter,
    updateHoursCallback: ({ periodHours, summary }) =>
      periodHours > 0
        ? summary.allocatedDuration.hours + periodHours
        : summary.allocatedDuration.hours
  });
};
