import { makeStyles } from '@material-ui/core';

export const useMoreOptionsStyles = makeStyles(theme => ({
  message: {
    padding: theme.spacing(0.75, 2)
  }
}));

export const useDropdownItemStyles = makeStyles(theme => ({
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    top: theme.spacing(-1),
    justifyContent: 'space-between'
  },
  groupUl: {
    padding: 0
  },
  resourceTitle: {
    flexGrow: 1,
    flexShrink: 1
  },
  group: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: theme.spacing(21.5)
  }
}));

export const useInputStyles = makeStyles(theme => ({
  option: {
    justifyContent: 'space-between',
    padding: '0px !important'
  },
  popper: {}
}));

export const useUserWithRoleColumnStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.75, 2)
  }
}));

export const useUserColumnStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0.75, 2)
  }
}));

export const useAvailabilityColumnStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.75, 2)
  },
  insufficientAvailability: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const NoDataItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.main,
    padding: theme.spacing(1.25, 2)
  }
}));

export const useTabStyles = makeStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
}));

export const useUserStyles = makeStyles(theme => ({
  label: {
    maxWidth: theme.spacing(19)
  },
  name: {
    fontSize: theme.typography.body1.fontSize
  }
}));

export const useIconButtonStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.625)
  }
}));

export const useAvailabilityTitleStyles = makeStyles(theme => ({
  isEnabled: { color: theme.palette.grey[900] },
  root: {
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[900]
  },
  isDisabled: {
    color: theme.palette.grey[400]
  }
}));

export const useTableSortLabelHoverStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      color: theme.palette.grey[900]
    }
  }
}));
