import { ReleaseResourceAllocationType } from '~/types';
import {
  isTaskEndsAfterAsOfDate,
  isAsOfDateFallingBetweenTaskStartAndEndDate,
  getImpactedTasksUsingAllocationAndTaskRoleMatch
} from './useImpactedTasksByReleasingResources';

export const getImpactedTasksByReleasingAllocations = ({
  resourceUser,
  allocations,
  releaseDate,
  releaseType,
  isPSAPswatFixReleaseAllocationEnabled
}) => {
  if (isPSAPswatFixReleaseAllocationEnabled) {
    const isAllAllocationsSelected = allocations.every(
      allocation => allocation.isSelected
    );

    const impactedTasks = isAllAllocationsSelected
      ? resourceUser?.assignedTasks || []
      : getImpactedTasksUsingAllocationAndTaskRoleMatch({
          resourceUser,
          allocations
        });

    return {
      selectedUsersForReleasing: impactedTasks.length ? [resourceUser] : [],
      impactedTasks:
        releaseType === ReleaseResourceAllocationType.Entirely
          ? impactedTasks
          : impactedTasks.filter(task =>
              isTaskEndsAfterAsOfDate({
                asOfDate: releaseDate,
                task
              })
            )
    };
  }
  const { roleCounts, selectedRoleCounts } = allocations.reduce(
    (acc, allocation) => {
      const roleUri = allocation.role && allocation.role.uri;

      if (!acc.roleCounts[roleUri]) {
        acc.roleCounts[roleUri] = 0;
      }
      acc.roleCounts[roleUri]++;

      if (allocation.isSelected) {
        if (!acc.selectedRoleCounts[roleUri]) {
          acc.selectedRoleCounts[roleUri] = 0;
        }
        acc.selectedRoleCounts[roleUri]++;
      }

      return acc;
    },
    { roleCounts: {}, selectedRoleCounts: {} }
  );

  const impactedTasks = (resourceUser?.assignedTasks || []).filter(
    task =>
      roleCounts[task.assignedRoleUri] ===
        selectedRoleCounts[task.assignedRoleUri] &&
      selectedRoleCounts[task.assignedRoleUri] > 0
  );

  if (impactedTasks.length === 0)
    return {
      selectedUsersForReleasing: [],
      impactedTasks: []
    };

  return {
    selectedUsersForReleasing: [resourceUser],
    impactedTasks:
      releaseType === ReleaseResourceAllocationType.Entirely
        ? impactedTasks
        : impactedTasks.filter(task =>
            isAsOfDateFallingBetweenTaskStartAndEndDate({
              asOfDate: releaseDate,
              task
            })
          )
  };
};
