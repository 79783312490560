import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogActions } from '@material-ui/core';
import {
  getLuxonJsDateFormatFromMe,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import useMeContext from '~/modules/me/useMeContext';
import { ReleaseResourceAllocationType } from '~/types';
import ReleaseResourceAllocationsDialogContent from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/components/ReleaseResourceRequestDialogContent/ReleaseResourceAllocationsDialogContent';
import useGetUsersAssignedImpactedTaskSummary from '../../hooks/useGetUsersAssignedImpactedTaskSummary';
import { ReleaseResourceRequestDialogActions } from './components';
import { useActionStyles } from './useStyles';
import {
  useReleaseAllocationsDialogState,
  useFormOnChangeHandlers,
  useReleaseTaskOwners,
  useBulkReleaseTaskOwnership,
  useTaskOwnedByResources,
  getImpactedTasksByReleasingAllocations
} from './hooks';

export const ReleaseAllocationsDialogContent = ({
  onReleaseSuccess,
  onReleaseResourceAllocations,
  projectUri,
  onCancelClick,
  allocatedUser,
  canEditTask,
  allocations,
  selectedAllocationId
}) => {
  const actionClasses = useActionStyles();
  const me = useMeContext();
  const {
    featureFlags: {
      isPsaRmpTaskAllocation1Enabled,
      isPSAPswatFixReleaseAllocationEnabled
    }
  } = me;

  const [isReleaseLoading, setIsReleaseLoading] = useState(false);
  const { values, setFieldValue } = useReleaseAllocationsDialogState({
    allocations,
    onReleaseResourceAllocations,
    selectedAllocationId
  });

  const {
    releaseDate,
    comment,
    releaseType,
    releaseConfirmed,
    releaseAllocationsRows
  } = values;

  const { bulkReleaseTaskOwnership } = useBulkReleaseTaskOwnership();
  const { releaseTaskOwners } = useReleaseTaskOwners();

  const {
    usersAssignedImpactedTaskSummary,
    isLoading
  } = useGetUsersAssignedImpactedTaskSummary({
    projectId: projectUri,
    userIds: [allocatedUser.user.uri],
    asOfDate:
      releaseType === ReleaseResourceAllocationType.Asof ? releaseDate : null,
    skip: !isPsaRmpTaskAllocation1Enabled
  });

  const {
    resources,
    isLoading: isLoadingImpactedTasksSummary
  } = useTaskOwnedByResources({
    projectId: projectUri,
    resourceIds: [allocatedUser.user.uri],
    skip: isPsaRmpTaskAllocation1Enabled
  });

  const {
    impactedTasks,
    selectedUsersForReleasing
  } = getImpactedTasksByReleasingAllocations({
    resourceUser: isLoadingImpactedTasksSummary ? [] : resources[0],
    allocations: releaseAllocationsRows,
    releaseDate,
    releaseType,
    isPSAPswatFixReleaseAllocationEnabled
  });

  const onReleaseClick = useCallback(async () => {
    setIsReleaseLoading(true);
    await onReleaseResourceAllocations({
      releaseDate,
      releaseType,
      comment,
      selectedAllocations: isPsaRmpTaskAllocation1Enabled
        ? releaseAllocationsRows
        : releaseAllocationsRows.filter(alloc => alloc.isSelected),
      formattedReleaseDate: mapIsoStringtoUtcObject(releaseDate).toFormat(
        getLuxonJsDateFormatFromMe(me)
      )
    });
    if (impactedTasks.length > 0 && !isPsaRmpTaskAllocation1Enabled)
      await releaseTaskOwners(impactedTasks);
    else if (isPsaRmpTaskAllocation1Enabled) {
      await bulkReleaseTaskOwnership({
        entries: usersAssignedImpactedTaskSummary,
        projectId: projectUri
      });
    }

    if (onReleaseSuccess) onReleaseSuccess({ releaseType, releaseDate });
    setIsReleaseLoading(false);
    onCancelClick();
  }, [
    onReleaseResourceAllocations,
    releaseDate,
    releaseType,
    comment,
    releaseAllocationsRows,
    me,
    impactedTasks,
    isPsaRmpTaskAllocation1Enabled,
    releaseTaskOwners,
    onReleaseSuccess,
    onCancelClick,
    bulkReleaseTaskOwnership,
    usersAssignedImpactedTaskSummary,
    projectUri
  ]);

  const {
    onSelectAllClick,
    onReleaseConfirmChange,
    onReleaseTypeChange,
    onCommentsChange,
    onRowSelectionChange,
    onReleaseDateChange
  } = useFormOnChangeHandlers({
    setFieldValue,
    releaseAllocationsRows
  });

  return (
    <>
      <ReleaseResourceAllocationsDialogContent
        allocatedUser={allocatedUser}
        allocations={releaseAllocationsRows}
        isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        onSelectAllClick={onSelectAllClick}
        onRowSelectionChange={onRowSelectionChange}
      />
      <DialogActions classes={actionClasses}>
        <ReleaseResourceRequestDialogActions
          onCancelClick={onCancelClick}
          onReleaseClick={onReleaseClick}
          noneSelected={
            releaseAllocationsRows.filter(a => a.isSelected).length === 0
          }
          releaseType={releaseType}
          releaseDate={releaseDate}
          onCommentsChange={onCommentsChange}
          releaseConfirmed={releaseConfirmed}
          isReleaseLoading={isReleaseLoading}
          onReleaseDateChange={onReleaseDateChange}
          onReleaseTypeChange={onReleaseTypeChange}
          onReleaseConfirmChange={onReleaseConfirmChange}
          comment={comment}
          impactedTasks={impactedTasks}
          usersAssignedImpactedTaskSummary={usersAssignedImpactedTaskSummary}
          isLoadingImpactedTasksSummary={
            isLoadingImpactedTasksSummary || isLoading
          }
          selectedUsersForReleasing={selectedUsersForReleasing}
          canEditTask={canEditTask}
          allocations={releaseAllocationsRows}
          releaseTaskOwners={releaseTaskOwners}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      </DialogActions>
    </>
  );
};

ReleaseAllocationsDialogContent.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  canEditTask: PropTypes.bool.isRequired,
  projectUri: PropTypes.string,
  allocations: PropTypes.array,
  onReleaseSuccess: PropTypes.func,
  allocatedUser: PropTypes.object,
  onReleaseResourceAllocations: PropTypes.func.isRequired,
  selectedAllocationId: PropTypes.string
};

export default ReleaseAllocationsDialogContent;
