import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '~/modules/App/AppContext';
import {
  MilestoneIcon,
  TaskIcon,
  TaskParentIcon
} from '~/modules/common/components/Icons';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';
import { useEditableState, useIsBreakpointUp } from '~/modules/common/hooks';
import { useProjectObjectPermissions } from '~/modules/common/hooks/project';
import { useMeContext } from '~/modules/me';
import { retryLoading } from '~/util';
import Drawer, { DrawerContent, DrawerHeader } from '../Drawer';
import { TaskTitle } from '../entities/task';
import TaskStatusSection from '../TaskStatusSection';
import { EditTaskButton, EditTaskForm, EditTaskForm2 } from './EditTask';
import ErrorDrawer from './ErrorDrawer';
import LoadingDrawer from './LoadingDrawer';
import TaskBurndownSection from './TaskBurndownSection/TaskBurndownSection';
import TaskDrawerHeaderInfoBar from './TaskDrawerHeaderInfoBar';
import { TaskResourceAllocationsWithExpansionPanel } from './TaskResourceAllocations';
import { TaskResourceEstimatesExpansionPanel as TaskResourceEstimates } from './TaskResourceEstimates/TaskResourceEstimatesExpansionPanel';
import TaskSummaryContextProvider from './TaskSummaryContextProvider';
import { useTaskDetailsQuery } from './useTaskDetailsQuery';

export const TaskBurndownChart = React.lazy(() =>
  retryLoading(() => import('./Charts/TaskBurndownChart'))
);

const useStyles = makeStyles(theme => ({
  header: {
    paddingLeft: theme.spacing(1)
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 11,
    backgroundColor: 'inherit',
    color: 'inherit',
    padding: theme.spacing(1, 0, 1, 2),
    borderBottom: `1px solid ${theme.palette.table.border}`,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  taskTitle: {
    overflow: 'hidden'
  },
  taskTitleText: {
    '& div > span:nth-of-type(1)': {
      fontWeight: theme.typography.fontWeightBold
    },
    '& div > span': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  titleIcon: {
    fontSize: theme.spacing(5),
    color: '#656565',
    margin: theme.spacing(0.75, 1.75)
  },
  titleText: ({ isRolledUpTaskEstimateCalculationMethodEnabled }) => ({
    ...theme.typography.h6,
    fontSize: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.spacing(2.75)
      : 'inherit',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  }),
  drawerContentOverride: {
    overflowX: 'unset',
    overflowY: 'unset',
    padding: 0
  },
  milestoneIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: theme.spacing(0, 1)
  }
}));

export const TaskDrawer = ({
  task,
  onClose,
  hasMore,
  loadMore,
  loadingMore,
  handleRefetch
}) => {
  const isDesktop = useIsBreakpointUp('md');
  const { state: { isProjectEditEnabled } = {} } = useLocation();
  const { editable: editing, toggleEditable, setEditable } = useEditableState(
    isProjectEditEnabled
  );
  const { dispatch } = useAppContext();
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: {
      PSAPRPTaskEstimateCalculation,
      isPsaRmpTaskAllocation1Enabled,
      isRmpTaskAllocationPhase2Enabled
    }
  } = useMeContext();

  useEffect(() => {
    setEditable(isProjectEditEnabled);
  }, [setEditable, isProjectEditEnabled]);

  const editButton = useMemo(
    () => <EditTaskButton onClick={toggleEditable} />,
    [toggleEditable]
  );

  const {
    canViewCost,
    canViewTeam,
    canEditTasks,
    canViewResourceTaskAllocations,
    canEditResourceTaskAllocations
  } = useProjectObjectPermissions(task);

  const showResourcesSection =
    isDesktop &&
    canViewTeam &&
    PSAPRPTaskEstimateCalculation ===
      TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT;

  const isPSAPRPTaskEstimateCalculationMethodEnabled =
    PSAPRPTaskEstimateCalculation !== TASK_ESTIMATE_CALCULATION_TYPES.OFF;

  const classes = useStyles({ isRolledUpTaskEstimateCalculationMethodEnabled });
  const editTaskFormComponent = isPsaRmpTaskAllocation1Enabled ? (
    <EditTaskForm2
      task={task}
      onClose={onClose}
      dispatch={dispatch}
      stopEditing={toggleEditable}
    />
  ) : (
    <EditTaskForm
      task={task}
      onClose={onClose}
      dispatch={dispatch}
      stopEditing={toggleEditable}
    />
  );

  const iconComponent = task.hasChildren ? (
    <TaskParentIcon className={classes.titleIcon} />
  ) : (
    <TaskIcon className={classes.titleIcon} />
  );

  return (
    <Drawer>
      <div
        className={
          isPSAPRPTaskEstimateCalculationMethodEnabled
            ? classes.stickyHeader
            : classes.header
        }
      >
        <DrawerHeader
          onClose={onClose}
          extraButtons={!editing && canEditTasks && editButton}
          bgColor="inherit"
          color="inherit"
          paddingLeft="0"
        >
          {task.isMilestone && (
            <MilestoneIcon className={classes.milestoneIcon} />
          )}
          {isRolledUpTaskEstimateCalculationMethodEnabled &&
            !isPsaRmpTaskAllocation1Enabled && (
              <DescriptionSharpIcon className={classes.titleIcon} />
            )}
          {isPsaRmpTaskAllocation1Enabled && iconComponent}
          <TaskTitle
            qeIdTag="TaskDrawer"
            titleVariant="h1"
            task={task}
            large
            className={classNames(classes.taskTitle, classes.taskTitleText)}
            titleClassName={classes.titleText}
            showCode
          />
        </DrawerHeader>
      </div>
      {editing && canEditTasks && (
        <DrawerContent className={classes.drawerContentOverride}>
          {editTaskFormComponent}
        </DrawerContent>
      )}
      {(!canEditTasks || !editing) && (
        <Suspense fallback="">
          <TaskDrawerHeaderInfoBar task={task} handleRefetch={handleRefetch} />
          <Divider />
          <DrawerContent className={classes.drawerContentOverride}>
            <TaskSummaryContextProvider>
              <TaskStatusSection
                task={task}
                hasMore={hasMore}
                loadMore={loadMore}
                loadingMore={loadingMore}
              />
              {showResourcesSection && isPsaRmpTaskAllocation1Enabled && (
                <TaskResourceEstimates
                  task={task}
                  canViewCost={canViewCost && isRmpTaskAllocationPhase2Enabled}
                />
              )}
              {showResourcesSection &&
                !isPsaRmpTaskAllocation1Enabled &&
                canViewResourceTaskAllocations && (
                  <TaskResourceAllocationsWithExpansionPanel
                    taskId={task.id}
                    assignedRole={task.assignedRole}
                    projectSlug={task.projectReference.slug}
                    editable={canEditResourceTaskAllocations}
                    initialEstimatedHours={task.initialEstimatedHours}
                  />
                )}
              {(isRolledUpTaskEstimateCalculationMethodEnabled ||
                isPsaRmpTaskAllocation1Enabled) && <Divider />}
              {PSAPRPTaskEstimateCalculation !==
                TASK_ESTIMATE_CALCULATION_TYPES.OFF && (
                <TaskBurndownSection task={task} isDesktop={isDesktop} />
              )}
            </TaskSummaryContextProvider>
          </DrawerContent>
        </Suspense>
      )}
    </Drawer>
  );
};

TaskDrawer.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  handleRefetch: PropTypes.func
};

const TaskDrawerWrapper = ({ taskId, onClose }) => {
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();
  const {
    loading,
    error,
    task,
    hasMore,
    loadMore,
    loadingMore,
    handleRefetch,
    isLoading
  } = useTaskDetailsQuery({
    taskId,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  if (loading || isLoading) return <LoadingDrawer onClose={onClose} />;
  if (!task && error) return <ErrorDrawer data={error} onClose={onClose} />;

  return (
    <TaskDrawer
      task={task}
      hasMore={hasMore}
      loadMore={loadMore}
      loadingMore={loadingMore}
      onClose={onClose}
      handleRefetch={handleRefetch}
    />
  );
};

TaskDrawerWrapper.propTypes = {
  taskId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TaskDrawerWrapper;
