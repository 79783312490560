import memoize from 'lodash.memoize';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { objectToFormatString } from '~/modules/common/dates/convert';
import { BillingFrequency } from '~/types';

export const BILLING_PLAN_FREQUENCY_ENUM = {
  [BillingFrequency.Daily]: BillingFrequency.Daily,
  [BillingFrequency.Weekly]: BillingFrequency.Weekly,
  [BillingFrequency.Biweekly]: BillingFrequency.Biweekly,
  [BillingFrequency.Semimonthly]: BillingFrequency.Semimonthly,
  [BillingFrequency.Monthly]: BillingFrequency.Monthly,
  [BillingFrequency.Quarterly]: BillingFrequency.Quarterly,
  [BillingFrequency.Yearly]: BillingFrequency.Yearly
};

const _getMessageValues = ({
  frequency,
  dayOfWeek,
  dayOfMonth,
  dateFormat,
  relativeDate
}) => ({
  frequency: (
    <FormattedMessage id={`billPlan.billPlanFrequency.${frequency}`} />
  ),
  dayOfMonth: (
    <FormattedMessage
      id={
        frequency === BILLING_PLAN_FREQUENCY_ENUM.SEMIMONTHLY
          ? `billPlan.semiMonthlyDays.${dayOfMonth}`
          : `billPlan.dayOfMonth.${dayOfMonth}`
      }
    />
  ),
  dayOfWeek: <FormattedMessage id={`billPlan.dayOfWeek.${dayOfWeek}`} />,
  formattedDate: relativeDate && objectToFormatString(relativeDate, dateFormat)
});
const getMessageValues = memoize(_getMessageValues);

export const getBillingPlanFrequencyDisplayText = (
  frequencyDetail,
  luxonFormat
) => {
  const { frequency, dayOfMonth, dayOfWeek, relativeDate } = frequencyDetail;
  const dateFormat =
    frequency === BILLING_PLAN_FREQUENCY_ENUM.BIWEEKLY
      ? `EEEE (${luxonFormat})`
      : 'MMM d';

  const messageValues = getMessageValues({
    frequency,
    dayOfMonth,
    dayOfWeek,
    relativeDate,
    dateFormat
  });

  const formatMessageId =
    frequency === BILLING_PLAN_FREQUENCY_ENUM.WEEKLY && dayOfWeek
      ? 'billPlan.weeklyFrequency'
      : (frequency === BILLING_PLAN_FREQUENCY_ENUM.MONTHLY ||
          frequency === BILLING_PLAN_FREQUENCY_ENUM.SEMIMONTHLY) &&
        dayOfMonth
      ? 'billPlan.monthlyFrequency'
      : (frequency === BILLING_PLAN_FREQUENCY_ENUM.QUARTERLY ||
          frequency === BILLING_PLAN_FREQUENCY_ENUM.BIWEEKLY) &&
        relativeDate
      ? 'billPlan.frequencyFromFormattedDate'
      : frequency === BILLING_PLAN_FREQUENCY_ENUM.YEARLY && relativeDate
      ? 'billPlan.frequencyOnFormattedDate'
      : `billPlan.billPlanFrequency.${frequency}`;

  return <FormattedMessage id={formatMessageId} values={messageValues} />;
};
