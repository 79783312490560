import { useResourceUserTaskAssignmentSummary } from './useResourceUserTaskAssignmentSummary';

export const useTaskEstimateRowContextDataBuilder = ({
  user,
  projectId,
  task,
  includeTaskResourceUserCostProgressSummary = false
}) => {
  const {
    loading,
    taskAssignmentSummary: { resourceUserTaskAssignmentSummary } = {}
  } = useResourceUserTaskAssignmentSummary({
    userId: user.id,
    projectId,
    taskId: task.id,
    includeTaskResourceUserCostProgressSummary
  });

  const taskDetail = {
    ...task,
    name: task.displayText,
    project: { id: projectId }
  };

  const taskResourceEstimate = {
    ...resourceUserTaskAssignmentSummary?.resourceEstimate,
    resourceUser: {
      id: user.id,
      displayText: user.displayText
    },
    taskResourceUserAllocation:
      resourceUserTaskAssignmentSummary?.taskResourceUserAllocation,
    taskResourceUserHoursProgressSummary:
      resourceUserTaskAssignmentSummary?.taskResourceUserHoursProgressSummary,
    taskResourceUserCostProgressSummary:
      resourceUserTaskAssignmentSummary?.taskResourceUserCostProgressSummary
  };

  return {
    loading,
    taskResourceEstimate,
    taskDetail
  };
};
