import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

export const taskDetailsFragment = gql`
  fragment TaskDetails on Task {
    id
    hasChildren
  }
`;

export const createTaskFragmentId = taskId => `Task:${taskId}`;

export const useAreDatesRolledUp = ({
  task,
  isPsaPswatTaskDateRollupEnabled = false
}) => {
  const { cache } = useApolloClient();

  if (!isPsaPswatTaskDateRollupEnabled || !task) {
    return false;
  }

  const { id, project } = task;
  const isTaskDateRollupEnabled = project?.isTaskDateRollupEnabled || false;

  const taskDeatilsFragmentFromCache = cache.readFragment({
    id: createTaskFragmentId(id),
    fragment: taskDetailsFragment
  });

  if (taskDeatilsFragmentFromCache) {
    const { hasChildren = false } = taskDeatilsFragmentFromCache;

    return hasChildren && isTaskDateRollupEnabled;
  }

  return false;
};
