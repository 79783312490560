import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  useCreateTaskResourceUserAllocation,
  useUpdateTaskResourceUserAllocation
} from '~/modules/common/components/TaskDrawer/common/hooks';

export const getTaskAllocationFieldsFromCacheData = mutationType => cacheData => {
  const {
    data: {
      [mutationType]: { taskResourceUserAllocation }
    }
  } = cacheData;

  const {
    id,
    startDate,
    endDate,
    scheduleRules,
    totalHours
  } = taskResourceUserAllocation;

  return {
    id,
    startDate,
    endDate,
    scheduleRules,
    totalHours
  };
};

export const onUpdate = ({
  getTaskAllocationFields,
  projectId,
  resourceEstimateId,
  resourceEstimates,
  roleId,
  setFieldValue,
  taskId
}) => (_, data) => {
  setFieldValue(
    'resourceEstimates',
    resourceEstimates.map(estimate =>
      estimate.id === resourceEstimateId
        ? {
            ...estimate,
            taskResourceUserAllocation: {
              ...getTaskAllocationFields(data),
              projectUri: projectId,
              roleUri: roleId,
              taskUri: taskId
            }
          }
        : estimate
    )
  );
};

export const useOnSubmit = ({ setFieldValue, resourceEstimates }) => {
  const createTaskResourceUserAllocation = useCreateTaskResourceUserAllocation();
  const updateTaskResourceUserAllocation = useUpdateTaskResourceUserAllocation();

  return useCallback(
    async ({
      setSubmitting,
      projectId,
      taskId,
      userId,
      tenantSlug,
      roleId,
      values,
      resourceEstimateId,
      taskResourceUserAllocation
    }) => {
      setSubmitting(true);

      const { startDate, endDate, allocatedHours } = values;

      const taskAllocationId =
        taskResourceUserAllocation?.id ||
        `urn:replicon-tenant:${tenantSlug}:psa-task-allocation:${uuidv4()}`;

      const variables = {
        input: {
          taskAllocationId,
          taskUri: taskId,
          projectUri: projectId,
          roleUri: roleId,
          dateRange: {
            startDate,
            endDate
          },
          allocationUserUri: taskResourceUserAllocation ? undefined : userId,
          allocationHours: allocatedHours
        }
      };

      const onUpdateParams = {
        setFieldValue,
        resourceEstimates,
        resourceEstimateId,
        projectId,
        roleId,
        taskId
      };

      await (taskResourceUserAllocation
        ? updateTaskResourceUserAllocation({
            variables,
            update: onUpdate({
              ...onUpdateParams,
              getTaskAllocationFields: getTaskAllocationFieldsFromCacheData(
                'updateTaskResourceUserAllocation'
              )
            })
          })
        : createTaskResourceUserAllocation({
            variables,
            update: onUpdate({
              ...onUpdateParams,
              getTaskAllocationFields: getTaskAllocationFieldsFromCacheData(
                'createTaskResourceUserAllocation'
              )
            })
          }));

      setSubmitting(false);
    },
    [
      createTaskResourceUserAllocation,
      resourceEstimates,
      setFieldValue,
      updateTaskResourceUserAllocation
    ]
  );
};

export default useOnSubmit;
