import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { ChangeResourceUserModificationType } from '~/types';
import { dateToMidnightUTCObject } from '~/modules/common/dates/convert';
import { delay } from '~/modules/common/util';

export const CHANGE_RESOURCE_USER = gql`
  mutation useChangeResourceUser($input: ChangeResourceUserInput!) {
    changeResourceUser(input: $input) {
      newResourceAllocationId
    }
  }
`;

export const useOnSubmit = ({
  existingResourceUserId,
  onChangeResourceSuccess,
  onClose,
  projectId
}) => {
  const [changeResourceUser] = useMutation(CHANGE_RESOURCE_USER);

  return {
    onChangeResourceUserSubmit: useCallback(
      async (values, { setSubmitting }) => {
        const { asOfDate, modificationType, newResourceUser } = values;

        setSubmitting(true);
        await changeResourceUser({
          variables: {
            input: {
              asOfDate:
                modificationType === ChangeResourceUserModificationType.Asof
                  ? typeof asOfDate === 'string'
                    ? dateToMidnightUTCObject(asOfDate)
                    : asOfDate
                  : null,
              existingResourceUserId,
              modificationType,
              newResourceUserId: newResourceUser?.id,
              projectId
            }
          }
        });
        await delay(2000);
        setSubmitting(false);
        onChangeResourceSuccess();
        onClose();
      },
      [
        changeResourceUser,
        existingResourceUserId,
        onChangeResourceSuccess,
        onClose,
        projectId
      ]
    )
  };
};

export default useOnSubmit;
