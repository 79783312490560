import React, { useCallback, useMemo, forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { getRTLPopperStyles } from '~/modules/common/util';
import {
  CertificateIcon,
  DelaStarIcon
} from '~/modules/common/components/Icons';
import SkillRating from '~/modules/resourcing/common/components/Skills/SkillRating';
import SkillCustomMetadataReadOnly from './SkillCustomMetadataReadOnly';

const useStyles = makeStyles(theme => ({
  root: {
    '&:last-child': {
      borderBottom: 'none'
    },
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingTop: 4,
    paddingBottom: 4
  },
  skillBarStarContainer: {
    marginTop: '-3px',
    flexGrow: 0,
    flexShrink: 0,
    minWidth: 80
  },
  skillHeader: {
    padding: theme.spacing(0, 1, 0, 0),
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  },
  skillName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    flexShrink: 1,
    paddingLeft: theme.spacing(0.5)
  },
  certificateIcon: {
    color: theme.palette.text.secondary,
    marginBottom: -10
  },
  certificateContainer: {
    width: theme.spacing(3)
  },
  suggestionButton: {
    padding: 0
  },
  checkCircle: {
    fill: theme.palette.success.main
  },
  cancelCircle: {
    fill: theme.palette.error.main
  },
  delaSkillButtonContainer: {
    display: 'flex',
    columnGap: theme.spacing(0.5)
  },
  skillMetadataDetails: {
    paddingLeft: theme.spacing(3.5)
  }
}));

const useGridStyles = makeStyles(theme => ({
  root: {
    '&:last-child': {
      borderBottom: 'none'
    },
    display: 'grid',
    gridTemplateColumns: `min-content minmax(0, 1fr)`,
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: [['"leftTray skillHeader"', '". skillCustomMetadata"']],
    columnGap: theme.spacing(0.5)
  },
  skillBarStarContainer: {
    flexGrow: 0,
    flexShrink: 0,
    minWidth: 80
  },
  skillHeader: {
    gridArea: 'skillHeader',
    padding: theme.spacing(0, 1, 0, 0),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  skillName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    flexShrink: 1
  },
  leftTrayContainer: {
    gridArea: 'leftTray',
    display: 'flex',
    minWidth: theme.spacing(3),
    columnGap: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  skillMetadataDetails: {
    gridArea: 'skillCustomMetadata'
  }
}));

const useTooltipPopperStyles = makeStyles(theme => ({
  popper: () => getRTLPopperStyles({ theme })
}));

const useDelaStarIconStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0.5),
    fill: theme.palette.Dela.darkIcon,
    fontSize: theme.spacing(1.8)
  }
}));

export const SkillDetails = forwardRef(
  (
    {
      skillAssignment,
      skillAssignment: { certificate },
      certificates,
      maxSkillLevel,
      category,
      onAcceptUserNudges,
      onRejectUserNudges,
      dataQeId
    },
    ref
  ) => {
    const isPsaPraaUserProfileNudgesEnabled = useHasFeatureFlag({
      featureFlag: 'isPsaPraaUserProfileNudgesEnabled'
    });
    const intl = useIntl();
    const classes = useStyles();
    const gridClasses = useGridStyles();
    const delaStarIconClasses = useDelaStarIconStyles();
    const tooltipPopperClasses = useTooltipPopperStyles();

    const skillCustomMetadataClasses = useMemo(
      () => ({
        skillMetadataDetails: isPsaPraaUserProfileNudgesEnabled
          ? gridClasses.skillMetadataDetails
          : classes.skillMetadataDetails
      }),
      [classes, gridClasses, isPsaPraaUserProfileNudgesEnabled]
    );

    const onAccept = useCallback(
      () =>
        onAcceptUserNudges([{ skillAssignments: [skillAssignment], category }]),
      [onAcceptUserNudges, skillAssignment, category]
    );

    const onReject = useCallback(
      () =>
        onRejectUserNudges([{ skillAssignments: [skillAssignment], category }]),
      [onRejectUserNudges, skillAssignment, category]
    );

    const cert =
      certificate &&
      certificates &&
      certificates.find(p => p.uri === certificate);
    const title =
      skillAssignment.skillLevel.rating > 0
        ? `${intl.formatMessage({
            id: 'skillInfoCard.proficiencyLevel'
          })}: ${skillAssignment.skillLevel.rating}`
        : intl.formatMessage({ id: 'skillInfoCard.unrated' });
    const acceptTooltipTitle = intl.formatMessage({
      id: 'skillInfoCard.acceptSuggestion'
    });
    const rejectTooltipTitle = intl.formatMessage({
      id: 'skillInfoCard.rejectSuggestion'
    });

    return isPsaPraaUserProfileNudgesEnabled ? (
      <ListItem className={gridClasses.root} disableGutters>
        <div className={gridClasses.leftTrayContainer}>
          {skillAssignment.isDelaRecommendation && (
            <div className={classes.delaSkillButtonContainer}>
              <Tooltip
                title={rejectTooltipTitle}
                aria-label={rejectTooltipTitle}
                placement="bottom-start"
                classes={tooltipPopperClasses}
              >
                <IconButton
                  className={classes.suggestionButton}
                  onClick={onReject}
                  aria-label={intl.formatMessage({
                    id: 'skillInfoCard.reject'
                  })}
                >
                  <Cancel className={classes.cancelCircle} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={acceptTooltipTitle}
                aria-label={acceptTooltipTitle}
                placement="bottom-start"
                classes={tooltipPopperClasses}
              >
                <IconButton
                  className={classes.suggestionButton}
                  onClick={onAccept}
                  aria-label={intl.formatMessage({
                    id: 'skillInfoCard.accept'
                  })}
                >
                  <CheckCircle className={classes.checkCircle} />
                </IconButton>
              </Tooltip>
            </div>
          )}
          {cert && (
            <div className={classes.certificateContainer}>
              <Tooltip title={cert.name}>
                <CertificateIcon className={classes.certificateIcon} />
              </Tooltip>
            </div>
          )}
        </div>
        <div data-qe-id={dataQeId} className={gridClasses.skillHeader}>
          <div className={gridClasses.skillName}>
            {skillAssignment.displayText}
            {skillAssignment.isDelaRecommendation && (
              <DelaStarIcon
                classes={delaStarIconClasses}
                titleAccess={intl.formatMessage({
                  id: 'dela.suggestedByDelaAI'
                })}
              />
            )}
          </div>
          {Boolean(maxSkillLevel) && (
            <Tooltip title={title}>
              <div className={gridClasses.skillBarStarContainer}>
                <SkillRating
                  dataQeId={`${skillAssignment.displayText}_SkillRating`}
                  skillAssignment={skillAssignment}
                  editable={false}
                  maxSkillLevel={maxSkillLevel}
                />
              </div>
            </Tooltip>
          )}
        </div>
        {skillAssignment &&
          skillAssignment.skillCustomMetadata &&
          skillAssignment.skillCustomMetadata.length > 0 && (
            <SkillCustomMetadataReadOnly
              {...skillAssignment}
              classes={skillCustomMetadataClasses}
            />
          )}
      </ListItem>
    ) : (
      <ListItem className={classes.root} disableGutters>
        <div data-qe-id={dataQeId} className={classes.skillHeader}>
          <div className={classes.certificateContainer}>
            {cert && (
              <Tooltip title={cert.name}>
                <CertificateIcon className={classes.certificateIcon} />
              </Tooltip>
            )}
          </div>
          <div className={classes.skillName}>{skillAssignment.displayText}</div>
          {Boolean(maxSkillLevel) && (
            <Tooltip title={title}>
              <SkillRating
                dataQeId={`${skillAssignment.displayText}_SkillRating`}
                className={classes.skillBarStarContainer}
                skillAssignment={skillAssignment}
                editable={false}
                maxSkillLevel={maxSkillLevel}
              />
            </Tooltip>
          )}
        </div>
        {skillAssignment &&
          skillAssignment.skillCustomMetadata &&
          skillAssignment.skillCustomMetadata.length > 0 && (
            <SkillCustomMetadataReadOnly
              {...skillAssignment}
              classes={skillCustomMetadataClasses}
            />
          )}
      </ListItem>
    );
  }
);

SkillDetails.propTypes = {
  dataQeId: PropTypes.string,
  skillAssignment: PropTypes.object.isRequired,
  certificates: PropTypes.array,
  maxSkillLevel: PropTypes.number,
  category: PropTypes.object.isRequired,
  onAcceptUserNudges: PropTypes.func.isRequired,
  onRejectUserNudges: PropTypes.func.isRequired
};

export default SkillDetails;
