import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, DialogContent, DialogActions } from '@material-ui/core';
import CurrentProgressBarContent from '~/modules/common/components/CurrentProgressBarContent';
import { useBatchStatus, useCancelBatch } from '~/modules/common/hooks';
import LoadingButton from '~/modules/common/components/LoadingButton';
import MassEditProjectResult from './MassEditProjectResult';

const progressDescriptionId = 'mass-edit-progress-description';
const progressActionsId = 'mass-edit-progress-actions';

export const MassEditProjectProgressContent = ({
  onClose,
  itemCounts,
  batchState,
  setBatchState,
  onBatchComplete
}) => {
  const { batchId, batchInProgress } = batchState;
  const {
    hasProcessed,
    overallProgress: { currentProgress = 0 } = {}
  } = useBatchStatus({ batchId });

  const cancelBatch = useCancelBatch();
  const [cancelBatchInProgress, setCancelBatchInProgress] = useState(false);

  useEffect(() => {
    if (hasProcessed) setBatchState({ batchId, batchInProgress: false });
  }, [setBatchState, batchId, hasProcessed, onBatchComplete]);
  const { formatMessage } = useIntl();

  const handleCancelBatch = useCallback(async () => {
    setCancelBatchInProgress(true);
    await cancelBatch(batchId);
    setCancelBatchInProgress(false);
    onBatchComplete();
    onClose();
  }, [cancelBatch, batchId, onClose, onBatchComplete]);

  const handleOkAction = useCallback(async () => {
    onClose();
    onBatchComplete();
  }, [onBatchComplete, onClose]);

  return (
    <>
      <DialogContent id={progressDescriptionId}>
        {!batchInProgress ? (
          <MassEditProjectResult batchId={batchId} />
        ) : (
          <CurrentProgressBarContent
            itemCounts={itemCounts}
            currentProgress={currentProgress}
            progressMessage={formatMessage({
              id: 'massEditProject.editingProjects'
            })}
            progressInfo={formatMessage({
              id: 'massEditProject.cancelActionInfo'
            })}
          />
        )}
      </DialogContent>
      <DialogActions id={progressActionsId}>
        {batchInProgress ? (
          <LoadingButton
            onClick={handleCancelBatch}
            disabled={cancelBatchInProgress}
            isLoading={cancelBatchInProgress}
          >
            {formatMessage({ id: 'button.cancel' })}
          </LoadingButton>
        ) : (
          <Button onClick={handleOkAction} color="primary">
            {formatMessage({ id: 'button.ok' })}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

MassEditProjectProgressContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  batchState: PropTypes.object.isRequired,
  setBatchState: PropTypes.func.isRequired,
  itemCounts: PropTypes.number.isRequired,
  onBatchComplete: PropTypes.func.isRequired
};

export default MassEditProjectProgressContent;
