import { useMeContext } from '~/modules/me';
import {
  getLuxonJsDateFormatFromMe,
  isoStringToFormattedISOString
} from '~/modules/common/dates/convert';
import useChangeResourceUserTaskAssignmentSummary from './useChangeResourceUserTaskAssignmentSummary';

const useErrorMessageSummary = ({
  asOfDate,
  canEditTask,
  existingResourceUser,
  isImpactedTaskSummaryLoading,
  maxTaskEndDate,
  newResourceUser,
  projectId,
  taskAssignmentCount
}) => {
  const messages = [];
  let title;

  const me = useMeContext();

  const {
    hasTaskAssignmentConflict,
    isLoading: isTaskAssignmentConflictLoading
  } = useChangeResourceUserTaskAssignmentSummary({
    asOfDate,
    existingResourceUserId: existingResourceUser.id,
    newResourceUserId: newResourceUser?.id,
    projectId,
    skip: !newResourceUser
  });

  const hasTaskPermissionError =
    !isImpactedTaskSummaryLoading && !canEditTask && taskAssignmentCount > 0;

  const isLoading =
    isImpactedTaskSummaryLoading ||
    (isTaskAssignmentConflictLoading && !hasTaskPermissionError);

  if (isLoading) {
    return {
      isLoading: true,
      messages,
      title
    };
  }

  if (hasTaskPermissionError) {
    title = { messageId: 'changeResourceDialog.taskPermissionError.title' };

    if (maxTaskEndDate) {
      const dateFormat = getLuxonJsDateFormatFromMe(me);

      messages.push({
        messageId:
          'changeResourceDialog.taskPermissionError.preventCurrentChange',
        values: {
          existingResourceUserName: existingResourceUser.displayText,
          maxTaskEndDate: isoStringToFormattedISOString(
            maxTaskEndDate,
            dateFormat
          )
        }
      });
    } else {
      messages.push({
        messageId:
          'changeResourceDialog.taskPermissionError.preventEntireChange',
        values: { existingResourceUserName: existingResourceUser.displayText }
      });
    }
  }

  if (!hasTaskPermissionError && hasTaskAssignmentConflict) {
    messages.push(
      {
        messageId:
          'changeResourceDialog.mergeErrorMessages.alreadyAssignedTask',
        values: {
          newResourceUserName: newResourceUser?.displayText,
          existingResourceUserName: existingResourceUser.displayText
        }
      },
      {
        messageId:
          'changeResourceDialog.mergeErrorMessages.combiningNotSupported'
      }
    );
  }

  return {
    isLoading: false,
    messages,
    title
  };
};

export default useErrorMessageSummary;
