import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';

const useAlertBannerStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    '& .MuiAlert-icon': { color: theme.palette.text.secondary }
  }
}));

const useAlertChipStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(0, 1.5),
    marginLeft: theme.spacing(2),
    '& .MuiAlert-icon': {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(0.5)
    }
  }
}));

const HiringRequestStatusAlert = ({ classes, titleId, descriptionId }) => {
  const alertChipClasses = useAlertChipStyles();
  const alertBannerClasses = useAlertBannerStyles();

  return (
    <Alert
      severity="warning"
      classes={descriptionId ? alertBannerClasses : alertChipClasses}
    >
      <AlertTitle className={classes.alertText}>
        <FormattedMessage id={titleId} />
      </AlertTitle>
      {descriptionId && (
        <Typography className={classes.alertText}>
          <FormattedMessage id={descriptionId} />
        </Typography>
      )}
    </Alert>
  );
};

HiringRequestStatusAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  titleId: PropTypes.string.isRequired,
  descriptionId: PropTypes.string
};

export default HiringRequestStatusAlert;
