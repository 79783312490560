import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  itemWidth,
  actionsWidth,
  totalWidth
} from '~/modules/common/charts/timeline/calculations';
import { TaskAssignmentDialog } from '~/modules/projects/resourcing-plan/components/TaskAssignmentDialog';
import { useDialogState } from '~/modules/common/hooks';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import {
  useResourceRequestToolbarContext,
  useRefetchQueriesOnTaskAllocationModification
} from '~/modules/projects/resourcing-plan/hooks';
import { UserTaskAssignmentFooterRow } from './components/UserTaskAssignmentFooterRow';

export const useActionStyles = makeStyles(theme => ({
  actionButton: {
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    margin: theme.spacing(0, 2, 0, 1.5),
    padding: theme.spacing(0.75, 1)
  }
}));

// FIXME on total component implementation
const leftComponentWidth = actionsWidth + itemWidth * 2 + totalWidth;

export const UserTaskAssignmentFooter = ({
  projectId,
  user,
  handleUpdateTaskAssignment
}) => {
  const actionClasses = useActionStyles();
  const { project } = useProjectContext();
  const { scale, chartDates } = useResourceRequestToolbarContext();
  const refetchQueriesOnRemoveTaskAssignment = useRefetchQueriesOnTaskAllocationModification(
    {
      userId: user.id,
      projectId
    }
  );

  const {
    open: taskAssignmentDialogOpen,
    openDialog: openTaskAssignmentDialog,
    closeDialog: closeTaskAssignmentDialog
  } = useDialogState(false);

  const onClose = useCallback(
    ({ isAssignmentDirty }) => {
      if (isAssignmentDirty) handleUpdateTaskAssignment();
      closeTaskAssignmentDialog();
    },
    [closeTaskAssignmentDialog, handleUpdateTaskAssignment]
  );

  const isEditAllowed = project.permittedActionUris.includes(
    'urn:replicon:project-action:edit-tasks'
  );

  const leftComponent = useMemo(
    () =>
      isEditAllowed ? (
        <Button
          className={actionClasses.actionButton}
          size="small"
          color="primary"
          variant="text"
          onClick={openTaskAssignmentDialog}
        >
          <FormattedMessage id="taskAllocationActions.assignTask" />
        </Button>
      ) : null,
    [isEditAllowed, actionClasses.actionButton, openTaskAssignmentDialog]
  );

  return (
    <>
      <UserTaskAssignmentFooterRow
        scale={scale}
        scaleItemCount={chartDates.length}
        leftComponent={leftComponent}
        leftComponentWidth={leftComponentWidth}
      ></UserTaskAssignmentFooterRow>
      {taskAssignmentDialogOpen && (
        <TaskAssignmentDialog
          permittedActionUris={project.permittedActionUris}
          projectId={projectId}
          resource={user}
          onClose={onClose}
          open={taskAssignmentDialogOpen}
          refetchQueriesOnRemoveTaskAssignment={
            refetchQueriesOnRemoveTaskAssignment
          }
        />
      )}
    </>
  );
};

UserTaskAssignmentFooter.propTypes = {
  projectId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  handleUpdateTaskAssignment: PropTypes.func.isRequired
};
