import { useCallback } from 'react';

export default ({
  handleIssueSyncClick,
  handleIssuePrintClick,
  handleIssueEmailClick,
  confirmationDialogName,
  onClose,
  setFailedLineItems,
  isPsaPrpCappedBillingEnabled
}) => {
  const handleClose = useCallback(() => {
    if (isPsaPrpCappedBillingEnabled) setFailedLineItems([]);
    onClose();
  }, [isPsaPrpCappedBillingEnabled, onClose, setFailedLineItems]);
  const handleClick = useCallback(async () => {
    ({
      sync: handleIssueSyncClick,
      print: handleIssuePrintClick,
      email: handleIssueEmailClick
    }[confirmationDialogName]());
    if (!isPsaPrpCappedBillingEnabled) onClose();
  }, [
    handleIssueSyncClick,
    handleIssuePrintClick,
    handleIssueEmailClick,
    confirmationDialogName,
    isPsaPrpCappedBillingEnabled,
    onClose
  ]);

  return {
    handleClose,
    handleClick
  };
};
