import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import {
  mapIsoStringtoUtcObject,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  fileExtension: {
    color: theme.palette.text.secondary
  }
}));

const getUploadedOnDate = ({ attachment, me }) => {
  const luxonFormat = getLuxonJsDateFormatFromMe(me);

  return mapIsoStringtoUtcObject(attachment.uploadedOn).toFormat(luxonFormat);
};

export const AttachmentsListSubTitleRow = ({ attachment }) => {
  const me = useMeContext();
  const classes = useStyles();

  const userDisplayText = useMemo(
    () =>
      attachment.uploadedBy
        ? { user: attachment.uploadedBy.displayText }
        : null,
    [attachment.uploadedBy]
  );

  return (
    <div className={classes.container}>
      {attachment.uploadedBy && attachment.uploadedBy.displayText && (
        <Typography variant="body2" color="textSecondary" noWrap>
          <FormattedMessage
            id="attachments.uploadedByDescription"
            values={userDisplayText}
          />
        </Typography>
      )}
      <Typography
        className={classes.fileExtension}
        variant="body2"
        color="textPrimary"
      >
        <FormattedMessage
          id={
            attachment.fileSize
              ? 'attachments.description'
              : 'attachments.uploadedOnDescription'
          }
          values={useMemo(
            () => ({
              fileSize: attachment.fileSize,
              date: getUploadedOnDate({ attachment, me })
            }),
            [attachment, me]
          )}
        />
      </Typography>
    </div>
  );
};

AttachmentsListSubTitleRow.propTypes = {
  attachment: PropTypes.object.isRequired
};

export default AttachmentsListSubTitleRow;
