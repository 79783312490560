/* eslint-disable react/jsx-max-depth */
import { Hidden, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import ListTablePropTypes from '../PropTypes';
import { MobileList } from '../MobileList';
import { DataTable } from '../components';
import { useListTableNavigationContext } from '../ListTableNavigationContext';

const useStyles = makeStyles(() => ({
  dataTable: {
    width: '100%'
  },
  tables: {
    top: '0px',
    width: '100%'
  }
}));

const NavigationBasedListTable = ({
  headers,
  footers,
  columns,
  records,
  totals,
  mobileListItem,
  mobileListItemSkeleton,
  onListItemClick,
  variant,
  useInlineStyles,
  hover,
  onRowClick,
  onCellClick,
  isLoading,
  loadingComponent: LoadingComponent,
  showLoadingComponent = true,
  showLoadMoreSkeleton = false,
  selected,
  onRowSelectionChange,
  showSelectAll
}) => {
  const classes = useStyles();
  const {
    disableInfiniteScroll: showNavigationButtons,
    currentPage,
    navigatePage,
    maximumPageSize
  } = useListTableNavigationContext();

  const handleSelectAll = useCallback(
    checked =>
      onRowSelectionChange &&
      onRowSelectionChange({
        isSelectAll: checked,
        records: checked ? records.map(r => r.id) : []
      }),
    [onRowSelectionChange, records]
  );

  const handleOnRowSelectionChange = useCallback(
    (id, checked) =>
      onRowSelectionChange &&
      onRowSelectionChange({
        isSelectAll: checked
          ? records?.length === selected?.records?.length + 1
          : false,
        records: checked
          ? [...selected?.records, id]
          : selected?.records?.filter(r => r !== id)
      }),
    [onRowSelectionChange, records?.length, selected?.records]
  );

  const navigationProps = useMemo(
    () => ({
      currentPage,
      navigatePage,
      maximumPageSize,
      showNavigationButtons
    }),
    [currentPage, navigatePage, maximumPageSize, showNavigationButtons]
  );

  return (
    <>
      <div className={classes.tables}>
        <Hidden smDown={Boolean(mobileListItem)}>
          {isLoading && showLoadingComponent ? (
            <LoadingComponent
              headers={headers}
              footers={footers}
              columns={columns}
            />
          ) : (
            <DataTable
              useInlineStyles={useInlineStyles}
              hover={hover}
              variant={variant}
              headers={headers}
              footers={footers}
              columns={columns}
              records={records}
              totals={totals}
              className={classes.dataTable}
              onRowClick={onRowClick}
              onCellClick={onCellClick}
              showLoadMoreSkeleton={isLoading && showLoadMoreSkeleton}
              onSelectAll={showSelectAll ? handleSelectAll : null}
              onRowSelectionChange={
                onRowSelectionChange ? handleOnRowSelectionChange : null
              }
              selected={selected}
              loading={isLoading}
              navigationProps={navigationProps}
              showSelectAll={showSelectAll}
            />
          )}
        </Hidden>
        {!!mobileListItem && (
          <Hidden mdUp={Boolean(mobileListItem)}>
            <MobileList
              mobileListItem={mobileListItem}
              mobileListItemSkeleton={mobileListItemSkeleton}
              records={records}
              isLoading={isLoading}
              onListItemClick={onListItemClick}
              selected={selected}
              onRowSelectionChange={
                onRowSelectionChange ? handleOnRowSelectionChange : null
              }
            />
          </Hidden>
        )}
      </div>
    </>
  );
};

NavigationBasedListTable.propTypes = {
  headers: ListTablePropTypes.headers,
  footers: ListTablePropTypes.footers,
  columns: ListTablePropTypes.columns,
  records: PropTypes.arrayOf(PropTypes.any),
  totals: PropTypes.any,
  isLoading: PropTypes.bool,
  loadingComponent: PropTypes.func,
  mobileListItem: PropTypes.any,
  mobileListItemSkeleton: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'table']),
  hover: PropTypes.bool,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onListItemClick: PropTypes.func,
  showLoadingComponent: PropTypes.bool,
  showLoadMoreSkeleton: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  selected: ListTablePropTypes.selected,
  useInlineStyles: PropTypes.bool,
  showSelectAll: PropTypes.bool
};

export default NavigationBasedListTable;
