import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Skeleton } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { getTextInBold } from '~/modules/common/util';

const useStyles = makeStyles(theme => ({
  alert: { marginTop: theme.spacing(2) },
  skeleton: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(0.5)
  },
  ul: {
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(0.5, 0)
  }
}));

export const ImpactedTaskSummaryAlert = ({
  classes: classesOverride,
  isLoading,
  messages,
  severity,
  skeletonHeight = 100,
  title
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <>
      {isLoading ? (
        <Skeleton
          className={classes.skeleton}
          height={skeletonHeight}
          width="100%"
          variant="rect"
        />
      ) : (
        <>
          {messages?.length > 0 && (
            <Alert severity={severity} icon={false} className={classes.alert}>
              <Typography variant="body1">
                <FormattedMessage
                  id={title.messageId}
                  values={getTextInBold(title?.values)}
                />
              </Typography>
              <ul className={classes.ul}>
                {messages.map(({ messageId, values }) => (
                  <li key={messageId}>
                    <Typography variant="body2">
                      <FormattedMessage
                        id={messageId}
                        values={getTextInBold(values)}
                      />
                    </Typography>
                  </li>
                ))}
              </ul>
            </Alert>
          )}
        </>
      )}
    </>
  );
};

ImpactedTaskSummaryAlert.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  messages: PropTypes.array,
  severity: PropTypes.string.isRequired,
  skeletonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.object
};

export default ImpactedTaskSummaryAlert;
