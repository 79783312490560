import PropTypes from 'prop-types';
import React from 'react';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  PERIOD_SCALE_ENUM,
  DISPLAY_UNIT_ENUM
} from '~/modules/resourcing/common/enums';
import {
  ResourceMatchList,
  ResourceMatchPopoverHeader,
  ResourceMatchPopoverFooter
} from './components';
import RESOURCE_MATCH_POPOVER_BUTTON_ACTION from './enum';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1.5)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3.75),
    minWidth: 500
  }
}));

const resourceMatchPopoverAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

const resourceMatchPopoverTransformOrigin = {
  vertical: 'top',
  horizontal: 'left'
};

export const ResourceMatchPopover = ({
  open,
  anchorEl,
  onClose,
  scale,
  displayUnit,
  resourceRequest,
  allocationChartRef,
  buttonType
}) => {
  const classes = useStyles();

  return (
    <Popover
      id="resource-match-popover"
      className={classes.root}
      anchorOrigin={resourceMatchPopoverAnchorOrigin}
      transformOrigin={resourceMatchPopoverTransformOrigin}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <div className={classes.content}>
        <ResourceMatchPopoverHeader resourceRequest={resourceRequest} />
        <ResourceMatchList
          resourceRequest={resourceRequest}
          onClose={onClose}
          allocationChartRef={allocationChartRef}
          buttonType={buttonType}
        />
        <ResourceMatchPopoverFooter
          resourceRequest={resourceRequest}
          scale={scale}
          displayUnit={displayUnit}
          onClose={onClose}
        />
      </div>
    </Popover>
  );
};

ResourceMatchPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.YEARS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.WEEKS,
    PERIOD_SCALE_ENUM.ENTIRE_PROJECT
  ]),
  displayUnit: PropTypes.oneOf([
    DISPLAY_UNIT_ENUM.HOURS,
    DISPLAY_UNIT_ENUM.PERCENTAGE
  ]),
  allocationChartRef: PropTypes.object,
  buttonType: PropTypes.oneOf([
    RESOURCE_MATCH_POPOVER_BUTTON_ACTION.ACCEPT,
    RESOURCE_MATCH_POPOVER_BUTTON_ACTION.PROPOSE
  ]).isRequired
};

export default ResourceMatchPopover;
