import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const GetUserProfileSkillsQuery = gql`
  query userProfileSkills($userUri: String!) {
    userProfileSkills(userUri: $userUri) {
      id
      skills {
        categoryUri
        categoryName
        skillUri
        skillName
        skillLevelUri
        skillLevelName
        skillLevel
      }
    }
  }
`;

export const useUserProfileSkills = ({
  userUri,
  isPsaPraaUserProfileNudgesEnabled
}) => {
  const { loading: isUserProfileSkillsLoading, error, data } = useQuery(
    GetUserProfileSkillsQuery,
    {
      variables: {
        userUri
      },
      skip: !isPsaPraaUserProfileNudgesEnabled
    }
  );

  return {
    error,
    userProfileSkills: data?.userProfileSkills?.skills || [],
    isUserProfileSkillsLoading
  };
};

export default useUserProfileSkills;
