import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { useMeContext } from '~/modules/me';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { ResourceAllocationEditor } from './components';
import useStyles from './useStyles';

const defaultOffSet = 80;
const paperProps = {
  elevation: 2
};

const ResourceAllocationPeriodEditPopover = ({
  classes: classesOverrides,
  anchorEl,
  periodDetails,
  allocatedHours = 0,
  scheduledHours = 0,
  availableHours = 0,
  popoverClasses,
  isAllocationComplete = false,
  useCreateAllocation,
  isDataLoading = false,
  handleAllocationPeriodClose,
  setNextPeriod,
  onAllocationChange,
  setPreviousPeriod,
  resourceAllocation,
  getAllocationPeriodAvailabilityDateRange,
  taskAllocationHours
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const isOpen = Boolean(anchorEl);
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = useMeContext();

  const handlePeriodUpdate = useCallback(
    event => {
      handleAllocationPeriodClose(periodDetails, event);
    },
    [handleAllocationPeriodClose, periodDetails]
  );

  const handleNext = useCallback(
    (allocation, allocatedValue) => {
      setNextPeriod(allocatedValue);
    },
    [setNextPeriod]
  );

  const handlePrevious = useCallback(
    (allocation, allocatedValue) => {
      setPreviousPeriod(allocatedValue);
    },
    [setPreviousPeriod]
  );

  const style = anchorEl
    ? {
        minWidth: anchorEl.offsetWidth,
        minHeight: anchorEl.offsetHeight,
        width: isPsaRmpTaskAllocation1Enabled ? 'min-content' : undefined
      }
    : null;

  const anchorProps = useMemo(() => {
    const anchorRight =
      mapIsoStringtoUtcObject(resourceAllocation.startDate) >
      periodDetails.startDate;
    const anchorLeft =
      mapIsoStringtoUtcObject(resourceAllocation.endDate) <
      periodDetails.endDate;

    return {
      vertical: 'top',
      horizontal: anchorRight ? 'right' : anchorLeft ? 'left' : 'center'
    };
  }, [periodDetails, resourceAllocation]);

  return (
    <Popover
      id="period-editor"
      anchorEl={anchorEl}
      anchorOrigin={anchorProps}
      transformOrigin={anchorProps}
      open={isOpen}
      onClose={handleAllocationPeriodClose}
      classes={popoverClasses}
      PaperProps={paperProps}
    >
      <div className={classes.container} style={style}>
        <ResourceAllocationEditor
          handlePeriodUpdate={handlePeriodUpdate}
          handlePreviousPeriod={handlePrevious}
          handleNextPeriod={handleNext}
          key={periodDetails.startDate.toISO()}
          isDataLoading={isDataLoading}
          isAllocationComplete={isAllocationComplete}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          useCreateAllocation={useCreateAllocation}
          allocatedHours={allocatedHours}
          targetWidth={anchorEl ? anchorEl.offsetWidth : defaultOffSet}
          targetHeight={anchorEl ? anchorEl.offsetHeight : defaultOffSet}
          handleAllocationPeriodClose
          scheduledHours={scheduledHours}
          availableHours={availableHours}
          periodDetails={periodDetails}
          resourceAllocation={resourceAllocation}
          getAllocationPeriodAvailabilityDateRange={
            getAllocationPeriodAvailabilityDateRange
          }
          onAllocationChange={onAllocationChange}
          taskAllocationHours={taskAllocationHours}
        />
      </div>
    </Popover>
  );
};

ResourceAllocationPeriodEditPopover.propTypes = {
  classes: PropTypes.object,
  anchorEl: PropTypes.object,
  periodDetails: PropTypes.object,
  handleAllocationPeriodClose: PropTypes.func,
  setNextPeriod: PropTypes.func,
  allocatedHours: PropTypes.number,
  scheduledHours: PropTypes.number,
  availableHours: PropTypes.number,
  popoverClasses: PropTypes.object,
  useCreateAllocation: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool,
  isAllocationComplete: PropTypes.bool,
  onAllocationChange: PropTypes.func,
  setPreviousPeriod: PropTypes.func,
  resourceAllocation: PropTypes.object,
  getAllocationPeriodAvailabilityDateRange: PropTypes.func,
  taskAllocationHours: PropTypes.number
};

export default ResourceAllocationPeriodEditPopover;
