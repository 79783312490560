import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 2,
    top: theme.spacing(2),
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: theme.spacing(83.125),
    '&.MuiAlert-root': {
      padding: theme.spacing(1, 2, 0),
      boxShadow: theme.shadows[4],
      backgroundColor: theme.palette.Dela.banner.background
    }
  },
  action: {
    color: theme.palette.text.primary,
    position: 'absolute',
    top: theme.spacing(0.75),
    right: theme.spacing(3),
    margin: 0
  },
  message: {
    color: theme.palette.text.primary
  },
  icon: {
    '&.MuiAlert-icon': {
      color: `${theme.palette.Dela.banner.icon} !important`
    }
  },
  container: {
    display: 'flex'
  },
  button: {
    padding: theme.spacing(0.875, 1),
    margin: theme.spacing(-1, 0, 0, 3)
  }
}));

export default useStyles;
