import { useMutation } from '@apollo/client';
import { CREATE_TASK_MUTATION } from '~/modules/projects/tasks/hooks/useCreateTask';
import { useMeContext } from '~/modules/me';

export const useCreateTask = ({
  setMilestoneTaskCreated,
  setTaskAddedCount
}) => {
  const me = useMeContext();
  const [createTask] = useMutation(CREATE_TASK_MUTATION);
  const { featureFlags } = me;
  const { isPsaRmpTaskAllocation1Enabled } = featureFlags;

  return {
    createTask: async ({
      parentUri,
      name,
      code,
      startDate,
      endDate,
      description,
      estimatedHours,
      estimatedCost,
      isMilestone,
      assignedUser,
      assignedRole,
      isTimeEntryAllowed,
      timeAndExpenseEntryType,
      assignedUserRoleId,
      costType,
      customFields,
      resourceEstimates,
      timesheetAccessUris
    }) => {
      const task = {
        parentUri,
        name,
        code,
        startDate,
        endDate,
        description,
        estimatedHours,
        estimatedCost,
        isMilestone,
        assignedUserUri: assignedUser ? assignedUser.id : null,
        assignedUserRoleId,
        assignedRoleUri: assignedRole ? assignedRole.id : null,
        isTimeEntryAllowed,
        timeAndExpenseEntryTypeUri:
          timeAndExpenseEntryType && timeAndExpenseEntryType.id,
        costTypeUri: costType ? costType.id : null,
        customFields,
        ...(isPsaRmpTaskAllocation1Enabled
          ? { resourceEstimates, timesheetAccessUris }
          : {})
      };

      const { data } = await createTask({
        variables: {
          task
        },
        refetchQueries: ['projectTasksQuery', 'TaskDropdown'],
        awaitRefetchQueries: true
      });

      if (data) {
        setMilestoneTaskCreated(isMilestone);
        if (isMilestone) setTaskAddedCount(prevCount => prevCount + 1);
      }
    }
  };
};

export default useCreateTask;
