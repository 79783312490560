import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TaskCopyOption } from '~/types';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';

const taskOptions = [
  TaskCopyOption.CopyTaskOnly,
  TaskCopyOption.CopyTaskWithAssignmentsAsPlaceholders,
  TaskCopyOption.CopyTaskResourceAssignments
];

export const TaskCopyDropdown = ({ value, onChange, ...rest }) => {
  const intl = useIntl();

  const options = taskOptions.map(key => ({
    id: key,
    displayText: intl.formatMessage({ id: `taskCopyOptions.${key}` })
  }));

  const selectedValue = useMemo(() => options.find(x => x.id === value), [
    options,
    value
  ]);

  return (
    <SimpleDropdown
      options={options}
      onChange={onChange}
      value={selectedValue}
      disableSort
      {...rest}
    />
  );
};

TaskCopyDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default TaskCopyDropdown;
