import { array, number, object, string, mixed, bool } from 'yup';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { useUpdateClientBillingInfo } from '~/modules/clients/client/hooks';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import { ConsolidateBillsBy } from '~/types';

export const initialState = ({
  id,
  defaultInvoicePaymentTerm,
  billLineItemsBy,
  invoiceCurrency,
  taxProfile,
  invoiceTemplate,
  description,
  internalNotes,
  showComments,
  billFrequency,
  billFrequencyRelativeDate,
  billFrequencyAbsoluteDate,
  consolidateBillsBy
}) => ({
  id,
  defaultInvoicePaymentTerm,
  billLineItemsBy: billLineItemsBy || [BILLING_COLUMN_TYPE.PROJECT],
  invoiceCurrency,
  taxProfile,
  invoiceTemplate,
  description: description || null,
  internalNotes: internalNotes || null,
  showComments: showComments || false,
  context: 'client',
  billFrequency,
  billFrequencyRelativeDate,
  billFrequencyAbsoluteDate,
  consolidateBillsBy: consolidateBillsBy || ConsolidateBillsBy.Client
});

export const buildValidationSchema = ({ intl }) =>
  object().shape({
    id: string(),
    invoiceCurrency: string().nullable(),
    invoiceTemplate: string().nullable(),
    defaultInvoicePaymentTerm: number()
      .min(0)
      .max(25000)
      .nullable(),
    taxProfile: string().nullable(),
    billLineItemsBy: array().of(
      mixed().oneOf(Object.keys(BILLING_COLUMN_TYPE))
    ),
    description: string()
      .max(
        255,
        intl.formatMessage({
          id: 'billDetails.validations.descriptionExceedsMaxLength'
        })
      )
      .nullable(),
    internalNotes: string().nullable(),
    showComments: bool()
  });

const useFormState = ({
  id,
  defaultInvoicePaymentTerm,
  billLineItemsBy,
  invoiceCurrency,
  taxProfile,
  invoiceTemplate,
  description,
  internalNotes,
  showComments,
  billFrequency,
  billFrequencyRelativeDate,
  billFrequencyAbsoluteDate,
  consolidateBillsBy
}) => {
  const intl = useIntl();
  const { onSubmit } = useUpdateClientBillingInfo();
  const initialValues = initialState({
    id,
    defaultInvoicePaymentTerm,
    billLineItemsBy,
    invoiceCurrency,
    taxProfile,
    invoiceTemplate,
    description,
    internalNotes,
    showComments,
    billFrequency,
    billFrequencyRelativeDate,
    billFrequencyAbsoluteDate,
    consolidateBillsBy
  });
  const validationSchema = useMemo(() => buildValidationSchema({ intl }), [
    intl
  ]);

  return {
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true
  };
};

export default useFormState;
