import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useLocations from '~/modules/common/enhancers/useLocations';
import { useAdditionalOptions } from '~/modules/common/hooks';
import { useOptionsMerge } from '~/modules/common/components/SearchAutocomplete';
import { useStyledGroupLabels } from './hooks';
import GroupDropdown from './GroupDropdown';

export const LocationsDropdown = ({
  classes,
  groupSettings,
  value = '',
  policy,
  onChange,
  enableNoChangeOption = false,
  enableNoValueOption = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const { location: locationLabel } = useStyledGroupLabels();
  const { locations, loading, hasMore } = useLocations({ searchTerm, policy });
  const additionalOptions = useAdditionalOptions({
    enableNoChangeOption,
    enableNoValueOption,
    noValueMessageId: 'dropdownItem.noLocation'
  });

  const noGroupsText = groupSettings.singularName
    ? formatMessage(
        { id: 'groupsDropdown.noGroup' },
        { group: groupSettings.singularName }
      )
    : formatMessage({ id: 'locationsDropdown.noLocation' });

  const options = useOptionsMerge({
    options: locations,
    additionalOptions
  });

  return (
    <GroupDropdown
      hasMore={hasMore}
      classes={classes}
      options={options}
      label={locationLabel}
      value={value}
      loading={loading}
      setSearchTerm={setSearchTerm}
      noOptionsText={noGroupsText}
      onChange={onChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.location'
      })}
      {...rest}
    />
  );
};

LocationsDropdown.propTypes = {
  classes: PropTypes.object,
  groupSettings: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
  policy: PropTypes.string,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool
};

export default LocationsDropdown;
