import { useCallback } from 'react';

const transformDropdownResult = result => ({
  displayText: result && result.label,
  id: result && result.value
});

export const useChangeHandlers = ({ setFieldValue }) => {
  const onWorkflowStageChange = useCallback(
    v => setFieldValue('workflowStage', v),
    [setFieldValue]
  );

  const onCurrencyChange = useCallback(v => setFieldValue('currency', v), [
    setFieldValue
  ]);

  const onClientChange = useCallback(
    v => {
      setFieldValue('client', transformDropdownResult(v));
      setFieldValue('clientRepresentative', undefined);
    },
    [setFieldValue]
  );

  const onClientRepresentativeChange = useCallback(
    v => setFieldValue('clientRepresentative', v),
    [setFieldValue]
  );

  const onProgramChange = useCallback(
    v => setFieldValue('program', transformDropdownResult(v)),
    [setFieldValue]
  );

  const onCostTypeChange = useCallback(v => setFieldValue('costType', v), [
    setFieldValue
  ]);

  const onProjectManagerChange = useCallback(
    (_, v) => setFieldValue('projectManager', v),
    [setFieldValue]
  );

  const onTimesheetApprovalChange = useCallback(
    v => setFieldValue('projectManagerTimesheetApproval', v),
    [setFieldValue]
  );

  const onTimeAndExpenseEntryChange = useCallback(
    v => setFieldValue('timeAndExpenseEntryType', v),
    [setFieldValue]
  );

  const onTimeEntryChange = useCallback(v => setFieldValue('timeEntry', v), [
    setFieldValue
  ]);

  const onCostCenterChange = useCallback(v => setFieldValue('costCenter', v), [
    setFieldValue
  ]);

  const onDepartmentChange = useCallback(v => setFieldValue('department', v), [
    setFieldValue
  ]);

  const onDivisionChange = useCallback(v => setFieldValue('division', v), [
    setFieldValue
  ]);

  const onEmployeeTypeChange = useCallback(
    v => setFieldValue('employeeType', v),
    [setFieldValue]
  );

  const onLocationChange = useCallback(v => setFieldValue('location', v), [
    setFieldValue
  ]);

  const onServiceCenterChange = useCallback(
    v => setFieldValue('serviceCenter', v),
    [setFieldValue]
  );

  return {
    onWorkflowStageChange,
    onCurrencyChange,
    onClientChange,
    onClientRepresentativeChange,
    onProgramChange,
    onCostTypeChange,
    onProjectManagerChange,
    onTimesheetApprovalChange,
    onTimeAndExpenseEntryChange,
    onTimeEntryChange,
    onCostCenterChange,
    onDepartmentChange,
    onDivisionChange,
    onEmployeeTypeChange,
    onLocationChange,
    onServiceCenterChange
  };
};

export default useChangeHandlers;
