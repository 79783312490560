import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useAdditionalOptions } from '~/modules/common/hooks';
import { useOptionsMerge } from '~/modules/common/components/SearchAutocomplete';
import { ProgramDropdown } from '../../project/common/components';
import useProgramDropdownOptionsForProject from './useProgramDropdownOptionsForProject';
import useMyProgramDropDownOptionsForProject from './useMyProgramDropDownOptionsForProject';

export const ProgramDropdownForProject = ({
  value,
  onChange,
  shouldFetchOnlyMyPrograms = false,
  enableNoChangeOption = false,
  enableNoValueOption = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const additionalOptions = useAdditionalOptions({
    enableNoChangeOption,
    enableNoValueOption,
    noValueMessageId: 'dropdownItem.noProgram'
  });
  const { options, loading } = useProgramDropdownOptionsForProject(
    shouldFetchOnlyMyPrograms
  );

  const {
    hasMore,
    options: myProgramOptions,
    loading: myProgramOptionsLoading
  } = useMyProgramDropDownOptionsForProject(!shouldFetchOnlyMyPrograms);

  const mergedOptions = useOptionsMerge({
    options: shouldFetchOnlyMyPrograms ? myProgramOptions : options,
    additionalOptions
  });

  return (
    <ProgramDropdown
      hasMore={hasMore}
      options={mergedOptions}
      isLoading={shouldFetchOnlyMyPrograms ? myProgramOptionsLoading : loading}
      value={value}
      onChange={onChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.programs'
      })}
      {...rest}
    />
  );
};

ProgramDropdownForProject.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  shouldFetchOnlyMyPrograms: PropTypes.bool,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool
};

export default ProgramDropdownForProject;
