import { useQuery } from '@apollo/client';
import { useState, useCallback } from 'react';
import get from 'lodash.get';
import {
  isoStringToObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me';
import { getTodayForUser } from '~/modules/common/dates/today';
import {
  getEquivalentTaskStatus,
  getEquivalentRolledUpTaskStatus,
  getRolledUpEstimatedAtCompletionHours,
  isProjectClosed
} from './util';
import { PROJECT_DETAILS_QUERY } from './projectDetailsQuery';

const PAGE_SIZE = 10;

const updateQueryOnFetchMoreResult = (prev, fetchMoreResult) => {
  if (!fetchMoreResult) return prev;

  return {
    project: {
      ...prev.project,
      tasks: [
        ...prev.project.tasks,
        ...fetchMoreResult.fetchMoreResult.project.tasks
      ]
    }
  };
};

const mapToEnrichedProject = ({
  project,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const {
    rolledUpSummary,
    rolledUpCostSummary,
    projectEstimatedExpenseRemainingSeriesByMonth,
    status,
    startDate,
    endDate,
    tasks,
    workflow
  } = project;
  const { actualHours, totalEstimatedAtCompletionHours } = rolledUpSummary;

  return {
    ...project,
    rolledUpSummary: {
      ...rolledUpSummary,
      totalEstimatedAtCompletionHours: getRolledUpEstimatedAtCompletionHours({
        status: status.name,
        actualHours,
        totalEstimatedAtCompletionHours
      })
    },
    rolledUpCostSummary: rolledUpCostSummary && {
      ...rolledUpCostSummary,
      intialEstimateOrBudgetedCost:
        rolledUpCostSummary.budgetedCostInProjectCurrency
    },
    projectEstimatedExpenseRemainingSeriesByMonth: projectEstimatedExpenseRemainingSeriesByMonth?.dataPoints.filter(
      e => e?.estimatedExpensesInProjectCurrency?.amount !== 0
    ),
    status: isRolledUpTaskEstimateCalculationMethodEnabled
      ? getEquivalentRolledUpTaskStatus({
          status: status.name,
          actualHours
        })
      : getEquivalentTaskStatus(status.name),
    startDate: isoStringToObject(startDate),
    endDate: isoStringToObject(endDate),
    tasks: tasks.map(task => ({
      ...task,
      closedOnDate: isoStringToObject(task.closedOnDate),
      endDate: isoStringToObject(task.endDate)
    })),
    isClosed: isProjectClosed(workflow)
  };
};

export const useProjectDetailsQuery = ({
  projectId,
  isRolledUpTaskEstimateCalculationMethodEnabled = false
}) => {
  const [loadingMore, setLoadingMore] = useState(false);

  const me = useMeContext();
  const {
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled }
  } = me;

  const includeRolledUpCostSummary =
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled;

  const { data, error, loading, fetchMore } = useQuery(PROJECT_DETAILS_QUERY, {
    variables: {
      projectId,
      page: 1,
      pageSize: PAGE_SIZE,
      projectEstimatedExpenseRemainingSeriesDateRange: {
        startDate: mapRepliconDateToUtcObject(getTodayForUser(me)).toISODate(),
        endDate: null
      },
      maxHierarchyDepth: 1,
      isRolledUpTaskEstimateCalculationMethodEnabled,
      includeRolledUpCostSummary
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  });

  const project = get(data, 'project');

  const hasMore =
    project && project.tasks && project.tasks.length % PAGE_SIZE === 0;

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    setLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          projectId,
          page: project.tasks.length / PAGE_SIZE + 1,
          pageSize: PAGE_SIZE
        },
        updateQuery: updateQueryOnFetchMoreResult
      });
    } finally {
      setLoadingMore(false);
    }
  }, [projectId, fetchMore, hasMore, project]);

  return {
    loading,
    error,
    project:
      !loading && !error
        ? mapToEnrichedProject({
            project,
            isRolledUpTaskEstimateCalculationMethodEnabled
          })
        : project,
    hasMore,
    loadMore,
    loadingMore
  };
};
