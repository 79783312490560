import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    fontSize: theme.typography.caption.fontSize,
    border: `1px solid ${theme.palette.table.border}`
  },
  name: {
    width: 140
  },
  cellHeader: {
    display: 'flex',
    alignItems: 'stretch'
  },
  cell: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0.75, 2)
  }
}));

export const useTableHeaderStyles = makeStyles(theme => ({
  cell: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.light
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    textAlign: 'left',
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1, 0.5, 1, 2)
  }
}));

export const usePageSelectorStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent'
  },
  pageList: {
    padding: theme.spacing(0, 1.5)
  }
}));

export const useToolTipStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: '16px !important'
  },
  container: {
    display: 'flex'
  }
}));

export default useStyles;
