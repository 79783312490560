import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button as MuiButton } from '@material-ui/core';

export const SubmitButton = ({ component = MuiButton, ...props }) => {
  const Button = component;

  return (
    <Button {...props}>
      <FormattedMessage id="button.submit" />
    </Button>
  );
};

SubmitButton.propTypes = {
  component: PropTypes.object
};

export default SubmitButton;
