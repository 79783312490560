import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

export const NoneDropdownItem = ({ value, classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <Typography variant="inherit" classes={classes} color="inherit">
      {value}
    </Typography>
  );
};

NoneDropdownItem.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string
};

export default NoneDropdownItem;
