import { useCallback } from 'react';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';

const getHasAssignedRole = ({ userRoles, assignedRole }) =>
  Number(Boolean(userRoles?.find(r => r.projectRole?.uri === assignedRole.id)));

export const useDropdownHandlers = ({
  onResourceChange,
  setSearchTerm,
  formatMessage,
  assignedRole,
  selectedResources,
  includeNoneOption,
  includeNoneOptionText
}) => {
  const handleOnChange = useCallback(
    resources => {
      const resource =
        resources && resources.length ? resources[resources.length - 1] : null;

      onResourceChange(resource);
      setSearchTerm('');
    },
    [onResourceChange, setSearchTerm]
  );

  const getOptionDisabled = useCallback(
    option =>
      option.isAssignedToTask ||
      option.id === MORE_AVAILABLE_OPTION_ID ||
      Boolean(selectedResources?.find(id => id === option?.id)),
    [selectedResources]
  );

  const handleInputChange = (_, v) => setSearchTerm(v.trim());

  const getGroupBy = useCallback(
    user =>
      user.hasAssignedRole
        ? `${assignedRole.displayText} ${formatMessage({
            id: 'addResource.resources'
          })}`
        : user.id === MORE_AVAILABLE_OPTION_ID
        ? null
        : formatMessage({
            id: user.isAllocatedResource
              ? 'taskResourceAssignments.allocatedResources'
              : 'taskResourceAssignments.timesheetAccess'
          }),
    [assignedRole, formatMessage]
  );

  const getOptions = useCallback(
    ({ users, hasMore }) => {
      const options = assignedRole
        ? users
            .map(u => ({
              ...u,
              hasAssignedRole: getHasAssignedRole({
                userRoles: u.roles,
                assignedRole
              })
            }))
            .sort((a, b) => b.hasAssignedRole - a.hasAssignedRole)
        : users;

      const shouldAddMoreAvailableOption = hasMore && options?.length;

      shouldAddMoreAvailableOption &&
        options.push({
          id: 'searchAutocomplete.moreAvailable',
          displayText: formatMessage(
            {
              id: MORE_AVAILABLE_OPTION_ID
            },
            {
              option: formatMessage({
                id: 'moreOptions.resources'
              })
            }
          )
        });

      return includeNoneOption
        ? [
            { id: NONE_OPTION_KEY, displayText: includeNoneOptionText },
            ...options
          ]
        : options;
    },
    [assignedRole, formatMessage, includeNoneOption, includeNoneOptionText]
  );

  return {
    handleOnChange,
    getOptionDisabled,
    handleInputChange,
    getGroupBy,
    getOptions
  };
};

export default useDropdownHandlers;
