import { Grid, makeStyles, Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import OverviewItem from './OverviewItem';
import useChartPermissions from './useChartPermissions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  budgetLegendDescription: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  budgetLegend: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: '1px',
    border: '1px',
    borderStyle: 'dashed',
    borderColor: grey[600]
  },
  loss: {
    color: theme.palette.error.dark
  }
}));

const HoursOverviewItemTitle = <FormattedMessage id="kpiCharts.hours" />;
const MarginOverviewItemTitle = <FormattedMessage id="kpiCharts.margin" />;
const CostOverviewItemTitle = <FormattedMessage id="kpiCharts.cost" />;
const RevenueOverviewItemTitle = (
  <FormattedMessage id="kpiCharts.billableRevenue" />
);
const ProfitOverviewItemTitle = <FormattedMessage id="kpiCharts.profit" />;

export const OverviewChart = ({
  costAmountSummary,
  timeEnteredSummary,
  revenueAmountSummary,
  profitAmountSummary,
  budget,
  previousYearsCostsAggregate,
  showCost = true,
  showRevenue,
  resourceLabels = null
}) => {
  const classes = useStyles();
  const { costActual } = costAmountSummary || {};
  const { timeEnteredActual } = timeEnteredSummary || {};
  const { revenueActual } = revenueAmountSummary || {};
  const { profitActual } = profitAmountSummary || {};

  const marginAmount =
    revenueActual && costActual && revenueActual.amount !== 0
      ? (revenueActual.amount - costActual.amount) / revenueActual.amount
      : null;

  const profitOverviewItemClasses = useMemo(
    () =>
      profitActual && profitActual.amount && Math.sign(profitActual.amount) < 0
        ? { value: classes.loss }
        : {},
    [classes.loss, profitActual]
  );
  const { showHours, showMargin } = useChartPermissions({
    showRevenue
  });

  return (
    <>
      {budget && budget.total && budget.total.amount > 0 && (
        <div className={classes.budgetLegendDescription}>
          <span className={classes.budgetLegend} />
          <Typography variant="subtitle1">
            <FormattedMessage id="dashboardOverviewChart.budgetLegend" />
          </Typography>
        </div>
      )}
      <Grid container className={classes.container} spacing={2}>
        {showRevenue && (
          <Grid item lg={3} md={3} xs={6}>
            <OverviewItem
              title={
                resourceLabels?.revenueOverview || RevenueOverviewItemTitle
              }
              value={revenueActual && revenueActual.amount}
              currency={revenueActual && revenueActual.currency}
              dataQeId="kpiBillableRevenue"
            />
          </Grid>
        )}
        {showCost && (
          <Grid item lg={3} md={3} xs={6}>
            <OverviewItem
              title={CostOverviewItemTitle}
              value={costActual && costActual.amount}
              currency={costActual && costActual.currency}
              budget={budget}
              previousYearsAggregate={previousYearsCostsAggregate}
              dataQeId="kpiCost"
            />
          </Grid>
        )}
        {showCost && showRevenue && (
          <Grid item lg={3} md={3} xs={6}>
            <OverviewItem
              title={resourceLabels?.profitOverview || ProfitOverviewItemTitle}
              value={profitActual && profitActual.amount}
              currency={profitActual && profitActual.currency}
              classes={profitOverviewItemClasses}
              dataQeId="kpiProfit"
            />
          </Grid>
        )}
        {showMargin || showHours ? (
          <Grid item lg={3} md={3} xs={6}>
            {showMargin ? (
              <OverviewItem
                title={
                  resourceLabels?.marginOverview || MarginOverviewItemTitle
                }
                value={marginAmount}
                dataQeId="kpiMargin"
                isPercentage
              />
            ) : (
              <OverviewItem
                title={HoursOverviewItemTitle}
                value={timeEnteredActual}
                dataQeId="kpiHours"
              />
            )}
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

OverviewChart.propTypes = {
  costAmountSummary: PropTypes.object.isRequired,
  timeEnteredSummary: PropTypes.object,
  revenueAmountSummary: PropTypes.object,
  profitAmountSummary: PropTypes.object,
  budget: PropTypes.object,
  previousYearsCostsAggregate: PropTypes.number,
  showCost: PropTypes.bool,
  showRevenue: PropTypes.bool,
  resourceLabels: PropTypes.object
};

export default OverviewChart;
