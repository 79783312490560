import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const PROJECT_INFO_MODIFICATION_BATCH_RESULTS_QUERY = gql`
  query GetProjectInfoModificationBatchResults($batchId: String!) {
    projectInfoModificationBatchResults(batchId: $batchId) {
      results {
        project {
          displayText
          id
          uri
        }
        notifications {
          displayText
          failureUri
          severityUri
        }
      }
    }
  }
`;

export const useGetProjectInfoModificationBatchResults = ({ batchId }) => {
  const { data, loading: isLoading } = useQuery(
    PROJECT_INFO_MODIFICATION_BATCH_RESULTS_QUERY,
    {
      variables: {
        batchId
      },
      fetchPolicy: 'network-only'
    }
  );

  const results = get(data, 'projectInfoModificationBatchResults.results', []);

  const { failedProjects, updatedProjects } = results.reduce(
    (acc, result) => {
      if (result.notifications && result.notifications.length > 0) {
        acc.failedProjects.push({
          id: result.project.id,
          projectName: result.project.displayText,
          errorMessages: result.notifications.map(
            notification => notification.displayText
          )
        });
      } else {
        acc.updatedProjects.push({
          id: result.project.id,
          projectName: result.project.displayText
        });
      }

      return acc;
    },
    { failedProjects: [], updatedProjects: [] }
  );

  return { isLoading, failedProjects, updatedProjects };
};

export default useGetProjectInfoModificationBatchResults;
