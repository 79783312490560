import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import FormattedDeltaChip from '../../FormattedDeltaChip/FormattedDeltaChip';
import EstimateItemMetric from './EstimateItemMetric/EstimateItemMetric';

export const useStyles = makeStyles(theme => ({
  estimate: {
    display: 'flex'
  },
  variance: {
    marginLeft: 4
  },
  icon: {
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  margin: {
    margin: theme.spacing(0, 0, 2)
  }
}));

export const useVarianceStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold'
  },
  diffPositive: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark
  }
}));

const getValueOrDefault = value => value || 0;

export const ResourceEstimateContent = ({
  resourceEstimate,
  isReadOnlyView = true
}) => {
  const classes = useStyles();
  const varianceClasses = useVarianceStyles();

  const {
    initialEstimatedHours,
    initialEstimatedCost,
    taskResourceUserCostProgressSummary,
    taskResourceUserHoursProgressSummary
  } = resourceEstimate;

  const { actualHours, estimatedAtCompletionHours, estimatedRemainingHours } =
    taskResourceUserHoursProgressSummary || {};
  const { actualCost, estimatedAtCompletionCost, estimatedRemainingCost } =
    taskResourceUserCostProgressSummary || {};
  const hoursVariance =
    getValueOrDefault(estimatedAtCompletionHours) -
    getValueOrDefault(initialEstimatedHours);
  const { canViewCost } = useTaskEstimateRowContext();
  const costVariance = canViewCost
    ? getValueOrDefault(estimatedAtCompletionCost?.amount) -
      getValueOrDefault(initialEstimatedCost?.amount)
    : 0;
  const currencySymbol = `${initialEstimatedCost?.currency?.symbol ||
    estimatedAtCompletionCost?.currency?.symbol} `;

  return (
    <>
      <EstimateItemMetric
        formatMessageKey="taskResourceEstimates.actual"
        hours={getValueOrDefault(actualHours)}
        cost={actualCost}
        isReadOnlyView={isReadOnlyView}
      />
      <span className={classes.icon}>
        <AddSharp />
      </span>
      <EstimateItemMetric
        formatMessageKey="taskResourceEstimates.workRemaining"
        hours={estimatedRemainingHours}
        cost={estimatedRemainingCost}
        isReadOnlyView={isReadOnlyView}
      />
      <Typography className={classes.icon}>=</Typography>
      <div className={classes.estimate}>
        <EstimateItemMetric
          formatMessageKey="taskResourceEstimates.estimate"
          hours={estimatedAtCompletionHours}
          cost={estimatedAtCompletionCost}
          isReadOnlyView={isReadOnlyView}
        />
        <div className={classes.variance}>
          <div>
            <br />
            {Boolean(hoursVariance) && (
              <FormattedDeltaChip
                isHour
                showAddIcon={hoursVariance > 0}
                isRoundedValue={false}
                classes={varianceClasses}
                formattedMessageKey="taskResourceEstimates.hours"
                value={hoursVariance}
              />
            )}
            {Boolean(costVariance) && (
              <>
                <br />
                <FormattedDeltaChip
                  value={costVariance}
                  prefix={currencySymbol}
                  isRoundedValue={false}
                  classes={varianceClasses}
                  showAddIcon={costVariance > 0}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ResourceEstimateContent.propTypes = {
  resourceEstimate: PropTypes.object,
  isReadOnlyView: PropTypes.bool
};

export default ResourceEstimateContent;
