import { useState, useCallback } from 'react';

export const useDialogState = (initialState = false, options = {}) => {
  const [open, setOpen] = useState(initialState);

  const closeDialog = useCallback(
    (_, reason) => {
      const preventClose =
        (options.disabledBackDropClick && reason === 'backdropClick') ||
        (options.disableEscapeKeyDown && reason === 'escapeKeyDown');

      if (preventClose) return;
      setOpen(false);
    },
    [options]
  );

  const openDialog = useCallback(() => setOpen(true), [setOpen]);

  return {
    open,
    setOpen,
    closeDialog,
    openDialog
  };
};
