import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';

export const AcceptButton = ({ ...props }) => (
  <Button {...props}>
    <FormattedMessage id="button.accept" />
  </Button>
);

export default AcceptButton;
