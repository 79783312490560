import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, makeStyles } from '@material-ui/core';
import Decimal from '../Decimal';

const useStyles = makeStyles(theme => ({
  progressDetails: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'baseline'
  },
  progressCount: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing(0, 0, 0, 2)
  },
  progressMessage: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing(0)
  },
  progressInfo: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 0, 0, 0)
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  }
}));

export const CurrentProgressBarContent = ({
  itemCounts,
  progressInfo,
  currentProgress,
  progressMessage
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.progressDetails}>
        {progressMessage ? (
          <span className={classes.progressMessage}>{progressMessage}</span>
        ) : null}
        <div className={classes.spacer} />
        <span className={classes.progressCount}>
          <Decimal value={currentProgress} precision={0} /> /
          <Decimal value={itemCounts} precision={0} />
        </span>
      </div>
      <LinearProgress
        variant="determinate"
        value={(currentProgress / itemCounts) * 100}
      />
      {progressInfo ? (
        <div className={classes.progressInfo}>{progressInfo}</div>
      ) : null}
    </>
  );
};

CurrentProgressBarContent.propTypes = {
  itemCounts: PropTypes.number.isRequired,
  progressInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  currentProgress: PropTypes.number.isRequired,
  progressMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default CurrentProgressBarContent;
