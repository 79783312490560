import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useIsBreakpointDown, useIsBreakpointUp } from '~/modules/common/hooks';
import { hasPermission } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  CompleteRequestActionButton,
  ProposedRequestActionButton,
  RequestActionButton,
  RequestRejectedRequestActionButton,
  SubmittedRequestActionButton,
  ToBeHiredRequestActionButton
} from '~/modules/resourcing/common/components';
import { supporedProjectPolicyRoles } from '~/modules/resourcing/common/hooks';
import { HiringStatus, ResourceRequestStatus } from '~/types';
import { HiringRequestActions } from '~/modules/common/components/WithdrawHiringRequestDialog/WithdrawHiringRequestDialog';

export const useResourceRequestDrawerActions = ({
  history,
  resourceRequest,
  onResourceRequestSubmit,
  onResourceRequestSplit,
  onResourceRequestAccept,
  onResourceRequestAllocationReject,
  onResourceRequestHiringStatusUpdate,
  onResourceRequestUnsubmit,
  onResourceRequestDuplicate,
  openRemoveRequestDialog,
  openReleaseResourcesDialog,
  onResourceRequestFulfilled,
  onAssignResourceAction,
  onResourceRequestReject,
  onResourceRequestToBeHired,
  onDeleteRequestClick,
  onSaveResourceRequest,
  closeDrawer,
  setResourceRequestSaving,
  setSnackBarMessage,
  canEditProject,
  canEditResourceRequest,
  isProjectResourcingView,
  canEditTeam,
  onResourceRequestMerge,
  hasExistingAllocationLoading = false,
  isResourceRequestMergeEnabled,
  isHiringRequested,
  openWithdrawHiringRequestDialog,
  setWithdrawHiringRequestDialog
}) => {
  const {
    userAccessRoles,
    permissionsMap,
    featureFlags: { isPsaRmpTaskAllocation1Enabled, isPsaDtmRequisitionEnabled }
  } = useMeContext();

  const isSMViewAndDown = useIsBreakpointDown('sm');
  const isSMViewAndUp = useIsBreakpointUp('sm');
  const isMDViewAndUp = useIsBreakpointUp('md');

  const { resourceAllocations } = resourceRequest;
  const intl = useIntl();

  const isViewUserResourceManagerEnabled = hasPermission(permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-user',
    dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
  });

  const isViewUserResourcePoolManagerEnabled = hasPermission(permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-user',
    dataAccessLevelUri:
      'urn:replicon:user-data-access-level:resource-pool-manager'
  });

  const isViewUserEnabled =
    isViewUserResourceManagerEnabled || isViewUserResourcePoolManagerEnabled;

  const canEditResourceAssignments = hasPermission(permissionsMap)({
    actionUri:
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
  });

  const isUserHasProjectPolicyRole = supporedProjectPolicyRoles.some(sr =>
    userAccessRoles.includes(sr)
  );

  const isUserHasResourceManagerRole = userAccessRoles.includes(
    USER_ACCESS_ROLE.RESOURCE_MANAGER
  );

  const canAllocateResources = isViewUserEnabled && canEditResourceAssignments;

  const showAllocateResourcesButtonForDraftRequest =
    isMDViewAndUp &&
    canAllocateResources &&
    isUserHasProjectPolicyRole &&
    isUserHasResourceManagerRole;

  const showToBeHiredButtonForDraftRequest =
    !canEditResourceRequest &&
    isUserHasProjectPolicyRole &&
    isUserHasResourceManagerRole;

  const submitAction = {
    id: 'submit',
    overflow: 'never',
    variant: 'contained',
    label: intl.formatMessage({ id: 'resourceRequestActions.submitRequest' }),
    onClick: useCallback(async () => {
      if (canEditResourceRequest) await onSaveResourceRequest();

      await onResourceRequestSubmit();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.requestSubmitted'
        })
      });
    }, [
      intl,
      setSnackBarMessage,
      onSaveResourceRequest,
      onResourceRequestSubmit,
      canEditResourceRequest
    ]),
    buttonComponent: SubmittedRequestActionButton
  };

  const saveAction = {
    id: 'save',
    overflow: 'xs',
    variant: 'contained',
    label: intl.formatMessage({ id: 'resourceRequestActions.saveDraft' }),
    onClick: useCallback(() => {
      onSaveResourceRequest();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.requestSaved'
        })
      });
    }, [intl, setSnackBarMessage, onSaveResourceRequest]),
    buttonComponent: RequestActionButton,
    secondary: !isSMViewAndUp
  };

  const cancelAction = {
    id: 'cancel',
    overflow: 'never',
    label: intl.formatMessage({ id: 'resourceRequestActions.cancel' }),
    onClick: closeDrawer
  };

  const proposeResourceAction = {
    id: 'propose-resource',
    label: intl.formatMessage({
      id: 'resourceAllocationActions.proposeResources'
    }),
    onClick: useCallback(() => {
      onResourceRequestFulfilled();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.resourcesProposed'
        })
      });
    }, [intl, setSnackBarMessage, onResourceRequestFulfilled]),
    disabled:
      resourceRequest.resourceAllocations.length === 0 ||
      resourceRequest.resourceAllocations.some(ra => !ra.user.userUri),
    buttonComponent: ProposedRequestActionButton,
    variant: 'contained'
  };

  const assignResourceActionButton = {
    id: 'assign-resource',
    overflow: 'never',
    variant: 'contained',
    label: intl.formatMessage({
      id: 'resourceAllocationActions.allocateResource'
    }),
    onClick: onAssignResourceAction,
    buttonComponent: SubmittedRequestActionButton
  };

  const rejectRequestAction = {
    id: 'reject-request',
    className: 'rejectIcon',
    label: intl.formatMessage({
      id: 'resourceAllocationActions.rejectRequest'
    }),
    onClick: useCallback(() => {
      onResourceRequestReject();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.requestRejected'
        })
      });
    }, [intl, setSnackBarMessage, onResourceRequestReject])
  };

  const recallAction = {
    id: 'recall',
    label: intl.formatMessage({ id: 'resourceAllocationActions.recall' }),
    disabled: isResourceRequestMergeEnabled && hasExistingAllocationLoading,
    onClick: useCallback(() => {
      onResourceRequestSubmit();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.requestRecalled'
        })
      });
    }, [intl, setSnackBarMessage, onResourceRequestSubmit])
  };

  const toBeHiredAction = {
    id: 'to-be-hired',
    label: intl.formatMessage({ id: 'resourceAllocationActions.toBeHired' }),
    onClick: useCallback(() => {
      onResourceRequestToBeHired();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.requestToBeHired'
        })
      });
    }, [intl, setSnackBarMessage, onResourceRequestToBeHired]),
    overflow: 'never',
    variant: 'contained',
    buttonComponent: ToBeHiredRequestActionButton
  };

  const cancelRequestRejectionAction = {
    id: 'cancel-request-rejection',
    overflow: 'never',
    variant: 'contained',
    buttonComponent: SubmittedRequestActionButton,
    label: intl.formatMessage({
      id: 'resourceAllocationActions.cancelRejection'
    }),
    onClick: useCallback(() => {
      onResourceRequestSubmit();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.cancelRequestRejection'
        })
      });
    }, [intl, setSnackBarMessage, onResourceRequestSubmit])
  };

  const cancelToBeHiredAction = {
    id: 'cancel-to-be-hired',
    overflow: 'never',
    variant: 'contained',
    buttonComponent: SubmittedRequestActionButton,
    label: intl.formatMessage({
      id: 'resourceAssignmentDialog.cancelToBeHired'
    }),
    onClick: useCallback(() => {
      const onHandleClick = () => {
        onResourceRequestSubmit();
        setSnackBarMessage({
          type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
          resourceRequestButtonActionMessage: intl.formatMessage({
            id: 'resourceRequestActions.cancelRequestToBeHired'
          })
        });
      };

      if (isHiringRequested) {
        setWithdrawHiringRequestDialog({
          actionType: HiringRequestActions.CANCEL_TOBEHIRED,
          onClick: onHandleClick
        });
        openWithdrawHiringRequestDialog();
      } else {
        onHandleClick();
      }
    }, [
      isHiringRequested,
      onResourceRequestSubmit,
      setSnackBarMessage,
      intl,
      setWithdrawHiringRequestDialog,
      openWithdrawHiringRequestDialog
    ])
  };

  const splitAction = {
    id: 'split',
    label: intl.formatMessage({ id: 'resourceRequestActions.splitRequest' }),
    onClick: useCallback(() => {
      onResourceRequestSplit();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.requestSplit'
        })
      });
    }, [intl, setSnackBarMessage, onResourceRequestSplit]),
    secondary: true
  };

  const acceptAction = {
    id: 'accept',
    overflow: 'xs',
    variant: 'contained',
    label: intl.formatMessage({ id: 'resourceRequestActions.acceptResources' }),
    disabled: isResourceRequestMergeEnabled && hasExistingAllocationLoading,
    onClick: useCallback(() => {
      if (isResourceRequestMergeEnabled) {
        onResourceRequestMerge();
        closeDrawer();
      } else {
        onResourceRequestAccept();
        if (isProjectResourcingView)
          setSnackBarMessage({
            type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
            resourceRequestButtonActionMessage: intl.formatMessage({
              id: 'resourceRequestActions.resourcesAccepted'
            })
          });
      }
    }, [
      isResourceRequestMergeEnabled,
      onResourceRequestMerge,
      closeDrawer,
      onResourceRequestAccept,
      isProjectResourcingView,
      setSnackBarMessage,
      intl
    ]),
    buttonComponent: CompleteRequestActionButton
  };

  const onCreateHiringRequestAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.createHiringRequest'
    }),
    onClick: () =>
      onResourceRequestHiringStatusUpdate({
        resourceRequest,
        hiringStatus: HiringStatus.HiringRequested
      })
  };

  const onWithdrawHiringRequestAction = {
    id: 'withdraw-hiring-request',
    label: intl.formatMessage({
      id: 'resourceRequestActions.withdrawHiringRequest'
    }),
    onClick: useCallback(() => {
      setWithdrawHiringRequestDialog({
        actionType: HiringRequestActions.WITHDRAW_HIRING_REQUEST,
        onClick: () =>
          onResourceRequestHiringStatusUpdate({
            resourceRequest,
            hiringStatus: HiringStatus.HiringWithdrawn
          })
      });
      openWithdrawHiringRequestDialog();
    }, [
      onResourceRequestHiringStatusUpdate,
      openWithdrawHiringRequestDialog,
      resourceRequest,
      setWithdrawHiringRequestDialog
    ])
  };

  const rejectResourcesAction = {
    id: 'reject-resources',
    variant: 'contained',
    label: intl.formatMessage({ id: 'resourceRequestActions.reject' }),
    disabled: isResourceRequestMergeEnabled && hasExistingAllocationLoading,
    onClick: useCallback(() => {
      onResourceRequestAllocationReject();
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.resourcesRejected'
        })
      });
    }, [intl, setSnackBarMessage, onResourceRequestAllocationReject]),
    buttonComponent: RequestRejectedRequestActionButton
  };

  const unsubmitAction = {
    id: 'unsubmit',
    overflow: 'xs',
    variant: 'contained',
    label: intl.formatMessage({ id: 'resourceRequestActions.unsubmitRequest' }),
    onClick: useCallback(() => {
      const onHandleClick = () => {
        onResourceRequestUnsubmit();
        setSnackBarMessage({
          type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
          resourceRequestButtonActionMessage: intl.formatMessage({
            id: 'resourceRequestActions.requestUnSubmitted'
          })
        });
      };

      if (isHiringRequested) {
        setWithdrawHiringRequestDialog({
          actionType: HiringRequestActions.UNSUBMIT_REQUEST,
          onClick: onHandleClick
        });
        openWithdrawHiringRequestDialog();
      } else {
        onHandleClick();
      }
    }, [
      isHiringRequested,
      onResourceRequestUnsubmit,
      setSnackBarMessage,
      intl,
      setWithdrawHiringRequestDialog,
      openWithdrawHiringRequestDialog
    ])
  };

  const removeRequestAction = {
    id: 'remove-request',
    label: intl.formatMessage({ id: 'resourceRequestActions.removeRequest' }),
    onClick: useCallback(() => {
      if (isHiringRequested) {
        setWithdrawHiringRequestDialog({
          actionType: HiringRequestActions.REMOVE_REQUEST,
          onClick: onDeleteRequestClick
        });
        openWithdrawHiringRequestDialog();
      } else {
        openRemoveRequestDialog();
      }
    }, [
      isHiringRequested,
      setWithdrawHiringRequestDialog,
      openWithdrawHiringRequestDialog,
      onDeleteRequestClick,
      openRemoveRequestDialog
    ]),
    color: 'secondary',
    secondary: true,
    className: 'remove'
  };

  const duplicateAction = {
    id: 'duplicate',
    label: intl.formatMessage({
      id: 'resourceRequestActions.duplicateRequest'
    }),
    onClick: useCallback(async () => {
      setResourceRequestSaving(true);
      const {
        data: { createResourceRequest: { resourceRequest: newRequest } = {} }
      } = await onResourceRequestDuplicate();

      if (newRequest) {
        closeDrawer();
        history.push(`#/resourceRequest/${newRequest.id}`);
      }
      setSnackBarMessage({
        type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
        resourceRequestButtonActionMessage: intl.formatMessage({
          id: 'resourceRequestActions.requestDuplicated'
        })
      });
      setResourceRequestSaving(false);
    }, [
      intl,
      history,
      closeDrawer,
      setSnackBarMessage,
      setResourceRequestSaving,
      onResourceRequestDuplicate
    ]),
    secondary: true
  };

  const releaseResourcesAction = {
    id: 'release-resources',
    label: intl.formatMessage({
      id: 'resourceRequestActions.releaseResources'
    }),
    onClick: openReleaseResourcesDialog,
    secondary: true
  };

  const okAction = {
    ...cancelAction,
    id: 'ok',
    label: intl.formatMessage({ id: 'resourceRequestActions.ok' })
  };

  const completeAllocationAction = {
    id: 'complete-allocation',
    overflow: 'xs',
    variant: 'contained',
    label: intl.formatMessage({
      id: 'resourceRequestActions.completeAllocation'
    }),
    onClick: useCallback(() => {
      if (isResourceRequestMergeEnabled) {
        onResourceRequestMerge();
        closeDrawer();
      } else {
        onResourceRequestAccept();
        setSnackBarMessage({
          type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
          resourceRequestButtonActionMessage: intl.formatMessage({
            id: 'resourceRequestActions.completeAllocationMessage'
          })
        });
      }
    }, [
      isResourceRequestMergeEnabled,
      onResourceRequestMerge,
      closeDrawer,
      onResourceRequestAccept,
      setSnackBarMessage,
      intl
    ]),
    buttonComponent: CompleteRequestActionButton
  };

  const allocateResourcesButtonActionForDraftAndReqRejectRequest = async () => {
    setResourceRequestSaving(true);

    if (canEditResourceRequest) await onSaveResourceRequest();

    setTimeout(async () => {
      await onResourceRequestSubmit();
      closeDrawer();
      onAssignResourceAction();
    }, 100);
  };

  switch (resourceRequest.requestStatus) {
    case ResourceRequestStatus.Submitted:
      return isUserHasProjectPolicyRole
        ? isUserHasResourceManagerRole // project manager & resource manager
          ? [
              ...(!isSMViewAndDown && canAllocateResources
                ? [assignResourceActionButton]
                : []),
              ...(canEditProject
                ? [
                    {
                      ...completeAllocationAction,
                      disabled: resourceAllocations.length === 0
                    }
                  ]
                : []),
              ...[
                ...(canEditResourceAssignments
                  ? [
                      {
                        ...rejectRequestAction,
                        secondary: true,
                        disabled: resourceAllocations.length > 0
                      },
                      {
                        ...toBeHiredAction,
                        secondary: true,
                        disabled: resourceAllocations.length > 0
                      }
                    ]
                  : [])
              ],
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject
                ? [{ ...unsubmitAction, secondary: true }, removeRequestAction]
                : [])
            ]
          : [
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject ? [unsubmitAction, removeRequestAction] : [])
            ] // project manager
        : [
            // resource manager
            ...(canAllocateResources ? [assignResourceActionButton] : []),
            ...(canEditResourceAssignments ? [proposeResourceAction] : []),
            ...(resourceAllocations.length === 0
              ? [
                  ...(canEditResourceAssignments
                    ? [
                        { ...rejectRequestAction, secondary: true },
                        {
                          ...toBeHiredAction,
                          secondary: true
                        }
                      ]
                    : [])
                ]
              : [])
          ];
    case ResourceRequestStatus.Tobehired:
      return isUserHasProjectPolicyRole
        ? isUserHasResourceManagerRole // project manager & resource manager
          ? [
              ...(canEditProject || canEditResourceAssignments
                ? [cancelToBeHiredAction]
                : []),
              ...(canEditResourceAssignments
                ? isPsaDtmRequisitionEnabled &&
                  resourceRequest.sourceMetadata?.hiringStatus ===
                    HiringStatus.HiringRequested
                  ? []
                  : [{ ...rejectRequestAction, secondary: true }]
                : []),
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject
                ? [{ ...unsubmitAction, secondary: true }]
                : []),
              ...(isHiringRequested
                ? [{ ...onWithdrawHiringRequestAction, secondary: true }]
                : isPsaDtmRequisitionEnabled
                ? [
                    {
                      ...onCreateHiringRequestAction,
                      secondary: true
                    }
                  ]
                : []),
              ...(canEditProject ? [removeRequestAction] : [])
            ]
          : [
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject
                ? [{ ...unsubmitAction, variant: 'text' }, removeRequestAction]
                : [])
            ] // project manager
        : [
            // resource manager
            ...(canEditProject || canEditResourceAssignments
              ? [cancelToBeHiredAction]
              : []),
            ...(isHiringRequested && canEditResourceAssignments
              ? [{ ...onWithdrawHiringRequestAction, secondary: true }]
              : isPsaDtmRequisitionEnabled && canEditResourceAssignments
              ? [{ ...onCreateHiringRequestAction, secondary: true }]
              : []),
            ...(canEditResourceAssignments
              ? isPsaDtmRequisitionEnabled &&
                resourceRequest.sourceMetadata?.hiringStatus ===
                  HiringStatus.HiringRequested
                ? []
                : [{ ...rejectRequestAction, secondary: true }]
              : [])
          ];
    case ResourceRequestStatus.Tentative:
      return isUserHasProjectPolicyRole
        ? isUserHasResourceManagerRole // project manager & resource manager
          ? [
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject && canEditTeam
                ? [
                    acceptAction,
                    { ...rejectResourcesAction, variant: 'contained' },
                    recallAction
                  ]
                : [])
            ]
          : [
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject && canEditTeam
                ? [acceptAction, rejectResourcesAction]
                : [])
            ] // project manager
        : [
            // resource manager
            ...(canEditProject ? [acceptAction] : [])
          ];
    case ResourceRequestStatus.Allocationrejected:
      return isUserHasProjectPolicyRole
        ? isUserHasResourceManagerRole // project manager & resource manager
          ? [
              ...(!isSMViewAndDown && canAllocateResources
                ? [assignResourceActionButton]
                : []),
              ...(canEditProject
                ? [
                    {
                      ...completeAllocationAction,
                      disabled: resourceAllocations.length === 0
                    }
                  ]
                : []),
              ...(canEditResourceAssignments
                ? [
                    {
                      ...rejectRequestAction,
                      secondary: true,
                      disabled: resourceAllocations.length > 0
                    },
                    {
                      ...toBeHiredAction,
                      secondary: true,
                      disabled: resourceAllocations.length > 0
                    },
                    { ...proposeResourceAction, secondary: true }
                  ]
                : []),
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject
                ? [{ ...unsubmitAction, secondary: true }]
                : [])
            ]
          : [
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : []),
              ...(canEditProject ? [{ ...acceptAction, variant: 'text' }] : [])
            ] // project manager
        : [
            // resource manager
            ...(isMDViewAndUp && canAllocateResources
              ? [assignResourceActionButton]
              : []),
            ...(canEditResourceAssignments
              ? [
                  {
                    ...proposeResourceAction,
                    disabled: resourceAllocations.length === 0
                  },
                  {
                    ...rejectRequestAction,
                    secondary: true,
                    disabled: resourceAllocations.length > 0
                  },
                  {
                    ...toBeHiredAction,
                    secondary: true,
                    disabled: resourceAllocations.length > 0
                  }
                ]
              : [])
          ];
    case ResourceRequestStatus.Rejected:
      return isUserHasProjectPolicyRole
        ? isUserHasResourceManagerRole // project manager & resource manager
          ? [
              ...(!canEditResourceRequest
                ? [
                    ...(canEditProject && canEditResourceAssignments
                      ? [cancelRequestRejectionAction]
                      : []),
                    ...(canEditResourceAssignments
                      ? [
                          {
                            ...toBeHiredAction,
                            secondary: !isSMViewAndUp
                          }
                        ]
                      : [])
                  ]
                : []),
              ...(!isSMViewAndDown && canAllocateResources && canEditProject
                ? [
                    {
                      ...assignResourceActionButton,
                      onClick: allocateResourcesButtonActionForDraftAndReqRejectRequest,
                      secondary: !canEditResourceRequest
                    }
                  ]
                : []),
              ...(canEditProject &&
              !canEditResourceRequest &&
              resourceRequest.quantity > 1
                ? [splitAction]
                : []),
              ...(canEditProject
                ? [{ ...submitAction, secondary: isMDViewAndUp }]
                : []),
              ...(canEditResourceRequest
                ? [...(canEditProject ? [saveAction] : []), cancelAction]
                : [
                    ...(canEditProject && !isProjectResourcingView
                      ? [duplicateAction]
                      : [])
                  ]),
              ...(canEditProject ? [removeRequestAction] : [])
            ]
          : [
              // project manager
              ...(canEditProject ? [submitAction] : []),
              ...(canEditProject &&
              !canEditResourceRequest &&
              resourceRequest.quantity > 1
                ? [splitAction]
                : []),
              ...(canEditResourceRequest
                ? [...(canEditProject ? [saveAction] : []), cancelAction]
                : [
                    ...(canEditProject && !isProjectResourcingView
                      ? [duplicateAction]
                      : [])
                  ]),
              ...(canEditProject ? [removeRequestAction] : [])
            ]
        : [
            // resource manager
            ...(canEditProject && canEditResourceAssignments
              ? [cancelRequestRejectionAction]
              : []),
            ...(canEditResourceAssignments
              ? [
                  {
                    ...toBeHiredAction,
                    secondary: !isSMViewAndUp
                  }
                ]
              : []),
            ...(canAllocateResources
              ? [{ ...assignResourceActionButton, secondary: true }]
              : [])
          ];
    case ResourceRequestStatus.Complete:
      return isUserHasProjectPolicyRole
        ? [
            okAction,
            ...(canEditTeam && !isPsaRmpTaskAllocation1Enabled
              ? [releaseResourcesAction]
              : []),
            ...(canEditProject && !isProjectResourcingView
              ? [duplicateAction]
              : [])
          ]
        : [okAction];
    default:
      return [
        ...(canEditProject && showAllocateResourcesButtonForDraftRequest
          ? [
              { ...submitAction, secondary: true },
              {
                ...assignResourceActionButton,
                onClick: allocateResourcesButtonActionForDraftAndReqRejectRequest
              }
            ]
          : [...(canEditProject ? [submitAction] : [])]),
        ...(showToBeHiredButtonForDraftRequest
          ? [
              ...(canEditResourceAssignments
                ? [
                    {
                      ...toBeHiredAction,
                      secondary: true
                    }
                  ]
                : [])
            ]
          : []),
        ...(canEditProject &&
        !canEditResourceRequest &&
        resourceRequest.quantity > 1
          ? [splitAction]
          : []),
        ...(canEditResourceRequest
          ? [...(canEditProject ? [saveAction] : []), cancelAction]
          : [
              ...(canEditProject && !isProjectResourcingView
                ? [duplicateAction]
                : [])
            ]),
        ...(canEditProject ? [removeRequestAction] : [])
      ];
  }
};

export default useResourceRequestDrawerActions;
