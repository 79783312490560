import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

import {
  NoneDropdownItem,
  SimpleAutocomplete,
  useOptionsMerge
} from '~/modules/common/components/SearchAutocomplete';
import { useMeContext } from '~/modules/me/useMeContext';
import User from '~/modules/common/components/User';
import { useProjectManagers } from '~/modules/common/hooks/useProjectManagers';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import {
  useAdditionalOptions,
  NO_CHANGE_OPTION_ID,
  NO_VALUE_OPTION_ID
} from '~/modules/common/hooks';

const useNoneDropdownStyles = makeStyles(theme => ({
  root: { paddingLeft: theme.spacing(0.5) }
}));

const getOptionLabel = option => option.displayText;

const getOptionSelected = (option, selected) => {
  return option.displayText === selected.displayText;
};

const renderOption = option => {
  if (option.id === NO_CHANGE_OPTION_ID || option.id === NO_VALUE_OPTION_ID)
    return <NoneDropdownItem value={option.label} />;
  if (option.id === MORE_AVAILABLE_OPTION_ID)
    return <MoreResult message={option.label} />;

  return <User user={option} disablePadding />;
};

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const SearchableProjectManagerDropdown = ({
  value,
  onChange,
  dataQeId,
  disabled = false,
  enableNoChangeOption = false,
  enableNoValueOption = false
}) => {
  const noneDropdownItemClasses = useNoneDropdownStyles();
  const me = useMeContext();
  const intl = useIntl();
  const additionalOptions = useAdditionalOptions({
    enableNoChangeOption,
    enableNoValueOption,
    noValueMessageId: 'dropdownItem.noProjectManager'
  });

  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);
  const handleOnChange = useCallback(
    (managers, _) =>
      onChange(
        _,
        managers && managers.length ? managers[managers.length - 1] : null
      ),
    [onChange]
  );

  const selectedValue = useMemo(() => (value ? [value] : []), [value]);
  const { isLoading, options, hasMore } = useProjectManagers({
    searchTerm: disabled ? me.displayText : searchTerm
  });

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        label={intl.formatMessage({ id: 'addProject.projectManagerReference' })}
        data-qe-id={dataQeId}
      />
    ),
    [intl, dataQeId]
  );

  const renderTags = useCallback(
    (values, _) =>
      values.map((option, index) => {
        if (
          option.id === NO_CHANGE_OPTION_ID ||
          option.id === NO_VALUE_OPTION_ID
        ) {
          return (
            <NoneDropdownItem
              key={option.displayTexts}
              classes={noneDropdownItemClasses}
              value={option.displayText}
            />
          );
        }

        return <User user={option} disablePadding key={option.displayText} />;
      }),
    []
  );

  const mergedOptions = useOptionsMerge({ options, additionalOptions });

  return (
    <SimpleAutocomplete
      loading={isLoading}
      options={mergedOptions}
      value={selectedValue}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      multiple
      fullWidth
      disabled={disabled}
      getOptionDisabled={getOptionDisabled}
      renderOption={renderOption}
      onInputChange={handleInputChange}
      optionPropText="label"
      hasMore={hasMore}
      forcePopupIcon
      renderTags={renderTags}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      optionTypeText={intl.formatMessage({ id: 'moreOptions.projectManagers' })}
    />
  );
};

SearchableProjectManagerDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  dataQeId: PropTypes.string,
  disabled: PropTypes.bool,
  canEditOnlyMyProjects: PropTypes.bool,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool
};

export default SearchableProjectManagerDropdown;
