import { Grid, Typography, styled, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { User } from '~/modules/common/components/ListTable/renderers';
import { ProjectFormatter } from '~/modules/billing-invoicing/common/columnFormatters';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1, 0, 2)
  }
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.text.secondary
}));

const column = {
  customNoValue: (
    <StyledTypography variant="body2">
      <FormattedMessage id="avaialbleToBillList.multipleProjects" />
    </StyledTypography>
  )
};

export const MobileAvailableToBillProjectRow = ({ record }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={6}>
        <ProjectFormatter field="project" record={record} column={column} />
      </Grid>
      <Grid item xs={6}>
        <User field="projectLeader" record={record} column={column} />
      </Grid>
    </Grid>
  );
};

MobileAvailableToBillProjectRow.propTypes = {
  record: PropTypes.object
};

export default MobileAvailableToBillProjectRow;
