import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useMeContext } from '~/modules/me';
import {
  assignedUserText,
  mapTaskEndDateDisplayValue,
  getMinDateFromTaskHierarchyOrDefault,
  assignedRoleDisplayText
} from '../components/Gantt/hooks/useGanttData';

export const CREATE_TASK_MUTATION = gql`
  mutation createTask($task: CreateTaskInput!) {
    createTask: createTask2(input: $task) {
      task {
        id
        uri
      }
      error {
        reason
        type
      }
    }
  }
`;

const getResourceEstimatesSummaryCount = resourceEstimates => ({
  resourceEstimatesCount: resourceEstimates.length,
  resourceEstimatesWithUsersCount: resourceEstimates.reduce(
    (pv, cv) => (cv?.resourceUserId ? pv + 1 : pv),
    0
  )
});

const updateGanttTask = ({
  isRolledUpTaskEstimateCalculationMethodEnabled,
  ganttRef,
  ganttTask,
  assignedUser,
  assignedRole,
  intl,
  isPsaRmpTaskAllocation1Enabled
}) => {
  let minDate = null;

  if (isRolledUpTaskEstimateCalculationMethodEnabled) {
    const ganttTasks = [];

    ganttRef.eachTask(task => ganttTasks.push(task));
    minDate = getMinDateFromTaskHierarchyOrDefault(ganttTasks);
  }

  ganttRef.addTask({
    id: ganttTask.id,
    text: ganttTask.name,
    code: ganttTask.code,
    parent: ganttTask.parentUri,
    start_date: isRolledUpTaskEstimateCalculationMethodEnabled
      ? ganttTask.startDate ||
        mapTaskEndDateDisplayValue(ganttTask.endDate) ||
        minDate
      : ganttTask.startDate,
    end_date: isRolledUpTaskEstimateCalculationMethodEnabled
      ? mapTaskEndDateDisplayValue(ganttTask.endDate) ||
        ganttTask.startDate ||
        minDate
      : mapTaskEndDateDisplayValue(ganttTask.endDate),
    startDate: ganttTask.startDate,
    endDate: ganttTask.endDate,
    unscheduled: isRolledUpTaskEstimateCalculationMethodEnabled
      ? false
      : !ganttTask.startDate || !ganttTask.endDate,
    taskStatus: 'NOTSTARTED',
    isClosed: ganttTask.isClosed,
    assignee: assignedUser,
    assigneeText: assignedUserText({ assignedUser, intl }),
    assignedRole,
    assignedRoleText: assignedRoleDisplayText({
      assignedRole,
      intl
    }),
    estimatedHours: isRolledUpTaskEstimateCalculationMethodEnabled
      ? ganttTask.estimatedHours
      : ganttTask.estimatedHours || 0,
    estimatedCompletionDate: ganttTask.estimatedCompletionDate,
    isMilestone: ganttTask.isMilestone || false,
    assignedUserRoleId: ganttTask.assignedUserRoleId,
    rolledUpSummary: isRolledUpTaskEstimateCalculationMethodEnabled
      ? {
          id: ganttTask.id,
          actualHours: 0,
          totalEstimatedAtCompletionHours: ganttTask.estimatedHours,
          totalEstimatedRemainingHours: ganttTask.estimatedHours
        }
      : null,
    ...(isPsaRmpTaskAllocation1Enabled
      ? {
          resourceEstimatesSummary: getResourceEstimatesSummaryCount(
            ganttTask.resourceEstimates
          )
        }
      : {})
  });

  if (ganttTask.parentUri) ganttRef.open(ganttTask.parentUri);
};

const openTaskDrawer = (history, taskId) => {
  // Prevent double-navigation
  if (
    !history ||
    (history &&
      history.location.hash &&
      history.location.hash.includes('#/task/'))
  ) {
    return;
  }

  history.push({
    hash: `#/task/${taskId}`,
    state: {
      isProjectEditEnabled: true
    }
  });
};

const useCreateTaskMutation = () => useMutation(CREATE_TASK_MUTATION);

export const useCreateTask = ({ ganttRef, costType }) => {
  const me = useMeContext();
  const intl = useIntl();
  const history = useHistory();
  const [createTaskMutation] = useCreateTaskMutation();
  const { isRolledUpTaskEstimateCalculationMethodEnabled, featureFlags } = me;

  return useCallback(
    async ({
      parentUri,
      name,
      code,
      startDate,
      endDate,
      assignedUser,
      assignedRole,
      description,
      estimatedHours,
      estimatedCost,
      isMilestone,
      isTimeEntryAllowed,
      timeAndExpenseEntryType,
      timesheetAccessUris,
      customFields,
      assignedUserRoleId,
      resourceAllocations,
      doNotCopyParentTaskResourceAllocations,
      shouldRollupTaskDates = false,
      resourceEstimates
    }) => {
      const task = {
        parentUri,
        name,
        code,
        startDate,
        endDate,
        estimatedHours,
        estimatedCost,
        isMilestone,
        assignedUserUri: assignedUser ? assignedUser.id : null,
        assignedUserRoleId,
        assignedRoleUri: assignedRole ? assignedRole.id : null,
        description,
        isTimeEntryAllowed,
        timeAndExpenseEntryTypeUri:
          timeAndExpenseEntryType && timeAndExpenseEntryType.id,
        costTypeUri: costType ? costType.id : null,
        customFields,
        ...(featureFlags?.isPsaRmpTaskAllocation1Enabled
          ? { resourceEstimates, timesheetAccessUris }
          : { resourceAllocations }),
        doNotCopyParentTaskResourceAllocations,
        shouldRollupTaskDates
      };

      const {
        data: {
          createTask: { task: createdTask, error }
        }
      } = await createTaskMutation({
        variables: {
          task
        },
        refetchQueries: ['projectTasksQuery'],
        awaitRefetchQueries: true
      });

      if (
        !error &&
        createdTask &&
        !isRolledUpTaskEstimateCalculationMethodEnabled
      ) {
        openTaskDrawer(history, createdTask.id);
      }

      if (!error && ganttRef) {
        updateGanttTask({
          isRolledUpTaskEstimateCalculationMethodEnabled,
          ganttRef,
          ganttTask: {
            ...task,
            ...createdTask
          },
          assignedUser,
          assignedRole,
          intl,
          isPsaRmpTaskAllocation1Enabled:
            featureFlags?.isPsaRmpTaskAllocation1Enabled
        });
      }

      return error;
    },
    [
      featureFlags?.isPsaRmpTaskAllocation1Enabled,
      costType,
      createTaskMutation,
      isRolledUpTaskEstimateCalculationMethodEnabled,
      ganttRef,
      history,
      intl
    ]
  );
};

export default useCreateTask;
