import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  MergeResourceRequestDialog,
  SecondaryActions,
  ResourceRequestDeleteActions,
  ResourceAllocationDeleteActions,
  ReleaseResourceRequestDialog,
  RequestActionButton
} from '~/modules/resourcing/common/components';
import {
  useDeleteResourceRequestHandler,
  useRequestStatusHandlers,
  useSecondaryResourceRequestActionHandlers
} from '~/modules/resourcing/common/hooks';
import {
  useRedirectToAssignResource,
  useResourceAllocationActionHandlers
} from '~/modules/resourcing/hooks';
import { useAppContext } from '~/modules/App/AppContext';
import {
  useSessionStorage,
  useGetKeyValueSettings,
  useDialogState,
  useHasFeatureFlag
} from '~/modules/common/hooks';
import { HiringStatus, ResourceRequestStatus } from '~/types';
import useMarkResourceRequestAsCompleteAndMerge from '~/modules/resourcing/common/hooks/useMarkResourceRequestAsCompleteAndMerge';
import useMarkToBeHiredResourceRequestWithHiringRequestedSubStatus from '~/modules/resourcing/common/hooks/useMarkToBeHiredResourceRequestWithHiringRequestedSubStatus';
import { WithdrawHiringRequestDialog } from '~/modules/common/components';
import { useWithdrawHiringRequestDialogState } from '~/modules/common/components/WithdrawHiringRequestDialog';
import useStyles from './useStyles';
import { useResourceRequestDrawerActions } from './hooks';

export const ResourceRequestDrawerActions = ({
  hasError = false,
  isProjectManagerView,
  isAssignmentDialog,
  onSaveClick,
  drawerOnClose,
  resourceRequest,
  canEditProject,
  resourceAllocations,
  isResourceRequestReadOnly,
  setResourceRequestSaving,
  isProjectResourcingView,
  canEditResourceRequest,
  canEditTask,
  canEditTeam,
  allocationChartRef,
  isResourceRequestMergeEnabled,
  mergeDialogState,
  chartSettingsKey,
  cacheUpdateParams
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { dispatch } = useAppContext();

  const isPsaDtmRequisitionEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaDtmRequisitionEnabled'
  });

  const isHiringRequested =
    isPsaDtmRequisitionEnabled &&
    resourceRequest?.sourceMetadata?.hiringStatus ===
      HiringStatus.HiringRequested;

  const { keyValueSettings: chartSettings } = useGetKeyValueSettings(
    chartSettingsKey || 'resource_plan_chart_settings'
  );
  const isResourceActualModeEnabled = chartSettings?.showActuals || false;

  const { deleteResourceRequestFromProject } = useDeleteResourceRequestHandler({
    isResourceActualModeEnabled
  });

  const {
    existingAllocationState: {
      hasExistingAllocation,
      hasExistingAllocationLoading
    }
  } = mergeDialogState;

  const { onRemoveAllResources } = useResourceAllocationActionHandlers({
    resourceRequest
  });
  const { storedValue: resourceRequestsQueryVariables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const req = useMemo(
    () => ({
      ...resourceRequest,
      resourceAllocations
    }),
    [resourceRequest, resourceAllocations]
  );

  const {
    onMarkResourceRequestAsCompleteAndMerge,
    isLoading: isMergeLoading
  } = useMarkResourceRequestAsCompleteAndMerge({
    resourceRequest,
    allocationChartRef,
    cacheUpdateParams
  });

  const history = useHistory();
  const onAssignResourceAction = useRedirectToAssignResource({
    history,
    resourceRequest,
    isResourcingTab: isProjectResourcingView,
    scale: chartSettings?.scale,
    displayUnit: chartSettings?.displayUnit
  });

  const {
    onResourceRequestDuplicate,
    onResourceRequestSplit
  } = useSecondaryResourceRequestActionHandlers({
    resourceRequest,
    isResourceActualModeEnabled
  });

  const {
    open: showReleaseResourcesDialog,
    openDialog: openReleaseResourcesDialog,
    closeDialog: _closeReleaseResourcesDialog
  } = useDialogState(false);

  const closeReleaseResourcesDialog = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

      _closeReleaseResourcesDialog();
    },
    [_closeReleaseResourcesDialog]
  );

  const {
    open: showRemoveAllocationDialog,
    openDialog: openRemoveAllocationDialog,
    closeDialog: closeRemoveAllocationDialog
  } = useDialogState(false);

  const {
    open: showRemoveRequestDialog,
    openDialog: openRemoveRequestDialog,
    closeDialog: closeRemoveRequestDialog
  } = useDialogState(false);

  const {
    open: showWithdrawHiringRequestDialog,
    openDialog: openWithdrawHiringRequestDialog,
    closeDialog: closeWithdrawDialog,
    withdrawHiringRequestDialogState,
    setWithdrawHiringRequestDialog
  } = useWithdrawHiringRequestDialogState();

  const onDeleteRequestClick = useCallback(() => {
    closeRemoveRequestDialog();
    deleteResourceRequestFromProject({
      projectUri: resourceRequest.projectUri,
      resourceRequestId: resourceRequest.id,
      resourceRequestsQueryVariables: resourceRequestsQueryVariables
        ? {
            ...resourceRequestsQueryVariables,
            projectUri: resourceRequest.projectUri
          }
        : null,
      isFromDrawer: true,
      request: resourceRequest
    });
    dispatch({
      type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
      resourceRequestButtonActionMessage: intl.formatMessage({
        id: 'resourceRequestActions.requestRemoved'
      })
    });
    drawerOnClose();
  }, [
    closeRemoveRequestDialog,
    deleteResourceRequestFromProject,
    resourceRequest,
    resourceRequestsQueryVariables,
    dispatch,
    intl,
    drawerOnClose
  ]);

  const onDeleteAllocationClick = useCallback(() => {
    closeRemoveAllocationDialog();
    onRemoveAllResources();
  }, [closeRemoveAllocationDialog, onRemoveAllResources]);
  const {
    onMarkToBeHiredResourceRequestWithHiringRequestedSubStatus: onResourceRequestHiringStatusUpdate
  } = useMarkToBeHiredResourceRequestWithHiringRequestedSubStatus();

  const {
    onResourceRequestToBeHired,
    onResourceRequestSubmit,
    onResourceRequestAccept,
    onResourceRequestReject,
    onResourceRequestAllocationReject,
    onResourceRequestFulfilled,
    onResourceRequestUnsubmit,
    onReleaseResourceAllocationsForRequest,
    onResourceRequestMerge
  } = useRequestStatusHandlers({
    resourceRequest,
    allocationChartRef,
    mergeDialogState,
    onMarkResourceRequestAsCompleteAndMerge
  });

  const actions = useResourceRequestDrawerActions({
    history,
    resourceRequest: req,
    onResourceRequestSubmit,
    onResourceRequestSplit,
    onResourceRequestAccept,
    onResourceRequestAllocationReject,
    onResourceRequestUnsubmit,
    onResourceRequestDuplicate,
    openRemoveRequestDialog,
    openReleaseResourcesDialog,
    onResourceRequestFulfilled,
    onAssignResourceAction,
    onResourceRequestReject,
    onResourceRequestToBeHired,
    onResourceRequestHiringStatusUpdate,
    onDeleteRequestClick,
    onSaveResourceRequest: onSaveClick,
    closeDrawer: drawerOnClose,
    isProjectManagerView,
    isAssignmentDialog,
    isResourceRequestReadOnly,
    setResourceRequestSaving,
    openRemoveAllocationDialog,
    setSnackBarMessage: dispatch,
    canEditProject,
    canEditResourceRequest,
    isProjectResourcingView,
    canEditTeam,
    onResourceRequestMerge,
    hasExistingAllocationLoading,
    isResourceRequestMergeEnabled,
    isHiringRequested,
    openWithdrawHiringRequestDialog,
    setWithdrawHiringRequestDialog
  });

  const secondaryActions = actions
    .filter(action => action.secondary)
    .map(action => ({
      ...action,
      disabled: action.id === 'remove-request' ? false : hasError
    }));
  const primaryActions = actions.filter(action => !action.secondary);

  return (
    <div className={classes.root}>
      {primaryActions.length > 0 &&
        primaryActions.map((primaryAction, index) => {
          const Component = primaryAction.buttonComponent
            ? primaryAction.buttonComponent
            : RequestActionButton;

          return (
            <Component
              key={primaryAction.label}
              disabled={hasError}
              className={index > 0 ? classes.buttonSpacing : null}
              {...primaryAction}
            />
          );
        })}

      {secondaryActions.length > 0 && (
        <>
          <div className={classes.spacer} />
          <SecondaryActions actions={secondaryActions} />
          {showRemoveRequestDialog && (
            <ResourceRequestDeleteActions
              showRemoveRequestDialog={showRemoveRequestDialog}
              closeRemoveRequestDialog={closeRemoveRequestDialog}
              onDeleteRequestClick={onDeleteRequestClick}
            />
          )}
          {showRemoveAllocationDialog && (
            <ResourceAllocationDeleteActions
              showRemoveAllocationDialog={showRemoveAllocationDialog}
              closeRemoveAllocationDialog={closeRemoveAllocationDialog}
              onDeleteAllocationClick={onDeleteAllocationClick}
            />
          )}
          {showReleaseResourcesDialog && (
            <ReleaseResourceRequestDialog
              resourceRequest={req}
              onResourceRequestSubmit={onResourceRequestSubmit}
              onReleaseResourceAllocationsForRequest={
                onReleaseResourceAllocationsForRequest
              }
              open={showReleaseResourcesDialog}
              onCancelClick={closeReleaseResourcesDialog}
              canEditTask={canEditTask}
            />
          )}
        </>
      )}
      {isResourceRequestMergeEnabled &&
        (resourceRequest.requestStatus === ResourceRequestStatus.Tentative ||
          resourceRequest.requestStatus === ResourceRequestStatus.Submitted ||
          resourceRequest.requestStatus ===
            ResourceRequestStatus.Allocationrejected) &&
        hasExistingAllocation && (
          <MergeResourceRequestDialog
            open={mergeDialogState.open}
            onClose={mergeDialogState.closeDialog}
            resourceRequest={resourceRequest}
            onMarkResourceRequestAsCompleteAndMerge={
              onMarkResourceRequestAsCompleteAndMerge
            }
            isMergeLoading={isMergeLoading}
          />
        )}
      {isHiringRequested && showWithdrawHiringRequestDialog && (
        <WithdrawHiringRequestDialog
          open={showWithdrawHiringRequestDialog}
          onClose={closeWithdrawDialog}
          onClick={withdrawHiringRequestDialogState.onClick}
          actionType={withdrawHiringRequestDialogState.actionType}
        />
      )}
    </div>
  );
};

ResourceRequestDrawerActions.propTypes = {
  hasError: PropTypes.bool,
  isProjectManagerView: PropTypes.bool,
  isAssignmentDialog: PropTypes.bool,
  onSaveClick: PropTypes.func,
  isResourceRequestReadOnly: PropTypes.bool,
  drawerOnClose: PropTypes.func,
  resourceRequest: PropTypes.object,
  resourceAllocations: PropTypes.array,
  onResourceRequestSubmit: PropTypes.func,
  onResourceRequestAccept: PropTypes.func,
  onResourceRequestAllocationReject: PropTypes.func,
  onResourceRequestUnsubmit: PropTypes.func,
  onResourceRequestFulfilled: PropTypes.func,
  onReleaseResourceAllocationsForRequest: PropTypes.func,
  onResourceRequestToBeHired: PropTypes.func,
  onResourceRequestReject: PropTypes.func,
  setResourceRequestSaving: PropTypes.func,
  isProjectResourcingView: PropTypes.bool,
  canEditProject: PropTypes.bool,
  canEditResourceRequest: PropTypes.bool,
  canEditTask: PropTypes.bool,
  canEditTeam: PropTypes.bool.isRequired,
  allocationChartRef: PropTypes.object,
  isResourceRequestMergeEnabled: PropTypes.bool,
  mergeDialogState: PropTypes.object,
  scale: PropTypes.string,
  chartSettingsKey: PropTypes.string,
  cacheUpdateParams: PropTypes.object
};

export default ResourceRequestDrawerActions;
