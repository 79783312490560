import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_INVOICE_STATUS_UPDATE_BATCH_RESULT = gql`
  query GetInvoiceStatusUpdateBatchResult($batchId: String!) {
    getInvoiceStatusUpdateBatchResult(batchId: $batchId) {
      result {
        updatedInvoices {
          invoice {
            id
            displayText
          }
        }
        failedInvoices {
          invoice {
            displayText
            id
          }
          details {
            project {
              id
              displayText
            }
            projectTCVAmount {
              amount
              currency {
                id
                symbol
                displayText
              }
            }
            exceededBillAmount {
              amount
              currency {
                id
                symbol
                displayText
              }
            }
          }
        }
      }
    }
  }
`;

export const useFetchInvoiceStatusUpdateBatchResult = () => {
  const apolloClient = useApolloClient();

  const fetchInvoiceStatusUpdateBatchResult = async batchId => {
    const { data } = await apolloClient.query({
      query: GET_INVOICE_STATUS_UPDATE_BATCH_RESULT,
      variables: { batchId },
      fetchPolicy: 'network-only'
    });

    return data?.getInvoiceStatusUpdateBatchResult?.result;
  };

  return { fetchInvoiceStatusUpdateBatchResult };
};
