import { useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { v4 as uuid } from 'uuid';
import { useMeContext } from '~/modules/me';
import resourceSkillsFragment from '~/modules/resource-management/graphql/resourceSkills.fragment.js';
import { GetUserProfileSkillsQuery } from './useUserProfileSkills';
import {
  combineSkillsAndUserProfileSkills,
  mapResourceSkillDetailsToUserSkillInputs,
  mapSkillAssignmentToUserProfileSkill
} from './mapSkills';

const ACCEPT_USER_NUDGES_AND_ASSIGN_SKILLS = gql`
  mutation acceptUserNudgesAndAssignSkills(
    $input: AcceptUserNudgesAndAssignSkillsInput!
  ) {
    acceptUserNudgesAndAssignSkills(input: $input) {
      skills {
        category {
          id
          uri
          displayText
        }
        skillAssignments {
          id
          uri
          displayText
          certificate
          skillLevel {
            id
            uri
            displayText
            rating
          }
          skillCustomMetadata {
            uri
            type
            text
            date
            number
            displayText
          }
        }
      }
    }
  }
`;

const buildOptimisticResponse = ({
  cache,
  userUri,
  tenantSlug,
  acceptedSkills
}) => {
  const cachedFragmentId = `ResourceDetails:${userUri}`;
  const cachedResourceDetails = cache.readFragment({
    id: cachedFragmentId,
    fragment: resourceSkillsFragment
  });
  const { resourceSkill: resourceSkills } = cachedResourceDetails;

  const updatedSkills = combineSkillsAndUserProfileSkills({
    skills: resourceSkills,
    userProfileSkills: acceptedSkills.flatMap(skill =>
      skill.skillAssignments.map(skillAssignment =>
        mapSkillAssignmentToUserProfileSkill({
          skillAssignment,
          category: skill.category
        })
      )
    ),
    mapCategory: category => ({
      ...category,
      __typename: 'Category',
      id: category.uri
    }),
    mapSkillAssignment: skillAssignment => {
      const skillUri =
        skillAssignment.uri ||
        `urn:replicon-tenant:${tenantSlug}:skill:temp-${uuid()}`;

      return {
        ...skillAssignment,
        __typename: 'Skill',
        id: skillUri,
        uri: skillUri,
        skillCustomMetadata: skillAssignment.skillCustomMetadata.map(
          customMetadata => ({
            ...customMetadata,
            __typename: 'CustomMetadataInput2'
          })
        )
      };
    }
  });

  return {
    acceptUserNudgesAndAssignSkills: {
      skills: updatedSkills
    }
  };
};

const updateUserProfileSkillsCache = ({
  cache,
  userUri,
  removedUserSkillsInput
}) => {
  const { userProfileSkills: cachedSkills } = cache.readQuery({
    query: GetUserProfileSkillsQuery,
    variables: { userUri }
  });

  const categoryMap = removedUserSkillsInput.reduce((acc, skill) => {
    if (!acc[skill.category.id]) acc[skill.category.id] = {};
    acc[skill.category.id][skill.name] = true;

    return acc;
  }, {});

  cache.writeQuery({
    query: GetUserProfileSkillsQuery,
    variables: { userUri },
    data: {
      userProfileSkills: {
        id: userUri,
        skills: cachedSkills.skills.filter(
          skill => !categoryMap[skill.categoryUri]?.[skill.skillName]
        )
      }
    }
  });
};

const updateResourceSkillsCache = ({ cache, mutationResponse, userUri }) => {
  const {
    data: {
      acceptUserNudgesAndAssignSkills: { skills: updatedSkills }
    }
  } = mutationResponse;

  const cachedFragmentId = `ResourceDetails:${userUri}`;

  const cachedResourceDetails = cache.readFragment({
    id: cachedFragmentId,
    fragment: resourceSkillsFragment
  });

  cache.writeFragment({
    id: cachedFragmentId,
    fragment: resourceSkillsFragment,
    data: {
      ...cachedResourceDetails,
      resourceSkill: updatedSkills
    }
  });
};

const useOnAcceptUserNudges = ({ userUri }) => {
  const apolloClient = useApolloClient();
  const { tenantSlug } = useMeContext();

  const [acceptUserNudgesAndAssignSkills] = useMutation(
    ACCEPT_USER_NUDGES_AND_ASSIGN_SKILLS,
    {}
  );

  const onAcceptUserNudges = useCallback(
    async acceptedSkills => {
      const userSkillsInput = acceptedSkills.flatMap(
        mapResourceSkillDetailsToUserSkillInputs
      );

      await acceptUserNudgesAndAssignSkills({
        variables: {
          input: {
            skills: userSkillsInput,
            userUri
          }
        },
        optimisticResponse: buildOptimisticResponse({
          cache: apolloClient.cache,
          userUri,
          tenantSlug,
          acceptedSkills
        }),
        update: (cache, mutationResponse) => {
          updateResourceSkillsCache({ cache, mutationResponse, userUri });
          updateUserProfileSkillsCache({
            cache,
            userUri,
            removedUserSkillsInput: userSkillsInput
          });
        }
      });
    },
    [acceptUserNudgesAndAssignSkills, userUri, tenantSlug, apolloClient]
  );

  return { onAcceptUserNudges };
};

export default useOnAcceptUserNudges;
