import { gql } from 'graphql-tag';
import { v4 as uuid } from 'uuid';
import { useMutation } from '@apollo/client';
import { useState, useCallback } from 'react';
import { useMeContext } from '~/modules/me';
import resourceSkillsFragment from '~/modules/resource-management/graphql/resourceSkills.fragment';
import { GetUserProfileSkillsQuery } from './useUserProfileSkills';

const UPDATE_SKILLS = gql`
  mutation updateUserSkills($input: UpdateUserSkillsInput2!) {
    updateUserSkills: updateUserSkills3(input: $input) {
      skills {
        category {
          id
          uri
          displayText
        }
        skillAssignments {
          id
          uri
          displayText
          certificate
          skillLevel {
            id
            uri
            displayText
            rating
          }
          skillCustomMetadata {
            uri
            type
            text
            date
            number
            displayText
          }
        }
      }
    }
  }
`;

export const useUpdateSkillsMutation = () => useMutation(UPDATE_SKILLS);

const updateResourceSkillsCache = ({ userUri, proxy, mutationResponse }) => {
  const {
    data: {
      updateUserSkills: { skills: updatedSkills }
    }
  } = mutationResponse;

  const cachedFragmentId = `ResourceDetails:${userUri}`;

  const cachedResourceDetails = proxy.readFragment({
    id: cachedFragmentId,
    fragment: resourceSkillsFragment
  });

  proxy.writeFragment({
    id: cachedFragmentId,
    fragment: resourceSkillsFragment,
    data: {
      ...cachedResourceDetails,
      resourceSkill: updatedSkills
    }
  });
};

const updateUserProfileSkillsCache = ({ userUri, cache, mutationResponse }) => {
  const {
    userProfileSkills: { skills: cachedSkills }
  } = cache.readQuery({
    query: GetUserProfileSkillsQuery,
    variables: { userUri }
  });

  const {
    data: {
      updateUserSkills: { skills: updatedSkills }
    }
  } = mutationResponse;

  const categoryMap = updatedSkills.reduce((acc, skill) => {
    acc[skill.category.uri] = skill.skillAssignments.reduce(
      (skillAssignmentsAcc, skillAssignment) => {
        // eslint-disable-next-line no-param-reassign
        skillAssignmentsAcc[skillAssignment.displayText] = skillAssignment.uri;

        return skillAssignmentsAcc;
      },
      {}
    );

    return acc;
  }, {});

  cache.writeQuery({
    query: GetUserProfileSkillsQuery,
    variables: { userUri },
    data: {
      userProfileSkills: {
        id: userUri,
        skills: cachedSkills.map(skill => ({
          ...skill,
          skillUri:
            skill.skillUri ||
            categoryMap[skill.categoryUri]?.[skill.skillName] ||
            ''
        }))
      }
    }
  });
};

export const updateCache = (userUri, isPsaPraaUserProfileNudgesEnabled) => (
  proxy,
  mutationResponse
) => {
  updateResourceSkillsCache({ userUri, proxy, mutationResponse });

  if (isPsaPraaUserProfileNudgesEnabled) {
    updateUserProfileSkillsCache({ userUri, cache: proxy, mutationResponse });
  }
};

const isLocalObject = obj => obj.isNew || obj.id.startsWith('LOCAL');

const mapCustomMetaData = skill =>
  skill.customMetadata.map(cm => ({
    uri: cm.uri,
    type: cm.type,
    text: cm.text,
    date: cm.date,
    number: cm.number,
    displayText: cm.displayText
  }));

const mapCacheCustomMetaData = skill =>
  skill.customMetadata.map(meta => ({
    __typename: 'SkillCustomMetadata',
    ...meta
  }));

export const useUpdateUserSkillAssignments = ({ userUri }) => {
  const {
    featureFlags: { isPsaPraaUserProfileNudgesEnabled }
  } = useMeContext();
  const [updateSkillsMutation] = useUpdateSkillsMutation();
  const [error, setError] = useState();

  const onSubmit = useCallback(
    async values => {
      const { skills } = values;

      const skillAssignments = skills.map(skill => {
        return {
          skillUri: isLocalObject(skill) ? null : skill.uri,
          skillLevelUri: skill.skillLevel ? skill.skillLevel.uri : null,
          certificate: skill.certificate,
          skillCustomMetadata: mapCustomMetaData(skill),
          name: (skill.displayText || skill.name).trim(),
          category: {
            id: isLocalObject(skill.category) ? null : skill.category.id,
            name: isLocalObject(skill.category)
              ? (skill.category.displayText || skill.category.name).trim()
              : undefined
          }
        };
      });

      try {
        await updateSkillsMutation({
          variables: {
            input: { userUri, skills: skillAssignments }
          },
          update: updateCache(userUri, isPsaPraaUserProfileNudgesEnabled),
          optimisticResponse: {
            __typename: 'Mutation',
            updateUserSkills: {
              __typename: 'updateSkillsResult',
              skills: Object.values(
                skills.reduce((acc, skill) => {
                  const categoryId = skill.category.id;

                  if (!acc[categoryId]) {
                    const categoryUri = uuid();

                    acc[categoryId] = {
                      __typename: 'ResourceSkillDetails',
                      category: isLocalObject(skill.category)
                        ? {
                            __typename: 'Category',
                            id: categoryUri,
                            uri: categoryUri,
                            name:
                              skill.category.displayText || skill.category.name,
                            displayText:
                              skill.category.displayText || skill.category.name
                          }
                        : skill.category,
                      skillAssignments: []
                    };
                  }

                  const skillUri = isLocalObject(skill) ? uuid() : skill.id;

                  acc[categoryId].skillAssignments.push({
                    __typename: 'Skill',
                    id: skillUri,
                    uri: skillUri,
                    displayText: skill.displayText || skill.name,
                    certificate: skill.certificate || null,
                    skillCustomMetadata: mapCacheCustomMetaData(skill),
                    skillLevel: {
                      __typename: 'SkillLevel',
                      ...skill.skillLevel,
                      rating: skill.skillLevel.rating || -1
                    }
                  });

                  return acc;
                }, {})
              )
            }
          }
        });
      } catch (err) {
        setError(err);
      }
    },
    [updateSkillsMutation, userUri, setError, isPsaPraaUserProfileNudgesEnabled]
  );

  return { onSubmit, error };
};

export default useUpdateUserSkillAssignments;
