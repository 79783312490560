import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getPeriodsByScale } from '~/modules/resourcing/common/hooks/usePeriodsByScale';
import {
  getTotalHoursForPeriodFromScheduleRules,
  getOverlappingDateRange
} from '~/modules/resourcing/common/util/scheduleUtil';

export const getScheduleRulesInChartDisplayRange = ({
  rangeStart,
  rangeEnd,
  scheduleRules
}) =>
  scheduleRules
    .map(rule => ({
      ...rule,
      startDate: mapIsoStringtoUtcObject(rule.dateRange.startDate),
      endDate: mapIsoStringtoUtcObject(rule.dateRange.endDate)
    }))
    .filter(rule => rule.startDate <= rangeEnd && rangeStart <= rule.endDate);

export const getAllocationTotalsByPeriods2 = ({
  chartDisplayDateRange: { startDate: chartStartDate, endDate: chartEndDate },
  resourceAllocation,
  scale,
  me
}) => {
  const { scheduleRules, startDate, endDate } = resourceAllocation || {};

  if (!startDate || !endDate) return { allocationPeriods: [] };

  const overlappingDateRange = getOverlappingDateRange(
    {
      startDate: mapIsoStringtoUtcObject(startDate),
      endDate: mapIsoStringtoUtcObject(endDate)
    },
    {
      startDate: chartStartDate,
      endDate: chartEndDate
    }
  );

  if (!overlappingDateRange) return { allocationPeriods: [] };

  const { rangeStart, rangeEnd } = overlappingDateRange;

  const scheduleRulesInRange = getScheduleRulesInChartDisplayRange({
    rangeStart,
    rangeEnd,
    scheduleRules
  });

  const { displayPeriods } = getPeriodsByScale({
    me,
    rangeStart,
    rangeEnd,
    scale
  });

  return {
    allocationPeriods: displayPeriods.map(range => {
      const { start, end, key } = range;

      return {
        key,
        totalHours: getTotalHoursForPeriodFromScheduleRules({
          start,
          end,
          scheduleRules: scheduleRulesInRange
        })
      };
    })
  };
};
