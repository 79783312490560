import { gql } from 'graphql-tag';

export const PROJECTS_BILLING_SETTINGS = gql`
  query projectsBillingSettings(
    $page: Int!
    $pageSize: Int!
    $projectFilter: ProjectFilter2
  ) {
    projects(page: $page, pagesize: $pageSize, projectFilter: $projectFilter) {
      id
      projectBillSettings {
        billLineItemsBy
        description
        internalNotes
        showComments
        defaultInvoicePaymentTerm
        invoiceCurrency {
          id
          symbol
        }
        taxProfile {
          id
          displayText
        }
        invoiceTemplate {
          id
          displayText
        }
        poNumber
      }
    }
  }
`;

export const useFetchProjectsBillingSettings = ({ apolloClient }) => {
  return {
    fetchProjectsBillingSettings: async projectIds => {
      const { data } = await apolloClient.query({
        query: PROJECTS_BILLING_SETTINGS,
        variables: {
          page: 1,
          pageSize: projectIds.length,
          projectFilter: { projects: projectIds }
        },
        fetchPolicy: 'network-only'
      });

      return (data?.projects || []).reduce(
        (acc, { id, projectBillSettings }) => ({
          ...acc,
          [id]: projectBillSettings
        }),
        {}
      );
    }
  };
};
