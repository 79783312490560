import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField, makeStyles } from '@material-ui/core';

// Styles overrides are required
// Dependant on https://github.com/mui-org/material-ui/issues/21409

const useStyles = makeStyles({
  hiddenLabel: {
    '&>div': {
      paddingTop: '0px!important'
    }
  },
  hiddenLabelInput: {
    '&>input.MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
      padding: '10px 12px 11px !important'
    }
  },
  multilineInput: {
    '&.MuiInputBase-multiline.MuiInputBase-marginDense': {
      paddingRight: '0px',
      paddingBottom: '0px',
      '& div': {
        alignSelf: 'flex-end'
      }
    }
  }
});

export const AutocompleteInput = ({
  label,
  hiddenLabel,
  variant,
  showError,
  errorMessage,
  placeholder,
  helperText,
  alwaysShowHelperText,
  multiline = false,
  dataQeId,
  ariaLabel,
  ariaControls,
  InputProps,
  className,
  inputProps,
  InputLabelProps,
  InputLabelPropsOverride,
  ...rest
}) => {
  const classes = useStyles();

  const endAdornment = useMemo(
    () =>
      multiline
        ? {
            ...InputProps.endAdornment,
            props: {
              children: [
                inputProps.value === ''
                  ? InputProps.endAdornment.props.children[1]
                  : InputProps.endAdornment.props.children[0]
              ]
            }
          }
        : InputProps.endAdornment,
    [InputProps.endAdornment, inputProps.value, multiline]
  );

  const autocompleteInputProps = useMemo(
    () => ({
      ...InputProps,
      endAdornment,
      className: classNames(InputProps.className, {
        [classes.hiddenLabelInput]: hiddenLabel,
        [classes.multilineInput]: multiline
      }),
      inputProps: {
        ...inputProps,
        'aria-label': ariaLabel || label,
        'aria-controls': ariaControls,
        ...(dataQeId && { 'data-qe-id': dataQeId })
      }
    }),
    [
      InputProps,
      ariaControls,
      ariaLabel,
      classes.hiddenLabelInput,
      classes.multilineInput,
      dataQeId,
      endAdornment,
      hiddenLabel,
      inputProps,
      label,
      multiline
    ]
  );

  const autoCompleteInputLabelProps = useMemo(
    () => ({ ...InputLabelProps, ...InputLabelPropsOverride }),
    [InputLabelProps, InputLabelPropsOverride]
  );

  return (
    <TextField
      multiline={multiline}
      error={showError}
      helperText={
        (showError && errorMessage) || (alwaysShowHelperText && helperText)
      }
      className={classNames(className, {
        [classes.hiddenLabelInput]: hiddenLabel,
        [classes.multilineInput]: multiline
      })}
      placeholder={placeholder}
      variant={variant}
      InputProps={autocompleteInputProps}
      hiddenLabel={hiddenLabel}
      label={hiddenLabel ? undefined : label}
      InputLabelProps={autoCompleteInputLabelProps}
      {...rest}
    />
  );
};

export default AutocompleteInput;

AutocompleteInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hiddenLabel: PropTypes.bool,
  variant: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.any,
  params: PropTypes.object,
  placeholder: PropTypes.string,
  helperText: PropTypes.any,
  alwaysShowHelperText: PropTypes.bool,
  multiline: PropTypes.bool,
  dataQeId: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaControls: PropTypes.string,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputLabelPropsOverride: PropTypes.object
};
