import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import classNames from 'classnames';
import { gantt } from '@replicon/dhtmlx-gantt';
import '@replicon/dhtmlx-gantt/codebase/ext/dhtmlxgantt_tooltip';
import '@replicon/dhtmlx-gantt/codebase/ext/dhtmlxgantt_undo';
import { TaskStatus } from '~/types';
import {
  isoStringToFormattedISOString,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

export const isSameYear = (a, b) => a.year === b.year;

const getClassNames = ({
  classes,
  taskBarBaseClass,
  statusClass,
  ganttScale,
  disableTaskDrag
}) =>
  classNames(
    classes.ganttFontFamily,
    taskBarBaseClass,
    {
      [classes.ganttTaskBarYear]: ganttScale === 'year',
      [classes.ganttTaskBarQuarter]: ganttScale === 'quarter',
      [classes.disableTaskDrag]: disableTaskDrag
    },
    statusClass
  );

const getGanttScaleClass = (classes, ganttScale) => ({
  [classes.ganttTaskBarYear]: ganttScale === 'year',
  [classes.ganttTaskBarQuarter]: ganttScale === 'quarter'
});

const getHoursText = (intl, hours) =>
  hours
    ? intl.formatNumber(hours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';

const taskBarText = (
  intl,
  classes,
  canViewEstimate,
  isPsaPrpManualTaskStatusEnabled
) => (start, end, task) => {
  const actualHours = getHoursText(intl, task.actualHours);
  const estimatedHours = getHoursText(intl, task.estimatedHours);
  const estimatedCompletionHours = getHoursText(
    intl,
    task.estimatedCompletionHours
  );

  const isActualOverEstimatedHours =
    task.actualHours > task.estimatedHours && task.estimatedHours > 0;
  const isEstCompletionOverEstimatedHours =
    task.estimatedCompletionHours > task.estimatedHours &&
    task.estimatedHours > 0;

  const actualHoursMessageValue = isActualOverEstimatedHours ? (
    <span className={classes.overEstimate}>{actualHours}</span>
  ) : (
    actualHours
  );

  const estimatedHoursMessageValue =
    task.taskStatus === TaskStatus.Inprogress ||
    (isPsaPrpManualTaskStatusEnabled &&
      task.taskStatus === TaskStatus.Notstarted) ? (
      isEstCompletionOverEstimatedHours ? (
        <span className={classes.overEstimate}>{estimatedCompletionHours}</span>
      ) : (
        estimatedCompletionHours
      )
    ) : (
      estimatedHours
    );

  const messageValues = {
    actual: actualHoursMessageValue,
    estimate: canViewEstimate ? estimatedHoursMessageValue : ''
  };

  const showActualAndEstimatedHours =
    canViewEstimate &&
    (task.estimatedHours ||
      task.estimatedCompletionHours ||
      (!task.estimatedHours && !task.actualHours));

  const progressValue = showActualAndEstimatedHours
    ? intl.formatMessage(
        {
          id: 'projectTasksPage.progressValue'
        },
        messageValues
      )
    : intl.formatMessage(
        { id: 'projectTasksPage.progressValueWithoutEstimate' },
        messageValues
      );

  return renderToString(
    <span className={classes.ganttTaskContentContainer}>
      <span className={classes.ganttTaskProgress}>{progressValue}</span>
      {task.isMilestone && <span className={classes.milestoneMark} />}
    </span>
  );
};

const taskBarMilestone = classes => (start, end, task) =>
  renderToString(
    <span>
      {task.isMilestone && <span className={classes.milestoneMarkRollUp} />}
    </span>
  );

const taskTooltip = (intl, canViewEstimate) => (startDate, endDate, task) => {
  const formattedStartDate = isSameYear(
    mapIsoStringtoUtcObject(task.startDate),
    mapIsoStringtoUtcObject(task.endDate)
  )
    ? isoStringToFormattedISOString(task.startDate, 'LLL d')
    : isoStringToFormattedISOString(task.startDate, 'DD');
  const formattedEndDate = isoStringToFormattedISOString(task.endDate, 'DD');

  const dateLine = `${formattedStartDate} — ${formattedEndDate}`;
  const taskLabel = intl.formatMessage({
    id: 'projectTasksPage.task'
  });
  const estimatedHoursLabel = intl.formatMessage({
    id: 'projectTasksPage.estimatedHours'
  });
  const estimatedHours = task.estimatedHours
    ? intl.formatNumber(task.estimatedHours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';

  const actualHoursLabel = intl.formatMessage({
    id: 'projectTasksPage.actualHours'
  });
  const actualHours = task.actualHours
    ? intl.formatNumber(task.actualHours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';
  const estimatedHoursAtCompletionLabel = intl.formatMessage({
    id: 'projectTasksPage.estimatedHoursAtCompletion'
  });

  const estimatedCompletionHours = task.estimatedCompletionHours
    ? intl.formatNumber(task.estimatedCompletionHours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';

  return renderToString(
    <span>
      <span>
        {taskLabel}: {task.text}
      </span>
      <br />
      <span>{dateLine}</span>
      {canViewEstimate && (
        <>
          <br />
          <span>
            {estimatedHoursLabel}: {estimatedHours}
          </span>
        </>
      )}
      <br />
      <span>
        {actualHoursLabel}: {actualHours}
      </span>
      <br />
      {canViewEstimate && (
        <span>
          {estimatedHoursAtCompletionLabel}: {estimatedCompletionHours}
        </span>
      )}
    </span>
  );
};

export const gridRowClass = (me, classes) => (start, end, task) => {
  const { $level } = task;
  const hideAddButton = $level === 10;

  return classNames(
    classes.ganttFontFamily,
    hideAddButton && classes.ganttGridRowNoAdd,
    me.isRolledUpTaskEstimateCalculationMethodEnabled
      ? task.parent
        ? classes.ganttGridRow2
        : classes.ganttGridRowProject
      : classes.ganttGridRow
  );
};

export const taskRowClass = (me, classes) => (start, end, task) =>
  classNames(
    classes.ganttFontFamily,
    me.isRolledUpTaskEstimateCalculationMethodEnabled
      ? task.parent
        ? classes.ganttGridRow2
        : classes.ganttGridRowProject
      : classes.ganttGridRow
  );

const getIndentationWidth = ({
  taskLevel,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => ({
  width: isRolledUpTaskEstimateCalculationMethodEnabled
    ? theme.spacing(taskLevel + 1)
    : theme.spacing(2.25) + theme.spacing(taskLevel) // theme.spacing(2.25) is the width of expand/collapse icon
});

const gridIndent = ({
  taskLevel,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) =>
  renderToString(
    <div
      style={getIndentationWidth({
        taskLevel,
        isRolledUpTaskEstimateCalculationMethodEnabled
      })}
    ></div>
  );

export const getGridTaskClass = (me, classes, isTaskDateRollupEnabled) => (
  start,
  end,
  task
) => {
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: {
      isPsaPrpManualTaskStatusEnabled,
      isPsaPswatTaskDateRollupEnabled
    }
  } = me;

  const disableTaskDrag =
    isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled;
  const taskHasChildren = isPsaPswatTaskDateRollupEnabled
    ? task.hasChildren
    : gantt.hasChild(task.id);

  if (isRolledUpTaskEstimateCalculationMethodEnabled) {
    if (!task.startDate || !task.endDate || task.startDate === task.endDate) {
      return classNames(classes.ganttFontFamily, classes.ganttTaskBar, {
        [classes.disableTaskDrag]:
          task.startDate && task.endDate && disableTaskDrag && taskHasChildren
      });
    }

    if (taskHasChildren) {
      return getGridParentTaskClass({
        task,
        me,
        classes,
        isTaskDateRollupEnabled
      });
    }

    if (isPsaPrpManualTaskStatusEnabled) {
      return classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar2Rounded,
        task.taskStatus === TaskStatus.Completed
          ? classes.ganttTaskBar2RoundedBorderCompleted
          : task.taskStatus === TaskStatus.Inprogress
          ? classes.ganttTaskBar2RoundedBorderInProgress
          : classes.ganttTaskBar2RoundedBorderNotStarted
      );
    }

    if (task.taskStatus === TaskStatus.Inprogress) {
      return classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar2RoundedBorder
      );
    }

    return classNames(classes.ganttFontFamily, classes.ganttTaskBar2Rounded);
  }

  return classNames(classes.ganttFontFamily, classes.ganttTaskBar, {
    [classes.disableTaskDrag]: disableTaskDrag && taskHasChildren
  });
};

const getGridParentTaskClass = ({
  task,
  me,
  classes,
  isTaskDateRollupEnabled
}) => {
  const {
    featureFlags: {
      isPsaPrpManualTaskStatusEnabled,
      isPsaPswatTaskDateRollupEnabled
    }
  } = me;

  const disableTaskDrag =
    isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled;

  const { taskStatus, progress } = task;

  if (isPsaPrpManualTaskStatusEnabled) {
    return taskStatus === TaskStatus.Completed
      ? classNames(
          classes.ganttFontFamily,
          classes.ganttTaskBar2,
          classes.ganttTaskBar2Completed,
          {
            [classes.disableTaskDrag]: disableTaskDrag
          }
        )
      : taskStatus === TaskStatus.Inprogress
      ? classNames(
          classes.ganttFontFamily,
          classes.ganttTaskBar2,
          classes.ganttTaskBar2InProgress,
          progress == 0
            ? classes.ganttTaskBar2InProgressEmpty
            : progress < 1
            ? classes.ganttTaskBar2InProgressPartlyFull
            : classes.ganttTaskBar2InProgressFull,
          {
            [classes.disableTaskDrag]: disableTaskDrag
          }
        )
      : classNames(
          classes.ganttFontFamily,
          classes.ganttTaskBar2,
          classes.ganttTaskBar2NotStarted,
          progress == 0
            ? classes.ganttTaskBar2NotStartedEmpty
            : progress < 1
            ? classes.ganttTaskBar2NotStartedPartlyFull
            : classes.ganttTaskBar2NotStartedFull,
          {
            [classes.disableTaskDrag]: disableTaskDrag
          }
        );
  }

  return taskStatus === TaskStatus.Completed
    ? classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar2,
        classes.ganttTaskBar2Completed,
        {
          [classes.disableTaskDrag]: disableTaskDrag
        }
      )
    : task.rolledUpSummary.actualHours
    ? classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar2,
        classes.ganttTaskBar2InProgress,
        {
          [classes.disableTaskDrag]: disableTaskDrag
        }
      )
    : classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar2,
        classes.ganttTaskBar2NotStarted,
        {
          [classes.disableTaskDrag]: disableTaskDrag
        }
      );
};

export const getGridTaskClass2 = (
  me,
  classes,
  ganttScale,
  isTaskDateRollupEnabled
) => (start, end, task) => {
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: {
      isPsaPrpManualTaskStatusEnabled,
      isPsaPswatTaskDateRollupEnabled
    }
  } = me;

  const disableTaskDrag =
    isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled;

  const { id, startDate, endDate, taskStatus, hasChildren } = task;
  const taskHasChildren = isPsaPswatTaskDateRollupEnabled
    ? hasChildren
    : gantt.hasChild(id);

  if (isRolledUpTaskEstimateCalculationMethodEnabled) {
    if (!startDate || !endDate || startDate === endDate) {
      return isPsaPrpManualTaskStatusEnabled
        ? classNames(
            classes.ganttFontFamily,
            classes.ganttTaskBar,
            getGanttScaleClass(classes, ganttScale)
          )
        : getClassNames({
            classes,
            taskBarBaseClass: classes.ganttTaskBar,
            ganttScale,
            disableTaskDrag:
              startDate && endDate && disableTaskDrag && taskHasChildren
          });
    }

    if (taskHasChildren) {
      getGridParentTaskClass2({
        task,
        me,
        classes,
        ganttScale,
        isTaskDateRollupEnabled
      });
    }

    if (isPsaPrpManualTaskStatusEnabled) {
      return classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar2Rounded,
        taskStatus === TaskStatus.Completed
          ? classes.ganttTaskBar2RoundedBorderCompleted
          : taskStatus === TaskStatus.Inprogress
          ? classes.ganttTaskBar2RoundedBorderInProgress
          : classes.ganttTaskBar2RoundedBorderNotStarted,
        getGanttScaleClass(classes, ganttScale)
      );
    }

    if (taskStatus === TaskStatus.Inprogress) {
      return getClassNames({
        classes,
        taskBarBaseClass: classes.ganttTaskBar2RoundedBorder,
        ganttScale,
        disableTaskDrag
      });
    }

    return getClassNames({
      classes,
      taskBarBaseClass: classes.ganttTaskBar2Rounded,
      ganttScale,
      disableTaskDrag
    });
  }

  return isPsaPrpManualTaskStatusEnabled
    ? classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar,
        getGanttScaleClass(classes, ganttScale)
      )
    : getClassNames({
        classes,
        taskBarBaseClass: classes.ganttTaskBar,
        ganttScale,
        disableTaskDrag: disableTaskDrag && taskHasChildren
      });
};

const getGridParentTaskClass2 = ({
  task,
  me,
  classes,
  ganttScale,
  isTaskDateRollupEnabled
}) => {
  const {
    featureFlags: {
      isPsaPrpManualTaskStatusEnabled,
      isPsaPswatTaskDateRollupEnabled
    }
  } = me;

  const disableTaskDrag =
    isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled;

  const { taskStatus, progress, rolledUpSummary } = task;

  if (isPsaPrpManualTaskStatusEnabled) {
    return taskStatus === TaskStatus.Completed
      ? classNames(
          classes.ganttFontFamily,
          classes.ganttTaskBar2,
          classes.ganttTaskBar2Completed,
          {
            [classes.disableTaskDrag]: disableTaskDrag
          },
          getGanttScaleClass(classes, ganttScale)
        )
      : taskStatus === TaskStatus.Inprogress
      ? classNames(
          classes.ganttFontFamily,
          classes.ganttTaskBar2,
          classes.ganttTaskBar2InProgress,
          {
            [classes.disableTaskDrag]: disableTaskDrag
          },
          progress == 0
            ? classes.ganttTaskBar2InProgressEmpty
            : progress < 1
            ? classes.ganttTaskBar2InProgressPartlyFull
            : classes.ganttTaskBar2InProgressFull,
          getGanttScaleClass(classes, ganttScale)
        )
      : classNames(
          classes.ganttFontFamily,
          classes.ganttTaskBar2,
          classes.ganttTaskBar2NotStarted,
          {
            [classes.disableTaskDrag]: disableTaskDrag
          },
          progress == 0
            ? classes.ganttTaskBar2NotStartedEmpty
            : progress < 1
            ? classes.ganttTaskBar2NotStartedPartlyFull
            : classes.ganttTaskBar2NotStartedFull,
          getGanttScaleClass(classes, ganttScale)
        );
  }

  return taskStatus === TaskStatus.Completed
    ? getClassNames({
        classes,
        taskBarBaseClass: classes.ganttTaskBar2,
        statusClass: classes.ganttTaskBar2Completed,
        ganttScale,
        disableTaskDrag
      })
    : rolledUpSummary.actualHours
    ? getClassNames({
        classes,
        taskBarBaseClass: classes.ganttTaskBar2,
        statusClass: classes.ganttTaskBar2InProgress,
        ganttScale,
        disableTaskDrag
      })
    : getClassNames({
        classes,
        taskBarBaseClass: classes.ganttTaskBar2,
        statusClass: classes.ganttTaskBar2NotStarted,
        ganttScale,
        disableTaskDrag
      });
};

export const useGanttTemplates = ({
  me,
  intl,
  classes,
  canViewEstimate,
  isRTL,
  ganttScale,
  isTaskDateRollupEnabled
}) =>
  useMemo(
    () => ({
      task_class: me.featureFlags.isPsaRmpTaskAllocation1Enabled
        ? getGridTaskClass2(me, classes, ganttScale, isTaskDateRollupEnabled)
        : getGridTaskClass(me, classes, isTaskDateRollupEnabled),
      scale_cell_class: () =>
        classNames(classes.ganttFontFamily, classes.ganttScaleCell, {
          [classes.ganttChartTimelineHeader]:
            me.featureFlags.isPsaRmpTaskAllocation1Enabled
        }),
      grid_row_class: gridRowClass(me, classes),
      task_row_class: taskRowClass(me, classes),
      grid_header_class: columnName =>
        classNames(classes.ganttFontFamily, {
          [classes.ganttAssigneeColumnHeader]:
            columnName === 'assigneeText' || columnName === 'assignedRoleText',
          [classes.ganttTaskNameHeaderCell]: columnName === 'text'
        }),
      task_text: me.isRolledUpTaskEstimateCalculationMethodEnabled
        ? taskBarMilestone(classes)
        : taskBarText(
            intl,
            classes,
            canViewEstimate,
            me.featureFlags.isPsaPrpManualTaskStatusEnabled
          ),
      ...(!me.isRolledUpTaskEstimateCalculationMethodEnabled && {
        tooltip_text: taskTooltip(intl, canViewEstimate)
      }),
      ...(me.featureFlags.isPsaRmpTaskAllocation1Enabled && {
        grid_indent: task =>
          gridIndent({
            taskLevel: task.$level,
            isRolledUpTaskEstimateCalculationMethodEnabled:
              me.isRolledUpTaskEstimateCalculationMethodEnabled
          })
      })
    }),
    [canViewEstimate, classes, ganttScale, intl, me, isTaskDateRollupEnabled]
  );

export default useGanttTemplates;
