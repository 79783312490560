import { Grid, ListItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import CreateBillWithLineItemsDialog from '~/modules/billing-invoicing/billingItem/CreateBillWithLineItemsDialog';
import { useDialogState, useHasFeatureFlag } from '~/modules/common/hooks';
import { useAvailableToBillListTableContext } from '~/modules/billing-invoicing/common/hooks';
import {
  MobileBillingTransactionAmountRow,
  MobileBillingTransactionClientRow
} from '../MobileBillingTransactionListItem/components';
import {
  MobileAvailableToBillHeaderRow,
  MobileAvailableToBillProjectRow
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  container: {
    padding: theme.spacing(1)
  },
  grid: {
    padding: theme.spacing(0, 1)
  }
}));

export const MobileAvailableToBillListItem = ({ record }) => {
  const classes = useStyles();
  const { client, editable, projectContext } = record;
  const { open, openDialog, closeDialog } = useDialogState(false);
  const isPsaPrpAddProjectColumnOnAvailableToBillEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpAddProjectColumnOnAvailableToBillEnabled'
  });

  const onCardClickHandler = useCallback(() => {
    editable && openDialog();
  }, [editable, openDialog]);

  const { refetchAvailableToBillRows } = useAvailableToBillListTableContext();

  return (
    <>
      <ListItem
        button
        onClick={onCardClickHandler}
        className={
          isPsaPrpAddProjectColumnOnAvailableToBillEnabled
            ? classes.container
            : classes.root
        }
      >
        <Grid
          className={classes.grid}
          container
          direction="column"
          wrap="nowrap"
          component={Paper}
          square
          elevation={0}
        >
          <Grid item>
            <MobileAvailableToBillHeaderRow record={record} />
            {isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
              <MobileAvailableToBillProjectRow record={record} />
            ) : (
              <MobileBillingTransactionClientRow client={client} />
            )}
            <MobileBillingTransactionAmountRow balanceAmount={record.amount} />
          </Grid>
        </Grid>
      </ListItem>
      {open && (
        <CreateBillWithLineItemsDialog
          open
          {...record}
          onClose={closeDialog}
          projectName={projectContext.projectName}
          refetchAvailableToBillRows={refetchAvailableToBillRows}
          projectUri={projectContext.projectUri}
        />
      )}
    </>
  );
};

MobileAvailableToBillListItem.propTypes = {
  record: PropTypes.object.isRequired
};

export default memo(MobileAvailableToBillListItem);
