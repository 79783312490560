import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { Date } from '~/modules/common/components';
import { compareDateObjects } from '~/modules/common/dates/compare';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  date: {
    display: 'flex'
  }
}));

const mapAvailableToBillDateToUtc = repliconDate =>
  repliconDate ? mapRepliconDateToUtcObject(repliconDate) : null;

export const DateFormatter = ({ record, variant = 'body2' }) => {
  const classes = useStyles();

  const isPsaPrpAddProjectColumnOnAvailableToBillEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpAddProjectColumnOnAvailableToBillEnabled'
  });

  if (
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled &&
    compareDateObjects(record.startDate, record.endDate) === 0
  )
    return <Date value={record.startDate} />;

  return (
    <Typography component="div" className={classes.date} variant={variant}>
      {shortDateRangeFormat({
        start: mapAvailableToBillDateToUtc(record.startDate),
        end: mapAvailableToBillDateToUtc(record.endDate)
      })}
    </Typography>
  );
};
DateFormatter.propTypes = {
  record: PropTypes.object,
  variant: PropTypes.string
};

export default DateFormatter;
