import { getTodayForUser } from '~/modules/common/dates/today';
import { useMeContext } from '~/modules/me';
import {
  useResourceMatches,
  useGetResourceUser
} from '~/modules/resourcing/common/hooks';

export const useGetResourceMatchRowData = ({ resourceRequestUri }) => {
  const me = useMeContext();
  const { loading, resourceMatches } = useResourceMatches({
    page: 1,
    pageSize: 3,
    resourceRequestUris: [resourceRequestUri]
  });
  const resourceRequestResourceMatch =
    resourceMatches.length === 1 ? resourceMatches[0].resourceMatches : [];

  const { loading: loading2, resourceUsers } = useGetResourceUser({
    page: 1,
    pageSize: 3,
    userUris: resourceRequestResourceMatch.map(
      resourceMatch => resourceMatch.id
    ),
    date: getTodayForUser(me),
    skip: loading
  });

  return {
    loading: loading || loading2,
    userResourceMatchesMap: resourceRequestResourceMatch.map(resourceMatch => ({
      user: resourceUsers.find(
        resourceUser => resourceUser.id === resourceMatch.id
      ),
      resourceMatch
    }))
  };
};

export default useGetResourceMatchRowData;
