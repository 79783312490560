import React from 'react';
import PropTypes from 'prop-types';
import { FormNumberField, FormTextField } from '~/modules/common/components';
import { useInputFieldPropsOverride } from '~/modules/common/hooks';
import {
  DATE_TYPE_URI,
  DROP_DOWN_TYPE_URI,
  NUMERIC_TYPE_URI,
  TEXT_TYPE_URI
} from '../definitionTypes';
import CustomFieldValuePropType from './CustomFieldValuePropType';
import EditableDateCustomField from './EditableDateCustomField';
import EditableDropDownCustomField from './EditableDropDownCustomField';

const SelectDisplayProps = { role: 'listbox' };
const getInputLabelProps = labelId => ({ id: labelId });

export const EditableCustomField = ({
  classes: classesOverride,
  customFieldValue,
  onChange,
  name,
  enableNoChangeOption = false,
  enableNoValueOption = false,
  ...rest
}) => {
  const {
    customField,
    customFieldType,
    number,
    text,
    customFieldDefinition: definition
  } = customFieldValue;
  const { id: typeUri } = customFieldType;
  const { displayText: label } = customField;
  const { classes: inputClasses, placeholder, InputLabelProps } =
    useInputFieldPropsOverride({
      enableNoChange:
        enableNoChangeOption &&
        (typeUri === DATE_TYPE_URI ||
          typeUri === TEXT_TYPE_URI ||
          typeUri === NUMERIC_TYPE_URI)
    }) || {};

  const classes =
    classesOverride && inputClasses
      ? { ...classesOverride, ...inputClasses }
      : inputClasses || classesOverride;

  switch (typeUri) {
    case DATE_TYPE_URI:
      return (
        <EditableDateCustomField
          classes={classes}
          id={name}
          required={definition.isRequired}
          customFieldValue={customFieldValue}
          onChange={onChange}
          placeholder={placeholder}
          InputLabelProps={InputLabelProps}
          ariaLabel={rest?.ariaLabel || label}
          {...rest}
        />
      );
    case DROP_DOWN_TYPE_URI:
      return (
        <EditableDropDownCustomField
          classes={classes}
          name={name}
          customFieldValue={customFieldValue}
          onChange={onChange}
          required={definition.isRequired}
          enableNoChangeOption={enableNoChangeOption}
          enableNoValueOption={enableNoValueOption}
          inputLabelProps={getInputLabelProps(`${rest?.id}-label`)}
          SelectDisplayProps={SelectDisplayProps}
          labelId={`${rest?.id}-label`}
          {...rest}
        />
      );
    case NUMERIC_TYPE_URI:
      return (
        <FormNumberField
          classes={classes}
          name={name}
          editable
          label={label}
          ariaLabel={rest?.ariaLabel || label}
          required={definition.isRequired}
          value={number}
          onValueChange={onChange}
          useNumberLabelStyles={false}
          precision={
            definition.numericConfiguration.decimalPlaces
              ? definition.numericConfiguration.decimalPlaces
              : 0
          }
          placeholder={placeholder}
          InputLabelProps={InputLabelProps}
          {...rest}
        />
      );
    case TEXT_TYPE_URI:
    default:
      return (
        <FormTextField
          classes={classes}
          id={name}
          required={definition.isRequired}
          editable
          label={label}
          ariaLabel={rest?.ariaLabel || label}
          value={text}
          onChange={onChange}
          placeholder={placeholder}
          InputLabelProps={InputLabelProps}
          inputClasses={inputClasses}
          {...rest}
        />
      );
  }
};

EditableCustomField.propTypes = {
  customFieldValue: CustomFieldValuePropType,
  onChange: PropTypes.func,
  name: PropTypes.string,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool,
  classes: PropTypes.object
};

export default EditableCustomField;
