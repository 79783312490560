import { makeStyles, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Alert } from '@material-ui/lab';
import { useHasPermission } from '~/modules/common/permissions';
import { roundToDecimals } from '~/modules/resourcing/common/util';
import OverAllocationConfirmationArea from './OverAllocationConfirmationArea/OverAllocationConfirmationArea';

const useStyles = makeStyles(theme => ({
  alertContainer: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  alert: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(-5.5)
  },
  container: {
    width: '100%'
  },
  resourceChange: {
    padding: theme.spacing(2, 0, 0.5, 1),
    width: '100%'
  },
  confirmation: {
    padding: theme.spacing(0, 1, 1, 1),
    color: theme.palette.text.secondary
  },
  checkbox: {
    marginTop: theme.spacing(3)
  },
  dateRange: {
    whiteSpace: 'nowrap'
  },
  noAllocationHours: {
    paddingTop: theme.spacing(1)
  }
}));

const useAlertStyles = makeStyles(theme => ({
  message: {
    width: '100%'
  }
}));

export const ChangeTaskResourceConfirmationArea = ({
  availableHours,
  existingResource,
  selectedResource,
  taskResourceUserAllocation,
  taskAllocationDateRange
}) => {
  const classes = useStyles();
  const alertClasses = useAlertStyles();
  const { totalHours: existingUserTaskAllocationHours } =
    taskResourceUserAllocation || {};
  const resourceDisplayText = useMemo(
    () => ({
      resourceDisplayText: selectedResource?.displayText
    }),
    [selectedResource]
  );

  const isResourceManager = useHasPermission({
    actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
  });

  const hasNoAvailableHours =
    taskResourceUserAllocation && availableHours === null;

  const hasInsufficientAvailableHours =
    availableHours !== null &&
    roundToDecimals(availableHours) <
      roundToDecimals(existingUserTaskAllocationHours);

  const noAllocationMessageValue = useMemo(
    () => ({
      resourceDisplayText: selectedResource?.displayText
    }),
    [selectedResource]
  );

  const noAllocationMessageHoursValue = useMemo(
    () => ({
      allocationHours: existingUserTaskAllocationHours?.toFixed(2)
    }),
    [existingUserTaskAllocationHours]
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.resourceChange}>
        <FormattedMessage
          id="changeTaskResourceDialog.resourceChange"
          values={resourceDisplayText}
        />
      </Typography>
      <div role="alert">
        {Boolean(hasInsufficientAvailableHours) && (
          <Alert
            severity="warning"
            icon={false}
            className={classes.alertContainer}
            classes={alertClasses}
            role="presentation"
            data-qe-id="InsufficientAvailableHoursWarning"
          >
            <OverAllocationConfirmationArea
              resourceDisplayText={resourceDisplayText}
              taskAllocationDateRange={taskAllocationDateRange}
              availableHours={availableHours}
              existingUserTaskAllocationHours={existingUserTaskAllocationHours}
              isResourceManager={isResourceManager}
            />
          </Alert>
        )}
        {Boolean(hasNoAvailableHours && !isResourceManager) && (
          <Alert
            severity="warning"
            icon={false}
            className={classes.alertContainer}
            classes={alertClasses}
            role="presentation"
            data-qe-id="NoAvailableHoursWarning"
          >
            <FormattedMessage
              id="changeTaskResourceDialog.noResourceAllocation"
              values={noAllocationMessageValue}
            />

            <div className={classes.noAllocationHours}>
              <FormattedMessage
                id="changeTaskResourceDialog.noResourceAllocationHoursMessage"
                values={noAllocationMessageHoursValue}
              />
            </div>
          </Alert>
        )}
      </div>
      <div role="alert">
        <Typography className={classes.confirmation}>
          <FormattedMessage id="changeTaskResourceDialog.confirmation" />
        </Typography>
      </div>
    </div>
  );
};

ChangeTaskResourceConfirmationArea.propTypes = {
  selectedResource: PropTypes.object,
  existingResource: PropTypes.object,
  taskResourceUserAllocation: PropTypes.object,
  taskAllocationDateRange: PropTypes.object,
  availableHours: PropTypes.number
};

export default ChangeTaskResourceConfirmationArea;
