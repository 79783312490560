import React from 'react';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useHasPermission } from '~/modules/common/permissions';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { UserRoleActualsTimeLineRow } from '~/modules/resourcing/common/components';
import ResourceAllocationChartTimeLineItem2 from '../ResourceAllocationChartTimeLineItem/ResourceAllocationChartTimeLineItem2';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    overflow: 'hidden'
  },
  rootWithBorder: {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  loadingRoot: {
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  singleAllocationBar: {
    width: '100%',
    paddingTop: theme.spacing(1)
  }
}));

const useActualStyles = makeStyles(theme => ({
  root: ({ allocationsCount }) => ({
    minHeight: (allocationsCount + 1) * theme.spacing(4) + 8
  })
}));

const mergeUserScheduleDetails = ({
  allocation,
  userScheduleDetails: {
    scheduleDurationByDay,
    scheduleDurationByPeriodMap,
    resourceAvailabilitySummaryByPeriodMap,
    holidays,
    timeoffs
  }
}) => ({
  ...allocation,
  user: {
    ...allocation.user,
    scheduleDurationByDay,
    scheduleDurationByPeriodMap,
    resourceAvailabilitySummaryByPeriodMap,
    holidays,
    timeoffs
  }
});

export const ResourceAllocationChartTimeline = ({
  actualSummaryBlockPopupProps,
  actualsData,
  allocatedUser,
  chartDisplayPeriods,
  classes: classesOverride,
  handleRemoveResourceAllocation,
  isEditSwitchEnabled,
  loading,
  resourceAllocations,
  userScheduleDetails,
  index,
  setSnackBarState,
  otherProjectAllocationsSummary
}) => {
  const {
    scale,
    dateRange,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();

  const classes = useStyles({ classes: classesOverride });
  const actualClasses = useActualStyles({
    allocationsCount: resourceAllocations.length
  });

  const isEditAllowed =
    useHasPermission({
      actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
    }) && isEditSwitchEnabled;

  return (
    <div
      className={classNames(classes.root, {
        [actualClasses.root]: !loading && isResourceActualModeEnabled,
        [classes.loadingRoot]: loading,
        [classes.rootWithBorder]: index > 0
      })}
    >
      {loading ? (
        <Skeleton variant="rect" />
      ) : (
        (resourceAllocations || []).map(allocation => {
          return (
            <div key={allocation.id} className={classes.singleAllocationBar}>
              <ResourceAllocationChartTimeLineItem2
                chartDisplayPeriods={chartDisplayPeriods}
                handleRemoveResourceAllocation={handleRemoveResourceAllocation}
                setSnackBarState={setSnackBarState}
                allocatedUser={allocatedUser}
                isEditEnabled={isEditAllowed}
                otherProjectAllocationsSummary={otherProjectAllocationsSummary}
                resourceAllocation={mergeUserScheduleDetails({
                  allocation,
                  userScheduleDetails
                })}
              />
            </div>
          );
        })
      )}

      {isResourceActualModeEnabled &&
        (loading ? (
          <Skeleton variant="rect" />
        ) : (
          <UserRoleActualsTimeLineRow
            actualSummaryBlockPopupProps={actualSummaryBlockPopupProps}
            actualsData={actualsData}
            chartDisplayDateRange={dateRange}
            chartDisplayPeriods={chartDisplayPeriods}
            resourceAllocations={resourceAllocations}
            scale={scale}
            user={allocatedUser.user}
            userScheduleDetails={userScheduleDetails}
          />
        ))}
    </div>
  );
};

export default ResourceAllocationChartTimeline;

ResourceAllocationChartTimeline.propTypes = {
  actualSummaryBlockPopupProps: PropTypes.object,
  actualsData: PropTypes.object,
  allocatedUser: PropTypes.object,
  chartDisplayPeriods: PropTypes.array,
  classes: PropTypes.object,
  index: PropTypes.number,
  handleRemoveResourceAllocation: PropTypes.func,
  otherProjectAllocationsSummary: PropTypes.object,
  isEditSwitchEnabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  resourceAllocations: PropTypes.array,
  userScheduleDetails: PropTypes.object.isRequired,
  setSnackBarState: PropTypes.func
};
