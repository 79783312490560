import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(26.75),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spinner: {
    marginRight: theme.spacing(1.5)
  }
}));

export const BatchInProgressIndicator = ({ messageId }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress size={24} className={classes.spinner} />
      <Typography variant="body2" color="textSecondary">
        <FormattedMessage id={messageId} />
      </Typography>
    </div>
  );
};

BatchInProgressIndicator.propTypes = {
  messageId: PropTypes.string.isRequired
};

export default BatchInProgressIndicator;
