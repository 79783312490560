import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  useHasFeatureFlag,
  useFetchRequisitionHiringStatus
} from '~/modules/common/hooks';
import {
  ResourceRequestRowDetailsContent1,
  ResourceRequestRowDetailsContent2
} from './ResourceRequestRowDetailsContent';

export const ResourceRequestRowDetails = ({
  resourceRequest,
  allocations,
  showResourceAllocation,
  isPsaRmpTaskAllocation1Enabled,
  maxSkillLevel,
  isPsaPraaResourcingUIAndRMRole
}) => {
  const { role, currency } = resourceRequest;
  const selectedRole = useMemo(
    () =>
      role
        ? {
            ...role,
            amount: role.currentRate ? role.currentRate.amount : null,
            symbol: currency ? currency.displayText : null
          }
        : null,
    [role, currency]
  );

  const isPsaDtmRequisitionEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaDtmRequisitionEnabled'
  });

  const requisitionHiringStatus = useFetchRequisitionHiringStatus({
    resourceRequestUri: resourceRequest.id,
    skip: !isPsaDtmRequisitionEnabled
  });

  return isPsaRmpTaskAllocation1Enabled ? (
    <ResourceRequestRowDetailsContent2
      resourceRequest={resourceRequest}
      allocations={allocations}
      maxSkillLevel={maxSkillLevel}
      requisitionHiringStatus={requisitionHiringStatus}
      isPsaDtmRequisitionEnabled={isPsaDtmRequisitionEnabled}
    />
  ) : (
    <ResourceRequestRowDetailsContent1
      allocations={allocations}
      resourceRequest={resourceRequest}
      selectedRole={selectedRole}
      maxSkillLevel={maxSkillLevel}
      showResourceAllocation={showResourceAllocation}
      isPsaPraaResourcingUIAndRMRole={isPsaPraaResourcingUIAndRMRole}
      requisitionHiringStatus={requisitionHiringStatus}
    />
  );
};

ResourceRequestRowDetails.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  allocations: PropTypes.array,
  showResourceAllocation: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  maxSkillLevel: PropTypes.number,
  isPsaPraaResourcingUIAndRMRole: PropTypes.bool
};

export default ResourceRequestRowDetails;
