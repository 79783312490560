/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { tabs } from './ResourceUsersDropdownTabs';
import AvailabilityListSubheader from './AvailabilityListSubheader';

export const useLoadingStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0, 0, 0, 2)
  },
  loadingMessage: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    width: 'max-content'
  }
}));

export const useSkeletonStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.75, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  resourceDetails: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  option: {
    paddingLeft: theme.spacing(1),
    width: '100%'
  }
}));

export const AvailabilitySkeleton = () => (
  <Skeleton variant="text" width={45} height={16} />
);
export const AvatarSkeleton = () => (
  <Skeleton variant="circle" width={32} height={32} />
);
export const ResourceNameSkeleton = () => (
  <Skeleton variant="text" width={128} height={16} />
);
export const RoleSkeleton = () => (
  <Skeleton variant="text" width={92} height={16} />
);

const ResourceUsersDropdownSkeleton = ({
  classes,
  isAvailabilityEnabled,
  sort,
  onSortChange,
  toggleAvailabilityColumnVisibility,
  hideAvailability,
  selectedTab,
  isProjectRoleEnabled,
  assignedRole,
  tabClasses,
  handleTabChange
}) => {
  const skeletonClasses = useSkeletonStyles();
  const loadingClasses = useLoadingStyles();
  const { formatMessage } = useIntl();

  const groupName =
    selectedTab === tabs.ALL_RESOURCES
      ? !assignedRole || !isProjectRoleEnabled
        ? formatMessage({ id: 'addResource.resource' })
        : `${assignedRole.displayText} ${formatMessage({
            id: 'addResource.resources'
          })}`
      : !assignedRole
      ? formatMessage({ id: 'taskResourceAssignments.allocatedResources' })
      : `${assignedRole.displayText} ${formatMessage({
          id: 'addResource.resources'
        })}`;

  const SkeletonRow = () => (
    <div className={skeletonClasses.root}>
      <AvatarSkeleton />
      <div className={skeletonClasses.option}>
        <div className={skeletonClasses.resourceDetails}>
          <ResourceNameSkeleton />
          {isAvailabilityEnabled && <AvailabilitySkeleton />}
        </div>
        <RoleSkeleton />
      </div>
    </div>
  );

  return (
    <>
      <AvailabilityListSubheader
        sort={sort}
        onSortChange={onSortChange}
        isAvailabilityEnabled={isAvailabilityEnabled}
        showAvailability={!hideAvailability}
        toggleAvailabilityColumnVisibility={toggleAvailabilityColumnVisibility}
        group={groupName}
        classes={classes}
      />
      {isAvailabilityEnabled && (
        <div className={loadingClasses.root}>
          {formatMessage({ id: 'addResource.loading' })}
          <div className={loadingClasses.loadingMessage}>
            {formatMessage({
              id: 'addResource.availabilityOnLoadingMessage'
            })}
          </div>
        </div>
      )}
      <div className={classes.groupUl}>
        {[...Array(4)].map((_, index) => (
          <SkeletonRow key={`Option_${index}`} />
        ))}
      </div>
    </>
  );
};

ResourceUsersDropdownSkeleton.propTypes = {
  classes: PropTypes.object,
  sort: PropTypes.object,
  onSortChange: PropTypes.func,
  isAvailabilityEnabled: PropTypes.bool,
  toggleAvailabilityColumnVisibility: PropTypes.func,
  hideAvailability: PropTypes.bool,
  selectedTab: PropTypes.oneOf([tabs.ALL_RESOURCES, tabs.PROJECT_RESOURCES]),
  isProjectRoleEnabled: PropTypes.bool,
  assignedRole: PropTypes.object,
  tabClasses: PropTypes.object,
  handleTabChange: PropTypes.func
};

export default ResourceUsersDropdownSkeleton;
