import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  legend: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1)
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0, 1, 0)
  },
  net: {
    padding: theme.spacing(2, 0.5, 0, 0)
  },
  showCommentLabel: {
    color: theme.palette.text.primary
  }
}));

export const useDropDownStyles = makeStyles(theme => ({
  root: {
    '& label': {
      transform: 'translate(8px, 17px) scale(0.85)'
    },
    '& label[data-shrink="true"]': {
      transform: 'translate(8px, 7px) scale(0.65)'
    }
  },
  valueContainer: {
    alignItems: 'unset',
    height: 22,
    flexWrap: 'nowrap',
    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  input: {
    height: '1.1875em',
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: 10,
    width: '100%'
  }
}));
