import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { buildCustomFieldFormValuesFromDefinitions } from '~/modules/customFields/utils';
import { mapNumberValueOrDefault } from '~/modules/customFields/utils/mapNumberValue';
import { pickFileAttributes } from '~/modules/projects/project/ProjectInfoDetails/AdditionalInfo/useUpdateProjectAdditionalInfo';
import {
  NO_CHANGE_OPTION_ID,
  NO_VALUE_OPTION_ID
} from '~/modules/common/hooks';

export const buildExtensionFieldValues = values =>
  Array.isArray(values)
    ? values.reduce(
        (extensionFieldValues, e) => ({
          ...extensionFieldValues,
          [e.id]: {
            definitionTypeUri: e.definitionTypeUri,
            definition: e
          }
        }),
        {}
      )
    : {};

export const mapCustomFieldValues = customFieldValues =>
  Object.values(customFieldValues)
    .filter(
      v => !!v.date || v.number !== null || !!v.text || !!v.dropDownOptionUri
    )
    .map(c => ({
      customField: {
        uri: c.customField.id
      },
      date: c.date,
      dropDownOption:
        c.dropDownOptionUri !== NO_CHANGE_OPTION_ID
          ? {
              uri:
                c.dropDownOptionUri === NO_VALUE_OPTION_ID
                  ? null
                  : c.dropDownOptionUri
            }
          : null,
      number: mapNumberValueOrDefault(c.number),
      text: c.text
    }));

export const mapExtensionFieldValues = extensionFieldValues =>
  Object.values(extensionFieldValues)
    .filter(
      v => v.numericValue !== undefined || v.textValue || v.fileValue || v.tag
    )
    .map(e => ({
      definition: {
        uri: e.definition.id
      },
      numericValue: e.numericValue,
      textValue: e.textValue,
      fileValue: e.fileValue
        ? {
            data: e.fileValue.data && pickFileAttributes(e.fileValue.data),
            identityUri: e.fileValue.identityUri
          }
        : null,
      tag:
        e.tag && e.tag?.id !== NO_CHANGE_OPTION_ID
          ? e.tag?.id === NO_VALUE_OPTION_ID
            ? null
            : { uri: e.tag?.id }
          : null
    }));

export const getValue = option => {
  if (option?.id === NO_CHANGE_OPTION_ID) return undefined;
  if (option?.id === NO_VALUE_OPTION_ID) return { id: null };

  return option;
};

export const mapValuesToInput = ({
  client,
  clientRepresentative,
  program,
  projectManager,
  workflowStage,
  projectManagerTimesheetApproval,
  costType,
  timeAndExpenseEntryType,
  timeEntry,
  currency,
  customFieldValues,
  extensionFieldValues,
  costCenter,
  department,
  division,
  employeeType,
  location,
  serviceCenter
}) => ({
  clientUri: getValue(client)?.id,
  clientRepresentativeUri: getValue(clientRepresentative)?.id,
  programUri: getValue(program)?.id,
  projectManagerUri: getValue(projectManager)?.id,
  projectWorkflowState: getValue(workflowStage)?.id,
  isProjectLeaderApprovalRequired: !projectManagerTimesheetApproval?.id
    ? projectManagerTimesheetApproval
    : projectManagerTimesheetApproval.id === 'required'
    ? true
    : projectManagerTimesheetApproval.id === 'noApproval'
    ? false
    : null,
  costTypeUri: getValue(costType)?.id,
  timeAndExpenseEntryTypeUri: getValue(timeAndExpenseEntryType)?.id,
  isTimeEntryAllowed: !timeEntry?.id
    ? timeEntry
    : timeEntry.id === 'taskSelectionOptional'
    ? true
    : timeEntry.id === 'taskSelectionRequired'
    ? false
    : null,
  defaultBillingCurrencyUri: getValue(currency)?.id,
  customFields: mapCustomFieldValues(customFieldValues),
  extensionFieldValues: mapExtensionFieldValues(extensionFieldValues),
  costCenterUri: getValue(costCenter)?.id,
  departmentUri: getValue(department)?.id,
  divisionUri: getValue(division)?.id,
  employeeTypeUri: getValue(employeeType)?.id,
  locationUri: getValue(location)?.id,
  serviceCenterUri: getValue(serviceCenter)?.id
});

const useOnSubmit = ({ createMassEditProjectBatch }) =>
  useCallback(
    async values => {
      const input = mapValuesToInput(values);

      await createMassEditProjectBatch(input);
    },
    [createMassEditProjectBatch]
  );

export const useForm = ({
  workflowStage,
  currency,
  client,
  clientRepresentative,
  program,
  costType,
  projectManager,
  projectManagerTimesheetApproval,
  timeAndExpenseEntryType,
  timeEntry,
  customFields,
  extensionFields,
  createMassEditProjectBatch,
  costCenter,
  department,
  division,
  employeeType,
  location,
  serviceCenter
}) => {
  const initialValues = useMemo(
    () => ({
      workflowStage,
      currency,
      client,
      clientRepresentative,
      program,
      costType,
      projectManager,
      projectManagerTimesheetApproval,
      timeAndExpenseEntryType,
      timeEntry,
      customFieldValues: buildCustomFieldFormValuesFromDefinitions(
        customFields || [],
        true
      ),
      extensionFieldValues: buildExtensionFieldValues(extensionFields),
      costCenter,
      department,
      division,
      employeeType,
      location,
      serviceCenter
    }),
    [
      workflowStage,
      currency,
      client,
      clientRepresentative,
      program,
      costType,
      projectManager,
      projectManagerTimesheetApproval,
      timeAndExpenseEntryType,
      timeEntry,
      customFields,
      extensionFields,
      costCenter,
      department,
      division,
      employeeType,
      location,
      serviceCenter
    ]
  );

  const onSubmit = useOnSubmit({ createMassEditProjectBatch });

  return useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  });
};
