import { useCallback, useState } from 'react';

export default ({
  me,
  issueBill,
  onMenuClick,
  record,
  updateTransactionRows,
  onClose
}) => {
  const {
    featureFlags: { isPsaPrpCappedBillingEnabled }
  } = me;
  const [failedLineItems, setFailedLineItems] = useState([]);
  const handleIssueSyncClick = useCallback(async () => {
    const result = await issueBill(true);

    if (isPsaPrpCappedBillingEnabled) {
      if (result.issueBill.success) {
        onClose();
        updateTransactionRows && updateTransactionRows();

        return;
      }
      setFailedLineItems(result.issueBill.failedLineItems);

      return;
    }

    updateTransactionRows && updateTransactionRows();
  }, [isPsaPrpCappedBillingEnabled, issueBill, onClose, updateTransactionRows]);

  const handleIssueEmailClick = useCallback(async () => {
    const result = await issueBill(false);

    if (isPsaPrpCappedBillingEnabled) {
      if (result.issueBill.success) {
        onClose();
        onMenuClick();
      } else setFailedLineItems(result.issueBill.failedLineItems);
    } else onMenuClick();
  }, [isPsaPrpCappedBillingEnabled, issueBill, onClose, onMenuClick]);

  const handleIssuePrintClick = useCallback(async () => {
    const result = await issueBill(false);

    if (isPsaPrpCappedBillingEnabled) {
      if (result.issueBill.success) {
        onClose();
        updateTransactionRows && updateTransactionRows();
        window.open(
          `${me.uiRootPath}invoicing2/invoice-snapshot/${record.id}`,
          '_blank'
        );

        return;
      }
      setFailedLineItems(result.issueBill.failedLineItems);

      return;
    }

    updateTransactionRows && updateTransactionRows();
    window.open(
      `${me.uiRootPath}invoicing2/invoice-snapshot/${record.id}`,
      '_blank'
    );
  }, [
    issueBill,
    me.uiRootPath,
    onClose,
    record.id,
    updateTransactionRows,
    isPsaPrpCappedBillingEnabled
  ]);

  return {
    failedLineItems,
    setFailedLineItems,
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick
  };
};
