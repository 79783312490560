import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { DateField } from '~/modules/common/components';
import { compareDateObjects } from '~/modules/common/dates/compare';

const useStyles = makeStyles(theme => ({
  input: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    textAlign: 'right'
  }
}));

export const TimelineCalendarContent = ({
  scheduleIndex,
  schedules = [],
  setValues,
  editable
}) => {
  const classes = useStyles();
  const schedule = schedules[scheduleIndex];
  const { formatMessage } = useIntl();

  const timelineOnChange = useCallback(
    val => {
      const scheduleArray = [...schedules];

      scheduleArray.splice(scheduleIndex, 1);

      const sortedSchedules = [
        ...scheduleArray,
        { ...schedule, effectiveDate: val }
      ].sort((a, b) => {
        if (!a.effectiveDate || !b.effectiveDate) {
          return 0;
        }

        return compareDateObjects(a.effectiveDate, b.effectiveDate);
      });

      setValues({ schedule: sortedSchedules });
    },
    [schedule, scheduleIndex, schedules, setValues]
  );

  const getDisabledDates = useCallback(
    date => {
      const effectiveDates = schedules.map(s => s.effectiveDate).filter(d => d);

      return effectiveDates.some(
        effectiveDate =>
          compareDateObjects(date.toObject(), effectiveDate) === 0
      );
    },
    [schedules]
  );

  return !schedule.effectiveDate ? (
    <Typography variant="body2" color="textSecondary">
      {formatMessage({ id: 'rolesForm.initialRoles' })}
    </Typography>
  ) : (
    <DateField
      clearable={false}
      classes={classes}
      name="effectiveDate"
      variant="standard"
      shouldDisableDate={getDisabledDates}
      data-qe-id="ProjectInfoStartDate"
      ariaLabel={formatMessage({ id: 'rolesForm.effectiveDate' })}
      value={schedule.effectiveDate}
      onChange={timelineOnChange}
      underline={editable}
      editable={editable}
      useDateFieldStyles={false}
    />
  );
};

TimelineCalendarContent.propTypes = {
  schedules: PropTypes.array,
  setValues: PropTypes.func.isRequired,
  scheduleIndex: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired
};

export default TimelineCalendarContent;
