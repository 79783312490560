import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TABLE_KEY = 'psa-clients';

export const columns = {
  name: {
    field: 'name',
    value: <FormattedMessage id="clientList.name" />,
    required: true,
    fixed: true,
    sortable: true
  },
  code: {
    field: 'code',
    value: <FormattedMessage id="clientList.code" />,
    sortable: true
  },
  clientManager: {
    field: 'clientManager',
    value: <FormattedMessage id="clientList.clientManager" />,
    sortable: true
  },
  isActive: {
    field: 'isActive',
    value: <FormattedMessage id="clientList.status" />,
    sortable: true
  },
  projectCount: {
    field: 'projectCount',
    value: <FormattedMessage id="clientList.projectCount" />,
    align: 'right',
    sortable: true
  },
  totalEstimatedHours: {
    field: 'totalEstimatedHours',
    value: <FormattedMessage id="clientList.estimatedResourceHours" />,
    align: 'right',
    sortable: true
  },
  totalActualHours: {
    field: 'totalActualHours',
    value: <FormattedMessage id="clientList.actual" />,
    align: 'right',
    sortable: true
  },
  totalEstimatedCost: {
    field: 'totalEstimatedCost',
    value: <FormattedMessage id="clientList.estimatedResourceCost" />,
    align: 'right',
    sortable: true
  },
  totalActualExpenseCost: {
    field: 'totalActualExpenseCost',
    value: <FormattedMessage id="clientList.actualExpenseCost" />,
    align: 'right',
    sortable: true
  },
  totalActualResourceCost: {
    field: 'totalActualResourceCost',
    value: <FormattedMessage id="clientList.actualResourceCost" />,
    align: 'right',
    sortable: true
  },
  totalActualCost: {
    field: 'totalActualCost',
    value: <FormattedMessage id="clientList.actual" />,
    align: 'right',
    sortable: true
  },
  totalEstimatedBillingWithFFPsaPswatEstimatedBillingColumnEnabled: {
    field: 'totalEstimatedBilling',
    value: <FormattedMessage id="clientList.totalEstimatedBilling" />,
    align: 'right',
    sortable: true
  },
  totalEstimatedBilling: {
    field: 'totalEstimatedBilling',
    value: <FormattedMessage id="clientList.totalProjectContractValue" />,
    align: 'right',
    sortable: true
  },
  totalActualBilling: {
    field: 'totalActualRevenue',
    value: <FormattedMessage id="clientList.billableRevenue" />,
    align: 'right',
    sortable: true
  },
  taxProfile: {
    field: 'taxProfile',
    value: <FormattedMessage id="clientList.taxProfile" />,
    align: 'right',
    sortable: true
  },
  estimatedCompletionDate: {
    field: 'estimatedCompletionDate',
    value: <FormattedMessage id="clientList.estimatedCompletionDate" />
  }
};

export const footers = {
  total: {
    fixed: true,
    value: <FormattedMessage id="clientList.total" />,
    align: 'right'
  },
  projectCount: {
    field: 'projectCount',
    requiredColumn: 'projectCount',
    align: 'right'
  },
  totalEstimatedHours: {
    field: 'totalEstimatedHours',
    requiredColumn: 'totalEstimatedHours',
    align: 'right'
  },
  totalActualHours: {
    field: 'totalActualHours',
    requiredColumn: 'totalActualHours',
    align: 'right'
  },
  totalEstimatedCost: {
    field: 'totalEstimatedCost',
    requiredColumn: 'totalEstimatedCost',
    align: 'right'
  },
  totalActualExpenseCost: {
    field: 'totalActualExpenseCost',
    requiredColumn: 'totalActualExpenseCost',
    align: 'right'
  },
  totalActualResourceCost: {
    field: 'totalActualResourceCost',
    requiredColumn: 'totalActualResourceCost',
    align: 'right'
  },
  totalActualCost: {
    field: 'totalActualCost',
    requiredColumn: 'totalActualCost',
    align: 'right'
  },
  totalEstimatedBilling: {
    id: 'totalEstimatedBilling',
    requiredColumn: 'totalEstimatedBilling',
    align: 'right'
  },
  totalActualBilling: {
    field: 'totalActualRevenue',
    requiredColumn: 'totalActualRevenue',
    align: 'right'
  },
  taxProfile: {
    field: 'taxProfile',
    requiredColumn: 'taxProfile',
    align: 'right'
  }
};

export const emptyFooter = field => ({
  field,
  requiredColumn: field
});

export const enabledBasicColumnsAndFooters = ({
  hasClientManagerRole,
  hasViewTaxProfile
}) => {
  return {
    basicColumns: [
      columns.name,
      columns.code,
      Boolean(hasClientManagerRole) && columns.clientManager,
      columns.isActive,
      columns.projectCount,
      Boolean(hasViewTaxProfile) && columns.taxProfile
    ].filter(x => x),
    basicFooters: [
      footers.total,
      emptyFooter(columns.code.field),
      Boolean(hasClientManagerRole) && emptyFooter(columns.clientManager.field),
      emptyFooter(columns.isActive.field),
      footers.projectCount,
      Boolean(hasViewTaxProfile) && footers.taxProfile
    ].filter(x => x)
  };
};

const enabledHoursColumnsAndFooters = permissionsMap => {
  const estimatesPermission =
    permissionsMap['urn:replicon:client-action:view-estimates'];

  return {
    columns: [
      Boolean(estimatesPermission) && columns.totalEstimatedHours,
      columns.totalActualHours
    ].filter(x => x),
    footers: [
      Boolean(estimatesPermission) && footers.totalEstimatedHours,
      footers.totalActualHours
    ].filter(x => x)
  };
};

const enabledCostColumnsAndFooters = ({
  permissionsMap,
  featureFlags,
  isExpenseProductEnabled
}) => {
  const estimatesPermission =
    permissionsMap['urn:replicon:client-action:view-estimates'];
  const costDataPermission =
    permissionsMap['urn:replicon:client-action:view-cost-data'];
  const includeEstimatedCost = Boolean(
    estimatesPermission && costDataPermission
  );
  const includeSplitColumns =
    isExpenseProductEnabled && Boolean(costDataPermission);
  const totalActualCostColumn = {
    ...columns.totalActualCost,
    value: <FormattedMessage id="clientList.actualCost" />
  };

  return {
    columns: [
      includeEstimatedCost && columns.totalEstimatedCost,
      includeSplitColumns && columns.totalActualExpenseCost,
      includeSplitColumns && columns.totalActualResourceCost,
      Boolean(costDataPermission) && totalActualCostColumn
    ].filter(x => x),
    footers: [
      includeEstimatedCost && footers.totalEstimatedCost,
      includeSplitColumns && footers.totalActualExpenseCost,
      includeSplitColumns && footers.totalActualResourceCost,
      Boolean(costDataPermission) && footers.totalActualCost
    ].filter(x => x)
  };
};

const enabledBillingColumnsAndFooters = ({
  permissionsMap,
  featureFlags = {}
}) => {
  const estimatesPermission =
    permissionsMap['urn:replicon:client-action:view-estimates'];
  const billingDataPermission =
    permissionsMap['urn:replicon:client-action:view-billing-data'];
  const includeEstimatedBilling = Boolean(
    estimatesPermission && billingDataPermission
  );

  const { isPSAPswatEstimatedBillingColumnEnabled = false } = featureFlags;

  const estimatedBillingColumn = isPSAPswatEstimatedBillingColumnEnabled
    ? columns.totalEstimatedBillingWithFFPsaPswatEstimatedBillingColumnEnabled
    : columns.totalEstimatedBilling;

  return {
    columns: [
      includeEstimatedBilling ? estimatedBillingColumn : null,
      Boolean(billingDataPermission) && columns.totalActualBilling
    ].filter(x => x),
    footers: [
      includeEstimatedBilling && footers.totalEstimatedBilling,
      Boolean(billingDataPermission) && footers.totalActualBilling
    ].filter(x => x)
  };
};

export const getEnabledColumnGroups = ({
  featureFlags = {},
  permissionsMap = {},
  isExpenseProductEnabled,
  hasViewTaxProfile,
  hasClientManagerRole
}) => {
  const { basicColumns, basicFooters } = enabledBasicColumnsAndFooters({
    hasClientManagerRole,
    hasViewTaxProfile
  });
  const {
    columns: hoursColumns,
    footers: hoursFooters
  } = enabledHoursColumnsAndFooters(permissionsMap);
  const {
    columns: costColumns,
    footers: costFooters
  } = enabledCostColumnsAndFooters({
    permissionsMap,
    featureFlags,
    isExpenseProductEnabled
  });
  const {
    columns: billingColumns,
    footers: billingFooters
  } = enabledBillingColumnsAndFooters({ permissionsMap, featureFlags });

  return [
    {
      id: 'basic',
      title: null,
      columns: basicColumns,
      footers: basicFooters
    },
    {
      id: 'hours',
      title: <FormattedMessage id="clientList.hours" />,
      columns: hoursColumns,
      footers: hoursFooters
    },
    Boolean(costColumns.length) && {
      id: 'cost',
      title: <FormattedMessage id="clientList.cost" />,
      columns: costColumns,
      footers: costFooters
    },
    Boolean(billingColumns.length) && {
      id: 'billing',
      title: <FormattedMessage id="clientList.billing" />,
      columns: billingColumns,
      footers: billingFooters
    }
  ].filter(x => x);
};

export const getMobileViewColumns = ({
  permissionsMap = {},
  hasClientManagerRole
}) =>
  [
    columns.name,
    columns.isActive,
    Boolean(hasClientManagerRole) && columns.clientManager,
    columns.totalActualHours,
    columns.totalActualCost,
    Boolean(permissionsMap['urn:replicon:client-action:view-billing-data']) &&
      columns.totalActualBilling
  ].filter(x => x);
