import { useCallback } from 'react';
import { useSetTaskTimeEntryDateRange } from '~/modules/tasks/hooks';

const useRescheduleTask = ({
  task,
  closeRescheduleTaskDialog,
  stopEditing,
  setFormSubmitting,
  areStartAndEndDatesRequired
}) => {
  const { setTaskTimeEntryDateRange, loading } = useSetTaskTimeEntryDateRange();

  const onRescheduleTaskSubmit = useCallback(
    async ({
      startDate: newStartDate,
      endDate: newEndDate,
      rescheduleTaskModificationOption
    }) => {
      await setTaskTimeEntryDateRange({
        taskId: task.id,
        startDate: newStartDate,
        endDate: newEndDate,
        rescheduleTaskModificationOption,
        shouldRollupTaskDates: areStartAndEndDatesRequired
      });
      closeRescheduleTaskDialog();
      setFormSubmitting(false);
      stopEditing();
    },
    [
      setTaskTimeEntryDateRange,
      task,
      closeRescheduleTaskDialog,
      setFormSubmitting,
      stopEditing
    ]
  );

  const onRescheduleTaskClose = useCallback(() => {
    closeRescheduleTaskDialog();
    setFormSubmitting(false);
    stopEditing();
  }, [closeRescheduleTaskDialog, setFormSubmitting, stopEditing]);

  return { onRescheduleTaskSubmit, onRescheduleTaskClose, loading };
};

export default useRescheduleTask;
