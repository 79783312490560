import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import DateValue from '~/modules/common/components/Date';

const useStyles = makeStyles(theme => ({
  skillMetadataDetails: {
    margin: 0,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.15,
    listStyle: 'none',
    color: theme.palette.text.secondary,
    paddingTop: 0
  },
  skillDetail: {
    display: 'inline',
    padding: 0,
    margin: theme.spacing(0, 1, 0, 0)
  },
  date: {
    display: 'inline',
    fontSize: theme.typography.caption.fontSize
  }
}));

const getDisplayValue = (metadata, classes) => {
  let displayValue = null;

  if (metadata.type === 'urn:replicon:skill-setting-type:date') {
    displayValue =
      metadata && metadata.date ? (
        <DateValue className={classes.date} value={metadata.date} />
      ) : null;
  }
  if (
    metadata.type === 'urn:replicon:skill-setting-type:text' ||
    metadata.type === 'urn:replicon:skill-setting-type:drop-down'
  ) {
    displayValue = metadata && metadata.text ? metadata.text : '';
  }
  if (metadata.type === 'urn:replicon:skill-setting-type:numeric') {
    displayValue = metadata && metadata.number ? metadata.number : '0';
  }

  return displayValue;
};

export const SkillCustomMetadataReadOnly = ({
  classes: classesOverride,
  skillCustomMetadata
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <List className={classes.skillMetadataDetails}>
      {skillCustomMetadata.map(metadata => {
        const displayValue = getDisplayValue(metadata, classes);

        return displayValue ? (
          <ListItem
            key={metadata.uri}
            className={classes.skillDetail}
            disableGutters
          >
            {metadata.displayText} : {displayValue}
          </ListItem>
        ) : null;
      })}
    </List>
  );
};

SkillCustomMetadataReadOnly.propTypes = {
  classes: PropTypes.object,
  skillCustomMetadata: PropTypes.array.isRequired
};

export default SkillCustomMetadataReadOnly;
