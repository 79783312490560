import React, { useCallback, useState } from 'react';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { useIntl } from 'react-intl';
import Popover from '@mui/material/Popover';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  rollupDisabledIcon: {
    cursor: 'pointer',
    marginTop: -theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.75)
  },
  rollupDisabledContent: {
    padding: theme.spacing(0.5, 1),
    fontSize: theme.spacing(1.75),
    color: theme.palette.text.secondary,
    fontWeight: 500
  },
  infoIconContainer: {
    marginLeft: -theme.spacing(7),
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(0.5)
  }
});

const popoverAnchorOrigin = {
  horizontal: 'right'
};

const RescheduleHeaderInfo = ({ isRolledUp }) => {
  const intl = useIntl();
  const classes = useStyles({});
  const [rollupInfoPopover, setRollupInfoPopover] = useState(null);
  const isRollupInfoPopoverOpen = Boolean(rollupInfoPopover);
  const handleRollupInfoClick = useCallback(
    event => {
      setRollupInfoPopover(event.currentTarget);
    },
    [setRollupInfoPopover]
  );

  const handleRollupInfoClose = useCallback(() => {
    setRollupInfoPopover(null);
  }, [setRollupInfoPopover]);

  const content = (
    <>
      <InfoSharpIcon
        className={classes.rollupDisabledIcon}
        onClick={handleRollupInfoClick}
      />
      <Popover
        anchorOrigin={popoverAnchorOrigin}
        open={isRollupInfoPopoverOpen}
        anchorEl={rollupInfoPopover}
        onClose={handleRollupInfoClose}
      >
        <Typography className={classes.rollupDisabledContent}>
          {intl.formatMessage({
            id: 'taskDrawerEdit.dateRollupHelperText'
          })}
        </Typography>
      </Popover>
    </>
  );

  return isRolledUp ? (
    content
  ) : (
    <div className={classes.infoIconContainer}>{content}</div>
  );
};

RescheduleHeaderInfo.propTypes = {
  isRolledUp: PropTypes.bool.isRequired
};

export default RescheduleHeaderInfo;
