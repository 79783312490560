import { ChangeResourceUserModificationType } from '~/types';

const useFormState = () => {
  const today = new Date();

  return {
    initialValues: {
      asOfDate: today.toISOString(),
      modificationType: ChangeResourceUserModificationType.Asof,
      newResourceUser: null
    }
  };
};

export default useFormState;
