/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { useMeContext } from '~/modules/me/useMeContext';
import TimeAndExpenseEntryTypeDropdown2 from '~/modules/tasks/components/TimeAndExpenseEntryTypeDropdown/TimeAndExpenseEntryTypeDropdown2';
import {
  ClientDropdownForProject,
  ProgramDropdownForProject
} from '~/modules/projects/components/Common';
import {
  CostTypeDropdown,
  SearchableProjectManagerDropdown,
  SearchableClientRepDropdown
} from '~/modules/projects/project/common/components';
import {
  useIsBreakpointDown,
  noChange,
  noValue,
  NO_CHANGE_OPTION_ID,
  NO_VALUE_OPTION_ID
} from '~/modules/common/hooks';
import {
  CurrencyDropdown,
  FormReadOnlyField,
  FormSection
} from '~/modules/common/components';
import { ExtensionField } from '~/modules/extensionFields/components';
import { useExtensionFieldChangeHandler } from '~/modules/projects/project/ProjectInfoDetails/AdditionalInfo/useExtensionFieldChangeHandler';
import { getNameAndOnChangeHandlerProps } from '~/modules/customFields/utils';
import { CustomField } from '~/modules/customFields/components';
import { useProjectPermissions } from '~/modules/common/hooks/project';
import {
  LocationsDropdown,
  ServiceCenterDropdown,
  CostCenterDropdown,
  DivisionDropdown,
  DepartmentDropdown,
  EmployeeTypeDropdown
} from '~/modules/common/components/Groups/project';
import { useGroupLabels } from '~/modules/common/components/Groups/hooks';
import { WorkflowDropdown } from './WorkflowDropdown';
import { TimeSheetApprovalDropdown } from './TimeSheetApprovalDropdown';
import { TimeEntryDropdown } from './TimeEntryDropdown';
import { useChangeHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: `30px !important`
    }
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0, 1, 1)
  },
  legend: {
    margin: 0,
    fontSize: theme.typography.body1.fontSize
  }
}));

const useCurrencyDropdownStyles = makeStyles(() => ({
  field: { marginTop: 0 }
}));

const projectCurrencyLabel = (
  <FormattedMessage id="massEditProject.projectCurrency" />
);

const projectTimeAndExpenseEntryLabel = (
  <FormattedMessage id="massEditProject.projectTimeAndExpenseEntry" />
);

const additionalInfoLabel = (
  <FormattedMessage id="massEditProject.additionalInfo" />
);

const clientRepresentative = (
  <FormattedMessage id="massEditProject.clientRepresentative" />
);

const selectClient = <FormattedMessage id="massEditProject.selectClient" />;

const getSelectProps = (id, labelId) => ({
  selectProps: {
    labelId,
    id,
    SelectDisplayProps: {
      role: 'listbox'
    }
  },
  inputLabelProps: {
    id: labelId
  }
});

export const AdditionalInfo = ({
  handleChange,
  setFieldValue,
  customFieldValues,
  extensionFieldValues
}) => {
  const classes = useStyles();
  const formClasses = useMemo(
    () => ({
      legend: classes.legend
    }),
    [classes.legend]
  );
  const [definitionId, setDefinitionId] = useState('');
  const handleExtensionFieldValueChange = useExtensionFieldChangeHandler();

  if (isEmpty(customFieldValues) && isEmpty(extensionFieldValues)) return null;

  return (
    <>
      <Divider className={classes.divider} />
      <Grid item xs={12}>
        <FormSection classes={formClasses} title={additionalInfoLabel} />
      </Grid>
      {Object.entries(customFieldValues)
        .filter(
          ([_, c]) =>
            c.customFieldDefinition.isVisible &&
            c.customFieldDefinition.isEnabled
        )
        .map(([key, customFieldValue]) => {
          const { name, onChange } = getNameAndOnChangeHandlerProps({
            handleChange,
            setFieldValue,
            path: 'customFieldValues',
            customFieldValue,
            key
          });

          return (
            <Grid item xs={12} sm={6} className={classes.item} key={key}>
              <CustomField
                variant="filled"
                fullWidth
                editable
                customFieldValue={customFieldValue}
                name={name}
                onChange={onChange}
                enableNoChangeOption
                enableNoValueOption
              />
            </Grid>
          );
        })}
      {Object.entries(extensionFieldValues).map(
        ([key, extensionFieldValue]) => (
          <Grid item xs={12} sm={6} className={classes.item} key={key}>
            <ExtensionField
              variant="filled"
              editable
              extensionField={extensionFieldValue}
              onChange={handleExtensionFieldValueChange({
                id: key,
                definitionTypeUri: extensionFieldValue.definitionTypeUri,
                setFieldValue
              })}
              definitionId={definitionId}
              setDefinitionId={setDefinitionId}
              defaultTextFieldRows={0}
              enableNoChangeOption
              enableNoValueOption
            />
          </Grid>
        )
      )}
    </>
  );
};

AdditionalInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  customFieldValues: PropTypes.object.isRequired,
  extensionFieldValues: PropTypes.object.isRequired
};

const hasPermission = (permissions, actionUri) =>
  Boolean(permissions.find(p => p.permissionActionUri === actionUri));

const MassEditProjectForm = ({ values, handleChange, setFieldValue }) => {
  const classes = useStyles();
  const intl = useIntl();
  const isMobile = useIsBreakpointDown('sm');
  const currencyDropdownClasses = useCurrencyDropdownStyles();
  const {
    groupSettings: {
      costCenter,
      department,
      division,
      employeeType,
      location,
      serviceCenter
    },
    permissions,
    hasViewProjectCostType,
    hasViewProjectBillingOptions
  } = useMeContext();
  const noChangeOption = noChange(intl);

  const isPPMProduct = Boolean(hasViewProjectCostType);

  const showLocation =
    location.isEnabled &&
    hasPermission(permissions, 'urn:replicon:project-action:edit-location');

  const showDivision =
    division.isEnabled &&
    hasPermission(permissions, 'urn:replicon:project-action:edit-division');

  const showServiceCenter =
    serviceCenter.isEnabled &&
    hasPermission(
      permissions,
      'urn:replicon:project-action:edit-service-center'
    );

  const showCostCenter =
    costCenter.isEnabled &&
    hasPermission(permissions, 'urn:replicon:project-action:edit-cost-center');

  const showDepartment =
    department.isEnabled &&
    hasPermission(permissions, 'urn:replicon:project-action:edit-department');

  const showEmployeeType =
    employeeType.isEnabled &&
    hasPermission(
      permissions,
      'urn:replicon:project-action:edit-employee-type'
    );

  const showGroups =
    showLocation ||
    showDivision ||
    showServiceCenter ||
    showCostCenter ||
    showDepartment ||
    showEmployeeType;

  const {
    hasEditClientPermission,
    hasEditProgramPermission,
    hasEditCustomFields,
    canViewAllProjects,
    canEditAllProjects,
    isClientManager,
    isProgramManager,
    projectManagerCanViewProject
  } = useProjectPermissions();

  const {
    onWorkflowStageChange,
    onCurrencyChange,
    onClientChange,
    onClientRepresentativeChange,
    onProgramChange,
    onCostTypeChange,
    onProjectManagerChange,
    onTimesheetApprovalChange,
    onTimeAndExpenseEntryChange,
    onTimeEntryChange,
    onCostCenterChange,
    onDepartmentChange,
    onDivisionChange,
    onEmployeeTypeChange,
    onLocationChange,
    onServiceCenterChange
  } = useChangeHandlers({
    setFieldValue
  });

  const groupLabels = useGroupLabels();

  const shouldFetchOnlyMyClients =
    !canViewAllProjects && !projectManagerCanViewProject && isClientManager;

  const shouldFetchOnlyMyPrograms =
    !canViewAllProjects && !projectManagerCanViewProject && isProgramManager;

  return (
    <Grid container spacing={isMobile ? 2 : 3}>
      <Grid item xs={12} sm={6} className={classes.item}>
        <WorkflowDropdown
          value={values.workflowStage || noChangeOption}
          onChange={onWorkflowStageChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <CurrencyDropdown
          classes={currencyDropdownClasses}
          variant="filled"
          label={projectCurrencyLabel}
          editable
          noneOption={false}
          value={values.currency || noChangeOption}
          onChange={onCurrencyChange}
          enableNoChangeOption
          {...getSelectProps('project-currency', 'project-currency-label')}
        />
      </Grid>
      {hasEditClientPermission && (
        <>
          <Grid item xs={12} sm={6} className={classes.item}>
            <ClientDropdownForProject
              variant="filled"
              enableNoChangeOption
              enableNoValueOption
              value={values.client || noChangeOption}
              onChange={onClientChange}
              disableClearable
              shouldFetchOnlyMyClients={shouldFetchOnlyMyClients}
            />
          </Grid>
          {!isPPMProduct && (
            <Grid item xs={12} sm={6} className={classes.item}>
              {values.client?.id &&
              values.client?.id !== NO_CHANGE_OPTION_ID &&
              values.client?.id !== NO_VALUE_OPTION_ID ? (
                <SearchableClientRepDropdown
                  enableNoValueOption
                  clientUri={values.client?.id}
                  value={
                    values.clientRepresentative ||
                    noValue(intl, 'dropdownItem.noclientRepresentative')
                  }
                  onChange={onClientRepresentativeChange}
                />
              ) : (
                <FormReadOnlyField label={clientRepresentative}>
                  <Typography color="textSecondary" variant="body2">
                    {selectClient}
                  </Typography>
                </FormReadOnlyField>
              )}
            </Grid>
          )}
        </>
      )}
      {hasEditProgramPermission && (
        <Grid item xs={12} sm={6} className={classes.item}>
          <ProgramDropdownForProject
            variant="filled"
            enableNoChangeOption
            enableNoValueOption
            disableClearable
            value={values.program || noChangeOption}
            onChange={onProgramChange}
            shouldFetchOnlyMyPrograms={shouldFetchOnlyMyPrograms}
          />
        </Grid>
      )}
      {isPPMProduct && (
        <Grid item xs={12} sm={6} className={classes.item}>
          <CostTypeDropdown
            enableNoChangeOption
            enableNoValueOption
            value={values.costType || noChangeOption}
            onChange={onCostTypeChange}
          />
        </Grid>
      )}
      {canEditAllProjects && (
        <Grid item xs={12} sm={6} className={classes.item}>
          <SearchableProjectManagerDropdown
            enableNoChangeOption
            enableNoValueOption
            value={values.projectManager || noChangeOption}
            onChange={onProjectManagerChange}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} className={classes.item}>
        <TimeSheetApprovalDropdown
          value={values.projectManagerTimesheetApproval || noChangeOption}
          onChange={onTimesheetApprovalChange}
        />
      </Grid>
      {hasViewProjectBillingOptions && (
        <Grid item xs={12} sm={6} className={classes.item}>
          <TimeAndExpenseEntryTypeDropdown2
            fullWidth
            variant="filled"
            label={projectTimeAndExpenseEntryLabel}
            value={values.timeAndExpenseEntryType?.id || noChangeOption.id}
            onChange={onTimeAndExpenseEntryChange}
            enableNoChangeOption
            {...getSelectProps(
              'time-and-expense-entry-type',
              'time-and-expense-entry-type-label'
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} className={classes.item}>
        <TimeEntryDropdown
          value={values.timeEntry || noChangeOption}
          onChange={onTimeEntryChange}
        />
      </Grid>
      {showGroups && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <FormattedMessage id="projectList.groups" />
            </Typography>
          </Grid>
          {showCostCenter && (
            <Grid item xs={12} sm={6} className={classes.item}>
              <CostCenterDropdown
                enableNoChangeOption
                enableNoValueOption
                value={values.costCenter || noChangeOption}
                onChange={onCostCenterChange}
                groupSettings={costCenter}
                ariaLabel={groupLabels.costCenter}
              />
            </Grid>
          )}
          {showDepartment && (
            <Grid item xs={12} sm={6} className={classes.item}>
              <DepartmentDropdown
                enableNoChangeOption
                enableNoValueOption
                value={values.department || noChangeOption}
                onChange={onDepartmentChange}
                groupSettings={department}
                ariaLabel={groupLabels.department}
              />
            </Grid>
          )}
          {showDivision && (
            <Grid item xs={12} sm={6} className={classes.item}>
              <DivisionDropdown
                enableNoChangeOption
                enableNoValueOption
                value={values.division || noChangeOption}
                onChange={onDivisionChange}
                groupSettings={division}
                ariaLabel={groupLabels.division}
              />
            </Grid>
          )}
          {showEmployeeType && (
            <Grid item xs={12} sm={6} className={classes.item}>
              <EmployeeTypeDropdown
                enableNoChangeOption
                enableNoValueOption
                value={values.employeeType || noChangeOption}
                onChange={onEmployeeTypeChange}
                groupSettings={employeeType}
                ariaLabel={groupLabels.employeeType}
              />
            </Grid>
          )}
          {showLocation && (
            <Grid item xs={12} sm={6} className={classes.item}>
              <LocationsDropdown
                enableNoChangeOption
                enableNoValueOption
                value={values.location || noChangeOption}
                onChange={onLocationChange}
                groupSettings={location}
                ariaLabel={groupLabels.location}
              />
            </Grid>
          )}
          {showServiceCenter && (
            <Grid item xs={12} sm={6} className={classes.item}>
              <ServiceCenterDropdown
                enableNoChangeOption
                enableNoValueOption
                value={values.serviceCenter || noChangeOption}
                onChange={onServiceCenterChange}
                groupSettings={serviceCenter}
                ariaLabel={groupLabels.serviceCenter}
              />
            </Grid>
          )}
        </>
      )}
      <AdditionalInfo
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        customFieldValues={hasEditCustomFields ? values.customFieldValues : {}}
        extensionFieldValues={values.extensionFieldValues}
      />
    </Grid>
  );
};

MassEditProjectForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default MassEditProjectForm;
