import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { DelaStarIcon, HiringStatusBadge } from '~/modules/common/components';
import { showHiringStatusBadge } from '~/modules/common/components/HiringStatusBadge';
import { RoleName } from '~/modules/common/components/entities/role';
import {
  IconLinkButton,
  ResourceRequestStatusChip
} from '~/modules/resourcing/common/components';
import { ResourceRequestStatus } from '~/types';
import { useMeContext } from '~/modules/me';
import ResourceRequestAllocations from '../../ResourceRequestAllocations';
import ResourceRequestResourceInfo from '../../ResourceRequestResourceInfo';
import {
  useStyles,
  useStatusChipStyle,
  useDelaStyles,
  useDelaStarIconStyles,
  useHiringBadgeStyles
} from './useStyles';

export const ResourceRequestRowDetailsContent1 = ({
  allocations,
  resourceRequest,
  showResourceAllocation,
  selectedRole,
  maxSkillLevel,
  isPsaPraaResourcingUIAndRMRole,
  requisitionHiringStatus
}) => {
  const {
    featureFlags: { isPsaPraaResourcingUIEnabled, isPsaDtmRequisitionEnabled }
  } = useMeContext();

  const classes = useStyles();
  const statusChipClasses = useStatusChipStyle();
  const delaClasses = useDelaStyles();
  const delaStarIconClasses = useDelaStarIconStyles();
  const hiringBadgeClasses = useHiringBadgeStyles();

  const shouldUseDelaColumn =
    isPsaPraaResourcingUIAndRMRole &&
    resourceRequest.requestStatus === ResourceRequestStatus.Submitted;
  const showAllocations = isPsaPraaResourcingUIEnabled
    ? (resourceRequest.requestStatus !== ResourceRequestStatus.Submitted ||
        !isPsaPraaResourcingUIAndRMRole) &&
      showResourceAllocation
    : showResourceAllocation;
  const showResourceRequestInfo = isPsaPraaResourcingUIEnabled
    ? (resourceRequest.requestStatus === ResourceRequestStatus.Draft ||
        (resourceRequest.requestStatus === ResourceRequestStatus.Submitted &&
          !isPsaPraaResourcingUIAndRMRole)) &&
      allocations.length === 0
    : (resourceRequest.requestStatus === ResourceRequestStatus.Draft ||
        resourceRequest.requestStatus === ResourceRequestStatus.Submitted) &&
      allocations.length === 0;

  const iconLinkButtonClasses = useMemo(
    () => ({ openButton: classes.openButton }),
    [classes.openButton]
  );

  return (
    <div
      className={classNames(classes.root, {
        [delaClasses.root]: resourceRequest.isDelaDraftResourceRequest
      })}
    >
      <div className={classes.column}>
        <div className={classes.roleColumn}>
          {selectedRole ? (
            <RoleName role={selectedRole} className={classes.roleName} />
          ) : (
            <span className={classes.noValue}>
              <FormattedMessage id="resourceRequestReadonlyItem.noRole" />
            </span>
          )}
          {isPsaDtmRequisitionEnabled &&
            showHiringStatusBadge({
              requisitionHiringStatus,
              resourceRequest
            }) && (
              <HiringStatusBadge
                requisitionHiringStatus={requisitionHiringStatus}
                classes={hiringBadgeClasses}
              />
            )}
        </div>
        <div
          className={classNames(classes.outerColumn, {
            [classes.delaColumn]: shouldUseDelaColumn
          })}
        >
          {(!isPsaPraaResourcingUIEnabled || showAllocations) && (
            <div className={classes.column}>
              {showResourceRequestInfo && (
                <ResourceRequestResourceInfo
                  resourceRequest={resourceRequest}
                  maxSkillLevel={maxSkillLevel}
                />
              )}
              {showAllocations && (
                <ResourceRequestAllocations
                  allocations={allocations}
                  showResourceAllocation={showResourceAllocation}
                  requestStatus={resourceRequest.requestStatus}
                  preferredResources={resourceRequest?.preferredResources}
                />
              )}
            </div>
          )}
          <div
            className={classNames(classes.statusColumn, {
              [classes.delaColumn]: shouldUseDelaColumn
            })}
          >
            {resourceRequest.isDelaDraftResourceRequest && (
              <DelaStarIcon classes={delaStarIconClasses} />
            )}
            <ResourceRequestStatusChip
              status={resourceRequest.requestStatus}
              classes={statusChipClasses}
            />

            <div>
              <IconLinkButton
                classes={iconLinkButtonClasses}
                qeIdTag="Drawer_Icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResourceRequestRowDetailsContent1.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  allocations: PropTypes.array,
  showResourceAllocation: PropTypes.bool,
  selectedRole: PropTypes.object,
  maxSkillLevel: PropTypes.number,
  isPsaPraaResourcingUIAndRMRole: PropTypes.bool,
  requisitionHiringStatus: PropTypes.string
};

export default ResourceRequestRowDetailsContent1;
