import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const LabelledField = ({
  children,
  label,
  align = 'left',
  labelVariant = 'caption',
  labelWeight = 500,
  className
}) => (
  <div>
    <Typography
      align={align}
      style={{ fontWeight: labelWeight }}
      variant={labelVariant}
      className={className}
    >
      {label}
    </Typography>
    {children}
  </div>
);

LabelledField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  labelVariant: PropTypes.string,
  labelWeight: PropTypes.number,
  align: PropTypes.string,
  className: PropTypes.string
};

export default LabelledField;
