import { useMeContext } from '~/modules/me';
import { TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY } from '~/modules/common/components/TaskDrawer/common/hooks/useGetTaskResourceUserAllocationsSummaryForUser';

export const useRefetchQueriesOnTaskAllocationModification = ({
  userId,
  projectId
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();

  const isCompleteResourceAllocationEditEnabled =
    isRmpTaskAllocationPhase2Enabled &&
    Boolean(
      permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
    );

  return [
    {
      query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
      variables: {
        userId,
        filter: {
          projectId
        }
      }
    },
    ...(isCompleteResourceAllocationEditEnabled
      ? ['getProjectTotalsBySlug']
      : [])
  ];
};
