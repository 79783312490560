import { useCallback } from 'react';
import {
  useKeyValueSettingsMutation,
  updateCache
} from '~/modules/common/hooks';

export const updateKeyValueSettings = ({
  putKeyValueSettings,
  displayUnit,
  scale,
  key
}) =>
  putKeyValueSettings({
    variables: {
      input: {
        key,
        settings: {
          displayUnit,
          scale
        }
      }
    }
  });

export const useResourcingStateCallbacks = ({
  scale,
  displayUnit,
  setScale,
  setDisplayUnit,
  key = 'assignment_chart_settings'
}) => {
  const [putKeyValueSettings] = useKeyValueSettingsMutation(updateCache(key));

  const onDisplayUnitChange = useCallback(
    newValue => {
      setDisplayUnit && setDisplayUnit(newValue);
      updateKeyValueSettings({
        putKeyValueSettings,
        displayUnit: newValue,
        scale,
        key
      });
    },
    [setDisplayUnit, putKeyValueSettings, scale, key]
  );

  const onScaleChange = useCallback(
    newValue => {
      setScale(newValue);
      updateKeyValueSettings({
        putKeyValueSettings,
        displayUnit,
        scale: newValue,
        key
      });
    },
    [setScale, putKeyValueSettings, displayUnit, key]
  );

  return {
    onDisplayUnitChange,
    onScaleChange
  };
};

export default useResourcingStateCallbacks;
