import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useGetProjectInfoModificationBatchResults from './hooks/useGetProjectInfoModificationBatchResults';
import ErrorDetails from './ErrorDetails';

const useStyles = makeStyles(theme => ({
  accordion: {
    background: theme.palette.error.lightBackground,
    color: '#560e06'
  },
  accordionDetails: {
    padding: theme.spacing(0, 2)
  },
  alert: {
    padding: theme.spacing(1, 0)
  },
  alertTitle: {
    marginBottom: '0px !important'
  }
}));

const expandIcon = <ExpandMoreIcon />;

export const MassEditProjectResult = ({ batchId }) => {
  const classes = useStyles();

  const {
    failedProjects,
    updatedProjects,
    isLoading
  } = useGetProjectInfoModificationBatchResults({
    batchId
  });

  return isLoading ? (
    <Skeleton variant="rect" width="80%" height={30} />
  ) : (
    <>
      {updatedProjects?.length > 0 && (
        <div className={classes.alert}>
          <Alert icon={false}>
            <AlertTitle className={classes.alertTitle}>
              <Typography variant="body1">
                <FormattedMessage
                  id="massEditProject.sucessfullUpdate"
                  values={{
                    count: updatedProjects.length
                  }}
                />
              </Typography>
            </AlertTitle>
          </Alert>
        </div>
      )}
      <div className={classes.alert}>
        {failedProjects?.length > 0 && (
          <Accordion elevation={0} className={classes.accordion}>
            <AccordionSummary expandIcon={expandIcon}>
              <Typography>
                <FormattedMessage
                  id="massEditProject.updateFailed"
                  className={classes.errorTitle}
                  values={{
                    count: failedProjects.length
                  }}
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <ErrorDetails failedProjects={failedProjects} />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </>
  );
};

MassEditProjectResult.propTypes = {
  batchId: PropTypes.string.isRequired
};

export default MassEditProjectResult;
