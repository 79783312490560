import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import AvailabilityPeriod from './AvailabilityPeriod';

const decimalCount = num => {
  const numStr = String(num);

  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }

  return 0;
};

export const calculateNetAvailableHours = ({
  availableHours,
  allocatedHours
}) => {
  return (
    parseFloat(availableHours.toFixed(decimalCount(allocatedHours))) -
    allocatedHours
  );
};

const CompleteAvailabilityPeriod = ({
  classes,
  originalAllocatedHours,
  isHoursMode,
  isAvailabilityLoading,
  resourceAllocationSeriesData: allocationSeriesData,
  seriesRefetch: refetch,
  seriesLoading: loading,
  allocatedHours
}) => {
  const resourceAllocationSeriesData = allocationSeriesData || [];

  useEffect(() => {
    if (isAvailabilityLoading) {
      setTimeout(() => {
        refetch();
      }, 6000);
    }
  }, [isAvailabilityLoading, refetch]);

  const totalSummaryHours = (resourceAllocationSeriesData &&
    resourceAllocationSeriesData.reduce(
      (acc, curr) => {
        acc.timeOffHours += curr.timeoff;
        acc.scheduledHours += curr.totalSchedule;
        acc.holidayHours += curr.holiday;
        acc.totalAllocatedHours += curr.allocated + curr.overAllocated;

        return acc;
      },
      {
        timeOffHours: 0,
        scheduledHours: 0,
        holidayHours: 0,
        totalAllocatedHours: 0
      }
    )) || {
    timeOffHours: 0,
    scheduledHours: 0,
    holidayHours: 0,
    totalAllocatedHours: 0
  };

  const otherAllocations =
    resourceAllocationSeriesData &&
    totalSummaryHours.totalAllocatedHours - originalAllocatedHours;

  const otherAllocationHours = resourceAllocationSeriesData && otherAllocations;

  const availableHours =
    (resourceAllocationSeriesData &&
      totalSummaryHours.scheduledHours -
        totalSummaryHours.timeOffHours -
        totalSummaryHours.holidayHours -
        otherAllocationHours) ||
    0;

  const netAvailableHours = calculateNetAvailableHours({
    availableHours,
    allocatedHours
  });

  const availableValue = isHoursMode
    ? netAvailableHours
    : roundToDecimals(
        (netAvailableHours * 100) / totalSummaryHours.scheduledHours
      );

  return !loading && !isAvailabilityLoading ? (
    <AvailabilityPeriod
      availableHours={availableValue}
      isHoursMode={isHoursMode}
      classes={classes}
    />
  ) : (
    <CircularProgress size={16} color="primary" />
  );
};

CompleteAvailabilityPeriod.propTypes = {
  classes: PropTypes.object,
  isAvailabilityLoading: PropTypes.bool,
  originalAllocatedHours: PropTypes.number,
  isHoursMode: PropTypes.bool,
  allocatedHours: PropTypes.number.isRequired,
  resourceAllocationSeriesData: PropTypes.array,
  seriesRefetch: PropTypes.func,
  seriesLoading: PropTypes.bool
};

export default CompleteAvailabilityPeriod;
