import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { getTextInBold } from '../util';

const useStyles = makeStyles(theme => ({
  alert: { marginTop: theme.spacing(2) },
  skeleton: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(0.5)
  },
  title: { marginBottom: theme.spacing(0.5) }
}));

const StackedListMessageSummaryAlert = ({
  classes: classesOverride,
  isLoading,
  messages,
  severity,
  skeletonHeight = 100,
  title
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <>
      {isLoading ? (
        <Skeleton
          className={classes.skeleton}
          height={skeletonHeight}
          width="100%"
          variant="rect"
        />
      ) : (
        <>
          {messages.length > 0 && (
            <Alert severity={severity} icon={false} className={classes.alert}>
              {title && (
                <Typography variant="body1" className={classes.title}>
                  <FormattedMessage
                    id={title.messageId}
                    values={getTextInBold(title?.values)}
                  />
                </Typography>
              )}
              {messages.map(({ messageId, values }) => (
                <Typography key={messageId} variant="body2">
                  <FormattedMessage
                    id={messageId}
                    values={getTextInBold(values)}
                  />
                </Typography>
              ))}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

StackedListMessageSummaryAlert.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  messages: PropTypes.array,
  severity: PropTypes.string.isRequired,
  skeletonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.object
};

export default StackedListMessageSummaryAlert;
