import { useState } from 'react';
import { useDialogState } from '~/modules/common/hooks';

export const useWithdrawHiringRequestDialogState = () => {
  const { open, closeDialog, openDialog } = useDialogState(false);
  const [
    withdrawHiringRequestDialogState,
    setWithdrawHiringRequestDialog
  ] = useState({
    actionType: null,
    onClick: null
  });

  return {
    open,
    openDialog,
    closeDialog,
    withdrawHiringRequestDialogState,
    setWithdrawHiringRequestDialog
  };
};

export default useWithdrawHiringRequestDialogState;
