import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ExpandMoreIcon, ExpandLessIcon, AddCircleIcon } from '../components';

const commonStyles = {
  treeIcon: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  icon: {
    cursor: 'pointer',
    width: theme.spacing(3.5),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    borderRadius: '50%',
    height: '50%',
    marginTop: theme.spacing(2)
  }
};

const useGanttTaskStyles = makeStyles({
  ganttGridRow: {
    backgroundColor: 'transparent !important',
    maxWidth: '100%',
    cursor: 'default !important',
    '& .gantt_tree_content': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      width: '80%',
      flexGrow: 1
    },
    '& .gantt_tree_icon': commonStyles.treeIcon,
    '& .gantt_tree_icon.gantt_open': {
      background: `url(
        ${ExpandMoreIcon} 
      )`,
      ...commonStyles.icon
    },
    '& .gantt_tree_icon.gantt_close': {
      background: `url(
        ${ExpandLessIcon} 
      )`,
      ...commonStyles.icon
    },
    '& .gantt_add': {
      background: `url(
        ${AddCircleIcon} 
      )`,
      ...commonStyles.icon,
      opacity: 1
    },
    '& .gantt_cell': {
      display: 'inline-flex'
    }
  },
  ganttGridRowProject: {
    borderBottom: '4px solid #d7d7d7',
    backgroundColor: 'transparent !important',
    cursor: 'default !important',
    maxWidth: '100%',
    '& .gantt_tree_content': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      width: '80%'
    },
    '& .gantt_tree_icon': {
      visibility: 'hidden'
    },
    '& .gantt_add': {
      background: `url(
        ${AddCircleIcon} 
      )`,
      ...commonStyles.icon,
      opacity: 1
    }
  },
  ganttTaskNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    cursor: 'pointer',
    height: '100%'
  },
  ganttTaskAdd: {
    marginRight: theme.spacing(1),
    '&>div': {
      width: 24,
      height: 35
    }
  },
  taskIcon: {
    margin: theme.spacing(1, 1, 0, 0),
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  ganttFontFamily: {
    fontFamily: theme.typography.fontFamily
  },
  ganttGridRowNoAdd: {
    '& .gantt_add': {
      visibility: 'hidden'
    }
  },
  ganttGridRow2: {
    borderBottom: '1px solid #d7d7d7 !important',
    backgroundColor: 'transparent !important',
    maxWidth: '100%',
    cursor: 'default !important',
    '& .gantt_tree_content': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      width: '100%',
      flexGrow: 1
    },
    '& .gantt_tree_icon': commonStyles.treeIcon,
    '& .gantt_tree_icon.gantt_open': {
      background: `url(
        ${ExpandMoreIcon} 
      )`,
      ...commonStyles.icon
    },
    '& .gantt_tree_icon.gantt_close': {
      background: `url(
        ${ExpandLessIcon} 
      )`,
      ...commonStyles.icon
    },
    '& .gantt_add': {
      background: `url(
        ${AddCircleIcon} 
      )`,
      ...commonStyles.icon,
      opacity: 1
    },
    '& .gantt_cell': {
      display: 'inline-flex'
    }
  },
  ganttTaskName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  ganttProjectName: {
    fontSize: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  ganttTaskNameBold: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  gantColumnName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    flexShrink: 1
  },
  ganttTaskContentContainer: {
    display: 'flex'
  },
  ganttTaskProgress: {
    marginLeft: theme.spacing(-1),
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightLight,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  ganttRowTaskName: {
    color: theme.palette.common.black,
    flexGrow: 1,
    fontWeight: theme.typography.fontWeightRegular
  },
  milestoneMark: {
    boxSizing: 'border-box',
    transform: 'rotate(45deg)',
    backgroundColor: theme.palette.task.milestone,
    border: `1px solid ${theme.palette.task.milestone}`,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    margin: '4px -12px 0px auto',
    float: 'right',
    position: 'absolute',
    right: 0,
    borderRadius: '2px'
  },
  milestoneMarkRollUp: {
    boxSizing: 'border-box',
    transform: 'rotate(45deg)',
    backgroundColor: theme.palette.task.milestone,
    border: `1px solid ${theme.palette.task.milestone}`,
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: '0 -12px 0px auto',
    float: 'right',
    position: 'absolute',
    right: 0,
    borderRadius: '2px'
  },
  overEstimate: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  },
  root: {
    backgroundColor: theme.palette.common.white
  },
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  showMoreButton: {
    display: 'flex',
    alignSelf: 'center'
  },
  ganttContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(0, 1)
  },
  ganttContainer2: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(0, 1),
    '& .gantt_task_line, .gantt_line_wrapper': {
      marginTop: '-15px'
    },
    '& .gantt_side_content.gantt_right': {
      overflow: 'visible',
      paddingLeft: 0
    },
    '& .gantt_side_content.gantt_left': {
      overflow: 'visible',
      paddingRight: 0
    },
    '& .gantt_task_row, .gantt_task_row.odd': {
      cursor: 'default',
      backgroundColor: '#f7f7f7'
    },
    '& $ganttTaskBar2Rounded:hover, $ganttTaskBar2RoundedBorder:hover': {
      boxShadow: 'rgba(0,0,0,1) 0 0 0 2px'
    },
    '& $ganttTaskBar2Rounded, $ganttTaskBar2RoundedBorder': {
      transition: 'box-shadow 0.2s',
      boxShadow: 'rgba(0,0,0,0) 0 0 0 2px'
    },
    '& .gantt_row_project': {
      cursor: 'default',
      '& .gantt_tree_content': {
        overflow: 'visible'
      },
      '& div:nth-child(n+2) > .gantt_tree_content': {
        display: 'none'
      }
    },
    '& .gantt_task_line > .gantt_task_drag.task_left': {
      left: theme.spacing(0.125)
    },
    '& .gantt_task_line > .gantt_task_drag.task_right': {
      right: theme.spacing(0.125)
    }
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    marginTop: '-9px'
  },
  ganttContent: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto'
  },
  ganttContainerFooter: {
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.information.light,
    color: theme.palette.text.primary
  },
  ganttTaskBar: {
    borderRadius: theme.spacing(3) / 2,
    height: `${theme.spacing(3)}px`,
    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      marginLeft: 'unset'
    },
    border: 'none !important'
  },
  ganttTaskBarYear: {
    '& .gantt_task_content': {
      marginLeft: theme.spacing(-0.8)
    }
  },
  ganttTaskBarQuarter: {
    '& .gantt_task_content': {
      marginLeft: theme.spacing(-0.5)
    }
  },
  ganttTaskBar2: {
    borderRadius: 0,
    height: `${theme.spacing(3)}px`,
    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: '33%',
      marginTop: '6px'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      marginLeft: 'unset'
    },
    boxShadow: 'rgba(0,0,0,0) 0 0 0 2px !important'
  },
  ganttTaskBar2Rounded: ({ isPsaPrpManualTaskStatusEnabled }) => ({
    borderRadius: theme.spacing(3) / 2,
    height: `${theme.spacing(3)}px`,
    ...(!isPsaPrpManualTaskStatusEnabled && { background: '#0bb8df' }),
    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      ...(isPsaPrpManualTaskStatusEnabled && { marginLeft: 'unset' })
    },
    border: 'none !important'
  }),
  ganttTaskBar2RoundedBorder: {
    borderRadius: theme.spacing(3) / 2,
    border: '1px solid #26d293 !important',
    height: `${theme.spacing(3)}px`,
    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      marginLeft: 'unset'
    }
  },
  ganttTaskBar2RoundedBorderCompleted: {
    border: 'none !important'
  },
  ganttTaskBar2RoundedBorderInProgress: {
    border: '1px solid #26d293 !important'
  },
  ganttTaskBar2RoundedBorderNotStarted: {
    border: '1px solid #0bb8df !important'
  },
  ganttTaskBar2InProgress: ({ isPsaPrpManualTaskStatusEnabled }) => ({
    ...(isPsaPrpManualTaskStatusEnabled
      ? {
          borderTop: 'none !important',
          borderBottom: 'none !important'
        }
      : {
          border: 'none !important',
          borderLeft: '4px solid #26d293 !important',
          borderRight: '4px solid #d2f9ea !important'
        }),
    background:
      'linear-gradient(180deg, #f7f7f7 33%, #d2f9ea 33%, #d2f9ea 66%, #f7f7f7 66%)'
  }),
  ganttTaskBar2InProgressEmpty: {
    borderLeft: '4px solid #d2f9ea !important',
    borderRight: '4px solid #d2f9ea !important'
  },
  ganttTaskBar2InProgressPartlyFull: {
    borderLeft: '4px solid #26d293 !important',
    borderRight: '4px solid #d2f9ea !important'
  },
  ganttTaskBar2InProgressFull: {
    borderLeft: '4px solid #26d293 !important',
    borderRight: '4px solid #26d293 !important'
  },
  ganttTaskBar2NotStarted: ({ isPsaPrpManualTaskStatusEnabled }) => ({
    ...(isPsaPrpManualTaskStatusEnabled
      ? {
          borderTop: 'none !important',
          borderBottom: 'none !important',
          background:
            'linear-gradient(180deg, #f7f7f7 33%, #cff4fd 33%, #cff4fd 66%, #f7f7f7 66%)'
        }
      : {
          border: 'none !important',
          borderLeft: '4px solid #0bb8df !important',
          borderRight: '4px solid #0bb8df !important',
          background:
            'linear-gradient(180deg, #f7f7f7 33%, #0bb8df 33%, #0bb8df 66%, #f7f7f7 66%)'
        })
  }),
  ganttTaskBar2NotStartedEmpty: {
    borderLeft: '4px solid #cff4fd !important',
    borderRight: '4px solid #cff4fd !important'
  },
  ganttTaskBar2NotStartedPartlyFull: {
    borderLeft: '4px solid #0bb8df !important',
    borderRight: '4px solid #cff4fd !important'
  },
  ganttTaskBar2NotStartedFull: {
    borderLeft: '4px solid #0bb8df !important',
    borderRight: '4px solid #0bb8df !important'
  },
  ganttTaskBar2Completed: {
    border: 'none !important',
    borderLeft: '4px solid #6c91a8 !important',
    borderRight: '4px solid #6c91a8 !important',
    background:
      'linear-gradient(180deg, #fff 33%, #6c91a8 33%, #6c91a8 66%, #fff 66%)'
  },
  ganttScaleCell: {
    minWidth: 60,
    textTransform: 'capitalize!important'
  },
  ganttTaskNameHeaderCell: {
    textTransform: 'none !important',
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  ganttChartTimelineHeader: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  disableTaskDrag: {
    '& .gantt_task_drag': {
      cursor: 'not-allowed',
      background: 'none'
    }
  }
});

export default useGanttTaskStyles;
