import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Divider,
  Grid
} from '@material-ui/core';
import classNames from 'classnames';
import { getEquivalentRolledUpTaskStatus } from '~/modules/common/components/ProjectDrawer';
import FormattedNameCode from '~/modules/common/components/entities/task/FormattedNameCode';
import {
  TaskCardTitle,
  TaskCardSubHeader,
  TaskCardHours,
  TaskCardVariation
} from '../TaskCard/components';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    zIndex: 10
  },
  cardBorder: {
    borderBottom: `4px solid rgba(0,0,0,0.6)}`
  },
  cardHeader: {
    lineHeight: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingBottom: theme.spacing(1.5)
  },
  cardHeaderContent: {
    overflow: 'hidden'
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  details: {
    padding: theme.spacing(1, 2)
  }
}));

export const ProjectCard = ({ rolledUpSummary, projectDetails, hasTasks }) => {
  const status = getEquivalentRolledUpTaskStatus({
    status: projectDetails.status.name,
    actualHours: rolledUpSummary.actualHours
  });
  const classes = useStyles();
  const cardHeaderClasses = useMemo(
    () => ({
      root: classes.cardHeader,
      content: classes.cardHeaderContent
    }),
    [classes.cardHeader, classes.cardHeaderContent]
  );

  const displayText = useMemo(() => {
    return (
      <FormattedNameCode
        name={projectDetails.projectName}
        code={projectDetails.projectCode}
        variant="body1"
      />
    );
  }, [projectDetails.projectCode, projectDetails.projectName]);

  const taskCardTitle = useMemo(
    () => (
      <TaskCardTitle isMilestone={false} displayText={displayText} isProject />
    ),
    [displayText]
  );

  const taskCardSubHeader = useMemo(
    () => (
      <TaskCardSubHeader
        startDate={projectDetails.startDate}
        endDate={projectDetails.endDate}
        taskStatus={status}
        isProject
      />
    ),
    [projectDetails.endDate, projectDetails.startDate, status]
  );

  const formattedProjectDetails = useMemo(
    () => ({
      initialEstimatedHours: projectDetails.budgetHours,
      taskStatus: status,
      rolledUpSummary
    }),
    [projectDetails.budgetHours, status, rolledUpSummary]
  );

  return (
    <Card
      className={classNames(classes.card, {
        [classes.cardBorder]: hasTasks
      })}
      square
      elevation={0}
    >
      <CardHeader
        classes={cardHeaderClasses}
        title={taskCardTitle}
        subheader={taskCardSubHeader}
      />
      <CardContent
        classes={useMemo(() => ({ root: classes.cardContent }), [
          classes.cardContent
        ])}
      >
        <Divider variant="middle" />
        <Grid container className={classes.details}>
          <Grid item xs={8}>
            <TaskCardHours task={formattedProjectDetails} isProject />
          </Grid>
          <Grid item xs={4}>
            <TaskCardVariation task={formattedProjectDetails} isProject />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProjectCard.propTypes = {
  rolledUpSummary: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  hasTasks: PropTypes.bool
};

export default ProjectCard;
