import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FormSelectField } from '~/modules/common/components';
import {
  useAdditionalOptions,
  NO_CHANGE_OPTION_ID,
  NO_VALUE_OPTION_ID
} from '~/modules/common/hooks';
import {
  NoneDropdownItem,
  useOptionsMerge
} from '~/modules/common/components/SearchAutocomplete';
import CustomFieldValuePropType from './CustomFieldValuePropType';

const useStyles = makeStyles({
  disableDropDowOption: { display: 'none' }
});

export const EditableDropDownCustomField = ({
  classes: overriddenClasses,
  customFieldValue,
  onChange,
  required,
  enableNoChangeOption = false,
  enableNoValueOption = false,
  ...rest
}) => {
  const classes = useStyles({ classes: overriddenClasses });
  const {
    customField,
    customFieldDefinition,
    dropDownOptionUri
  } = customFieldValue;

  const { displayText: label } = customField;

  const { isRequired, dropDownOptions } = customFieldDefinition || {
    dropDownOptions: []
  };

  const additionalOptions = useAdditionalOptions({
    enableNoChangeOption,
    enableNoValueOption,
    noValueMessageId: 'dropdownItem.noValue',
    noValueMessageValues: { value: label }
  });

  const options = useOptionsMerge({
    options: dropDownOptions,
    additionalOptions
  });

  const value = enableNoChangeOption
    ? dropDownOptionUri || NO_CHANGE_OPTION_ID
    : dropDownOptionUri;

  const showNoneOption = !enableNoValueOption && !isRequired;

  return (
    <FormSelectField
      shrink={Boolean(value)}
      editable
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      {...rest}
    >
      {showNoneOption && (
        <MenuItem value={null}>
          <FormattedMessage id="projectTasksPage.none" />
        </MenuItem>
      )}
      {options.map(option => {
        if (
          option.id === NO_CHANGE_OPTION_ID ||
          option.id === NO_VALUE_OPTION_ID
        )
          return (
            <MenuItem key={option.id} value={option.id}>
              <NoneDropdownItem value={option.displayText} />
            </MenuItem>
          );

        return (
          <MenuItem
            className={classNames(
              !option.isEnabled && classes.disableDropDowOption
            )}
            key={option.id}
            value={option.id}
          >
            {option.displayText}
          </MenuItem>
        );
      })}
    </FormSelectField>
  );
};

EditableDropDownCustomField.propTypes = {
  customFieldValue: CustomFieldValuePropType,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  classes: PropTypes.object,
  enableNoChangeOption: PropTypes.bool,
  enableNoValueOption: PropTypes.bool
};

export default EditableDropDownCustomField;
