import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import DateFormatter from '~/modules/billing-invoicing/common/components/AvailableToBillListTable/columns/formatters/date';
import ClientFormatter from '~/modules/billing-invoicing/common/components/AvailableToBillListTable/columns/formatters/client';

const useStyles = makeStyles(theme => ({
  name: {
    fontWeight: theme.typography.fontWeightBold
  },
  container: {
    padding: theme.spacing(1, 0, 1)
  },
  header: {
    padding: theme.spacing(1)
  },
  date: {
    color: theme.palette.text.secondary
  },
  chip: {
    marginRight: '0px'
  }
}));

export const MobileAvailableToBillHeaderRow = ({ record }) => {
  const classes = useStyles();
  const isPsaPrpAddProjectColumnOnAvailableToBillEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpAddProjectColumnOnAvailableToBillEnabled'
  });

  return (
    <Grid
      container
      alignItems="center"
      {...(isPsaPrpAddProjectColumnOnAvailableToBillEnabled
        ? { spacing: 0, className: classes.container }
        : { className: classes.header, justifyContent: 'space-between' })}
    >
      <Grid
        item
        {...(isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? { xs: 6 } : {})}
      >
        <Typography className={classes.name} variant="body2" component="div">
          <FormattedMessage id="mobileAvailableToBillHeaderRow.availableToBill" />
        </Typography>
        <div className={classes.date}>
          {DateFormatter({
            record,
            variant: 'caption'
          })}
        </div>
      </Grid>
      {isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
        <Grid item xs={6}>
          <ClientFormatter field="client" record={record} />
        </Grid>
      ) : null}
    </Grid>
  );
};

MobileAvailableToBillHeaderRow.propTypes = {
  record: PropTypes.object
};

export default MobileAvailableToBillHeaderRow;
