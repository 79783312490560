import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import BillingCard from '~/modules/common/components/BillingCard';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import useFormState from './useFormState';

const ClientBillingCard = ({ clientDetails, editable, isLoading }) => {
  const isPsaFPOvertimeBillingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFPOvertimeBillingEnabled'
  });
  const {
    id,
    billingSettings: {
      billLineItemsBy,
      description,
      internalNotes,
      showComments,
      billFrequency,
      billFrequencyRelativeDate,
      billFrequencyAbsoluteDate,
      consolidateBillsBy
    } = {},
    defaultInvoicePaymentTerm,
    invoiceCurrency,
    invoiceTemplate,
    taxProfile
  } = clientDetails;

  const filteredBillLineItemOptions = billLineItemsBy?.filter(columnOption => {
    if (columnOption === BILLING_COLUMN_TYPE.EXPENSE_CODE) {
      return true;
    }
    if (
      columnOption === BILLING_COLUMN_TYPE.PAYCODE ||
      columnOption === BILLING_COLUMN_TYPE.ROLE
    ) {
      return isPsaFPOvertimeBillingEnabled;
    }

    return true;
  });

  const { onSubmit, initialValues, validationSchema } = useFormState({
    id,
    defaultInvoicePaymentTerm,
    invoiceCurrency,
    invoiceTemplate,
    taxProfile,
    billLineItemsBy: filteredBillLineItemOptions,
    description,
    internalNotes,
    showComments,
    billFrequency,
    billFrequencyRelativeDate,
    billFrequencyAbsoluteDate,
    consolidateBillsBy
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <BillingCard editable={editable} isLoading={isLoading} />
    </Formik>
  );
};

ClientBillingCard.propTypes = {
  clientDetails: PropTypes.any,
  editable: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default ClientBillingCard;
