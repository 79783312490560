import { useCallback } from 'react';
import { ResourceRequestStatus } from '~/types';
import { useMeContext } from '~/modules/me';
import { useUpdateCompletedResourceAllocationProjectCacheOnAddWithProposedRequest2 } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks/useUpdateCompletedResourceAllocationCache';
import {
  useResourceAllocationHandlers,
  useMarkResourceRequestAsCompleteAndMerge
} from '~/modules/resourcing/common/hooks';
import { buildResourceAllocationFromResourceRequest } from '~/modules/resourcing/hooks/useCreateResourceAllocationHandler';
import useMarkResourceRequestAsTentative from '~/modules/resourcing/common/enhancers/requestStatusHandlers/useMarkResourceRequestAsTentative';
import useMarkResourceRequestAsComplete from '~/modules/resourcing/common/enhancers/requestStatusHandlers/useMarkResourceRequestAsComplete';
import RESOURCE_MATCH_POPOVER_BUTTON_ACTION from '../enum';

export const useCreateAllocationAndSetRequestStatus = ({
  resourceRequest,
  user,
  allocationChartRef,
  buttonType
}) => {
  const me = useMeContext();
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = me;

  const allocation = buildResourceAllocationFromResourceRequest({
    resourceRequest,
    scheduleRules: resourceRequest.scheduleRules,
    user,
    me
  });

  const { onCreateResourceAllocation } = useResourceAllocationHandlers();
  const cacheUpdate = useUpdateCompletedResourceAllocationProjectCacheOnAddWithProposedRequest2(
    {
      allocationChartRef
    }
  );
  const { onMarkResourceRequestAsComplete } = useMarkResourceRequestAsComplete({
    resourceRequest,
    additionalRequestParams: {
      refetchQueries: ['getProjectTotalsBySlug'],
      ...(buttonType &&
        allocationChartRef && {
          cacheUpdate
        })
    }
  });
  const {
    onMarkResourceRequestAsCompleteAndMerge
  } = useMarkResourceRequestAsCompleteAndMerge({
    resourceRequest,
    allocationChartRef
  });

  const {
    onMarkResourceRequestAsTentative
  } = useMarkResourceRequestAsTentative({
    resourceRequest
  });

  return useCallback(async () => {
    if (resourceRequest.requestStatus === ResourceRequestStatus.Submitted) {
      await onCreateResourceAllocation({
        allocation: {
          ...allocation,
          user: {
            ...allocation.user,
            userAllocationId: `${allocation.id}_${user.uri}`
          },
          totalHours: resourceRequest.totalHours
        },
        user
      });
    }

    if (buttonType === RESOURCE_MATCH_POPOVER_BUTTON_ACTION.PROPOSE) {
      return onMarkResourceRequestAsTentative();
    }

    if (isPsaRmpTaskAllocation1Enabled) {
      return onMarkResourceRequestAsCompleteAndMerge();
    }

    return onMarkResourceRequestAsComplete();
  }, [
    allocation,
    resourceRequest.requestStatus,
    onCreateResourceAllocation,
    onMarkResourceRequestAsComplete,
    onMarkResourceRequestAsTentative,
    buttonType
  ]);
};

export default useCreateAllocationAndSetRequestStatus;
