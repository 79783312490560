import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { convertDurationToHours } from '~/modules/common/util';
import { useMeContext } from '~/modules/me';
import useProjectResourcePlanPermissions from '~/modules/resourcing/hooks/useProjectResourcePlanPermissions';

export const RESOURCE_USER_SCHEDULE_DETAILS_QUERY = gql`
  query GetScheduleDetailsForResourceUser(
    $filter: GetResourceUsersFilter!
    $dateRange: DateRangeInput!
    $showHolidays: Boolean!
    $showTimeOff: Boolean!
    $periodResolution: PeriodResolutionOption!
    $includeResourceAvailabilitySummary: Boolean!
  ) {
    resourceUsers2(filter: $filter) {
      id
      scheduleDurationSeries(
        dateRange: $dateRange
        periodResolution: $periodResolution
      ) {
        dateRange {
          startDate: startDate2
          endDate: endDate2
        }
        hours
      }
      resourceAvailabilitySummarySeries(
        dateRange: $dateRange
        periodResolution: $periodResolution
      ) @include(if: $includeResourceAvailabilitySummary) {
        dateRange {
          startDate: startDate2
          endDate: endDate2
        }
        scheduledDuration {
          hours
          minutes
          seconds
        }
        timeOffDuration {
          hours
          minutes
          seconds
        }
        holidayDuration {
          hours
          minutes
          seconds
        }
        allocatedDuration {
          hours
          minutes
          seconds
        }
      }
      holidays(dateRange: $dateRange) @include(if: $showHolidays) {
        date
        uri
        name
        duration {
          hours
          minutes
          seconds
        }
        effectiveDuration {
          hours
          minutes
          seconds
        }
      }
      timeoffs(dateRange: $dateRange) @include(if: $showTimeOff) {
        dateRange {
          startDate
          endDate
        }
        timeOffType {
          displayText
        }
        hours
        days
        entries {
          date
          hours
          days
        }
      }
    }
  }
`;

const enhancer = users =>
  users.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.id]: {
        ...current,
        scheduleDurationByPeriodMap: (
          current.scheduleDurationSeries || []
        ).reduce((acc, duration) => {
          acc[duration.dateRange.startDate] = duration;

          return acc;
        }, {}),
        resourceAvailabilitySummaryByPeriodMap: (
          current.resourceAvailabilitySummarySeries || []
        ).reduce((acc, entry) => {
          const timeOffDuration = convertDurationToHours(entry.timeOffDuration);
          const holidayDuration = convertDurationToHours(entry.holidayDuration);
          const allocatedDuration = convertDurationToHours(
            entry.allocatedDuration
          );
          const scheduledDuration = convertDurationToHours(
            entry.scheduledDuration
          );
          const workHours =
            scheduledDuration - timeOffDuration - holidayDuration;

          acc[entry.dateRange.startDate] = {
            scheduledDuration,
            timeOffDuration,
            holidayDuration,
            allocatedDuration,
            workHours: Math.max(0, workHours),
            availableHours: workHours - allocatedDuration
          };

          return acc;
        }, {})
      }
    }),
    {}
  );

const useScheduleDetailsForResourceUser = ({
  userIds,
  dateRange,
  periodResolution,
  includeDisabled = true,
  loadingUsers,
  includeResourceAvailabilitySummary = false
}) => {
  const {
    permissionsMap,
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();

  const {
    isViewAdvancedSettingsEnabled,
    isViewAvailabilitySettingsEnabled
  } = useProjectResourcePlanPermissions(permissionsMap);

  const queryVariables = {
    filter: { userIds, includeDisabled },
    showTimeOff: Boolean(dateRange && isViewAvailabilitySettingsEnabled),
    showHolidays: Boolean(dateRange && isViewAdvancedSettingsEnabled),
    dateRange,
    periodResolution,
    includeResourceAvailabilitySummary:
      isRmpTaskAllocationPhase2Enabled && includeResourceAvailabilitySummary
  };

  const { data, loading } = useQuery(RESOURCE_USER_SCHEDULE_DETAILS_QUERY, {
    variables: queryVariables,
    skip: Boolean(!userIds?.length) || loadingUsers,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  return {
    scheduleDetailsByUserMap: enhancer(data?.resourceUsers2 || []),
    loading
  };
};

export default useScheduleDetailsForResourceUser;
