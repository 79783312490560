import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { LoadingButton } from '~/modules/common/components';
import FailedInvoiceDetails from '../BillingTransactionTab/components/FailedInvoiceDetails';
import { useBillIssueDialogOpen } from './hooks';

const useStyles = makeStyles(theme => ({
  billDisplayTextLabel: {
    fontWeight: 500
  },
  errorMessageListWrapper: {
    background: theme.palette.error.lightBackground,
    color: '#560e06',
    padding: theme.spacing(2)
  },
  errorMessageList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    listStyleType: 'disc'
  },
  header: {
    fontWeight: 'bold',
    color: 'black'
  },
  description: {
    paddingBottom: theme.spacing(1.5),
    color: 'black'
  },
  syncMessageText: {
    fontWeight: 500
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.breakpoints.down('sm') ? 'none' : '520px'
  }
}));

const useDialogActionStyles = makeStyles(theme => ({
  root: { paddingRight: theme.spacing(2.5) }
}));

const getResourceKeys = confirmationDialogName => ({
  header: `billDetails.${confirmationDialogName}Dialog.${confirmationDialogName}Bill`,
  details: `billDetails.${confirmationDialogName}Dialog.details`,
  description: `billDetails.${confirmationDialogName}Dialog.description`,
  cancel: `billDetails.${confirmationDialogName}Dialog.cancel`,
  actionButton: `billDetails.${confirmationDialogName}Dialog.actionButton`
});

export const BillIssueConfirmationDialog = ({
  open,
  onClose,
  confirmationDialogName,
  handleIssuePrintClick,
  handleIssueEmailClick,
  handleIssueSyncClick,
  loading,
  billDisplayText,
  failedLineItems,
  setFailedLineItems
}) => {
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpCappedBillingEnabled }
  } = me;
  const classes = useStyles();
  const dialogStyle = useDialogStyles();
  const dialogActionClasses = useDialogActionStyles();
  const failedLineItemsCount = useMemo(
    () => ({ count: failedLineItems.length }),
    [failedLineItems]
  );
  const resourceKeys = useMemo(() => getResourceKeys(confirmationDialogName), [
    confirmationDialogName
  ]);

  const { handleClose, handleClick } = useBillIssueDialogOpen({
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick,
    confirmationDialogName,
    onClose,
    isPsaPrpCappedBillingEnabled,
    setFailedLineItems
  });

  return (
    <Dialog
      classes={isPsaPrpCappedBillingEnabled ? dialogStyle : undefined}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullScreen={useMediaQuery(theme => theme.breakpoints.down('sm'), {
        noSsr: true
      })}
      open={open}
      onClose={onClose}
      TransitionProps={useMemo(() => ({ role: 'presentation' }), [])}
      aria-labelledby="entity-confirmation-dialog-title"
      aria-describedby="entity-confirmation-dialog-description"
    >
      <DialogTitle id="entity-confirmation-dialog-title">
        <FormattedMessage className={classes.header} id={resourceKeys.header} />
      </DialogTitle>
      <DialogContent id="entity-confirmation-dialog-description">
        <Typography variant="body2" className={classes.description}>
          <FormattedMessage id={resourceKeys.details} />
        </Typography>
        {isPsaPrpCappedBillingEnabled && failedLineItems.length > 0 ? (
          <>
            <div className={classes.errorMessageListWrapper}>
              <Typography
                variant="body1"
                className={classes.billDisplayTextLabel}
              >
                {billDisplayText}
              </Typography>
              <ul className={classes.errorMessageList}>
                <li>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="billingTransactionList.failedBillIssueMessage"
                      values={failedLineItemsCount}
                    />
                  </Typography>
                </li>
              </ul>
              <FailedInvoiceDetails details={failedLineItems} />
            </div>
          </>
        ) : (
          <Typography variant="body2" className={classes.description}>
            <FormattedMessage id={resourceKeys.description} />
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        classes={isPsaPrpCappedBillingEnabled ? dialogActionClasses : undefined}
      >
        <Button
          onClick={handleClose}
          disabled={isPsaPrpCappedBillingEnabled && loading}
        >
          <FormattedMessage id={resourceKeys.cancel} />
        </Button>
        <LoadingButton
          onClick={handleClick}
          disabled={
            isPsaPrpCappedBillingEnabled
              ? loading || failedLineItems.length > 0
              : false
          }
          color="primary"
          autoFocus
          isLoading={isPsaPrpCappedBillingEnabled && loading}
          data-qe-id="IssueConfirm"
        >
          <FormattedMessage id={resourceKeys.actionButton} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

BillIssueConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  confirmationDialogName: PropTypes.string,
  handleIssuePrintClick: PropTypes.func,
  handleIssueEmailClick: PropTypes.func,
  handleIssueSyncClick: PropTypes.func,
  loading: PropTypes.bool,
  billDisplayText: PropTypes.string,
  failedLineItems: PropTypes.array,
  setFailedLineItems: PropTypes.func
};

export default BillIssueConfirmationDialog;
