import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress
} from '@material-ui/core';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import {
  useActionStyles,
  useActionTaskAllocationFFOnStyles,
  useContentStyles,
  useContentTaskAllocationFFOnStyles,
  useSpinnerStyles
} from './useStyles';

export const ReleaseAllocationsDialogLoadingContent = ({ onCancelClick }) => {
  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  const actionClasses = useActionStyles();
  const actionFFOnClasses = useActionTaskAllocationFFOnStyles();
  const contentClasses = useContentStyles();
  const contentFFOnClasses = useContentTaskAllocationFFOnStyles();
  const loadingStyles = useSpinnerStyles();

  return (
    <>
      <DialogContent
        id="release-allocations-dialog-content"
        classes={
          isPsaRmpTaskAllocation1Enabled ? contentFFOnClasses : contentClasses
        }
      >
        <div className={loadingStyles.progress}>
          <CircularProgress size={24} className={loadingStyles.spinner} />
        </div>
      </DialogContent>
      <DialogActions
        classes={
          isPsaRmpTaskAllocation1Enabled ? actionFFOnClasses : actionClasses
        }
      >
        <Button onClick={onCancelClick}>
          <FormattedMessage id="releaseResourceRequestDialog.cancel" />
        </Button>
        {isPsaRmpTaskAllocation1Enabled && (
          <Button disabled>
            <FormattedMessage id="releaseResourceRequestDialog.release" />
          </Button>
        )}
      </DialogActions>
    </>
  );
};

ReleaseAllocationsDialogLoadingContent.propTypes = {
  onCancelClick: PropTypes.func.isRequired
};

export default ReleaseAllocationsDialogLoadingContent;
