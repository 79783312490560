import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Decimal } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  columnLabel: {
    fontWeight: 500
  },
  row: {
    padding: theme.spacing(0.5, 2)
  },
  leftAlignedCell: {
    padding: theme.spacing(0.5, 0.5, 0.5, 2),
    color: 'inherit',
    borderBottom: 'none',
    textAlign: 'left',
    width: '35%',
    verticalAlign: 'top'
  },
  rightAlignedCell: {
    padding: theme.spacing(0.5, 2, 0.5, 0.5),
    color: 'inherit',
    borderBottom: 'none',
    textAlign: 'right',
    width: '33%',
    verticalAlign: 'top'
  }
}));

const FailedInvoiceDetails = ({ details }) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          {['project', 'totalContractValue', 'totalAmountIssued'].map(
            labelId => (
              <TableCell
                key={labelId}
                className={
                  labelId === 'project'
                    ? classes.leftAlignedCell
                    : classes.rightAlignedCell
                }
              >
                <Typography variant="body2" className={classes.columnLabel}>
                  <FormattedMessage
                    id={`billingTransactionList.failedBillIssueColumnLabels.${labelId}`}
                  />
                </Typography>
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {details.map(({ project, projectTCVAmount, exceededBillAmount }) => (
          <TableRow key={project.id} className={classes.row}>
            <TableCell className={classes.leftAlignedCell}>
              <Typography variant="body2">{project.displayText}</Typography>
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              <Decimal
                value={projectTCVAmount.amount}
                prefix={`${projectTCVAmount.currency.symbol} `}
              />
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              <Decimal
                value={exceededBillAmount.amount}
                prefix={`${exceededBillAmount.currency.symbol} `}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

FailedInvoiceDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      project: PropTypes.object.isRequired,
      projectTCVAmount: PropTypes.object.isRequired,
      exceededBillAmount: PropTypes.object.isRequired
    })
  )
};

export default FailedInvoiceDetails;
