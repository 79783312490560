import { useSubscription } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESUME_PARSING_PROCESSING_SUBSCRIPTION = gql`
  subscription ResumeParsingProcessingCompleted {
    resumeParsingProcessingCompleted {
      id
      userSlug
    }
  }
`;

const useResumeParsingProcessingSubscription = ({ skipSubscription }) => {
  const { data: { resumeParsingProcessingCompleted } = {} } = useSubscription(
    RESUME_PARSING_PROCESSING_SUBSCRIPTION,
    {
      skip: skipSubscription
    }
  );

  return resumeParsingProcessingCompleted || { userSlug: null };
};

export default useResumeParsingProcessingSubscription;
