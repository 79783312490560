import { number, object } from 'yup';
import { useUpdateFinancials } from './useUpdateFinancials';

const initialBudget = (
  budgetCost,
  projectCurrency,
  isRolledUpTaskEstimateCalculationMethodEnabled
) => {
  return isRolledUpTaskEstimateCalculationMethodEnabled && !budgetCost
    ? {
        amount: null,
        currency: projectCurrency
      }
    : budgetCost
    ? {
        amount: budgetCost.amount,
        currency: budgetCost.currency
      }
    : {
        amount: 0,
        currency: projectCurrency
      };
};

export const initialState = ({
  budgetHours,
  totalEstimatedContract,
  limitIssuedBillsToTCV,
  budgetedCost,
  resourceBudgetedCost,
  expenseBudgetedCost,
  estimatedHours,
  estimatedCost,
  projectCurrency,
  costType,
  formatMessage,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => ({
  budgetHours: isRolledUpTaskEstimateCalculationMethodEnabled
    ? budgetHours
    : budgetHours || 0,
  totalEstimatedContract: totalEstimatedContract
    ? {
        amount: totalEstimatedContract.amount,
        currency: totalEstimatedContract.currency
      }
    : {
        amount: 0,
        currency: projectCurrency
      },
  limitIssuedBillsToTCV: limitIssuedBillsToTCV || false,
  budgetedCost: initialBudget(
    budgetedCost,
    projectCurrency,
    isRolledUpTaskEstimateCalculationMethodEnabled
  ),
  resourceBudgetedCost: initialBudget(
    resourceBudgetedCost,
    resourceBudgetedCost?.currency || budgetedCost?.currency || projectCurrency,
    isRolledUpTaskEstimateCalculationMethodEnabled
  ),
  expenseBudgetedCost: initialBudget(
    expenseBudgetedCost,
    expenseBudgetedCost?.currency || budgetedCost?.currency || projectCurrency,
    isRolledUpTaskEstimateCalculationMethodEnabled
  ),
  estimatedHours: isRolledUpTaskEstimateCalculationMethodEnabled
    ? estimatedHours
    : estimatedHours || 0,
  estimatedCost:
    isRolledUpTaskEstimateCalculationMethodEnabled && !estimatedCost
      ? {
          amount: null,
          currency: projectCurrency
        }
      : estimatedCost
      ? {
          amount: estimatedCost.amount,
          currency: estimatedCost.currency
        }
      : {
          amount: 0,
          currency: projectCurrency
        },
  projectCurrency,
  isProjectCurrencyChanged: false,
  isBudgetCurrencyChanged: false,
  costType: costType
    ? {
        id: costType.id,
        displayText: formatMessage({
          id: `projectFinancialsCard.${costType.id}`
        })
      }
    : {}
});

export const validationMessage = max =>
  `Exceeds max allowed value: ${max.toLocaleString('en')}`;

const ESTIMATED_HOURS_MAX = 99999999;
const ESTIMATED_COST_MAX = 999999999999;

export const validationSchema = () =>
  object().shape({
    budgetHours: number()
      .transform(value => (!isNaN(value) ? value : 0))
      .max(ESTIMATED_HOURS_MAX, validationMessage(ESTIMATED_HOURS_MAX)),
    totalEstimatedContract: object().shape({
      amount: number().max(
        ESTIMATED_COST_MAX,
        validationMessage(ESTIMATED_COST_MAX)
      )
    }),
    budgetedCost: object().shape({
      amount: number()
        .transform(value => (!isNaN(value) ? value : 0))
        .max(ESTIMATED_COST_MAX, validationMessage(ESTIMATED_COST_MAX))
    }),
    resourceBudgetedCost: object().shape({
      amount: number()
        .transform(value => (!isNaN(value) ? value : 0))
        .max(ESTIMATED_COST_MAX, validationMessage(ESTIMATED_COST_MAX))
    }),
    expenseBudgetedCost: object().shape({
      amount: number()
        .transform(value => (!isNaN(value) ? value : 0))
        .max(ESTIMATED_COST_MAX, validationMessage(ESTIMATED_COST_MAX))
    }),
    estimatedHours: number()
      .transform(value => (!isNaN(value) ? value : 0))
      .max(ESTIMATED_HOURS_MAX, validationMessage(ESTIMATED_HOURS_MAX)),
    estimatedCost: object().shape({
      amount: number()
        .transform(value => (!isNaN(value) ? value : 0))
        .max(ESTIMATED_COST_MAX, validationMessage(ESTIMATED_COST_MAX))
    })
  });

const useFormState = ({
  onSetCurrencyChanged,
  projectUri,
  budgetHours,
  totalEstimatedContract,
  limitIssuedBillsToTCV,
  budgetedCost,
  resourceBudgetedCost,
  expenseBudgetedCost,
  estimatedHours,
  estimatedCost,
  projectCurrency,
  costType,
  formatMessage,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const onSubmit = useUpdateFinancials({
    onSetCurrencyChanged,
    projectUri
  });
  const initialValues = initialState({
    budgetHours,
    totalEstimatedContract,
    limitIssuedBillsToTCV,
    budgetedCost,
    resourceBudgetedCost,
    expenseBudgetedCost,
    estimatedHours,
    estimatedCost,
    projectCurrency,
    costType,
    formatMessage,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  return {
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  };
};

export default useFormState;
