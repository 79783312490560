import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

const SelectAllBanner = ({ count }) => {
  const values = useMemo(() => ({ count }), [count]);

  return (
    <Alert severity="warning" icon={false}>
      <Typography variant="body1">
        <FormattedMessage
          id="tableHeaderNavigation.selectAllBanner"
          values={values}
        />
      </Typography>
    </Alert>
  );
};

SelectAllBanner.propTypes = {
  count: PropTypes.number.isRequired
};

export default SelectAllBanner;
