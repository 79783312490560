import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const PROPOSE_TOP_MATCH = gql`
  mutation proposeTopMatch($input: PRAA_ProposeTopMatchInput!) {
    proposeTopMatch(input: $input)
  }
`;

export const useProposeTopMatch = () => {
  const [proposeTopMatch] = useMutation(PROPOSE_TOP_MATCH);

  return {
    proposeTopMatch: ({ resourceRequestUris }) =>
      proposeTopMatch({
        variables: {
          input: {
            resourceRequestUris
          }
        },
        refetchQueries: [
          'resourceRequests',
          'resourcePlansSummaryForCurrentTenant'
        ]
      })
  };
};

export default useProposeTopMatch;
