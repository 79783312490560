import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, DialogContent, Typography } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import { MoreResult } from '~/modules/common/components';
import { useResourceUserPrimaryRole } from '~/modules/common/hooks';
import TaskAssignmentSearchBox from './components/TaskAssignmentSearchBox';
import { useGetPageOfTaskAssignmentSummary } from './hooks';
import TaskAssignmentListSkeleton from './TaskAssignmentListSkeleton';
import { TaskAssignmentList } from './components/TaskAssignmentList';

export const MAX_RECORD_COUNT = 500;

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    top: theme.spacing(5),
    minHeight: theme.spacing(40)
  },
  noTasks: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightRegular
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5)
  },
  moreResult: {
    paddingLeft: theme.spacing(2.5),
    fontStyle: 'italic'
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const TaskAssignmentDialogContent = ({
  projectId,
  resource,
  permittedActionUris,
  onTaskAssignmentUpdateSuccess,
  setActiveRequests,
  refetchQueriesOnRemoveTaskAssignment
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [searchCriteria, setSearchCriteria] = useState({
    keywords: [],
    criterions: {}
  });

  const textSearch = searchCriteria.keywords.length
    ? searchCriteria.keywords.join(' ')
    : undefined;

  const {
    loading,
    assignmentSummary,
    loadingMore,
    loadMore,
    hasMoreAndNotLoading
  } = useGetPageOfTaskAssignmentSummary({
    projectId,
    textSearch,
    userId: resource.id
  });

  const { primaryRole, loading: roleLoading } = useResourceUserPrimaryRole({
    variables: {
      page: 1,
      pageSize: 1,
      filter: { userIds: [resource.id] }
    }
  });

  return (
    <DialogContent className={classes.root}>
      <div className={classes.header}>
        <TaskAssignmentSearchBox
          searchCriteria={searchCriteria}
          setSearchCriteria={setSearchCriteria}
        />
      </div>
      {!loading && !assignmentSummary.length ? (
        <Typography variant="subtitle2" className={classes.noTasks}>
          <FormattedMessage id="timesheetAccess.noTasks" />
        </Typography>
      ) : !loading && !roleLoading ? (
        <TaskAssignmentList
          resource={resource}
          assignmentSummaryList={assignmentSummary}
          permittedActionUris={permittedActionUris}
          projectId={projectId}
          primaryRole={primaryRole}
          onTaskAssignmentUpdateSuccess={onTaskAssignmentUpdateSuccess}
          setActiveRequests={setActiveRequests}
          refetchQueriesOnRemoveTaskAssignment={
            refetchQueriesOnRemoveTaskAssignment
          }
        />
      ) : (
        <TaskAssignmentListSkeleton />
      )}
      {loadingMore && (
        <div className={classes.circularProgress}>
          <CircularProgress size={25} />
        </div>
      )}
      {hasMoreAndNotLoading && assignmentSummary.length < MAX_RECORD_COUNT && (
        <Waypoint
          fireOnRapidScroll
          bottomOffset="-20%"
          onEnter={loadMore}
          key={assignmentSummary.length}
        />
      )}
      {hasMoreAndNotLoading && assignmentSummary.length === MAX_RECORD_COUNT && (
        <div className={classes.moreResult}>
          <MoreResult
            message={formatMessage({
              id: 'taskAssignmentDialog.moreTasksAvailable'
            })}
          />
        </div>
      )}
    </DialogContent>
  );
};

TaskAssignmentDialogContent.propTypes = {
  resource: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  permittedActionUris: PropTypes.array.isRequired,
  onTaskAssignmentUpdateSuccess: PropTypes.func.isRequired,
  setActiveRequests: PropTypes.func,
  refetchQueriesOnRemoveTaskAssignment: PropTypes.array
};

export default TaskAssignmentDialogContent;
