import PropTypes from 'prop-types';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { ClickAwayListener } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import { useMenuState } from '~/modules/common/hooks';
import LegendSection from './LegendSection';

const useStyles = makeStyles(theme => ({
  legendList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 3, 2, 0)
  },
  legendLabel: {
    ...theme.typography.body2,
    textTransform: 'none',
    color: theme.palette.text.secondary
  }
}));

const legendsPropTypeShape = PropTypes.shape({
  isProgressBarLegend: PropTypes.bool,
  barType: PropTypes.oneOf(['dashedBar', 'roundBar', 'squareBar']),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string
});

export const usePopperStyles = makeStyles(
  theme =>
    theme.direction === 'rtl' && {
      popper: {
        right: 'unset !important',
        left: `-${theme.spacing(10)}px`
      }
    }
);

const LegendsPopup = ({ legends }) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const classes = useStyles();
  const popperClasses = usePopperStyles();

  return (
    <>
      <ClickAwayListener onClickAway={onMenuClose}>
        <Button
          onClick={onMenuClick}
          className={classes.legendLabel}
          variant="text"
          startIcon={<InfoIcon />}
        >
          <FormattedMessage id="resourcingLegends.legend" />
        </Button>
      </ClickAwayListener>
      {anchorEl && (
        <ArrowPopup
          id="legends-popup"
          anchorEl={anchorEl}
          placement="bottom"
          preventOverflow
          onClose={onMenuClose}
          classes={popperClasses}
        >
          <div className={classes.legendList}>
            <LegendSection legendSection={legends.none} />
            <LegendSection
              legendHeaderId="resourcingLegends.resourceRequests"
              legendSection={legends.resourceRequests}
            />
            <LegendSection
              legendHeaderId="resourcingLegends.resources"
              legendSection={legends.resources}
            />
          </div>
        </ArrowPopup>
      )}
    </>
  );
};

LegendsPopup.propTypes = {
  legends: PropTypes.shape({
    none: PropTypes.arrayOf(legendsPropTypeShape).isRequired,
    resourceRequests: PropTypes.arrayOf(legendsPropTypeShape).isRequired,
    resources: PropTypes.arrayOf(legendsPropTypeShape).isRequired
  }).isRequired
};

export default LegendsPopup;
