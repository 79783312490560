import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import List from '@material-ui/core/List';
import { useMeContext } from '~/modules/me/useMeContext';
import { withRoutes } from '~/modules/common/routes';
import MenuItem from '../MenuItem';

export const MainMenuItems = ({ routes }) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const location = useLocation();

  const menuItems = useMemo(
    () =>
      Object.values(routes).map(
        route =>
          !route.hidden && (
            <MenuItem
              key={route.path}
              path={route.path}
              dataQeId={route.dataQeId}
              icon={route.icon && <route.icon />}
              title={route.title && <route.title />}
              selected={!!matchPath(location.pathname, route)}
              divider={route.showDivider}
            />
          )
      ),
    [routes, location]
  );

  return isPsaPrpWcagFixesQ4Enabled ? (
    menuItems
  ) : (
    <List disablePadding>{menuItems}</List>
  );
};

MainMenuItems.propTypes = {
  routes: PropTypes.object
};

export default withRoutes(MainMenuItems);
