import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelButton from '../CancelButton';

export const HiringRequestActions = {
  UNSUBMIT_REQUEST: 'unsubmitRequest',
  REMOVE_REQUEST: 'removeRequest',
  CANCEL_TOBEHIRED: 'cancelToBeHired',
  WITHDRAW_HIRING_REQUEST: 'withdrawHiringRequest'
};

export const withdrawHiringRequestMessageIds = {
  unsubmitRequest: {
    action: 'withdrawHiringRequestDialog.unsubmitRequest.action',
    confirmation: 'withdrawHiringRequestDialog.unsubmitRequest.confirmation'
  },
  removeRequest: {
    action: 'withdrawHiringRequestDialog.removeRequest.action',
    confirmation: 'withdrawHiringRequestDialog.removeRequest.confirmation'
  },
  cancelToBeHired: {
    action: 'withdrawHiringRequestDialog.cancelToBeHired.action',
    confirmation: 'withdrawHiringRequestDialog.cancelToBeHired.confirmation'
  },
  withdrawHiringRequest: {
    action: 'withdrawHiringRequestDialog.withdraw.action',
    description: 'withdrawHiringRequestDialog.withdraw.description',
    confirmation: 'withdrawHiringRequestDialog.withdraw.confirmation'
  }
};

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(2, 3, 1)
  },
  text: {
    margin: theme.spacing(1.5, 0, 2),
    color: theme.palette.text.secondary
  },
  alertText: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export const WithdrawHiringRequestDialog = ({
  actionType,
  open,
  onClose,
  onClick
}) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    onClick();
    onClose();
  }, [onClick, onClose]);

  const withdrawHiringRequestLocalizationIds =
    withdrawHiringRequestMessageIds?.[actionType];

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.title}>
        <FormattedMessage id={withdrawHiringRequestLocalizationIds.action} />
      </DialogTitle>
      <DialogContent>
        {actionType === HiringRequestActions.WITHDRAW_HIRING_REQUEST ? (
          <Typography>
            <FormattedMessage
              id={withdrawHiringRequestLocalizationIds.description}
            />
          </Typography>
        ) : (
          <Alert icon={false} severity="warning">
            <AlertTitle>
              <FormattedMessage id="withdrawHiringRequestDialog.hiringRequested" />
            </AlertTitle>
            <Typography className={classes.alertText}>
              <FormattedMessage id="withdrawHiringRequestDialog.willWithdrawHiringRequested" />
            </Typography>
          </Alert>
        )}
        <Typography className={classes.text}>
          <FormattedMessage
            id={withdrawHiringRequestLocalizationIds.confirmation}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <Button
          color={
            actionType === HiringRequestActions.CANCEL_TOBEHIRED
              ? 'primary'
              : 'secondary'
          }
          onClick={handleClick}
        >
          <FormattedMessage id={withdrawHiringRequestLocalizationIds.action} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WithdrawHiringRequestDialog.propTypes = {
  actionType: PropTypes.oneOf(Object.values(HiringRequestActions)).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default WithdrawHiringRequestDialog;
