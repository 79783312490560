import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Card, makeStyles } from '@material-ui/core';

import ListTableVariantContext from './ListTableVariantContext';
import ListTableSkeleton from './ListTableSkeleton';
import ListTablePropTypes from './PropTypes';
import ScrollableListTable from './ScrollableListTable';
import { ListTableContextProvider } from './ListTableContext';
import { PAGINATION_TYPE } from './enum';
import NavigationBasedListTable from './NavigationBasedListTable';

const useStyles = makeStyles({
  card: {
    overflow: 'visible',
    margin: '0px'
  }
});

const ListTableProviders = ({
  variant,
  sortArray,
  sort,
  isMultiSortEnabled,
  onSortChange,
  disableSort,
  children
}) => (
  <ListTableVariantContext.Provider value={variant}>
    <ListTableContextProvider
      sort={sort}
      sortArray={sortArray}
      onSortChange={onSortChange}
      disableSort={disableSort}
      isMultiSortEnabled={isMultiSortEnabled}
    >
      {children}
    </ListTableContextProvider>
  </ListTableVariantContext.Provider>
);

ListTableProviders.propTypes = {
  variant: PropTypes.oneOf(['standard', 'table']),
  sort: ListTablePropTypes.sort,
  sortArray: ListTablePropTypes.sortArray,
  isMultiSortEnabled: PropTypes.bool,
  onSortChange: PropTypes.func,
  disableSort: PropTypes.bool,
  children: PropTypes.node.isRequired
};

const ListTable = ({
  classes: classesOverride,
  children,
  headers,
  footers,
  columns,
  records,
  hasMore,
  loadMore,
  totals,
  isLoading,
  loadingComponent = ListTableSkeleton,
  mobileListItem,
  mobileListItemSkeleton,
  onListItemClick,
  variant = 'standard',
  useInlineStyles = true,
  hover,
  onRowClick,
  onCellClick,
  sort,
  sortArray = [],
  isMultiSortEnabled = false,
  disableSort = false,
  onSortChange,
  dataQeId,
  elevation,
  showLoadingComponent = true,
  showLoadMoreSkeleton = false,
  selected,
  onRowSelectionChange,
  paginationType,
  isMobile,
  showSelectAll = false
}) => {
  const classes = useStyles({ classes: classesOverride });

  const sorts = useMemo(() => (sort ? [sort, ...sortArray] : sortArray), [
    sort,
    sortArray
  ]);

  const Component =
    paginationType === PAGINATION_TYPE.NAVIGATION && !isMobile
      ? NavigationBasedListTable
      : ScrollableListTable;

  return (
    <ListTableProviders
      variant={variant}
      sortArray={sorts}
      sort={sort}
      isMultiSortEnabled={isMultiSortEnabled}
      onSortChange={onSortChange}
      disableSort={disableSort}
    >
      <Card
        data-qe-id={dataQeId}
        className={classes.card}
        elevation={elevation}
      >
        {children}
        <Component
          headers={headers}
          footers={footers}
          columns={columns}
          records={records}
          hasMore={hasMore}
          loadMore={loadMore}
          totals={totals}
          mobileListItem={mobileListItem}
          mobileListItemSkeleton={mobileListItemSkeleton}
          onListItemClick={onListItemClick}
          variant={variant}
          useInlineStyles={useInlineStyles}
          hover={hover}
          onRowClick={onRowClick}
          onCellClick={onCellClick}
          isLoading={isLoading}
          loadingComponent={loadingComponent}
          showLoadingComponent={showLoadingComponent}
          showLoadMoreSkeleton={showLoadMoreSkeleton}
          onRowSelectionChange={onRowSelectionChange}
          selected={selected}
          showSelectAll={showSelectAll}
        />
      </Card>
    </ListTableProviders>
  );
};

ListTable.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  headers: ListTablePropTypes.headers,
  footers: ListTablePropTypes.footers,
  columns: ListTablePropTypes.columns,
  records: PropTypes.any,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  totals: PropTypes.any,
  isLoading: PropTypes.bool,
  loadingComponent: PropTypes.func,
  mobileListItem: PropTypes.any,
  mobileListItemSkeleton: PropTypes.func,
  onListItemClick: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'table']),
  useInlineStyles: PropTypes.bool,
  hover: PropTypes.bool,
  dataQeId: PropTypes.string,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  disableSort: PropTypes.bool,
  elevation: PropTypes.number,
  sort: ListTablePropTypes.sort,
  sortArray: ListTablePropTypes.sortArray,
  isMultiSortEnabled: PropTypes.bool,
  onSortChange: PropTypes.func,
  showLoadingComponent: PropTypes.bool,
  showLoadMoreSkeleton: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  selected: ListTablePropTypes.selected,
  paginationType: PropTypes.oneOf([
    PAGINATION_TYPE.NAVIGATION,
    PAGINATION_TYPE.SCROLLABLE
  ]),
  isMobile: PropTypes.bool,
  showSelectAll: PropTypes.bool
};

export default ListTable;
