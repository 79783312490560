import React from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import FlagIcon from './FlagIcon';

export const FlagToggle = ({
  isFlagged,
  onToggle,
  readOnly,
  className,
  ...otherProps
}) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: isFlagged ? 'flagToggle.flagged' : 'flagToggle.notFlagged'
      })}
      aria-label={intl.formatMessage({
        id: isFlagged ? 'flagToggle.flagged' : 'flagToggle.notFlagged'
      })}
    >
      <div>
        <IconButton
          className={className}
          {...otherProps}
          onClick={onToggle}
          disabled={readOnly}
        >
          <FlagIcon isFlagged={isFlagged} className={className} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

FlagToggle.propTypes = {
  isFlagged: PropTypes.bool,
  onToggle: PropTypes.func,
  readOnly: PropTypes.bool,
  className: PropTypes.string
};

export default FlagToggle;
