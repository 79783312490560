export const mapUserProfileSkillToSkillAssignment = ({
  userProfileSkill,
  certificate,
  skillCustomMetadata
}) => {
  const {
    skillUri,
    skillName,
    skillLevelUri,
    skillLevelName,
    skillLevel
  } = userProfileSkill;

  return {
    id: skillUri,
    uri: skillUri,
    displayText: skillName,
    certificate: certificate || null,
    skillLevel: {
      id: skillLevelUri,
      uri: skillLevelUri,
      displayText: skillLevelName,
      rating: skillLevel
    },
    skillCustomMetadata: skillCustomMetadata || []
  };
};

export const mapSkillAssignmentToUserProfileSkill = ({
  skillAssignment,
  category
}) => ({
  categoryUri: category.uri,
  categoryName: category.displayText,
  skillUri: skillAssignment.uri,
  skillName: skillAssignment.displayText,
  skillLevelUri: skillAssignment.skillLevel.uri,
  skillLevelName: skillAssignment.skillLevel.displayText,
  skillLevel: skillAssignment.skillLevel.rating
});

const removeTypenameKey = obj => {
  const { __typename, ...rest } = obj;

  return rest;
};

export const mapResourceSkillDetailsToUserSkillInputs = resourceSkill =>
  resourceSkill.skillAssignments.map(skillAssignment => ({
    skillUri: skillAssignment.uri,
    name: skillAssignment.displayText,
    skillLevelUri: skillAssignment.skillLevel.uri,
    certificate: skillAssignment.certificate,
    skillCustomMetadata: skillAssignment.skillCustomMetadata.map(
      removeTypenameKey
    ),
    category: {
      id: resourceSkill.category.uri,
      name: resourceSkill.category.displayText
    }
  }));

const createNewCategorySkills = ({ categoryUri, categoryName }) => ({
  category: {
    id: categoryUri,
    uri: categoryUri,
    displayText: categoryName
  },
  newSkills: [],
  skills: {}
});

export const combineSkillsAndUserProfileSkills = ({
  skills,
  userProfileSkills,
  mapCategory,
  mapSkillAssignment
}) => {
  const combinedSkillsAndUserProfileSkills = skills.reduce(
    (categoryMap, skill) => {
      const { category, skillAssignments } = skill;
      const categorySkills = {
        category,
        newSkills: [],
        skills: skillAssignments.reduce((skillsMap, skillAssignment) => {
          return {
            ...skillsMap,
            [skillAssignment.uri]: {
              skillAssignment,
              mappingMetadata: { fromResoureDetails: true }
            }
          };
        }, {})
      };

      return {
        ...categoryMap,
        [category.uri]: categorySkills
      };
    },
    {}
  );

  userProfileSkills.forEach(userProfileSkill => {
    const {
      categoryUri,
      categoryName,
      skillUri,
      skillLevelUri,
      skillLevelName,
      skillLevel
    } = userProfileSkill;
    let categorySkills = combinedSkillsAndUserProfileSkills[categoryUri];

    if (!categorySkills) {
      categorySkills = createNewCategorySkills({ categoryUri, categoryName });
      combinedSkillsAndUserProfileSkills[categoryUri] = categorySkills;
    }

    const isAssignedSkill = skillUri && categorySkills.skills[skillUri];

    if (isAssignedSkill) {
      const { skillAssignment } = categorySkills.skills[skillUri];

      if (skillAssignment && skillAssignment.skillLevel?.id !== skillLevelUri) {
        categorySkills.skills[skillUri].skillAssignment = {
          ...skillAssignment,
          skillLevel: {
            id: skillLevelUri,
            uri: skillLevelUri,
            displayText: skillLevelName,
            rating: skillLevel
          }
        };
        categorySkills.skills[
          skillUri
        ].mappingMetadata.fromUserProfileSkills = true;
      }
    } else {
      categorySkills.newSkills.push({
        skillAssignment: mapUserProfileSkillToSkillAssignment({
          userProfileSkill
        }),
        mappingMetadata: { fromUserProfileSkills: true }
      });
    }
  });

  return Object.keys(combinedSkillsAndUserProfileSkills).reduce((acc, key) => {
    const {
      category,
      skills: combinedSkills,
      newSkills
    } = combinedSkillsAndUserProfileSkills[key];
    const combinedSkillsMap = Object.values(combinedSkills).concat(newSkills);

    acc.push({
      category: mapCategory ? mapCategory(category) : category,
      skillAssignments: mapSkillAssignment
        ? combinedSkillsMap.map(combinedSkillEntry =>
            mapSkillAssignment(
              combinedSkillEntry.skillAssignment,
              combinedSkillEntry.mappingMetadata
            )
          )
        : combinedSkillsMap.map(c => c.skillAssignment)
    });

    return acc;
  }, []);
};
