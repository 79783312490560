import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberTextField } from '~/modules/common/components';
import { useChangeOnBlurInputHandlers } from '~/modules/resourcing/common/chart/hooks';

const ResourceRequestQuantityField = ({
  value,
  classes,
  onChange,
  dataQeId
}) => {
  const { formatMessage } = useIntl();
  const {
    numberValue,
    handleOnValueChange,
    handleOnBlur,
    highlightTarget
  } = useChangeOnBlurInputHandlers({
    value,
    onChangeCallback: onChange,
    triggerOnChange: true
  });

  return (
    <Tooltip
      title={
        <>
          <FormattedMessage id="resourceRequestQuantityField.quantity" />
          {': '}
          {numberValue}
        </>
      }
    >
      <NumberTextField
        dataqeid={dataQeId}
        align="left"
        classes={classes}
        value={numberValue}
        ariaLabel={formatMessage({
          id: 'resourceRequestQuantityField.quantity'
        })}
        onChange={handleOnValueChange}
        onNumberBlur={handleOnBlur}
        onFocus={highlightTarget}
        step={1}
        min={1}
        max={50}
        variant="standard"
      />
    </Tooltip>
  );
};

ResourceRequestQuantityField.propTypes = {
  dataQeId: PropTypes.string,
  value: PropTypes.number,
  classes: PropTypes.object,
  onChange: PropTypes.func
};
export default ResourceRequestQuantityField;
