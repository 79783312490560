import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: theme.spacing(24.75)
  },
  column: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: [[0, 0, 0, -1]],
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    },
    minWidth: theme.spacing(12.5),
    width: '100%',
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  columnBorder: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  clickableColumn: {
    cursor: 'pointer',
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.15)
    }
  },
  allocationColumn: {
    flexDirection: 'column'
  },
  actionsColumn: {
    flexGrow: 0,
    flexShrink: 0,
    minWidth: theme.spacing(9),
    flexBasis: theme.spacing(9)
  },
  statusColumn: {
    flexGrow: 0,
    flexShrink: 0,
    minWidth: theme.spacing(1),
    flexBasis: theme.spacing(1)
  },
  actions: {
    flexBasis: theme.spacing(8),
    flexGrow: 0,
    overflow: 'visible',
    padding: theme.spacing(1, 0, 0, 0.25)
  },
  delaActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  openButton: {
    display: 'none',
    opacity: 1,
    alignSelf: 'flex-start'
  },
  requestContainer: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(1.25, 1, 0, 1),
    color: theme.palette.text.secondary
  },
  multipleRequestsLabel: {
    ...theme.typography.body2,
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignContent: 'center',
    padding: theme.spacing(0, 0, 0, 1)
  }
}));

export default useStyles;
