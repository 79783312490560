import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0.5, 1, 0, 1),
    fontSize: theme.typography.caption.fontSize,
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden'
  },
  noValue: {
    display: 'block',
    fontWeight: theme.typography.fontWeightBold,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap'
  },
  labelRow: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    lineHeight: `${theme.spacing(2)}px`,
    height: `${theme.spacing(2)}px`
  },
  roleName: {
    display: 'block',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: `${theme.spacing(2)}px`,
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
    width: theme.spacing(12),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  roleDetails: {
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(2)}px`,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline'
  },
  loading: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    color: theme.palette.text.secondary,
    fontStyle: 'normal'
  },
  tooltipDetails: {
    margin: theme.spacing(0.5, 0, 0, 0)
  },
  adjustedLoading: {
    fontStyle: 'italic'
  },
  badgeContainer: {
    margin: theme.spacing(1, 0, 0)
  }
}));

export default useStyles;
