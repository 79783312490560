import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { DelaStarIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    color: theme.palette.common.white,
    background: `linear-gradient(118.89deg, ${theme.palette.Dela.button.gradient.purple} 17.59%, ${theme.palette.Dela.button.gradient.blue} 77.78%)`,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[0],
    '&:hover': {
      boxShadow: theme.shadows[2]
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.3),
      padding: theme.spacing(0.3, 1)
    }
  }
}));

const useDelaStarIconStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0.5),
    fill: theme.palette.Dela.lightBackground,
    fontSize: theme.spacing(1.5)
  }
}));

export const DelaAcceptAllButton = ({
  classes: classesOverride,
  children,
  onClick,
  dataQeId,
  ...otherProps
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const delaStarIconClasses = useDelaStarIconStyles();

  return (
    <Button
      variant="contained"
      {...otherProps}
      className={classes.root}
      classes={classes}
      onClick={onClick}
      data-qe-id={dataQeId}
    >
      <DelaStarIcon
        classes={delaStarIconClasses}
        titleAccess={intl.formatMessage({
          id: 'dela.suggestedByDelaAI'
        })}
        role="img"
      />
      {children}
    </Button>
  );
};

DelaAcceptAllButton.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  dataQeId: PropTypes.string
};

export default DelaAcceptAllButton;
