import React from 'react';
import PropTypes from 'prop-types';
import { TabBar, TabContents } from './components';

const TabbedViews = ({ tabRoutes, bgColor, ...props }) => (
  <>
    <TabBar tabRoutes={tabRoutes} bgColor={bgColor} />
    <TabContents tabRoutes={tabRoutes} {...props} />
  </>
);

TabbedViews.propTypes = {
  tabRoutes: PropTypes.array.isRequired,
  bgColor: PropTypes.string
};

export default TabbedViews;
