/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, List } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  projectName: {
    fontWeight: theme.typography.fontWeightMedium,
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: theme.spacing(2.5)
  },
  errors: {
    listStyleType: 'disc'
  },
  project: {
    paddingBottom: theme.spacing(1)
  }
}));

export const ErrorDetails = ({ failedProjects }) => {
  const classes = useStyles();

  return (
    <List>
      {failedProjects.map(project => (
        <li className={classes.project} key={project.id}>
          <Typography variant="body2" className={classes.projectName}>
            {project.projectName}
          </Typography>
          <ul className={classes.errorMessageList}>
            {project.errorMessages.map((error, index) => (
              <li className={classes.errors} key={`error_${index}`}>
                <Typography variant="body2">{error}</Typography>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

ErrorDetails.propTypes = {
  failedProjects: PropTypes.array.isRequired
};

export default ErrorDetails;
