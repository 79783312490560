import { useMemo } from 'react';
import {
  PROJECT_DAL,
  PROJECT_PERMISSION,
  USER_ACCESS_ROLE
} from '~/modules/common/enums';
import { useMeContext } from '~/modules/me/useMeContext';

const allowedAddProjectDals = [
  PROJECT_DAL.PROJECT_ADMIN,
  PROJECT_DAL.PROJECT_MANAGER,
  PROJECT_DAL.CLIENT_MANAGER,
  PROJECT_DAL.PROGRAM_MANAGER
];

export const getProjectPermissions = ({ permissionsMap, userAccessRoles }) => {
  const viewProjectPermission = permissionsMap[PROJECT_PERMISSION.VIEW_PROJECT];
  const canViewAllProjects = (
    (viewProjectPermission && viewProjectPermission.dataAccessLevelUris) ||
    []
  ).includes(PROJECT_DAL.PROJECT_ADMIN);

  const editProjectPermission = permissionsMap[PROJECT_PERMISSION.EDIT_PROJECT];

  const canEditAllProjects =
    (editProjectPermission &&
      editProjectPermission.dataAccessLevelUris.includes(
        PROJECT_DAL.PROJECT_ADMIN
      )) ||
    Boolean(
      userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_ADMIN) &&
        editProjectPermission &&
        editProjectPermission.dataAccessLevelUris.includes(PROJECT_DAL.ALL)
    );

  const canAddProject = Boolean(
    (editProjectPermission &&
      editProjectPermission.dataAccessLevelUris.some(x =>
        allowedAddProjectDals.includes(x)
      )) ||
      Boolean(
        userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_ADMIN) &&
          editProjectPermission &&
          editProjectPermission.dataAccessLevelUris.includes(PROJECT_DAL.ALL)
      )
  );

  const projectManagerCanViewProject = Boolean(
    viewProjectPermission &&
      viewProjectPermission.dataAccessLevelUris.includes(
        PROJECT_DAL.PROJECT_MANAGER
      )
  );

  const isClientManager = userAccessRoles.includes(
    USER_ACCESS_ROLE.CLIENT_MANAGER
  );

  const isProgramManager = userAccessRoles.includes(
    USER_ACCESS_ROLE.PROGRAM_MANAGER
  );

  const isProjectManager = userAccessRoles.includes(
    USER_ACCESS_ROLE.PROJECT_MANAGER
  );

  const isPortfolioManager = userAccessRoles.includes(
    USER_ACCESS_ROLE.PORTFOLIO_MANAGER
  );

  return {
    hasEditProjectPermission: Boolean(editProjectPermission),
    hasEditClientPermission: Boolean(
      permissionsMap[PROJECT_PERMISSION.EDIT_CLIENT]
    ),
    hasEditProgramPermission: Boolean(
      permissionsMap[PROJECT_PERMISSION.EDIT_PROGRAM]
    ),
    hasEditPortfolioPermission: Boolean(
      permissionsMap[PROJECT_PERMISSION.EDIT_PORTFOLIO]
    ),
    hasEditCustomFields: Boolean(
      permissionsMap[PROJECT_PERMISSION.EDIT_CUSTOM_FIELDS]
    ),
    canAddProject,
    canViewAllProjects,
    canEditAllProjects,
    projectManagerCanViewProject,
    isClientManager,
    isProgramManager,
    isProjectManager,
    isPortfolioManager
  };
};

export default () => {
  const { permissionsMap, userAccessRoles } = useMeContext();

  return useMemo(
    () => getProjectPermissions({ permissionsMap, userAccessRoles }),
    [permissionsMap, userAccessRoles]
  );
};
