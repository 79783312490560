import { PAGE_SIZE } from '~/modules/common/const';

const mergeObjectDefault = (existing, incoming, { mergeObjects }) => {
  return mergeObjects(existing || {}, incoming || {});
};

export const mergeArrays = (
  existing,
  incoming,
  { readField, mergeObjects },
  indexKey
) => {
  const merged = existing ? existing.slice(0) : [];
  const arrayIndex = {};

  if (existing) {
    existing.forEach((item, index) => {
      arrayIndex[readField(indexKey, item)] = index;
    });
  }

  incoming.forEach(item => {
    const itemId = readField(indexKey, item);
    const index = arrayIndex[itemId];

    if (index !== undefined) {
      merged[index] = mergeObjects(merged[index], item);
    } else {
      merged.push(item);
    }
  });

  return merged;
};

export const paginationDefaultMerge = (
  existing,
  incoming,
  { args: { page = 1, pageSize = PAGE_SIZE } }
) => {
  const offset = (page - 1) * pageSize;

  return [...(existing ? existing.slice(0, offset) : []), ...incoming];
};

export const paginationMergeByPageReplacement = (
  existing,
  incoming,
  { args: { page = 1, pageSize = PAGE_SIZE, skipMerge = false } }
) => {
  if (skipMerge) return incoming;
  const offset = (page - 1) * pageSize;
  const merged = existing ? existing.slice(0) : [];

  if (!existing && offset !== 0) {
    for (let i = 0; i < offset; ++i) {
      merged[i] = null;
    }
  }

  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }

  return merged;
};

export const paginationCursorMerge = ({
  fieldKey,
  idField = 'id',
  readFieldOverride
}) => (existing, incoming, { args: { cursor }, readField }) => {
  const readFieldFn = readFieldOverride
    ? readFieldOverride(readField)
    : readField;

  const merged = cursor
    ? { ...existing, [fieldKey]: existing[fieldKey].slice(0) }
    : { [fieldKey]: [], nextPageCursor: null };

  const offset = merged[fieldKey].length;
  const existingIds = merged[fieldKey]
    .map(r => readFieldFn(idField, r))
    .filter(id => id !== undefined && id !== null);

  const incomingCleaned = incoming[fieldKey].filter(record => {
    const id = readFieldFn(idField, record);

    return id !== undefined && id !== null && !existingIds.includes(id);
  });

  for (let i = 0; i < incomingCleaned.length; ++i)
    merged[fieldKey][offset + i] = incomingCleaned[i];
  merged.nextPageCursor = incoming.nextPageCursor;

  return merged;
};

export const paginationResourceUserMerge = (
  existing,
  incoming,
  { args: { page = 1, pagesize, skipMerge } }
) =>
  paginationMergeByPageReplacement(existing, incoming, {
    args: { page, pageSize: pagesize, skipMerge }
  });

export default {
  Query: {
    fields: {
      projectRequests: {
        keyArgs: ['filter', 'sort'],
        merge: paginationDefaultMerge
      },
      taskEntries3: {
        keyArgs: ['filter', 'sort'],
        merge: paginationDefaultMerge
      },
      portfolios: {
        keyArgs: ['filter'],
        merge: paginationDefaultMerge
      },
      childPortfolios: {
        keyArgs: ['parentId'],
        merge: paginationDefaultMerge
      },
      expenseCardSettings: {
        keyArgs: ['projectId'],
        merge: (existing, incoming) => incoming
      },
      resourceUsers2: {
        keyArgs: [
          'filter',
          'resourceRequestId',
          'periodResolution',
          'dateRange',
          'sort',
          'scoreComputeType'
        ],
        merge: paginationResourceUserMerge
      },
      resourceRequests: {
        keyArgs: [
          'requestStatusList',
          'roleUri',
          'projectUri',
          'requestAccessLevel',
          'filter',
          'sort'
        ],
        merge: paginationCursorMerge({ fieldKey: 'resourceRequests' })
      },
      pageOfTaskAssignmentSummaryForProjectAndResourceUser: {
        keyArgs: ['input', ['projectId', 'userId', 'filter']],
        merge(existing = { taskSummary: [] }, incoming, { args }) {
          return {
            taskSummary: paginationDefaultMerge(
              existing.taskSummary,
              incoming.taskSummary,
              { args }
            )
          };
        }
      }
    }
  },
  ProjectRequest: {
    keyFields: ['id'],
    fields: {
      status: {
        merge: mergeObjectDefault
      },
      score: {
        merge: mergeObjectDefault
      }
    }
  },
  Project: {
    keyFields: ['id'],
    fields: {
      pageOfProjectRate: {
        keyArgs: ['filters', 'sort'],
        merge: paginationDefaultMerge
      }
    }
  },
  ResourceUser: {
    keyFields: ['id'],
    fields: {
      resourceAvailabilitySummarySeries: {
        merge: false
      },
      scheduleDurationByDay: {
        merge: false
      },
      projectAllocationSummary: {
        keyArgs: ['filter'],
        merge: paginationCursorMerge({
          fieldKey: 'entries',
          readFieldOverride: readField => (id, item) => {
            const outerItem = readField('project', item);

            return readField(id, outerItem);
          }
        })
      }
    }
  },
  ResourceRequest: {
    keyFields: ['id'],
    fields: {
      scheduleRules: {
        merge: false
      },
      resourcePools: {
        merge: false
      },
      sourceMetadata: {
        merge: false
      }
    }
  },
  TaskResourceUserAllocation: {
    keyFields: ['id'],
    fields: {
      scheduleRules: {
        merge: false
      }
    }
  },
  ResourceAllocationUser: {
    keyFields: ['userAllocationId'],
    fields: {
      timeoffs: {
        merge(existing, incoming, { readField, mergeObjects }) {
          return mergeArrays(
            existing,
            incoming,
            { readField, mergeObjects },
            'uri'
          );
        }
      },
      holidays: {
        merge(existing, incoming, { readField, mergeObjects }) {
          return mergeArrays(
            existing,
            incoming,
            { readField, mergeObjects },
            'uri'
          );
        }
      }
    }
  },
  ResourceAllocation: {
    keyFields: ['id'],
    fields: {
      scheduleRules: {
        merge: false
      },
      totalUserCostByCurrency: {
        merge: false
      },
      totalRoleCostByCurrency: {
        merge: false
      }
    }
  },
  Me: {
    fields: {
      timeOff: {
        merge: true
      },
      timesheet: {
        merge: true
      },
      groupSettings: {
        merge: true
      }
    }
  }
};
