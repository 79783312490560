import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  TaskAssignmentDialogTitle,
  TaskAssignmentDialogContent,
  TaskAssignmentDialogFooter
} from '~/modules/tasks/components/TaskAssignmentDialog';

export const TaskAssignmentDialog = ({
  permittedActionUris,
  projectId,
  resource,
  onClose,
  open,
  refetchQueriesOnRemoveTaskAssignment
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const [isAssignmentDirty, setIsAssignmentDirty] = useState(false);

  const onTaskAssignmentUpdateSuccess = useCallback(
    () => setIsAssignmentDirty(true),
    [setIsAssignmentDirty]
  );

  const onDialogClose = useCallback(() => onClose({ isAssignmentDirty }), [
    onClose,
    isAssignmentDirty
  ]);

  const [activeRequests, setActiveRequests] = useState(0);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      open={open}
      onClose={onDialogClose}
    >
      <TaskAssignmentDialogTitle resource={resource} />
      <TaskAssignmentDialogContent
        resource={resource}
        projectId={projectId}
        permittedActionUris={permittedActionUris}
        onTaskAssignmentUpdateSuccess={onTaskAssignmentUpdateSuccess}
        setActiveRequests={setActiveRequests}
        refetchQueriesOnRemoveTaskAssignment={
          refetchQueriesOnRemoveTaskAssignment
        }
      />
      <TaskAssignmentDialogFooter
        onClose={onDialogClose}
        disabled={activeRequests > 0}
      />
    </Dialog>
  );
};

TaskAssignmentDialog.propTypes = {
  permittedActionUris: PropTypes.array,
  projectId: PropTypes.string,
  resource: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  refetchQueriesOnRemoveTaskAssignment: PropTypes.array
};
