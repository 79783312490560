import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HiringStatus, ResourceRequestStatus } from '~/types';

export const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.dtm.main,
    borderRadius: theme.spacing(0.5),
    textAlign: 'center'
  },
  text: {
    ...theme.typography.caption,
    color: theme.palette.dtm.text,
    padding: theme.spacing(0.5, 1),
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export const showHiringStatusBadge = ({
  resourceRequest,
  requisitionHiringStatus
}) =>
  Boolean(
    requisitionHiringStatus ||
      (resourceRequest.requestStatus === ResourceRequestStatus.Tobehired &&
        resourceRequest.sourceMetadata?.hiringStatus ===
          HiringStatus.HiringRequested)
  );

export const HiringStatusBadge = ({
  classes: classesOverrides,
  requisitionHiringStatus
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const values = useMemo(() => {
    if (requisitionHiringStatus) {
      return {
        message: { hiringStatus: requisitionHiringStatus },
        tooltip: requisitionHiringStatus
      };
    }

    return {
      message: { hiringStatus: '...' },
      tooltip: <FormattedMessage id="hiringBadge.noStatus" />
    };
  }, [requisitionHiringStatus]);

  return (
    <div className={classes.container}>
      <Tooltip title={values.tooltip}>
        <Typography className={classes.text}>
          <FormattedMessage id="hiringBadge.hiring" values={values.message} />
        </Typography>
      </Tooltip>
    </div>
  );
};

HiringStatusBadge.propTypes = {
  classes: PropTypes.object,
  requisitionHiringStatus: PropTypes.string
};

export default HiringStatusBadge;
