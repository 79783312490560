import React, { useCallback, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControl,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import {
  AttachmentsList,
  NewAttachmentButton,
  NoAttachments
} from '~/modules/attachments/AttachmentsBlock';
import { FileWebAddressDialog } from '~/modules/extensionFields/components';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import useAccordionStyles from './useAccordionStyles';

const expandIcon = <ExpandMoreIcon />;
const useStyles = makeStyles(theme => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

export const DocumentsAccordion = ({
  attachmentsData,
  initialIsExpanded = false
}) => {
  const {
    accordionClasses,
    summaryClasses,
    titleClass,
    summaryContainerClass
  } = useAccordionStyles();
  const classes = useStyles();

  const [expanded, setExpanded] = useState(initialIsExpanded);
  const onChange = useCallback(() => setExpanded(!expanded), [expanded]);
  const { canEditProjectRequest } = useProjectRequestObjectPermissions();
  const {
    attachments,
    isDialogOpen,
    onDialogOpen,
    onDialogClose,
    onFileUpload,
    isUploading,
    isLoading,
    error,
    deleteAttachment,
    enabledSourceUris
  } = attachmentsData;
  const attachmentsCount = useMemo(() => ({ count: attachments.length }), [
    attachments.length
  ]);

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      classes={accordionClasses}
    >
      <AccordionSummary
        data-qe-id="DocumentsAccordion"
        expandIcon={expandIcon}
        classes={summaryClasses}
      >
        <div className={summaryContainerClass}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={titleClass}
          >
            <FormattedMessage id="projectRequest.documents" />
          </Typography>
          {expanded || isLoading ? null : (
            <Typography variant="body2">
              <FormattedMessage
                id={
                  attachmentsCount.count === 1
                    ? 'projectRequest.documentsCount.singular'
                    : 'projectRequest.documentsCount.plural'
                }
                values={attachmentsCount}
              />
            </Typography>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {attachments.length > 0 ? (
          <AttachmentsList
            attachments={attachments}
            deleteAttachment={deleteAttachment}
            editable={canEditProjectRequest}
          />
        ) : (
          <NoAttachments />
        )}
        {error ? (
          <FormControl error>
            <FormHelperText>{error}</FormHelperText>
          </FormControl>
        ) : null}
        {canEditProjectRequest ? (
          <NewAttachmentButton
            onClick={onDialogOpen}
            disabled={isLoading}
            hasAttachment={attachments.length > 0}
          />
        ) : null}
        {isDialogOpen ? (
          <FileWebAddressDialog
            open={isDialogOpen}
            onCancel={onDialogClose}
            onOK={onFileUpload}
            enabledSourceUris={enabledSourceUris}
            showWebAddressNameField
            initialValue={null}
            isUploading={isUploading}
            multiple
          />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
DocumentsAccordion.propTypes = {
  attachmentsData: PropTypes.object.isRequired,
  initialIsExpanded: PropTypes.bool
};
export default DocumentsAccordion;
