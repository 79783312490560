import { CircularProgress, DialogContentText } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  DrawerFooter,
  DrawerHeaderComponent,
  VoidBillReasonDetails
} from '~/modules/billing-invoicing/common/components';
import {
  BatchStatus,
  RemoveEntityConfirmationDialog
} from '~/modules/common/components';
import { useLinkProps, useDialogState } from '~/modules/common/hooks';
import { useProgramBySlug } from '~/modules/programs/program/hooks';
import {
  useBillDetails,
  useBillFormState,
  useDeleteLineItemForBill,
  usePutBillHandler,
  usePutBillFormState,
  useUpdateCurrencyForBill,
  useBillTotalProps,
  useLineItems,
  usePutLineItemForBill,
  useBillPermission,
  useBillSubStatus
} from '~/modules/billing-invoicing/bill/hooks';
import {
  useFormOnCancel,
  useProjectReference,
  useAvailableToBillTotals,
  useIsBillDataReflectedOnAvailableToBill,
  useOnBillDataUpdatedOnAvailableToBill,
  useHasBillPermission,
  useClientDefaultData
} from '~/modules/billing-invoicing/common/hooks';
import { useMeContext } from '~/modules/me';
import { BillStatus } from '~/types';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import BillDetails, { BillInfo, BillInfoReadOnly } from '../BillDetails';
import { useEditBillDrawerHandler } from './hooks/useEditBillDrawerHandler';
import CurrencyChangeConfirmationDialog from './CurrencyChangeConfirmationDialog';
import { useTabSummary } from './useTabSummary';

const footerResourceKeys = ({ intl }) => ({
  actionButton: 'addDialog.save',
  deleteButton: 'billDetails.deleteBill',
  removeButton: 'billDetails.removeBill',
  deleteMenu: intl.formatMessage({ id: 'billDetails.deleteBill' })
});

const deleteDialogResourceKeys = {
  header: 'billDetails.deleteDialog.removeBill',
  actionButton: 'billDetails.deleteDialog.removeBill'
};

const useSpinnerStyles = makeStyles(theme => ({
  spinner: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4.5)
  },
  loading: {
    marginTop: '45%',
    marginLeft: '45%'
  }
}));

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: ({ summarizeColumnOptions, editable, standardLineItems }) =>
        editable ||
        (summarizeColumnOptions || []).length <= 3 ||
        !standardLineItems.length
          ? '700px'
          : `${summarizeColumnOptions.length * 60 + 600}px`,
      display: 'block'
    }
  }
}));

export const EditBillDrawer = ({
  id,
  open,
  client,
  clientCurrency,
  onClose,
  queryParams,
  ...rest
}) => {
  const [associateCreditTotal, setAssociateCreditTotal] = useState(0);
  const history = useHistory();
  const [taxTotal, setTaxTotal] = useState(0);
  const me = useMeContext();
  const { isPsaPswatBillingDraftSubStatusEnabled } = me.featureFlags;
  const [summarizeColumnOptions, setSummarizeColumOptions] = useState([
    BILLING_COLUMN_TYPE.PROJECT
  ]);

  const { loading: loadingBillSubStatus, billSubStatus } = useBillSubStatus(
    BillStatus.Draft,
    isPsaPswatBillingDraftSubStatusEnabled
  );
  const { loading, bill = {} } = useBillDetails(
    id,
    setSummarizeColumOptions,
    isPsaPswatBillingDraftSubStatusEnabled
  );
  const { clientDefaultData } = useClientDefaultData({
    client: bill?.client
  });

  const { billingSettings } = clientDefaultData || {};
  const {
    loading: standardLineItemsLoading,
    lineItems: standardLineItems,
    hasMore: standardLineItemsHasMore,
    loadMore: standardLineItemsLoadMore
  } = useLineItems({
    billId: id,
    summarizeColumnOptions,
    filter: { isAdhoc: false }
  });
  const {
    loading: adhocLineItemsLoading,
    lineItems: adhocLineItems,
    hasMore: adhocLineItemsHasMore,
    loadMore: adhocLineItemsLoadMore
  } = useLineItems({
    billId: id,
    summarizeColumnOptions,
    filter: { isAdhoc: true }
  });

  const { billPermission } = useBillPermission({ billId: bill.id });

  const permissions = useHasBillPermission({ bill, billPermission });

  const intl = useIntl();
  const {
    saving,
    setSaving,
    editable,
    setEditable,
    lineItemAdding,
    setLineItemAdding,
    batchState,
    setBatchState
  } = useBillFormState();
  const {
    open: showCurrencyChangeDialog,
    openDialog: openCurrencyChangeDialog,
    closeDialog: closeCurrencyChangeDialog
  } = useDialogState(false);
  const { putBill } = usePutBillHandler();

  const { updateCurrencyForBill } = useUpdateCurrencyForBill({
    billId: id,
    setBatchState
  });

  const formik = usePutBillFormState({
    me,
    bill,
    client,
    clientCurrency,
    putBill,
    setSaving,
    onClose,
    setEditable,
    history,
    standardLineItems,
    adhocLineItems,
    summarizeColumnOptions,
    billingSettings
  });
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    errors,
    isSubmitting
  } = formik;

  const projectSlug = queryParams ? queryParams.get('projectSlug') : null;
  const programSlug = queryParams ? queryParams.get('programSlug') : null;
  const isGlobalBilling = queryParams ? queryParams.get('globalBilling') : null;
  const createAndView = queryParams ? queryParams.get('view') : false;
  const { projectUri } = useProjectReference({
    projectSlug
  });

  const { id: programUri } = useProgramBySlug({
    slug: programSlug
  });

  const { client: { id: clientUri } = {} } = values;
  const { refetchAvailableToBillTotals } = useAvailableToBillTotals({
    clientUri: isGlobalBilling ? null : clientUri,
    projectUri,
    programUri
  });

  const { refetchFilterSummary } = useTabSummary({
    isGlobalBilling,
    projectUri,
    programUri,
    clientUri,
    me
  });

  const { fetchCalculationStatus } = useIsBillDataReflectedOnAvailableToBill();
  const {
    onBillDataUpdatedOnAvailableToBill
  } = useOnBillDataUpdatedOnAvailableToBill({
    billId: id,
    fetchCalculationStatus,
    refetchAvailableToBillTotals,
    refetchFilterSummary
  });

  const {
    putLineItemForBill,
    inProgress,
    setInProgress
  } = usePutLineItemForBill();
  const { deleteLineItemForBill } = useDeleteLineItemForBill({
    billId: id,
    onBillDataUpdatedOnAvailableToBill
  });
  const { onCancel } = useFormOnCancel({
    handleReset,
    onClose,
    saving,
    inProgress
  });
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const {
    standardLineItemsTotal,
    adhocLineItemsTotal,
    billBalanceTotal
  } = useBillTotalProps({
    standardLineItems,
    adhocLineItems,
    values,
    associateCreditTotal,
    taxTotal
  });

  const spinnerClasses = useSpinnerStyles();
  const drawerClasses = useDrawerStyles({
    summarizeColumnOptions,
    editable,
    standardLineItems
  });
  const {
    onDeleteConfirm,
    billDeleteInProgress,
    onCloseRemoveDialog
  } = useEditBillDrawerHandler({
    values,
    createAndView,
    onBillDataUpdatedOnAvailableToBill,
    onCancel,
    closeRemoveDialog
  });
  const isBillUnpaid = useMemo(() => bill.balanceStatus === 'UNALLOCATED', [
    bill
  ]);
  const setToEditMode = useCallback(() => {
    if (!editable) setEditable(!editable);
  }, [editable, setEditable]);

  const { canEditBill, canEditBasicDetailOrDeleteBill } = permissions;
  const allowEditableActions = editable && canEditBill;

  const setToReadOnly = useCallback(() => {
    setEditable(false);
    handleReset();
  }, [handleReset, setEditable]);

  const onBillSubmit = useCallback(() => {
    if (values.initialBillCurrency.symbol !== values.billCurrency.symbol) {
      openCurrencyChangeDialog();

      return;
    }
    handleSubmit(values);
  }, [handleSubmit, openCurrencyChangeDialog, values]);

  const onCurrencyChangeConfirm = useCallback(() => {
    closeCurrencyChangeDialog();
    updateCurrencyForBill({ currencyId: values.billCurrency.id });
    setSaving(true);
  }, [closeCurrencyChangeDialog, setSaving, updateCurrencyForBill, values]);

  const linkProps = useLinkProps({
    linkUrl: `${me.uiRootPath}invoicing2/invoice-snapshot/${values.id}`,
    target: '_blank'
  });

  const isLoading =
    loading ||
    standardLineItemsLoading ||
    adhocLineItemsLoading ||
    loadingBillSubStatus;

  const hasAccessLineItems = useMemo(() => {
    const standardLineItemsCount =
      standardLineItems &&
      standardLineItems.filter(lineItem => lineItem.hasAccessToLineItemProject)
        .length;
    const adhocLineItemsCount =
      adhocLineItems &&
      adhocLineItems.filter(lineItem => lineItem.hasAccessToLineItemProject)
        .length;
    const totalLineItemCount = standardLineItemsCount + adhocLineItemsCount;

    return totalLineItemCount;
  }, [adhocLineItems, standardLineItems]);

  return (
    <>
      <MuiDrawer
        anchor="right"
        open={open}
        onClose={editable ? null : onCancel}
        classes={drawerClasses}
      >
        {!isLoading && (
          <DrawerHeaderComponent
            ariaLabel={intl.formatMessage({ id: 'billDetails.editBill' })}
            values={values}
            onCancel={onCancel}
            canEdit={canEditBasicDetailOrDeleteBill}
            balanceStatus={bill.balanceStatus}
            billStatus={bill.billStatus}
            editable={editable}
            setToEditMode={setToEditMode}
            drawerTitleId="billDetails.drawerTitle"
            billSubStatusList={billSubStatus}
            billSubStatusId={bill.draftSubStatusUri}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isPsaPswatBillingDraftSubStatusEnabled={
              isPsaPswatBillingDraftSubStatusEnabled
            }
          />
        )}
        {isLoading && (
          <CircularProgress
            data-qe-id="editBill_saving"
            size={24}
            className={spinnerClasses.loading}
          />
        )}
        {allowEditableActions ? (
          <BillInfo
            values={values}
            hasClient
            isBillUnpaid={isBillUnpaid}
            errors={errors}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            {...rest}
          />
        ) : (
          <>
            {!isLoading &&
            bill.billStatus === BillStatus.Void &&
            values.voidBillDescription ? (
              <VoidBillReasonDetails
                voidBillDescription={values.voidBillDescription}
              />
            ) : null}
            {!isLoading && (
              <BillInfoReadOnly
                values={values}
                me={me}
                canEdit={canEditBasicDetailOrDeleteBill}
                editable={editable}
                setToEditMode={setToEditMode}
              />
            )}
            {!isLoading && (
              <BillDetails
                setInProgress={setInProgress}
                isLineItemUpdating={inProgress}
                values={values}
                billBalanceTotal={billBalanceTotal}
                isBillUnpaid={isBillUnpaid}
                permissions={permissions}
                lineItemAdding={lineItemAdding}
                setLineItemAdding={setLineItemAdding}
                standardLineItemsTotal={standardLineItemsTotal}
                adhocLineItemsTotal={adhocLineItemsTotal}
                handleSubmit={handleSubmit}
                setSummarizeColumOptions={setSummarizeColumOptions}
                putLineItemForBill={putLineItemForBill}
                deleteLineItemForBill={deleteLineItemForBill}
                setFieldValue={setFieldValue}
                history={history}
                deleteBill={onDeleteConfirm}
                onBillClose={onClose}
                hasAccessLineItems={hasAccessLineItems}
                setAssociateCreditTotal={setAssociateCreditTotal}
                taxTotal={taxTotal}
                setTaxTotal={setTaxTotal}
                onBillDataUpdatedOnAvailableToBill={
                  onBillDataUpdatedOnAvailableToBill
                }
                billStatus={bill.billStatus}
                refetchFilterSummary={refetchFilterSummary}
                standardLineItemsHasMore={standardLineItemsHasMore}
                standardLineItemsLoadMore={standardLineItemsLoadMore}
                standardLineItemsLoading={standardLineItemsLoading}
                adhocLineItemsHasMore={adhocLineItemsHasMore}
                adhocLineItemsLoadMore={adhocLineItemsLoadMore}
                adhocLineItemsLoading={adhocLineItemsLoading}
              />
            )}
          </>
        )}
        {batchState.batchInProgress && (
          <BatchStatus
            batchState={batchState}
            setBatchState={setBatchState}
            onBatchComplete={handleSubmit}
          >
            <CircularProgress
              data-qe-id="editBillDrawer_batchInProgress"
              size={24}
              className={spinnerClasses.spinner}
            />
          </BatchStatus>
        )}
        {!isLoading && (
          <DrawerFooter
            linkProps={linkProps}
            bill={bill}
            balanceTotal={billBalanceTotal}
            showTotal={!editable}
            isSaving={saving}
            canDelete={editable}
            resourceKeys={footerResourceKeys({ intl })}
            onAdd={onBillSubmit}
            onCancel={setToReadOnly}
            onCloseDrawer={onCancel}
            onDelete={openRemoveDialog}
            editable={editable}
            showReadOnlyBillActions={canEditBill}
            createAndView={createAndView}
            refetchAvailableToBillTotals={refetchAvailableToBillTotals}
            billPermission={billPermission}
            summarizeColumnOptions={summarizeColumnOptions}
          />
        )}
      </MuiDrawer>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={onCloseRemoveDialog}
          onConfirm={onDeleteConfirm}
          isRemoving={billDeleteInProgress}
          disabled={billDeleteInProgress}
        >
          <DialogContentText>
            <FormattedMessage id="billDetails.deleteDialog.description" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
      {showCurrencyChangeDialog && (
        <CurrencyChangeConfirmationDialog
          openDialog={showCurrencyChangeDialog}
          onDialogClose={closeCurrencyChangeDialog}
          onConfirmClick={onCurrencyChangeConfirm}
          hasLineItems={
            values.standardLineItems.length > 0 ||
            values.adhocLineItems.length > 0
          }
          billCurrency={values.initialBillCurrency}
          newBillCurrency={values.billCurrency}
        />
      )}
    </>
  );
};

EditBillDrawer.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  projectUri: PropTypes.string,
  projectName: PropTypes.string,
  client: PropTypes.object,
  clientCurrency: PropTypes.object,
  queryParams: PropTypes.object,
  onClose: PropTypes.func
};

export default EditBillDrawer;
