import { useMemo } from 'react';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me/useMeContext';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useGetProjectsQuery } from '~/modules/projects/graphql/useGetProjectsQuery';

export const GET_ALLOCATED_PROJECTS_SLUG = gql`
  query getAllocatedProjectsSlugs(
    $page: Int!
    $pagesize: Int!
    $projectFilter: ProjectFilter2
  ) {
    projects(page: $page, pagesize: $pagesize, projectFilter: $projectFilter) {
      id
      slug
    }
  }
`;

export const useProjectSlugByIdMap = ({ allocatedProjectIds }) => {
  const { userAccessRoles } = useMeContext();

  const enableAllAccess =
    userAccessRoles.includes(USER_ACCESS_ROLE.BILLING_MANAGER) ||
    userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_ADMIN);

  const isInDataAccessLevels = useMemo(
    () =>
      enableAllAccess
        ? undefined
        : [
            'urn:replicon:project-data-access-level:project-management-data-access-scope',
            'urn:replicon:project-data-access-level:project-manager',
            'urn:replicon:project-data-access-level:client-manager',
            'urn:replicon:project-data-access-level:program-manager',
            'urn:replicon:project-data-access-level:portfolio-manager'
          ],
    [enableAllAccess]
  );

  const queryVariables = useMemo(
    () => ({
      page: 1,
      pagesize: allocatedProjectIds?.length,
      projectFilter: {
        projects: allocatedProjectIds,
        isInDataAccessLevels
      }
    }),
    [allocatedProjectIds, isInDataAccessLevels]
  );

  const { data, loading } = useGetProjectsQuery(
    {
      variables: queryVariables,
      fetchPolicy: 'network-only',
      skip: !allocatedProjectIds.length
    },
    GET_ALLOCATED_PROJECTS_SLUG
  );

  return {
    projectSlugByIdMap: (data?.projects || []).reduce((acc, { id, slug }) => {
      acc[id] = slug;

      return acc;
    }, {}),
    loading
  };
};

export default useProjectSlugByIdMap;
