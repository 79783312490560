import {
  useCurrentRoleCostRateQuery,
  useCurrentResourceCostRateQuery
} from '~/types';

const computeRate = ({
  canViewCost,
  initialEstimatedHours,
  initialEstimatedCost
}) =>
  canViewCost && initialEstimatedHours && initialEstimatedCost?.amount
    ? {
        amount: initialEstimatedCost.amount / initialEstimatedHours,
        currency: initialEstimatedCost.currency
      }
    : null;

export const useCurrentRoleRate = ({
  roleId,
  targetCurrencyId,
  canViewCost,
  initialEstimatedHours,
  initialEstimatedCost
}) => {
  const roleRate = computeRate({
    canViewCost,
    initialEstimatedHours,
    initialEstimatedCost
  });
  const shouldQuery = canViewCost && roleRate === null;

  const { data, loading } = useCurrentRoleCostRateQuery({
    variables: {
      roleId,
      targetCurrencyId
    },
    skip: !shouldQuery
  });

  return {
    roleRateLoading: loading,
    roleRate: data?.role?.currentRate || roleRate
  };
};

export const useCurrentResourceRate = ({
  resourceId,
  targetCurrencyId,
  canViewCost,
  initialEstimatedHours,
  initialEstimatedCost
}) => {
  const resourceRate = computeRate({
    canViewCost,
    initialEstimatedHours,
    initialEstimatedCost
  });
  const shouldQuery = canViewCost && resourceRate === null;

  const { data, loading } = useCurrentResourceCostRateQuery({
    variables: {
      page: 1,
      pageSize: 1,
      filter: {
        userIds: [resourceId]
      },
      targetCurrencyId
    },
    skip: !shouldQuery
  });

  return {
    resourceRateLoading: loading,
    resourceRate:
      data?.resourceUsers2[0]?.currentCostRateInTargetCurrency || resourceRate
  };
};
