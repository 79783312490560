import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { OverviewItemTooltip } from '~/modules/common/charts/dashboard';
import {
  getReactMoneyFormatFromMe,
  getReactNumberFormatFromMe
} from '~/modules/common/numbers';
import Financials from '../Financials/Financials';
import useBillingBannerHelper from './hooks/useBillingBannerHelper';

const useStyles = makeStyles(theme => ({
  alert: ({ editable }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: editable ? 'pointer' : 'default',
    padding: '0px 16px !important'
  })
}));

const BillingLimitBanner = ({ financialProps, totalEstimatedAmount }) => {
  const me = useMeContext();
  const moneySettings = getReactMoneyFormatFromMe(me);
  const numberSettings = getReactNumberFormatFromMe(me);
  const { editable } = financialProps;
  const classes = useStyles({ editable });

  const {
    showFinancialEditableCard,
    setShowFinancialEditableCard,
    renderAlert,
    values
  } = useBillingBannerHelper({
    editable,
    totalEstimatedAmount,
    totalEstimatedContractInProjectCurrency:
      financialProps.totalEstimatedContractInProjectCurrency
  });

  return (
    <>
      {renderAlert() && (
        <Alert
          className={classes.alert}
          icon={false}
          severity={renderAlert().severity}
          onClick={renderAlert().onClick}
          size="small"
        >
          <Typography variant="body2">
            <FormattedMessage id={renderAlert().messageId} />
            <OverviewItemTooltip
              currencySymbol={
                totalEstimatedAmount?.currency?.displayText ||
                totalEstimatedAmount?.currency?.symbol
              }
              value={values.amount}
              moneySettings={moneySettings}
              numberSettings={numberSettings}
              intlKey="cost"
              id="projectFinancialsCard.costDescription"
            />
          </Typography>
        </Alert>
      )}
      {editable && showFinancialEditableCard && (
        <Financials
          {...financialProps}
          showFinancialEditableCard={showFinancialEditableCard}
          setShowFinancialEditableCard={setShowFinancialEditableCard}
        />
      )}
    </>
  );
};

BillingLimitBanner.propTypes = {
  totalEstimatedAmount: PropTypes.object,
  financialProps: PropTypes.shape({
    editable: PropTypes.bool,
    totalEstimatedContractInProjectCurrency: PropTypes.object,
    limitIssuedBillsToTCV: PropTypes.bool
  }).isRequired
};

export default BillingLimitBanner;
