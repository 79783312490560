import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CREATE_MASS_EDIT_PROJECT_BATCH_MUTATION = gql`
  mutation CreateMassProjectInfoModificationBatch(
    $projectIds: [String!]!
    $modificationInput: ProjectInfoModificationInput!
  ) {
    createMassProjectInfoModificationBatch(
      projectIds: $projectIds
      modificationInput: $modificationInput
    )
  }
`;

export const useCreateMassEditProjectBatch = ({
  projectIds,
  setBatchState
}) => {
  const [massEditProjectBatch] = useMutation(
    CREATE_MASS_EDIT_PROJECT_BATCH_MUTATION
  );

  return useCallback(
    async input => {
      setBatchState({
        batchId: null,
        batchInProgress: true
      });
      const { data, error } = await massEditProjectBatch({
        variables: {
          projectIds,
          modificationInput: input
        }
      });

      const batchId = data?.createMassProjectInfoModificationBatch;

      setBatchState({
        batchId,
        batchInProgress: !error
      });
    },
    [setBatchState, massEditProjectBatch, projectIds]
  );
};

export default useCreateMassEditProjectBatch;
