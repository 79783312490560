import { Divider, Grid, Box, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useMemo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  FormSection,
  NumberTextField,
  InvoiceTemplateDropdown
} from '~/modules/common/components';
import { SearchableTaxProfileDropdown } from '~/modules/common/components/TaxProfileDropdown';
import { ShowCommentsOnInvoiceCheckBox } from '~/modules/billing-invoicing/common/components';
import {
  useGetCurrenciesQuery,
  useIsBreakpointDown
} from '~/modules/common/hooks';
import { getError, hasError } from '~/util';
import { useTaxProfiles } from '~/modules/clients/client/ClientInfo/dialogs/billing/hooks';
import { CurrencySelect } from '~/modules/clients/client/ClientInfo/dialogs/billing/InvoiceCurrencySelect';
import { useMeContext } from '~/modules/me';
import { useFormOnChangeHandlers, useStyles, useDropDownStyles } from './hooks';
import { BillLineItemsByDropdown } from './BillLineItemsByDropdown';
import TaxProfileDetails from './TaxProfileDetails';
import { BillFrequencyDropdown } from './BillFrequencyDropdown';
import { ConsolidateBillsByDropdown } from './ConsolidateBillsByDropdown';

export const highlightTarget = event => event.target.select();

const BillingCardEditable = ({
  billingCardResourceKeys,
  isSubmitting,
  errors
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const me = useMeContext();
  const { isPsaPrpAddProjectColumnOnAvailableToBillEnabled } = me.featureFlags;
  const label = resourceKey => <FormattedMessage id={resourceKey} />;
  const classes = useStyles();
  const dropDownClasses = useDropDownStyles();

  const { currencies } = useGetCurrenciesQuery();
  const [taxProfileSearchTerm, setTaxProfileSearchTerm] = useState('');
  const handleInputChange = useCallback(
    (_, v) => setTaxProfileSearchTerm(v),
    []
  );
  const { loading, taxProfiles } = useTaxProfiles({ taxProfileSearchTerm });
  const paymentTermAdornment = useMemo(
    () => (
      <span className={classes.net}>
        {label(billingCardResourceKeys.paymentTermNet)}
      </span>
    ),
    [billingCardResourceKeys.paymentTermNet, classes.net]
  );
  const inputRefCallback = useCallback(
    errorId => inputRef => {
      if (isSubmitting && hasError(errors, errorId) && inputRef)
        inputRef.focus();
    },
    [isSubmitting, errors]
  );

  const { values, setFieldValue } = useFormikContext();

  const {
    defaultInvoicePaymentTerm,
    billLineItemsBy,
    description,
    internalNotes,
    showComments,
    invoiceCurrency,
    taxProfile,
    invoiceTemplate,
    poNumber,
    context,
    billFrequency,
    billFrequencyAbsoluteDate,
    consolidateBillsBy
  } = values;

  const {
    onPaymentTermChange,
    onBillingCurrencyChange,
    onTaxProfileChange,
    onInvoiceTemplateChange,
    onDescriptionChange,
    onInternalNotesChange,
    onShowCommentsOnInvoiceChange,
    onPONumberChange,
    onBillFrequencyChange,
    onBillFrequencyAbsoluteDateChange,
    onConsolidateBillsByChange
  } = useFormOnChangeHandlers({
    setFieldValue,
    values,
    currencies
  });

  const showConsolidateBillsByDropdown =
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled && consolidateBillsBy;

  const showBillFrequencyDropdown =
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled && context !== 'project';

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={label(billingCardResourceKeys.schedule)}
          />
        </Grid>
        {showBillFrequencyDropdown ? (
          <Grid item xs={12} md={8}>
            <BillFrequencyDropdown
              value={billFrequency}
              billFrequencyAbsoluteDate={billFrequencyAbsoluteDate}
              onFrequencyChange={onBillFrequencyChange}
              onBillFrequencyAbsoluteDateChange={
                onBillFrequencyAbsoluteDateChange
              }
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sm={showBillFrequencyDropdown ? 12 : 4}
          md={4}
          data-qe-id="PaymentTermDropdown"
        >
          <NumberTextField
            id="paymentTerm"
            variant="filled"
            fullWidth
            label={label(billingCardResourceKeys.paymentTerm)}
            startAdornment={paymentTermAdornment}
            min={0}
            max={25000}
            step={1}
            onChange={onPaymentTermChange}
            value={defaultInvoicePaymentTerm}
          />
        </Grid>
        {!isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
          <Grid item xs={12} sm={7}>
            <ShowCommentsOnInvoiceCheckBox
              showCommentsLabel={billingCardResourceKeys.showComments}
              showComments={showComments}
              onShowCommentsOnInvoiceChange={onShowCommentsOnInvoiceChange}
              isReadOnly={false}
              dataQeId="EditableShowCommentsOnInvoice"
            />
          </Grid>
        ) : null}
        <Divider className={classes.divider} />
        <Grid item xs={12} sm={12}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={label(billingCardResourceKeys.structure)}
          />
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
        {showConsolidateBillsByDropdown ? (
          <Grid item xs={12} md={4}>
            <ConsolidateBillsByDropdown
              value={consolidateBillsBy}
              onChange={onConsolidateBillsByChange}
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          md={isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? 8 : 12}
        >
          <BillLineItemsByDropdown
            billLineItemOptions={billLineItemsBy}
            setFieldValue={setFieldValue}
          />
        </Grid>
        {isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
          <Grid item xs={12}>
            <ShowCommentsOnInvoiceCheckBox
              variant="body2"
              showCommentsLabel={billingCardResourceKeys.showComments}
              showComments={showComments}
              onShowCommentsOnInvoiceChange={onShowCommentsOnInvoiceChange}
              isReadOnly={false}
              labelClassName={classes.showCommentLabel}
              dataQeId="EditableShowCommentsOnInvoice"
            />
          </Grid>
        ) : null}
        <Divider className={classes.divider} />
        <Grid item xs={12} sm={12}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={label(billingCardResourceKeys.defaults)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencySelect
            currencies={currencies}
            value={invoiceCurrency}
            onChange={onBillingCurrencyChange}
          />
        </Grid>

        <Grid item xs={12} sm={4} data-qe-id="TaxProfileDropdown">
          <SearchableTaxProfileDropdown
            classes={dropDownClasses}
            loading={loading}
            options={taxProfiles}
            onChange={onTaxProfileChange}
            label={label(billingCardResourceKeys.taxProfile)}
            value={taxProfile}
            onInputChange={handleInputChange}
          />

          {taxProfile ? <TaxProfileDetails taxProfile={taxProfile} /> : null}
        </Grid>
        {context === 'project' ? (
          <Grid item xs={12} sm={4}>
            <TextField
              id="bill.poNumber"
              variant="filled"
              fullWidth
              inputRef={inputRefCallback('poNumber')}
              label={label(billingCardResourceKeys.poNumber)}
              value={poNumber}
              onChange={onPONumberChange}
              error={hasError(errors, 'poNumber')}
              helperText={getError(errors, 'poNumber')}
              data-qe-id="billDetails.poNumber"
            />
          </Grid>
        ) : null}
        <Box width="100%" />
        <Grid item xs={12} sm={4} data-qe-id="InvoiceTemplateDropdown">
          <InvoiceTemplateDropdown
            value={invoiceTemplate}
            onChange={onInvoiceTemplateChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="bill.description"
            variant="filled"
            fullWidth
            inputRef={inputRefCallback('description')}
            label={label(billingCardResourceKeys.description)}
            value={description ?? ''}
            error={hasError(errors, 'description')}
            helperText={getError(errors, 'description')}
            onChange={onDescriptionChange}
            data-qe-id="Description"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="bill.internalNotes"
            variant="filled"
            multiline
            maxrows={isMobile ? 2 : 3}
            fullWidth
            inputRef={inputRefCallback('internalNotes')}
            label={label(billingCardResourceKeys.internalNotes)}
            value={internalNotes ?? ''}
            onChange={onInternalNotesChange}
            data-qe-id="InternalNotes"
          />
        </Grid>
      </Grid>
    </>
  );
};

BillingCardEditable.propTypes = {
  billingCardResourceKeys: PropTypes.any,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object
};

export default BillingCardEditable;
