import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';
import { HiringStatus, ResourceRequestStatus } from '~/types';

export const getSourceMetadata = ({
  resourceRequest,
  isPsaDtmRequisitionEnabled = false,
  hiringStatus = HiringStatus.HiringWithdrawn
}) => {
  if (!isPsaDtmRequisitionEnabled) {
    return resourceRequest.sourceMetadata;
  }

  if (resourceRequest.requestStatus !== ResourceRequestStatus.Tobehired) {
    return resourceRequest.sourceMetadata;
  }

  return {
    ...resourceRequest.sourceMetadata,
    hiringStatus
  };
};

export const MARK_REQUEST_WITH_HIRING_SUBSTATUS = gql`
  mutation MarkToBeHiredResourceRequestWithHiringRequestedSubstatus(
    $input: MarkToBeHiredResourceRequestWithHiringRequestedSubStatusInput!
  ) {
    markToBeHiredResourceRequestWithHiringRequestedSubStatus(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
`;

export const useMarkToBeHiredResourceRequestWithHiringRequestedSubStatus = () => {
  const [
    markToBeHiredResourceRequestWithHiringRequestedSubStatus
  ] = useMutation(MARK_REQUEST_WITH_HIRING_SUBSTATUS);

  const onMarkToBeHiredResourceRequestWithHiringRequestedSubStatus = useCallback(
    ({ resourceRequest, hiringStatus }) =>
      markToBeHiredResourceRequestWithHiringRequestedSubStatus({
        variables: {
          input: {
            resourceRequestId: resourceRequest.id,
            hiringStatus
          }
        },
        context: {
          debounceKey: `${resourceRequest.id}_hiring_status`,
          debounceTimeout: 100
        },
        optimisticResponse: {
          __typename: 'Mutation',
          markToBeHiredResourceRequestWithHiringRequestedSubStatus: {
            __typename:
              'MarkToBeHiredResourceRequestWithHiringRequestedSubStatusResult',
            isOptimistic: true,
            resourceRequest: {
              ...resourceRequest,
              sourceMetadata: {
                ...resourceRequest.sourceMetadata,
                hiringStatus,
                __typename: 'SourceMetadata'
              },
              __typename: 'ResourceRequest'
            }
          }
        }
      }),
    [markToBeHiredResourceRequestWithHiringRequestedSubStatus]
  );

  return {
    onMarkToBeHiredResourceRequestWithHiringRequestedSubStatus
  };
};

export default useMarkToBeHiredResourceRequestWithHiringRequestedSubStatus;
