import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import { hasPermission } from '~/modules/common/permissions/useHasPermission';
import useResumeParsingProcessingSubscription from './useResumeParsingProcessingSubscription';
import useStyles from './useStyles';

const UserResumeParsingProcessedAlert = () => {
  const classes = useStyles();
  const { userAccessRoles, permissionsMap, featureFlags } = useMeContext();
  const isMobile = useIsBreakpointDown('sm');
  const { userSlug } = useResumeParsingProcessingSubscription({
    skipSubscription:
      !featureFlags.isPsaPraaResourceMatchingEnabled ||
      isMobile ||
      !userAccessRoles.some(
        r => r === 'urn:replicon:user-access-role:resource-manager'
      ) ||
      !hasPermission(permissionsMap)({
        actionUri: 'urn:replicon:user-action:view-user',
        dataAccessLevelUri:
          'urn:replicon:user-data-access-level:resource-manager'
      })
  });

  const { open, closeDialog, openDialog } = useDialogState();

  useEffect(() => {
    if (userSlug) openDialog();
  }, [openDialog, userSlug]);

  if (open) {
    return (
      <Alert severity="info" onClose={closeDialog} classes={classes}>
        <AlertTitle>
          <FormattedMessage id="userResumeParsingProcessedAlert.userProfile" />
        </AlertTitle>
        <div className={classes.container}>
          <FormattedMessage id="userResumeParsingProcessedAlert.description" />
          <Button
            href={`/resource-pool/${userSlug}?edit=true`}
            color="primary"
            className={classes.button}
          >
            <FormattedMessage id="userResumeParsingProcessedAlert.viewSuggestions" />
          </Button>
        </div>
      </Alert>
    );
  }

  return <></>;
};

export default UserResumeParsingProcessedAlert;
