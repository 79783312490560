import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_OTHER_PROJECT_ALLOCATION_SUMMARY_QUERY = gql`
  query GetOtherProjectAllocationSummaryForUsers(
    $projectId: String!
    $input: OtherProjectAllocationSummaryForUsersInput!
  ) {
    project(projectId: $projectId) {
      id
      otherProjectAllocationSummaryForUsers(input: $input) {
        user {
          id
          displayText
        }
        periods {
          dateRange {
            startDate: startDate2
            endDate: endDate2
          }
          totalHours
        }
      }
    }
  }
`;

export const useOtherProjectAllocationSummaryForUsers = ({
  userIds,
  dateRange,
  periodResolution,
  projectId,
  loadingUsers,
  skip = false
}) => {
  const { data, loading } = useQuery(
    GET_OTHER_PROJECT_ALLOCATION_SUMMARY_QUERY,
    {
      variables: {
        input: {
          userUris: userIds,
          periodResolution,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        projectId
      },
      skip
    }
  );

  const otherProjectAllocationSummaryForUsers =
    data?.project?.otherProjectAllocationSummaryForUsers || [];

  const otherProjectAllocationSummaryByPeriodMap = otherProjectAllocationSummaryForUsers.reduce(
    (acc, { user, periods }) => {
      acc[user.id] = periods.reduce((periodAcc, period) => {
        const { dateRange: periodDateRange, totalHours } = period;

        return {
          ...periodAcc,
          [periodDateRange.startDate]: totalHours
        };
      }, {});

      return acc;
    },
    {}
  );

  return {
    otherProjectAllocationSummaryByPeriodMap,
    loading
  };
};

export default useOtherProjectAllocationSummaryForUsers;
